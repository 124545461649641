<div class='h-100 w-100 p-4'>
    <div class='btn btn-light border' type="button" (click)='previous()'>
        <span class='text-uppercase small'><i aria-hidden='true' class="text-muted far fa-arrow-to-left mr-1"></i>
            previous</span>
    </div>
    <div class='my-4'>
        <app-spinner *ngIf='!getWorkflows()'></app-spinner>

        <span  *ngIf='getWorkflows() && getWorkflows().length'  ><span class='font-weight-bold'>Select</span> a solution to run on this project.</span>
    </div>
    <div>
        <app-available-workflow *ngFor="let workflow of getWorkflows()" [filegroupTypes]='_.toArray(dataTypes)' [filegroupId]='filegroupId' (workflowSelected)='next($event)'
            [canSelect]='true' [workflowId]='workflow.workflow_id' [subjects]='subjects'
            [selected]='workflow.workflow_id === getSelectedWorkflowId()'>
        </app-available-workflow>
        <span class='my-4 font-weight-bold' *ngIf='getWorkflows() && !getWorkflows().length'>No solution are available for this project.</span>
        <div class='w-100 margin'> </div>
    </div>

</div>