import { Component, Input, OnInit } from '@angular/core';
import { FileManager, FileType } from 'src/app/api/file-manager.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment'

import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Status } from 'src/app/shared/helpers/status';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';
import { DeletedFilesManagerService } from 'src/app/api/deleted-files-manager.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { Features } from 'src/environments/features';
import { availableFeatures } from 'src/environments/environment';
@Component({
  selector: 'file',
  styleUrls: ['../../../shared/layout/managers.scss'],
  templateUrl: './file.component.html',
})
export class FileComponent implements OnInit {
  @Input() item: any;
  manager;
  moment = moment;
  TabsName = TabsName;
  _ = _;
  FileType = FileType;
  PagesName = PagesName
  @Input() selected;
  public octet_stream: any = '';
  public iconClass: string[] = ['far fa-circle'];
  public iconText = '';
  Features = Features;
  availableFeatures = availableFeatures
  bulkSelected;
  Status = Status
  isRecent = false
  constructor(
    public fileManager: FileManager,
    public _DomSanitizer: DomSanitizer,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public myFilesManager: MyFilesManagerService,
    public sharedFilesManager: SharedFilesManagerService,
    public deletedFilesManager: DeletedFilesManagerService,
  ) { }
  ngOnInit() {
    if (this.fileManager.viewTab === TabsName.DeletedFiles) {
      this.manager = this.deletedFilesManager
    } else if (this.fileManager.viewTab === TabsName.SharedFiles) {
      this.manager = this.sharedFilesManager
    } else if (this.fileManager.viewTab === TabsName.MyFiles) {
      this.manager = this.myFilesManager
    }
    this.isRecent = this.checkIfRecent(60 * 10)
    if (this.isRecent){
      setInterval(() =>{
        this.isRecent = this.checkIfRecent(60 * 10)
      },60)
    }
  }
  checkIfRecent(value){
    return (moment().unix() - this.item.date_of_creation) < value
  }
  hasMetaData = () => {
    let hasMetaData;
    if (this.item.metadata && Object.keys(this.item.metadata).length > 0) {
      hasMetaData = true
    } else {
      hasMetaData = false
    }
    return hasMetaData
  }
  activateQualityMode = (id, title) => {
    _.set(this.fileManager, "quality", { id: id, title: title })
  }

  getStatus() {
    return _.get(this, 'item.status', null)
  }
  getCanDownload(){
    return _.get(this, 'item.capabilities.can_download',false)
  }
  getCanTrash(){
    return _.get(this, 'item.capabilities.can_trash',false)
  }
  setIconText(text) {
    this.iconText = text;
  }
  unSetIconText() {
    this.iconText = '';
  }
  getTooltip(name?){
    if (name && name === 'download'){
      if (this.manager.canDownload.value){
        return ''
      } else{
        return "You don't have the permission to download"

      }
    }
    else if (name && name === 'delete'){
    return (!_.includes(availableFeatures, Features.DeleteFile) || !this.fileManager.deleteFilePermission) ? "You don't have the permission to delete": ""
    }
  }

}
