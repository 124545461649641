<div class='p-3 w-100 d-flex align-items-center justify-content-center flex-nowrap sidebar-button-container'
  [class.closed]="!getIsSideBarWide()" [class.justify-content-center]='!getIsSideBarWide()'
  [class.justify-content-start]='getIsSideBarWide()' (click)='redirect()' (mouseenter)="setIsHovered(true)"
  (mouseleave)="setIsHovered(false)" [class.hovered]='getIsHovered()' [class.active]='getIsActive()'>
  <div class='icon-container'>
    <i *ngIf='getIconClass()' [class.white]='!(getIsHovered() || getIsActive()) ' [class.text-secondary]='!getIsHovered() && isPurple && unreadNotificationNumber.value === 0 '
      [class.text-primary]='getIsHovered() || getIsActive()' [ngClass]='getIconClass()' aria-hidden="true"></i>
    <span *ngIf='unreadNotificationNumber.value > 0' class="notif d-flex flex-column font-weight-bold align-items-center"><span>{{unreadNotificationNumber.value}}</span></span>
  </div>
  <p *ngIf="getIsSideBarWide() && getTitle()">{{getTitle()}}</p>
  <ng-content></ng-content>
</div>
