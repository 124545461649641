
export const PagesName = {
    FileManager: 'file-manager',
    Account: 'account',
    Login: 'login',
    ProjectManager: 'project-manager',
    QualityOverview: 'quality',
    ProjectOverview: 'overview',
    TeamsManager: 'teams-manager',
    Workflow: 'workflow',
    WorkflowEditor: 'workflow-editor',
    WorkflowDebugger: 'workflow-debugger',
    WorflowCreateMethod: 'create-method',
    Home: 'home',
    Notifications: 'notifications',
    ProjectCreation: 'project-creation',
    DefineProject: 'define-project',
    ChooseWorkflow: 'choose-workflows',
    ConfigureWorkflow: 'configure-workflows',
    ChooseDiseases: 'choose-diseases',
    ChooseDataTypes: 'choose-data-types',
    WorkflowInProgress: 'workflow-in-progress',
    UserCreation: 'user-creation',
    UserNotifications: 'user-notifications',
    NewAccount : 'new-account',
    DevTest: 'dev-test'

};

