import { Component, Input, OnInit } from '@angular/core';
import { TeamsService } from '../../teams.service';
import { AdditionalTypes, InputJson, IsRequired, TeamIdHelp } from 'src/app/shared/helpers/formattedInput';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-team-subscription-form',
  templateUrl: './team-subscription-form.component.html',
  styleUrls: []
})
export class TeamSubscriptionFormComponent {
  @Input() team;
  teamIdFieldObject: InputJson = {
    formControlName: 'subscriptionId',
    type: AdditionalTypes.ShortText,
    placeholder: "",
    iconClassName: 'far fa-info-circle',
    validations: [TeamIdHelp],
    label : 'Enter a subscription ID',
    default_value : ''
  }
  valueUpdate = new BehaviorSubject<any>(null);
  constructor(public teamsService: TeamsService) {
  }

  ngOnInit(){
    this.valueUpdate.next(_.get(this.team, 'subscription_id'))
  }
  getTeamSubscriptionForm() {
    return this.teamsService.teamSubscriptionForm || null
  }
  get subscriptionId() {
    return this.teamsService.teamSubscriptionForm.get('subscriptionId');
  }
}
