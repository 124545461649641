import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss']
})
export class SidebarButtonComponent implements OnInit, OnDestroy {
  @Input() isSideBarWidening?
  @Input() isSideBarWide?
  @Input() goToArray?
  @Input() unhoveredIconClass?
  @Input() hoveredIconClass?
  @Input() isActiveIconClass?
  @Input() isActive?
  @Input() notifications? : BehaviorSubject<any>
  notificationSubscription;
  @Input() title?
  _ = _
  @Input() isPurple?= false
  isHovered = false;

  unreadNotificationNumber = new BehaviorSubject<Number>(0)

  constructor(private router: Router) { }

  ngOnInit() {
    if (this.notifications) {
      this.notificationSubscription = this.notifications.subscribe((notifications) => {
        this.updateNotificationsNumber(notifications)
      })
    }
  }
  ngOnDestroy() {
    if(this.notificationSubscription)
    this.notificationSubscription.unsubscribe()
  }
  getIconClass() {
    if (this.isHovered || this.isActive) {
      return this.getHoveredIconClass()
    } else {
      return this.getUnhoveredIconClass()
    }
  }
  setIsHovered(value) {
    _.set(this, 'isHovered', value)
  }

  hideReadNotifications = (notifications) => {
    return _.reject(notifications, (o) => { return o.read})
  }
  updateNotificationsNumber(notifications) {
    let unreadNotifs = this.hideReadNotifications(notifications)
    this.unreadNotificationNumber.next(Math.min(unreadNotifs.length, 99))
  }

  getIsHovered() {
    return _.get(this, 'isHovered', false)
  }
  getIsSideBarWide() {
    return _.get(this, 'isSideBarWide', false)
  }
  getIsSideBarWidening() {
    return _.get(this, 'isSideBarWidening', false)
  }
  getIsActive() {
    return _.get(this, 'isActive', false)
  }
  getGoToArray() {
    return _.get(this, 'goToArray', false)
  }
  getUnhoveredIconClass() {
    return _.get(this, 'unhoveredIconClass', '')
  }
  getHoveredIconClass() {
    return _.get(this, 'hoveredIconClass', '')
  }
  getActiveIconClass() {
    return _.get(this, 'activeIconClass', '')
  }
  getTitle() {
    return _.get(this, 'title', false)
  }
  redirect() {
    this.router.navigate(this.getGoToArray());
  }
}
