import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { ItemType } from 'src/app/api/share.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { DeletedFilesManagerService } from 'src/app/api/deleted-files-manager.service';
import { FileManager, SortDirection, SortType } from 'src/app/api/file-manager.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-deleted-files',
  templateUrl: './deleted-files.component.html',
  styleUrls: ['./deleted-files.component.scss', '../../../shared/layout/managers.scss']
})
export class DeletedFilesComponent implements OnInit, OnDestroy {
  TabsName = TabsName;
  ItemType = ItemType;
  SortDirection = SortDirection;
  SortType = SortType;
  timer;
  subscription
  constructor(private fileManager : FileManager, public deletedFilesManager: DeletedFilesManagerService, public layout: LayoutOrganizationService) { }

  ngOnInit(): void {
    this.deletedFilesManager.listContent()
    // this.timer = setInterval(() => {
    //   this.deletedFilesManager.listContent()
    // }, 60000)
    if (_.get(this.fileManager,'countrySelected'))
      this.subscription = this.fileManager.countrySelected.subscribe((res)=>{
        if (res && this.fileManager.viewTab === TabsName.DeletedFiles){
          this.deletedFilesManager.resetFolder()
        }
      })
    }
    

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    if(this.subscription){
      this.subscription.unsubscribe()
    }
  }

  trackByFn(index, item) {
    return item.id
  }

  pageChanged() {
    setTimeout(() => {
      this.deletedFilesManager.listContent()
    }, 1)
  }

}
