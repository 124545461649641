import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { FileManager } from 'src/app/api/file-manager.service';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';

@Component({
  selector: 'app-metadata-upload-form',
  templateUrl: './metadata-upload-form.component.html',
  styleUrls: ['../../../shared/styles/forms.component.scss'],
})
export class MetadataUploadFormComponent implements OnInit {
  manager;
  TabsName = TabsName
  constructor(public fileManager: FileManager, public myFilesManagerService: MyFilesManagerService, public sharedFilesManagerService: SharedFilesManagerService) { }

  ngOnInit() {
    if (this.fileManager.viewTab === TabsName.SharedFiles) {
      this.manager = this.sharedFilesManagerService
    } else if (this.fileManager.viewTab === TabsName.MyFiles) {
      this.manager = this.myFilesManagerService
    }
    this.manager.metadataUploadForm = new FormGroup({
      metadataInput: new FormControl(null, [
        Validators.required,
        this.extensionValidator,
      ]),
    });
  }
  getMetadata(event) {
    this.manager.metadataToUpload = event.target.files[0];
  }
  isCsv = (name) => {
    return !!name.match(/.csv$/i);
  };
  isJson = (name) => {
    return !!name.match(/.json$/i);
  };
  extensionValidator = (control: FormControl): ValidationErrors | null => {
    if (control.value) {
      return this.isCsv(control.value) || this.isJson(control.value)
        ? null
        : { extensionMustBeCsvOrJson: true };
    } else {
      return null;
    }
  };

  get metadataInput() {
    return this.manager.metadataUploadForm.get('metadataInput');
  }
}
