import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { LocalStorage } from 'ngx-webstorage';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { availableFeatures, environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { ProjectsService } from 'src/app/api/projects.service';
import { FileManager } from 'src/app/api/file-manager.service';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { Features } from 'src/environments/features';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';

// This interceptor adds the access token to every http request but the login one.
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  session;
  user;

  public basePath = environment.apiBasePath ? environment.apiBasePath : 'http://localhost:8081';

  constructor(
    private groupsManagerService: GroupsManagerService,
    private localService: LocalService,
    private http: HttpClient,
    private fileManager: FileManager,
    private projectsService: ProjectsService,
    private projectsManagerService: ProjectManagerService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let finalRequest = req.clone({ withCredentials: true });
    this.session = this.localService.getFromLocalStorage('session');
    this.user = this.localService.getFromLocalStorage('user');

    if (this.session && !req.url.includes('auth/activate')) {
      let finalURL = this.session.apiPath ? req.url.replace(environment.apiBasePath, this.session.apiPath) : req.url;
      finalRequest = req.clone({
        url: finalURL,
      });
      if (!req.headers.has('X-Access-Token') && this.session.accessToken) {
        finalRequest = req.clone({
          headers: req.headers.append('X-Access-Token', this.session.accessToken),
          url: finalURL,
        });
      }
      const concernFileSystem =
        !req.url.includes('projects') &&
        !req.url.includes('filegroups') &&
        (req.url.includes('fs') || req.url.includes('dashboard-genomic')) &&
        !req.url.includes('usergroups');
      if (
        _.includes(availableFeatures, Features.MultiCountry) &&
        concernFileSystem &&
        this.localService.getFromLocalStorage('country', 'access_token') &&
        this.localService.getFromLocalStorage('country', 'url')
      ) {
        finalRequest = req.clone({
          headers: req.headers.append('X-Access-Token', this.localService.getFromLocalStorage('country', 'access_token')),
          url: this.localService.getRegionalizedURL(finalURL),
        });
      }
      const concernProjects =
        req.url.includes('fs') && req.url.includes('projects') && !req.url.includes('filegroups') && !req.url.includes('usergroups');
      if (_.includes(availableFeatures, Features.MultiCountry) && concernProjects) {
        if (environment.name === 'prod' && req.url.substr(req.url.length - 9) === '/projects') {
          finalRequest = req.clone();
        } else {
          const country = this.projectsService.getProjectStorageCountry();
          if (country && this.localService.getSpecificCountryAccessToken(country) && this.localService.getSpecificCountryUrl(country)) {
            if (country != 'FR') {
              finalURL = finalURL.replace('/ca/', '/').replace('/fr/', '/');
            }

            finalRequest = req.clone({
              headers: req.headers.set('X-Access-Token', this.localService.getSpecificCountryAccessToken(country)),
              url: this.localService.getRegionalizedURL(finalURL, country),
            });
          }
        }
      }
      const concernSearch = req.url.includes('search');
      if (_.includes(availableFeatures, Features.MultiCountry) && concernSearch) {
        const country = this.projectsService.getProjectStorageCountry();
        if (country && this.localService.getSpecificCountryAccessToken(country) && this.localService.getSpecificCountryUrl(country)) {
          finalRequest = req.clone({
            headers: req.headers.append('X-Access-Token', this.localService.getSpecificCountryAccessToken(country)),
            url: this.localService.getRegionalizedURL(finalURL, country),
          });
        }
      }
      const concernFilegroups = req.url.includes('fs') && req.url.includes('filegroups') && !req.url.includes('usergroups');
      if (_.includes(availableFeatures, Features.MultiCountry) && concernFilegroups) {
        const country = this.groupsManagerService.getFilegroupsStorageCountry();
        if (country && this.localService.getSpecificCountryAccessToken(country) && this.localService.getSpecificCountryUrl(country)) {
          finalRequest = req.clone({
            headers: req.headers.append('X-Access-Token', this.localService.getSpecificCountryAccessToken(country)),
            url: this.localService.getRegionalizedURL(finalURL, country),
          });
        }
      }

      if (req.url.includes('/dashboard/') || req.url.includes('/dashboard-interactions/')) {
        const country = this.projectsService.getProjectStorageCountry();
        if (country && this.localService.getSpecificCountryAccessToken(country) && this.localService.getSpecificCountryUrl(country)) {
          if (country != 'FR') {
            finalURL = finalURL.replace('/ca/', '/').replace('/fr/', '/');
          }

          finalRequest = req.clone({
            headers: req.headers.set('X-Access-Token', this.localService.getSpecificCountryAccessToken(country)),
            url: this.localService.getRegionalizedURL(finalURL, country),
          });
        }
      } else if (
        req.url.includes('/mas/workflows') ||
        (req.url.includes('/mas/teams') && window.location.href.includes('define-project')) ||
        req.url.includes('/mas/methods') ||
        req.url.includes('/mas/notifs') ||
        window.location.href.includes('/workflow')
      ) {
        if (!_.isEmpty(_.get(this.user, 'external_user_ids.FR'))) {
          finalRequest = req.clone({
            headers: req.headers.append('X-Access-Token', this.localService.getSpecificCountryAccessToken('FR')),
            url: this.localService.getRegionalizedURL(finalURL.replace('/ca', ''), 'FR'),
          });
        }
      }
    }

    return next.handle(finalRequest).pipe(
      catchError((err, caught: Observable<HttpEvent<any>>) => {
        if (err instanceof HttpErrorResponse && err.status === 403) {
          if (err.error.message === 'invalid access token') {
            localStorage.clear();
            window.location.reload();
          }
          return throwError(err);
        }
        throw err;
      })
    );
  }
}
