import { Component, OnInit } from '@angular/core';
import { ProjectsService } from '../../api/projects.service';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { DashboardService } from 'src/app/api/dashboard.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { TimeAgoPipe } from 'src/app/mims-ui-lib/core/pipes/time-ago.pipe';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Status } from 'src/app/shared/helpers/status';

@Component({
  selector: 'project-overview',
  styleUrls: ['./project-overview.component.scss'],
  templateUrl: './project-overview.component.html',
})
export class ProjectOverviewComponent implements OnInit {
  TimeAgoPipe = TimeAgoPipe;
  PagesName = PagesName;
  tabIndex = 0;
  projectId = undefined;
  currentInstanceIndex = 0;
  showPopSegLegend = false;
  Status = Status;
  project = new BehaviorSubject<any>(undefined);
  _ = _;
  legendfile1 = new BehaviorSubject<any>(null);
  legendfile2 = new BehaviorSubject<any>(null);
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    public projectManagerService: ProjectManagerService,
    public dashboardService: DashboardService,
    public layout: LayoutOrganizationService
  ) {
    this.route.params.subscribe((params) => {
      if (params['id'] && params['country']) {
        this.projectId = params['id'];
        this.projectManagerService.setProjectStorageCountry(params['country']);
      }
    });
  }

  handleTabSelection(project, event) {
    if (event === undefined) {
      return;
    }
    this.tabIndex = event;
  }
  handleInstanceSelection(project, event) {
    this.currentInstanceIndex = _.findIndex(this.getFormattedInstances(project), (o) => {
      return o.value === event.value;
    });
    this.tabIndex = 0;
  }

  getTitle(project) {
    return _.get(project, 'value.title', '');
  }
  getDateOfCreation(project) {
    return moment.unix(_.get(project, 'value.date_of_creation', 0));
  }
  getCanExecute(project) {
    return _.get(project, 'value.capabilities.can_execute', false);
  }

  retrieveProject() {
    this.projectsService
      .projectGet(this.projectId)
      .pipe(
        map((res) => {
          let formattedInstances = this.formatInstances(
            _.get(res, 'instances').sort((a, b) => {
              return b.date_of_creation - a.date_of_creation;
            })
          );
          _.set(res, 'formattedInstances', formattedInstances);
          return res;
        })
      )
      .subscribe((res) => {
        this.project.next(res);
        this.checkForPopSegLegends(this.project, this.currentInstanceIndex);
        this.tabIndex = 0;
      });
  }

  checkForPopSegLegends(project, currentInstanceIndex) {
    if (this.getWorkflowId(project, currentInstanceIndex))
      this.projectsService.getWorkflow(this.getWorkflowId(project, currentInstanceIndex)).subscribe((res) => {
        if (_.includes(_.get(res, 'name'), 'popseg')) {
          this.showPopSegLegend = true;
          let fileURL1 = '../../../assets/docs/PopSeg_Solution/dashboard_1.md';
          let fileURL2 = '../../../assets/docs/PopSeg_Solution/dashboard_2.md';
          fetch(fileURL1)
            .then((response) => {
              return response.text();
            })
            .then((data) => {
              this.legendfile1.next(data);
            });
          fetch(fileURL2)
            .then((response) => {
              return response.text();
            })
            .then((data) => {
              this.legendfile2.next(data);
            });
        }
      });
  }
  ngOnInit() {
    console.log(this.tabIndex);
    this.retrieveProject();
  }
  formatInstances = (instances) => {
    let result = [];
    instances.forEach((el) => {
      let array = [];
      if (_.get(el, 'dashboard_files'))
        for (const [key, value] of Object.entries(_.get(el, 'dashboard_files'))) {
          array.push({ tabName: key, dashboardId: value });
        }
      result.push({
        label: moment.unix(_.get(el, 'date_of_creation', 0)).format('YYYY/MM/DD - HH:mm'),
        value: array,
        extra: { workflowId: _.get(el, 'workflow_id'), endTime: _.get(el, 'end_time', 0) * 1000, status: _.get(el, 'status') },
      });
    });
    return result;
  };

  getTabNames(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'value').map((tab) => tab.tabName);
  }
  getLinks(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'value').map((tab) => tab.dashboardId);
  }
  getWorkflowId(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'extra.workflowId');
  }
  getInstance(project, currentInstanceIndex) {
    return _.nth(this.getFormattedInstances(project), currentInstanceIndex);
  }
  getFormattedInstances(project) {
    return _.get(project, 'value.formattedInstances');
  }
  getInstanceEndTime(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'extra.endTime');
  }
  getInstanceStatus(project, currentInstanceIndex) {
    return _.get(this.getInstance(project, currentInstanceIndex), 'extra.status');
  }

  handleReRun(project) {
    if (confirm('Are you sure you want to re-run this project ? Execution can take a while')) {
      this.projectsService.triggerWorkflow(this.projectId, this.getWorkflowId(project, 0)).subscribe(
        (res) => {},
        (error) => {
          alert('Code : ' + error.error.code + '. Message : ' + error.error.message + '. Reason  : ' + error.error.reason + '.');
        }
      );
    }
  }

  handleLegend() {
    this.layout.open('modalLegend');
  }
}
