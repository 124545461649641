<div class='w-100 h-100 d-flex flex-column'>
  <app-header>

    <span class="path h-100 w-100 d-flex align-items-center">
      <span class='path-element'>
        Manage teams
      </span>
    </span>

  </app-header>
  <app-content class='scrollable' [isHeader]='true'>

    <div class='d-flex my-4'>
      <div class='btn bg-dark text-light mx-3' (click)='handleNewTeam()'><i class="fas mx-2 fa-plus"
          aria-hidden="true"></i>
        Create team
      </div>

      <div class='d-flex flex-column flex-grow-1 mr-3'>
        <app-spinner *ngIf='getTeams() === null'></app-spinner>
        <span *ngIf='getTeams() !== null && getTeams().length'>
          <app-team *ngFor='let team of getTeams(); trackBy : trackFn' [team]='team'></app-team>
        </span>
        <div class='empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
          *ngIf='getTeams() !== null  && !getTeams().length'>
          <p>
            All your teams will display here.
          </p>
        </div>
    </div>
</div>
</app-content>

</div>

<app-custom-modal [modalTitle]="hasSubscription(teamsService.selectedTeam) ? 'Manage subscription' : 'Add subscription'"
  idName="modalManageSubscription" [show]="layout.isManageSubscriptionOpen" confirmTitle="Save"
  [confirmActions]="[handleChangeSubscription]" confirmTooltip='Please verify your form.' [form]="getTeamSubscriptionForm()
" [cancelActions]="[resetSelectedTeam]">
  <div class="custom-modal-content">
    <app-team-subscription-form *ngIf='layout.isManageSubscriptionOpen' [team]='teamsService.selectedTeam'>
    </app-team-subscription-form>
  </div>

</app-custom-modal>
