import { Features } from "./features";

// `ng build -c alpha` environment
export const environment = {
  production: true,
  name: 'alpha',
  activationPath: 'https://test-mims.westus2.cloudapp.azure.com',
  platformName: 'MIMsomicAlpha',
  platformUrl: "https://mimsomicalpha.mims.ai",
  apiBasePath: 'https://test-mims.westus2.cloudapp.azure.com',
};
export const availableFeatures = [
  Features.Explorer,
  Features.UploadFile,
  Features.DeleteFile,
  Features.DeleleMultipleFiles,
]
