import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { VizType } from 'src/app/shared/helpers/vizType';
import { Observable } from 'rxjs';

export enum VizMode {
  FullScreen = 'fullscreen',
  GeneratedForThumbnail = 'generatedForThumbnail',
  Regular = 'regular',
}
export const col1 = '#087282';
export const col2 = '#CF367F';
export const col3 = '#21B7CF';
export const col4 = '#CFB90C';
export const col5 = '#82750E';
export const col6 = '#820143';

export const cyan = [
  [0, '#e3fafc'],
  [0.11111111, '#c5f6fa'],
  [0.22222222, '#99e9f2'],
  [0.33333333, '#66d9e8'],
  [0.44444444, '#3bc9db'],
  [0.55555555, '#22b8cf'],
  [0.66666666, '#15aabf'],
  [0.77777777, '#1098ad'],
  [0.88888888, '#0c8599'],
  [1, '#0b7285'],
];
export const test = [
  [0, '#e3fafc'],
  [1, '#d4bfc8'],
];

export const pink = [
  [0, '#d4bfc8'],
  [0.11111111, '#d8adc0'],
  [0.22222222, '#d991b1'],
  [0.33333333, '#d9709f'],
  [0.44444444, '#d7558f'],
  [0.55555555, '#ce4281'],
  [0.66666666, '#c23876'],
  [0.77777777, '#af3069'],
  [0.88888888, '#9A285B'],
  [1, '#850b41'],
];


// export const cyanAndPink = [
//   [0, '#0b7285'],
//   [0.055555555, '#0c8599'],
//   [0.11111111, '#1098ad'],
//   [0.166666665, '#15aabf'],
//   [0.22222222, '#22b8cf'],
//   [0.277777775, '#3bc9db'],
//   [0.33333333, '#66d9e8'],
//   [0.388888885, '#99e9f2'],
//   [0.44444444, '#c5f6fa'],
//   [0.5, '#dcdee4'],
//   [0.555555555, '#d8adc0'],
//   [0.61111111, '#d991b1'],
//   [0.666666665, '#d9709f'],
//   [0.72222222, '#d7558f'],
//   [0.777777775, '#ce4281'],
//   [0.83333333, '#c23876'],
//   [0.888888885, '#af3069'],
//   [0.94444444, '#9A285B'],
//   [1, '#850b41'],
// ];

export const cyanAndPink = [
  [0, '#850b41'],
  [0.055555555, '#9A285B'],
  [0.11111111, '#af3069'],
  [0.166666665, '#c23876'],
  [0.22222222, '#ce4281'],
  [0.277777775, '#d7558f'],
  [0.33333333, '#d9709f'],
  [0.388888885, '#d991b1'],
  [0.44444444, '#d8adc0'],
  [0.5, '#dcdee4'],
  [0.555555555, '#c5f6fa'],
  [0.61111111, '#99e9f2'],
  [0.666666665, '#66d9e8'],
  [0.72222222, '#3bc9db'],
  [0.777777775, '#22b8cf'],
  [0.83333333, '#15aabf'],
  [0.888888885, '#1098ad'],
  [0.94444444, '#0c8599'],
  [1, '#0b7285'],
];


@Injectable()
export class VizService {
  selectedViz;
  constructor() {
  }

  setSelectedViz(viz) {
    this.selectedViz = viz
  }
  getSelectedViz() {
    return _.get(this, 'selectedViz', null)
  }
  getType(viz) {
    return _.get(viz, 'type', null)
  }
  getJavascript(viz) {
    return _.get(viz, 'content.value.javascript', undefined)
  }
  getLayout(viz) {
    return _.get(viz, 'content.value.layout', null)
  }
  getData(viz, trace?) {
    if (trace) {
      return _.get(viz, 'content.value.data[' + trace + ']', null)
    } else {
      return _.get(viz, 'content.value.data', null)
    }
  }
  getTitleSelect(viz) {
    return _.get(viz, 'content.value.data[0].titleSelect', "Select a Strain ID")
  }

  getInteractionTrace(viz, nb) {
    return _.get(viz, 'interactions.value[' + nb + '].points', [])
  }

  getSubType(viz, trace?) {
    if (trace) {
      return _.get(viz, 'content.value.data[' + trace + '].type', null)
    } else {
      return _.get(viz, 'content.value.data[0].type', null)
    }
  }


  modifyData(viz) {
    this.getData(viz).forEach((element) => {
      // _.set(element, ['marker', 'color'], null);
      // _.set(element, ['line', 'color'], null);
      if (_.get(element, ['marker', 'showscale'], false) === false) {
        return
      }
      let cmin = _.get(element, ['marker', 'cmin'], 0);
      let cmax = _.get(element, ['marker', 'cmax'], 0)
      if (cmin < 0 && cmax <= 0) {
        _.set(element, ['marker', 'colorscale'], pink);
      }
      if (cmin < 0 && cmax > 0) {
        _.set(element, ['marker', 'colorscale'], cyanAndPink);
      }
      if (cmin >= 0) {
        _.set(element, ['marker', 'colorscale'], cyan);
      }
      _.set(element, ['marker', 'colorbar', 'len'], null);
      _.set(element, ['marker', 'colorbar', 'thickness'], 20);
      _.set(element, ['marker', 'colorbar', 'outlinewidth'], 0.1);
      if (this.getType(viz) === VizType.ColorscaledImage) {
        _.set(element, ['marker', 'colorbar', 'xpad'], 0)
      }
    })

    return viz

  }
  modifyLayout(viz) {
    let layout = this.getLayout(viz)
    _.set(layout, 'plot_bgcolor', 'rgba(0,0,0,0)')
    _.set(layout, 'paper_bgcolor', 'rgba(0,0,0,0)')
    _.set(layout, 'font', { family: 'Montserrat' });
    _.set(layout, 'width', null)
    _.set(layout, 'height', null)
    _.set(layout, 'autosize', true)
    _.set(layout, 'template', null)
    _.set(layout, 'xaxis.automargin', true)
    _.set(layout, 'xaxis.ticksuffix', '   ')
    _.set(layout, 'xaxis.tickfont.family', 'Montserrat')
    _.set(layout, 'yaxis.ticksuffix', '   ')
    _.set(layout, 'yaxis.tickfont.family', 'Montserrat')
    _.set(layout, 'yaxis.automargin', true)
    _.set(layout, 'xaxis.zeroline', false)
    _.set(layout, 'yaxis.zeroline', false)
    _.set(layout, 'title', { text: this.getTitleText(viz), font: { family: 'Montserrat', color: 'white', size: 1 } });
    _.set(layout, 'colorway', ['#087282', '#CF367F', '#21B7CF', '#CFB90C', '#82750E', '#820143']);
    if (VizType.Node === this.getType(viz)) {
      _.set(layout, 'font', { family: 'Montserrat', size: 11 })
    }
    return viz
  }

  getTitleText(viz) {
    let title = _.get(this.getLayout(viz), 'title', null);
    let title2 = _.get(this.getLayout(viz), 'title.text', null);
    if (title && _.isString(title)) {
      return title
    } else if (title2 && _.isString(title2)) {
      return title2
    } else {
      return null
    }
  }
  getTitle(viz) {
    return _.get(this.getLayout(viz), 'title.text', null)
  }
  getInteractivity(viz) {
    return _.get(viz, 'isInteractive', false)
  }
  getInteractionsLength(viz) {
    return _.get(viz, 'interactions.value.length', 0)
  }
  getValues(viz, nb?) {
    if (nb) {
      return _.get(viz, 'content.value.data[' + nb + '].values', null)
    }
    return _.get(viz, 'content.value.data[0].values', null)
  }
  getVizContent(viz): Observable<any> {
    return _.get(viz, 'content').asObservable();
  }
  getVizInteractions(viz): Observable<any> {
    return _.get(viz, 'interactions').asObservable();
  }
  isPlotly(viz) {
    return _.includes([VizType.Plot, VizType.Treemap, VizType.ColorscaledImage, VizType.Node], this.getType(viz))
  }
  getErrorTypes(viz) {
    return _.get(viz, 'error.type', [])
  }
  getTableHeaders(viz) {
    return _.get(viz, 'content.value.data[0].headers', [])
  }
  getTableCells(viz) {
    return _.get(viz, 'content.value.data[0].cells', [])
  }
  getTableContentValues(viz) {
    return _.get(viz, 'content.value.data[0].values', [])
  }
  getId(viz) {
    return _.get(viz, 'id', null)
  }
  getTrace(viz, nb) {
    return _.get(viz, 'content.value.data[' + nb + ']', null)
  }

  sanitizeString(input) {
    input = input.replace('\r', '');
    input = input.replace('\t', '');
    input = '"' + input + '"';
    return input;
  }
  addCSVValue(data_value) {
    if (typeof data_value === 'string') {
      data_value = this.sanitizeString(data_value);
    }
    return data_value + ',';
  }
  generateHeadersRow(input_data) {
    let result = '';
    for (let i = 0; i < input_data.length; ++i)
      result += this.addCSVValue(input_data[i]);
    return result.slice(0, -1) + '\n';
  }
  generateCSVRows(input_data) {
    const first_entries = input_data[0];
    const nbr_cols = first_entries.length;
    if (nbr_cols === 0 || !Array.isArray(input_data)) {
      return '';
    }
    const nbr_rows = input_data.length;
    let result = '';
    for (let row_index = 0; row_index < nbr_rows; ++row_index) {
      for (let col_index = 0; col_index < nbr_cols; ++col_index) {
        result += this.addCSVValue(input_data[row_index][col_index]);
      }
      result = result.slice(0, -1) + '\n';
    }
    return result;
  }
  generateOctetStream(viz) {
    let csv_data = this.generateHeadersRow(_.get(this.getTrace(viz, 0), 'headers', []));
    csv_data += this.generateCSVRows(_.get(this.getTrace(viz, 0), 'cells', []));
    return encodeURI(csv_data);
  }


}
