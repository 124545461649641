import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { TabsPublicName } from '../shared/helpers/tabsPublicName';
import { PagesPublicName } from '../shared/helpers/pagesPublicName';
import { Status } from '../shared/helpers/status';
import { BehaviorSubject } from 'rxjs';
import { FileManager } from './file-manager.service';
import { availableFeatures } from 'src/environments/environment';
import { GroupsManagerService } from './groups-manager.service';
import { LocalService } from './local-storage.service';
import { Features } from 'src/environments/features';

export enum SortType {
    Title = 'title',
    IsFolder = 'is_folder',
    DateCreation = 'date_of_creation',
}
export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}
@Injectable()
export class SharedFilesManagerService {
    path = new BehaviorSubject<any>([])
    displayedFiles = new BehaviorSubject<any>([]);
    metadataToUpload: any;
    bulkSelection = [];
    finishedListing;
    page = 1;
    count;
    status = Status
    page2 = 1;
    pageSize = 10;
    filtersVisible = false;
    limitParam = 10;
    folderCreationForm: FormGroup;
    fileUploadForm: FormGroup;
    metadataUploadForm: FormGroup;
    groups = []
    pathIsReady = false
    dlLink = null
    size = 0
    itemsWithInfoDisplayed;
    download = false;
    canDownload = new BehaviorSubject<boolean>(false);

    constructor(private localService: LocalService, private fileManager: FileManager, private groupsManagerService : GroupsManagerService) {
    }
    getBasePath = () => {
       return _.includes(availableFeatures, Features.MultiCountry) ? [{ name: PagesPublicName.FileManager, id: null, page: undefined }, { name: TabsPublicName.SharedFiles, id: null, page: undefined }, { name: 'Storage location - ' + this.localService.getFromLocalStorage('country', 'name'), id: 'list', page: undefined }]
        : [{ name: PagesPublicName.FileManager, id: null, page: undefined }, { name: TabsPublicName.SharedFiles, id: 'list', page: undefined }]
    }
    openInfo() {
        return this.fileManager.openInfo()
    }
    openShare() {
        return this.fileManager.openShare()
    }
    getDisplayedFilesLength = () => {
        return this.fileManager.getDisplayedFilesLength(this)
    }
    getFinishedListing = () => {
        return this.fileManager.getFinishedListing(this)
    }
    resetFinishedListing = () => {
        return this.fileManager.resetFinishedListing(this)
    }
    getDisplayedFiles = () => {
        return this.fileManager.getDisplayedFiles(this)
    }
    getCompletePath = () => {
        return this.fileManager.getCompletePath(this)
    }
    changeCurrentLocationId = (id) => {
        return this.fileManager.changeCurrentLocationId(id, this)
    };

    setBulkToOneFileOnly(id) {
        return this.fileManager.setBulkToOneFileOnly(id, this)
    }
    getParentFolderId = () => {
        return this.fileManager.getParentFolderId(this)
    }
    getParentFolderName = () => {
        return this.fileManager.getParentFolderName(this)
    };

    getOpenedFolderId = () => {
        return this.fileManager.getOpenedFolderId(this, 'SharedFilesManagerService')
    }
    openFolderInPath = (id) => {
        return this.fileManager.openFolderInPath(id, this)
    };
    openFolder = (id) => {
        return this.fileManager.openFolder(id, this)
    };
    resetFolder = (id) => {
        return this.fileManager.resetFolder(id, this)
    };
    moveInsideFolder = () => {
        return this.fileManager.moveInsideFolder(this)
    };
    refreshFolder = () => {
        return this.fileManager.refreshFolder(this)
    }
    closeFolder = (id) => {
        return this.fileManager.closeFolder(id, this)
    }
    listContent = () => {
        return this.fileManager.listContent(this, 'SharedFilesManagerService')
    }
    // retrieveContent = (sortby, filters, limitParam, page) => {
    //     return this.fileManager.retrieveContent(sortby, filters, limitParam, page, this)
    // }

    checkCanDownload = (path) => {
        return this.fileManager.checkCanDownload(path, this, 'SharedFilesManagerService')
    }
    setCurrentFolderPage = (value) => {
        return this.fileManager.setCurrentFolderPage(value, this)
    };
    setParentFolderPage = (value) => {
        return this.fileManager.setParentFolderPage(value, this)
    };
    resetMetadataUploadForm = () => {
        return this.fileManager.resetMetadataUploadForm(this)
    };
    rename = (itemId, value, field) => {
        return this.fileManager.rename(itemId, value, field, this)
    }
    getInfos = () => {
        return this.fileManager.getInfos(this)
    }
    // printList = () => {
    //     return this.fileManager.printList(this)
    // }
    getSortByQuery = () => {
        return this.fileManager.getSortByQuery(this)
    }
    setSortFilter = (value: SortType) => {
        return this.fileManager.setSortFilter(value, 'SharedFilesManagerService')
    }
    setSortDirection = (value: SortDirection) => {
        return this.fileManager.setSortDirection(value, 'SharedFilesManagerService')
    }
    getSortFilter = () => {
        return this.fileManager.getSortFilter('SharedFilesManagerService')
    }
    getSortDirection = () => {
        return this.fileManager.getSortDirection('SharedFilesManagerService')
    }
    getBulkSelectionLength = () => {
        return this.fileManager.getBulkSelectionLength(this)
    }
    toggleSortDirection = () => {
        return this.fileManager.toggleSortDirection(this)
    }
    resetBulkSelection = () => {
        return this.fileManager.resetBulkSelection(this)
    }
    selectAllDisplayedFiles = () => {
        return this.fileManager.selectAllDisplayedFiles(this)
    }
    toggleFilter = () => {
        return this.fileManager.toggleFilter(this)
    }
    addToBulkSelection = (id) => {
        return this.fileManager.addToBulkSelection(id, this);
    }
    removeFromBulkSelection = (id) => {
        return this.fileManager.removeFromBulkSelection(id, this);
    }
    isBulkSelected = (id?) => {
        return this.fileManager.isBulkSelected(this, id ? id : undefined)
    }
    getBulkSize = () => {
        return this.fileManager.getBulkSize(this);
    }

    handleNewMetaData = () => {
        return this.fileManager.handleNewMetaData(this)
    }



    handleCompressAndDownload = (item) => {
        return this.fileManager.handleCompressAndDownload(item, this);

    }
    onCompressionToastClose = () => {
        return this.fileManager.onCompressionToastClose(this);

    }

    getTitleFromGroupId(id) {
        return this.fileManager.getTitleFromGroupId(this.groupsManagerService.groups, id)
    }
    _handleDelete = (id, title?) => {
        return this.fileManager._handleDelete(id, this, title ? title : undefined)
    }
    handleDelete = (item) => {
        return this.fileManager.handleDelete(item, this)
    }

    allowDrop = (event) => {
        return this.fileManager.allowDrop(event)
    }
    getDragEndTargetId = (id) => {
        return this.fileManager.getDragEndTargetId(id, this)
    }
    getDragEndGroupTargetId = (id) => {
        return this.fileManager.getDragEndGroupTargetId(id)
    }
    handleMoveSelection = (grabbedElementId) => {
        return this.fileManager.handleMoveSelection(grabbedElementId, this)
    }

    handleMoveSelectionToGroup = (grabbedElementId) => {
        return this.fileManager.handleMoveSelectionToGroup(grabbedElementId, this)
    }

    handleMoveSelectionToFolder = (grabbedElementId) => {
        return this.fileManager.handleMoveSelectionToFolder(grabbedElementId, this)
    }
    getTitleFromId = (id) => {
        return this.fileManager.getTitleFromId(id, this)
    }
    handleMoveBulkSelection = () => {
        return this.fileManager.handleMoveBulkSelection(this)
    }

    handleDownloadReset = () => {
        return this.fileManager.handleDownloadReset(this)
    }
    downloadFile = () => {
        return this.fileManager.downloadFile(this)
    }



}