<form [formGroup]="myFilesManager.folderCreationForm" class="d-flex flex-column custom-modal-content">
  <div>
    <input class="form-control" id="folderInput" type="text" formControlName="folderInput"
      placeholder="enter folder's name" />
  </div>
  <div class="correct" *ngIf="folderInput.valid && (folderInput.dirty || folderInput.touched)">
    &#10003;
  </div>
  <div class="incorrect" *ngIf="!folderInput.valid && (folderInput.dirty || folderInput.touched)">
    &#10005; This field is required
  </div>
</form>