<div class="scroll-container" tabindex="-1" #scrollContainer>
  <mims-loader *ngIf='!isDownloaded.value' [isLoading]='!isDownloaded.value'></mims-loader>
  <div *ngIf="error">
    <div class="my-3 text-center">
      <i class="far fa-exclamation-circle" aria-hidden="true"></i>
      Process is done. Impossible to get the dashboard.
    </div>
  </div>
  <div *ngIf="!error">
    <section class='dynamic' (deferLoad)="el.show = true" *ngFor='let el of sectionArray'>
      <div class='viz-container'>
        <app-viz2 *ngIf="el.show" [graphId]='el.graphId'
          (webGlContexts)='[handleWebGlContexts($event),updateScatterGlPool()]'
          (showChildren)='[handleShowChildren($event),updateScatterGlPool()]' [projectId]='projectId'
          [dashboardId]='dashboardId'></app-viz2>
      </div>
    </section>
  </div>
</div>

<!-- <div *ngIf='!dynamicDashboard' class="scroll-container static">
  <section class='static' (deferLoad)="el.show = true" *ngFor='let el of sectionArray '>
    <div *ngIf="el.show"  class='viz-tile-container '>
      <app-viz2 *ngIf="el.show" [graphId]='el.graphId'
      (webGlContexts)='[handleWebGlContexts($event),updateScatterGlPool()]'
      (showChildren)='[handleShowChildren($event),updateScatterGlPool()]' [projectId]='projectId'
      [dashboardId]='dashboardId' [static]='true'></app-viz2>
    </div>
  </section>
</div> -->

<!-- 


<app-full-screen-modal (click)='dashboardService.getVizIndex(vizService.getSelectedViz())'
  *ngIf='layout.isVizModalOpen && tileView && vizService.getSelectedViz()' idName='modalViz'
  [title]='vizService.getTitle(vizService.getSelectedViz())' class='d-flex flex-column'>
  <app-viz [vizMode]='VizMode.FullScreen' class='w-100 p-4 flex-grow-1 m-0' [viz]='vizService.getSelectedViz()'>
  </app-viz>
  <div class='title-container align-items-center d-flex'>
    <div class='w-100 text-center text-wrap'>
      <div class='btn' (click)='navigateLeft()'><i class="far mx-2 fa-chevron-left" aria-hidden="true"></i></div>
      <div class='btn' (click)='navigateRight()'> <i class="far mx-2 fa-chevron-right" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</app-full-screen-modal> -->
