<div class='w-100 h-100 d-flex flex-column'>
    <app-header>
        <span class="path h-100 w-100 d-flex align-items-center">
            <span class='path-element'>
                <span class='btn-link path-element' (click)="navigateToTeamsManager()">Manage teams</span>
                <i class="fal mx-2 fa-chevron-right" aria-hidden="true"></i> <span
                    class='last-path-element'>{{getName()}} </span>
            </span>
        </span>

    </app-header>
    <app-content class='scrollable' [isHeader]='true'>
        <div class='d-flex my-4'>
            <div class='d-flex flex-column'>
            <div class='btn bg-dark text-light mb-3 mx-3' (click)='handleAddUser()'><i class="fas mx-2 fa-plus"
                    aria-hidden="true"></i> Add existing
                member</div>
                <div *ngIf='createUserPermission' class='btn bg-dark text-light mb-3 mx-3' (click)='handleAddNewUser()' ><i class=" fas mx-2 fa-plus"
                    aria-hidden="true"></i> Invite new user
                </div>
                </div>
            <div class='d-flex flex-column flex-grow-1 mr-3'>
                <div class="table-responsive">
                    <table aria-describedby="team content table" class="table table-hover w-100 mx-auto table-striped">
                        <thead>
                            <tr>
                                <th [scope]="header? header : index"
                                    *ngFor="let header of getHeaders(); let first = first, let index = index"
                                    [class.small-col]='first'>
                                    {{ header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr  class='cursor-pointer' *ngFor="let user of getUsers();trackBy : trackFn">
                                <td (click)='openUserCard(user)' class='align-middle'>
                                    <i class='far mx-2  fa-user' aria-hidden="true"></i>
                                </td>
                                <td (click)='openUserCard(user)' class='align-middle'>
                                    {{ getUserEmail(user) }}
                                </td>
                    
                                <td (click)='openUserCard(user)' class='align-middle'>
                                    {{ getUserRole(user) }}
                                </td>

                                <!-- <td class='align-middle small-column text-right'>

                                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class=" far fa-edit" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a (click)='handleChangePermission(user, "Admin")' class="dropdown-item"><i
                                                    class="far" [class.fa-check]=' getUserRole(user) === "Admin"'
                                                    [class.icon-blank]=' getUserRole(user) === "Admin"'
                                                    aria-hidden="true"></i>
                                                Admin</a>
                                        </div>
                                    <button (click)="handleDelete(getUserId(user))" class="btn">
                                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>



    </app-content>

</div>

<app-custom-modal modalTitle="Enter a member's ID" idName="modalMemberAddition" [show]="layout.isMemberAdditionModalOpen"
    confirmTitle="Save" [confirmActions]="[_handleAddUserById]" [form]="teamsService.memberAdditionForm"
[cancelActions]="[teamsService.resetMemberAdditionForm]">
    <div class="custom-modal-content">

        <app-member-addition-form></app-member-addition-form>
    </div>

</app-custom-modal>
<app-custom-modal modalTitle="Invite a new user" idName="modalNewMemberAddition" [show]="layout.isNewMemberAdditionModalOpen"
    confirmTitle="Save" [confirmActions]="[handleInvitation]" confirmTooltip='Please verify your form.' [form]="getNewMemberAdditionForm()
" [cancelActions]="[]">
    <div class="custom-modal-content">
        <app-new-member-addition-form  *ngIf='layout.isNewMemberAdditionModalOpen'  ></app-new-member-addition-form>
    </div>

</app-custom-modal>
<app-custom-modal idName="modalUserCard" [show]="layout.isUserCardOpen"
    confirmTitle="Ok" [confirmActions]="[]" [confirmValidator]='true' [buttons]='false' [cancelActions]="[]">
    
    <app-user-card [hidden]='!layout.isUserCardOpen' [team]='body' [user]='selectedUser'></app-user-card>
</app-custom-modal>
