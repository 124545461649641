import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { AdditionalTypes, InputJson} from 'src/app/shared/helpers/formattedInput';
import { TeamsService } from '../../teams-manager/teams.service';
import * as _ from 'lodash';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-file-upload-form',
  templateUrl: './file-upload-form.component.html',
  styleUrls: [],
})
export class fileUploadFormComponent implements OnInit {
  teamInputFieldObject: InputJson = {
    formControlName: 'team',
    type: AdditionalTypes.Object,
    label: 'Select a team',
    fitToContent: false,
    is_array: true,
    multiple_selection: false,
    retrieved_allowed_values: new BehaviorSubject<any>(undefined),
    retrievedValue: new BehaviorSubject<any>(undefined)
  }
  fileInputFieldObject: InputJson = {
    formControlName: 'fileInput',
    type: AdditionalTypes.File,
    label: 'Select files',
    fitToContent: true,
    multiple_selection: true,
    validations: [Validators.required]
  }

  constructor(private teamsService: TeamsService, private layout: LayoutOrganizationService, public myFilesManager: MyFilesManagerService) { }

  ngOnInit() {
    this.retrieveTeams().then((res) => {
      this.teamInputFieldObject.retrieved_allowed_values.next(res)
      this.teamInputFieldObject.retrievedValue.next(_.nth(res, 0))
    })
  }
  retrieveTeams = () => new Promise((resolve, reject) => {
    this.teamsService.getTeams().subscribe((res) => {
      resolve(res)
    }, () => {
      this.layout.toast('Impossible to get teams', null, 8000, '', 'warning')
      reject()
    })
  })

}
