<div class='h-100 flex-nowrap  row m-0'>
    <div class=' utility-bar utility-bar-at-the-left '>

        <button class="mb-2 text-left disabled btn btn-dark" placement="right"
        ngbTooltip="You can't perform this action in this tab" tooltipClass="custom-tooltip">
            <i class="mx-2 far fa-file-upload" aria-hidden="true"></i>
            <span>
                Upload
                files </span>
        </button>
       
        <div class="btn-group-vertical " role="group">
            <button placement="right"
            ngbTooltip="You can't perform this action in this tab" tooltipClass="custom-tooltip" class="btn text-left disabled btn-dark">
                <i class="mx-2 far fa-folder-plus" aria-hidden="true"></i>
                <span> Add folder
                </span>
            </button>
            <button  placement="right"
            ngbTooltip="You can't perform this action in this tab" tooltipClass="custom-tooltip" class="btn text-left disabled btn-dark">
                <i class="mx-2 far fa-object-group" aria-hidden="true"></i>
                <span> Create group
                </span>
            </button>
        </div>

    </div>
    <div class='col p-0 h-100 d-flex flex-column' id='FILES-AND-GROUPS'>
        <div class='scrollable h-100'>
            <div id='FILES' class='m-3'>
                <div id="top-utility-bar" class=" rounded hidden-on-mobile">
                    <div [style.min-height.px]="35" class='d-flex flex-wrap justify-content-between'>
                        <span class='flex-nowrap'>
                            <span class="cursor-info"
                                [ngbTooltip]="sharedFilesManager.isBulkSelected() ? 'Unselect all files' : 'Select all files'"
                                tooltipClass="custom-tooltip" placement="bottom">
                                <app-checkbox [id]="" [selectAction]='sharedFilesManager.selectAllDisplayedFiles'
                                    [unSelectAction]='sharedFilesManager.resetBulkSelection'
                                    [selected]="sharedFilesManager.isBulkSelected()" [fileType]='null'
                                    [selected]="sharedFilesManager.isBulkSelected()" bulkType='allFiles'>
                                </app-checkbox>
                            </span>

                            <span class="px-3 text-light"
                                [class.font-weight-bold]='!sharedFilesManager.isBulkSelected()'>
                                Check all</span>
                            |
                            <span class="px-3  text-light"
                                [class.font-weight-bold]='sharedFilesManager.isBulkSelected()'>Uncheck
                                all</span>
                            <span *ngIf="sharedFilesManager.getBulkSelectionLength()">|</span>
                            <span class="px-3 text-light" *ngIf="sharedFilesManager.getBulkSelectionLength()"
                                click=""><span
                                    class="font-weight-bold">{{sharedFilesManager.getBulkSelectionLength()}}</span>
                                item{{sharedFilesManager.getBulkSelectionLength()>1 ? 's':''}}
                                selected <span class='mx-2'> -
                                </span><strong>{{sharedFilesManager.getBulkSize()[0]}}</strong>
                                {{sharedFilesManager.getBulkSize()[1]}}</span>
                        </span>

                        <div *ngIf="sharedFilesManager.filtersVisible" class="d-flex flex-nowrap">
                            <button
                                (click)='sharedFilesManager.setSortFilter(SortType.Title);sharedFilesManager.toggleSortDirection(); sharedFilesManager.refreshFolder()'
                                class="btn noBoxShadow">
                                <span class="text-light">Sort By Name <i class="fas"
                                        *ngIf='sharedFilesManager.getSortFilter() === SortType.Title'
                                        [class.fa-sort-up]="sharedFilesManager.getSortDirection() === SortDirection.Asc"
                                        [class.fa-sort-down]="sharedFilesManager.getSortDirection() === SortDirection.Desc"
                                        aria-hidden="true"></i></span>
                            </button>

                            <button
                                (click)='sharedFilesManager.setSortFilter(SortType.DateCreation);sharedFilesManager.toggleSortDirection(); sharedFilesManager.refreshFolder()'
                                class="btn noBoxShadow">
                                <span class="text-light">Sort By Date <i class="fas"
                                        *ngIf='sharedFilesManager.getSortFilter() === SortType.DateCreation'
                                        [class.fa-sort-up]="sharedFilesManager.getSortDirection() === SortDirection.Asc"
                                        [class.fa-sort-down]="sharedFilesManager.getSortDirection() === SortDirection.Desc"
                                        aria-hidden="true"></i></span>
                            </button>

                        </div>
                        <div class="flex-nowrap">
                            <button *ngIf="!sharedFilesManager.filtersVisible"
                                (click)='sharedFilesManager.toggleFilter()' class="btn noBoxShadow">
                                <span class="text-light font-weight-bold">Show filters</span>
                            </button>
                            <button *ngIf="sharedFilesManager.filtersVisible"
                                (click)='sharedFilesManager.toggleFilter()' class="btn noBoxShadow">
                                <span class="text-light font-weight-bold">Hide filters</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class='d-flex flex-column flex-nowrap'>
                    <div *ngIf="sharedFilesManager.getOpenedFolderId() !== 'list'">
                        <div id="return" (drop)="sharedFilesManager.getDragEndTargetId('return')"
                            (dragover)="sharedFilesManager.allowDrop($event)"
                            class="list-container d-flex justify-content-between">
                            <p>
                                <button class="btn btn-link"
                                    (click)="sharedFilesManager.closeFolder(sharedFilesManager.getParentFolderId())">
                                    <i class="far fa-arrow-left" aria-hidden="true"></i> Back to
                                    {{sharedFilesManager.getParentFolderName()}}
                                </button>
                            </p>
                        </div>
                    </div>
                    <div id="files-content" *ngIf='sharedFilesManager.getDisplayedFilesLength() && sharedFilesManager.finishedListing'
                        class="w-100 d-flex flex-column">
                        <div class="my-2 d-flex flex-column">
                            <div *ngFor="let item of 
                            sharedFilesManager.getDisplayedFiles()
                              ; trackBy: trackByFn">
                                <app-item-list [selected]='sharedFilesManager.isBulkSelected(item.id)' [item]="item">
                                </app-item-list>
                            </div>
                            <ngb-pagination (pageChange)="pageChanged()" class="pager my-3"
                                [(page)]="sharedFilesManager.page" [maxSize]="5"
                                [pageSize]="sharedFilesManager.pageSize" [collectionSize]="sharedFilesManager.count">
                                <ng-template ngbPaginationPrevious><i class="far fa-chevron-left"
                                        aria-hidden="true"></i></ng-template>
                                <ng-template ngbPaginationNext><i class="far fa-chevron-right" aria-hidden="true"></i>
                                </ng-template>
                            </ngb-pagination>
                        </div>

                    </div>
                    <div class='empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
                        *ngIf='sharedFilesManager.finishedListing && !sharedFilesManager.getDisplayedFilesLength() && sharedFilesManager.getOpenedFolderId() !== "root"'>
                        <p>
                            Empty folder.
                        </p>
                    </div>
                    <div class='empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
                        *ngIf='sharedFilesManager.finishedListing && !sharedFilesManager.getDisplayedFilesLength() && sharedFilesManager.getOpenedFolderId() === "root"'>
                        <p>
                            All your files and folders will display here.
                        </p>
                    </div>
                </div>
            </div>
            <app-groups *ngIf='groupsManagerService.finishedListing' ></app-groups>
            <app-groups *ngIf='groupsManagerService.finishedListing' [sharedWithMe]='true' [marginBottom]='true'></app-groups>

        </div>
    </div>
    <div [class.active]='layout.isFilesInfosModalOpen || layout.isFilesShareModalOpen || layout.isGroupShareModalOpen' class="col-3 p-0" id='PANNEL'>
        <div id="info-content" [class.active]="layout.isFilesInfosModalOpen">
            <div *ngIf="layout.isFilesInfosModalOpen" id="modalFilesInfos">
                <div class="d-flex justify-content-end">
                    <button type="button" class="close" aria-label="Close" (click)="layout.close('modalFilesInfos')">
                        <span><i class="far fa-times" aria-hidden="true"></i></span>
                    </button>
                </div>
                <div *ngIf="sharedFilesManager.itemsWithInfoDisplayed">
                    <ul class="info-scroll">
                        <info *ngFor="let item of sharedFilesManager.itemsWithInfoDisplayed" [item]="item"></info>
                    </ul>
                </div>
            </div>
        </div>
        <div id="share-content" [class.active]="layout.isFilesShareModalOpen">
            <div *ngIf="layout.isFilesShareModalOpen" class='h-100 d-flex flex-column'>
                <div class="d-flex flex-column flex-fill" id="modalFilesShare">
                    <div class="d-flex justify-content-end">
                        <button type="button" class="close" aria-label="Close"
                            (click)="layout.close('modalFilesShare')">
                            <span><i class="far fa-times" aria-hidden="true"></i></span>
                        </button>
                    </div>
                    <div class='flex-fill' *ngIf="sharedFilesManager.itemsWithInfoDisplayed">
                        <ul class="info-scroll d-flex flex-column">
                            <app-share-file class='flex-fill d-flex flex-column'
                                *ngFor="let item of sharedFilesManager.itemsWithInfoDisplayed" [item]="item">
                            </app-share-file>
                        </ul>
                    </div>
                </div>
                <div class="btn-container">
                    <div class="d-flex flex-nowrap">
                        <button type="button" class="modal-btn btn btn-dark"
                            (click)="layout.close();shareService.resetNonSentInvitations()">
                            Cancel
                        </button>
                        <button type="button" class="modal-btn btn btn-dark"
                            [disabled]="!shareService.usersSelected.length"
                            (click)="shareService.shareToUsers(ItemType.File, sharedFilesManager.bulkSelection[0])">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <div id="share-content-group" [class.active]="layout.isGroupShareModalOpen">
            <div *ngIf="layout.isGroupShareModalOpen" class='h-100 d-flex flex-column'>
              <div class="d-flex flex-column flex-fill" id="modalGroupsShare">
                <div class="d-flex justify-content-end">
                  <button type="button" class="close" aria-label="Close" (click)="layout.close('modalGroupsShare')">
                    <span><i class="far fa-times" aria-hidden="true"></i></span>
                  </button>
                </div>
                <div class='flex-fill' *ngIf="groupsManagerService.itemsWithInfoDisplayed">
                  <ul class="info-scroll d-flex flex-column">
                    <app-share-group class='flex-fill d-flex flex-column'
                      *ngFor="let item of groupsManagerService.itemsWithInfoDisplayed" [item]="item">
                    </app-share-group>
                  </ul>
                </div>
              </div>
              <div class="btn-container">
                <div class="d-flex flex-nowrap">
                  <button type="button" class="modal-btn btn btn-dark"
                    (click)="layout.close();shareService.resetNonSentInvitations()">
                    Cancel
                  </button>
                  <button type="button" class="modal-btn btn btn-dark" [disabled]="!shareService.usersSelected.length"
                    (click)="shareService.shareToUsers(ItemType.FileGroup, groupsManagerService.bulkSelection[0])">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
      
          </div>
    </div>
</div>
<app-custom-modal modalTitle="Upload meta data relative to your file" idName="modalMetaData"
    [show]="layout.isMetaDataModalOpen" confirmTitle="Save" [confirmActions]="[sharedFilesManager.handleNewMetaData]"
    [confirmValidator]="
    sharedFilesManager.metadataUploadForm?.controls?.metadataInput
      ? sharedFilesManager.metadataUploadForm.controls.metadataInput.valid
      : false
  " [cancelActions]="[sharedFilesManager.resetMetadataUploadForm]">
    <div class="custom-modal-content">
        <app-metadata-upload-form></app-metadata-upload-form>
    </div>
</app-custom-modal>