import { Component, Input } from '@angular/core';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';

@Component({
  selector: 'app-displayed-projects',
  templateUrl: './displayed-projects.component.html',
  styleUrls: [
    './displayed-projects.component.scss',
    '../../../shared/layout/managers.scss',
  ],
})
export class DisplayedProjectsComponent {
  @Input() displayedProjects;
  @Input() viewTab;
  TabsName = TabsName;
  constructor(
    public projectManagerService: ProjectManagerService,

  ) { }

}
