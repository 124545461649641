import { Component, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { LoginHttpService } from 'src/app/pages/login/login-http-service/login-http.service';
import moment from 'moment';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { TeamsService } from '../../teams.service';
import { BehaviorSubject, pipe } from 'rxjs';


@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['../../../../shared/layout/managers.scss', './user-card.component.scss']
})
export class UserCardComponent implements OnInit {
  @Input() user
  @Input() team
  // @Output() newRole = new BehaviorSubject<any>(null)
  userDetails = {};
  constructor(private loginHttp: LoginHttpService, public layout: LayoutOrganizationService, private teamsService: TeamsService) { }

  ngOnInit(): void {
    if (!this.user){
      return
    }
    this.user.subscribe((res) => {
      if (!res) {
        return
      }
      this.updateUserDetails()
    })
  }

  omitKeys = (res) => {
    return _.omitBy(res, (value, key) => (
      _.includes(key, 'kind') ||
      _.includes(key, 'links') ||
      _.includes(key, 'first_name') ||
      _.includes(key, 'last_name')) ||
      _.includes(key, 'file_hosting_country')
    );
  }
  omitEmptyValues = (res) => { return _.omitBy(res, (value, key) => (value === null || value === '')) }
  addRoleKey = (res) => { 
    let object = _.cloneDeep(res)
    _.set(object, 'role', this.getUserRole())
    return object
  }
  updateUserDetails() {
    this.loginHttp.getUser(this.getId()).subscribe((res) => {
      this.userDetails = pipe(this.omitKeys,
        this.omitEmptyValues,
        this.addRoleKey
        )(res)
    }, () => {
      this.layout.toast("Impossible to retrieve user information", null, 5000, '', 'danger')
    })
  }

  handleRemove() {
    let text = 'This user will be remove from this team. Are you sure ?'
    let self = this
    this.layout.customConfirm(text, function () {
        self._handleRemove()
    })
}

  _handleRemove = () => {
    let newBody = _.cloneDeep(this.team);
    let newArray = _.get(newBody, 'user_ids');
    newArray = _.without(newArray, this.getId());
    _.set(newBody, 'user_ids', newArray);
    this.teamsService.updateTeam(this.getTeamId(), newBody).subscribe(() => {
      this.layout.toast('Member <b>successfully removed</b> from the team', null, 5000, '', 'success')
      this.teamsService.refreshTeamId.next(this.getTeamId())
      this.layout.close('modalUserCard')
    }, () => {
      this.layout.toast('<b>Error</b> while <b>removing</b> the user from the team', null, 8000, '', 'danger')
    })
  }
  handleDelete() {
    let line1 = 'This user account and its filesytems will be permanently deleted.'
    let line2 = '<br>' + 'Are you sure?'
    let text = line1 + line2
    let self = this
    this.layout.customConfirm(text, function () {
        self._handleDelete()
    })
}
  _handleDelete = () => {
    this.loginHttp.deleteAccount(this.getId()).subscribe(() => {
      this.layout.toast('Account <b>successfully deleted</b>', null, 5000, '', 'success')
      this.teamsService.refreshTeamId.next(this.getTeamId())
      this.layout.close('modalUserCard')
    }, () => {
      this.layout.toast('<b>Error</b> while <b>deleting</b> this account', null, 8000, '', 'danger')
    })
  }
  handleChangePermission(role) {
    let newArray = _.get(this, 'team.owner_user_ids', [])
    if (this.getUserRole() === role) {
      return
    }
    if (role === 'Member') {
      newArray = _.without(newArray, this.getId())
    } else if (role === 'Team Manager') {
      newArray.push(this.getId())
    }
    let newBody = _.cloneDeep(this.team)
    _.set(newBody, 'owner_user_ids', newArray)
    
    this.teamsService.updateTeam(this.getTeamId(), newBody).subscribe(() => {
      _.set(this.userDetails,'role',role)
     this.teamsService.refreshTeamId.next(this.getTeamId())
    },
      () => {
        this.layout.toast('<b>Error</b> while <b>updating</b> the user role in the team', null, 8000, '', 'danger')
      })
  }

  getTeamId() {
    return this.team._key || null
  }
  getUserRole = () => {
    return this.user.value.role || ''
  }
 

  getReadableKey(key) {
    if (key === 'external_user_ids') {
      key = 'external_storage'
    }
    let name = _.split(key, '_');
    name = _.join(name, ' ');
    return name
  }
  getReadableValue(key, value) {
    if ((key === 'last_activity_time' || key === 'created_on') && _.isNumber(value)) {
      if (value === 0) {
        return 'Unknown date'
      }
      return moment(value * 1000).fromNow()
    }
    if (_.isBoolean(value)) {
      return value ? 'Yes' : 'No'
    }
    if (_.isObject(value)) {
      let newvalue = _.keysIn(value)
      newvalue = _.join(newvalue, ' ');
      return newvalue
    }
    return value
  }

  getId() {
    return this.user.value.id || null
  }
}
