import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-content',
  styleUrls: ['../../../../shared/layout/managers.scss'],
  templateUrl: './group-content.component.html',
})
export class GroupContentComponent implements OnInit {
  @Input() groupItem
  @Input() group
  
  @Input() handleRemove
  iconText
  constructor() { }

  ngOnInit(): void {
  }
  setIconText(text) {
    this.iconText = text;
  }
  unSetIconText() {
    this.iconText = '';
  }

}
