import {
  Component,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import moment from 'moment';
import { FileManager } from 'src/app/api/file-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { Status } from '../../../shared/helpers/status';
import { ItemType } from 'src/app/api/share.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { TeamsService } from '../../teams-manager/teams.service';
@Component({
  selector: 'info',
  styleUrls: ['./info.component.scss'],
  templateUrl: './info.component.html',
})
export class InfoComponent implements OnInit, OnDestroy {
  public dateCreation: any;
  public dateModification: any;
  public size: any;

  public metadata: any;
  public Status = Status
  Features = Features;
  availableFeatures = availableFeatures
  public highlightDate = false;
  public _ = _;
  public ItemType = ItemType;
  TabsName = TabsName;
  hasMetaData = new BehaviorSubject<boolean>(false);
  teamName = new BehaviorSubject<string>(null);

  @Input() item: any;
  originalSelectedItemId;
  constructor(
    public fileManager: FileManager,
    public myFilesService: MyFilesManagerService,
    public sharedFilesService: SharedFilesManagerService,
    public layout: LayoutOrganizationService,
    public router: Router,
    public teamsService: TeamsService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.item.metadata && Object.keys(this.item.metadata).length > 0) {
      this.hasMetaData.next(true);
    }
    this.size = '0 kB'
    if (this.item.content_size !== 0) {
      var i = Math.floor(Math.log(this.item.content_size) / Math.log(1000));
      let calc: any = this.item.content_size / Math.pow(1000, i)
      this.size = calc.toFixed(1) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }
    this.dateCreation = this.fromNowOrNow(this.item.date_of_creation);
    this.dateModification = this.fromNowOrNow(this.item.date_of_modification);
    setInterval(() => {
      this.dateCreation = this.fromNowOrNow(this.item.date_of_creation);
      this.dateModification = this.fromNowOrNow(this.item.date_of_modification);
    }, 10000);
    if (_.get(this.item, 'team_id'))
    this.teamsService.getTeam(_.get(this.item, 'team_id')).subscribe((res)=>{this.teamName.next(res.team.name)})
  }

  fromNowOrNow(date) {
    date = date < moment().unix() ? date : moment().unix();
    return moment.unix(date).fromNow();
  }

  getTooltip(name) {
    if (name === 'upload') {
      if (_.includes(availableFeatures, Features.UploadFile)) {
        return 'Select a metadata file to upload'
      } else {
        return "You don't have the permission to upload"
      }
    }
  }
  ngOnDestroy() {
    if (this.route.snapshot.url[0].path !== PagesName.FileManager) {
      return;
    }
    // if (this.fileManager.getBulkSelectionLength()) {
    //   this.router.navigate([PagesName.FileManager, this.fileManager.viewTab, this.fileManager.openedFolderId, this.fileManager.bulkSelection[0]]);
    // } else {
    //   this.router.navigate([PagesName.FileManager, this.fileManager.viewTab, this.fileManager.openedFolderId]);
    // }
  }


}
