<div id="{{ group.id }}" (mouseleave)="iconText ? unSetIconText() : ''" class="list">
  <div class="list-container d-flex justify-content-between list-container-group group">
    <span class='flex-fill' (click)="contentVisibilitySwitcher()">
      <span class="item-left-icon mr-2">
        <i class="far fa-object-group" aria-hidden="true"></i>
      </span>
      <span> Group {{ group.name }}</span>
    </span>

    <div class="item-icons-container mx-0 px-0 row col-6">
      <div class="item-icon-text mx-0 px-0 col">
        {{ iconText }}
      </div>

      <div class="item-icons col-md-auto mx-0 px-0">

        <div   (mouseenter)="group.id !== groupsManagerService.openedGroupId ? setIconText('Show content') :  setIconText('Hide content')" (mouseleave)="unSetIconText()" (click)="contentVisibilitySwitcher()"
          class="btn btn-link hover-active">
          <i *ngIf="group.id !== groupsManagerService.openedGroupId" class="far fa-eye" aria-hidden="true"></i>
          <i *ngIf="group.id === groupsManagerService.openedGroupId" class="far fa-eye-slash" aria-hidden="true"></i>
        </div>
        <div *ngIf='group.capabilities.can_share'  (mouseenter)="setIconText('Share file group')" (mouseleave)="unSetIconText()" (click)="groupsManagerService.handleShareFilegroup(group.id)"
          class="btn btn-link hover-active">
          <i class="far fa-share-square" aria-hidden="true"></i>
        </div>
        <div *ngIf='group.capabilities.can_delete'  (mouseenter)="setIconText('Delete file group')" (mouseleave)="unSetIconText()" (click)="groupsManagerService.handleGroupDelete(group.id)"
          class="btn btn-link hover-active">
          <i class="far fa-trash-alt" aria-hidden="true"></i>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="getGroupContent()" class="group-files" [class.hidden]="group.id !== groupsManagerService.openedGroupId">
    <ul class='pl-0' *ngFor="let groupItem of getGroupContent(); trackBy : trackFn">
        <app-group-content *ngIf="!isAlreadyDeleted(groupItem.id)" [group]='group' [groupItem]='groupItem' [handleRemove]='handleRemove'></app-group-content>
    </ul>
    <div *ngIf='hasMore.value' class='mb-3 cursor-pointer text-center '>
      <span role='button' (click)='listContent()'
        class='text-uppercase btn-link  mx-2 font-weight-bold small text-primary'>
        List more
        <i aria-hidden='true' class="fas fa-chevron-down"></i>
      </span>
    </div>
  </div>
  <div (dragover)="groupsManagerService.allowDrop($event)"
    *ngIf="group.capabilities.can_modify && getGroupContent() && !getGroupContentLength()"
    [class.hidden]="group.id !== groupsManagerService.openedGroupId">
    <ul>
      <p>
        This group is empty. Please drag and drop your uploaded files here
        <i class="far fa-arrow-up" aria-hidden="true"></i>
      </p>
    </ul>
  </div>
  <div *ngIf='!group.capabilities.can_modify && getGroupContent() && !getGroupContentLength()'
    [class.hidden]="group.id !== groupsManagerService.openedGroupId">
    <ul>
      <p>
        This group is empty.
      </p>
    </ul>
  </div>
</div>
