import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import {
  DataType,
  lettersAA,
  lettersDNA,
  lettersRNA,
  colorsAA,
  colorsDNA,
  colorsRNA,
  LetterType,
  LegendType,
} from './viz-alignment.component';
@Component({
  selector: 'app-viz-alignment-thumbnail',
  templateUrl: './viz-alignment-thumbnail.component.html',
  styleUrls: ['./viz-alignment.scss', '../viz-style.scss'],
})
export class VizAlignmentThumbnailComponent implements OnInit {
  @Input() data;
  dataModified;
  @Input() layout;
  @Input() size;
  @Input() title;
  @Input() dataType;
  lettersAA = lettersAA;
  lettersDNA = lettersDNA;
  lettersRNA = lettersRNA;
  colorsAA = colorsAA;
  colorsDNA = colorsDNA;
  colorsRNA = colorsRNA;
  DataType = DataType;
  letters: Array<LetterType>;
  colors: Array<LegendType>;

  constructor() {}

  shrinkTableLength(table, maxNbColumns, maxNbLines) {
    const tableCopy = _.cloneDeep(table);
    tableCopy.cells = _.take(tableCopy.cells, maxNbLines);
    for (let i = 0; i < maxNbLines; i++) {
      if (tableCopy.cells[i]) {
        tableCopy.cells[i] = _.take(tableCopy.cells[i], maxNbColumns);
      }
    }
    return tableCopy;
  }

  getTypeFromLetter(letter) {
    return _.find(this.letters, { letter }, 'Blank').type;
  }
  getColorFromType(type) {
    return _.find(this.colors, { type }, null).color;
  }
  getColorFromLetter(letter) {
    const type = this.getTypeFromLetter(letter);
    return this.getColorFromType(type);
  }
  showNumber(i) {
    return i % 10 === 0 ? true : false;
  }
  setRefData() {
    if (this.dataType === this.DataType.AA) {
      this.letters = this.lettersAA;
      this.colors = this.colorsAA;
    }
    if (this.dataType === this.DataType.RNA) {
      this.letters = this.lettersRNA;
      this.colors = this.colorsRNA;
    }
    if (this.dataType === this.DataType.DNA) {
      this.letters = this.lettersDNA;
      this.colors = this.colorsDNA;
    }
  }
  processInput() {
    if (this.data && this.data.length) {
      this.dataModified = _.cloneDeep(
        this.shrinkTableLength(this.data[0], 15, 10)
      );
    }
  }
  ngOnInit(): void {
    this.setRefData();
    this.processInput();
  }
}
