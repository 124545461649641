import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';

@Component({
  selector: 'app-homepage',
  styleUrls: ['./homepage.component.scss'],
  templateUrl: './homepage.component.html',
})
export class HomepageComponent {
  createProjectPermission = false;
  environment = environment;
  folder = true;
  file = true;

  constructor(public l: LayoutOrganizationService,  private permissions: PermissionsHttpService,) {
    this.permissions
    .checkPermission({
      permissions: {
        createProject: {},
      },
    })
    .subscribe((res) => {
      this.createProjectPermission = res.createProject;
    });

  }

  changeFolderSrc() {
    this.folder = !this.folder;
  }

  changeFileSrc() {
    this.file = !this.file;
  }
}
