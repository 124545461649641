
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { FileSystemState } from '../pages/login/login-http-service/login-http.service';


@Injectable({ providedIn: 'root' })
export class LocalService {
  @LocalStorage() session;

  constructor() { }
  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  getFromLocalStorage(name, field?, defaultValue?) {
    let storage = localStorage.getItem("ngx-webstorage|" + name)
    if (storage && this.isJsonString(storage)) {
      let object = _.cloneDeep(JSON.parse(storage))
      if (field) {
        return _.get(object, field, defaultValue ? defaultValue : null)
      }
      return object
    }
    return defaultValue ? defaultValue : null
  }
  editLocalStorage(name, key, value) {
    let storage = this.getFromLocalStorage(name)
    _.set(storage, key, value)
    this.setLocalStorage(name, storage)
  }
  setLocalStorage = (name, value) => {
    return localStorage.setItem("ngx-webstorage|" + name, JSON.stringify(value));
  }
  getSpecificCountryUrl = (country) => {
    const file_system = this.getSpecificFileSystem(country)
    return _.get(file_system, 'url', this.session.apiPath)
  }
  getSpecificFileSystem(country) {
    const file_systems = this.getFromLocalStorage('user', 'file_systems')
    return _.find(file_systems, function (o) { return o.value === country; });
  }
  getSpecificCountryAccessToken = (country) => {
    const file_system = this.getSpecificFileSystem(country)
    return _.get(file_system, 'access_token', this.session.accessToken)
  }
  getRegionalizedURL = (url: string, country?) => {
    if (url.includes("/ca/") || url.includes("/fr/")) {
      return url; // already regionalized
    }

    if (country) {
      return _.replace(url, this.session.apiPath, this.getSpecificCountryUrl(country))
    }
      return _.replace(url, this.session.apiPath, this.getFromLocalStorage('country', 'url', this.session.apiPath))
  }
  // getRegionalizedURL2 = (url: string, country) => {
  //   if (url.includes("/ca/") || url.includes("/fr/")) {
  //     return url; // already regionalized
  //   }

  //   if (country) {
  //     return _.replace(url, environment.apiBasePath, this.getSpecificCountryUrl(country))
  //   }

  // }

  getAvailableCountries = () => {
    const file_systems = this.getFromLocalStorage('user', 'file_systems', [])
    let countryArray = []
    file_systems.forEach(element => {
      if (element.accessState !== FileSystemState.Disabled) {
        countryArray.push(element.value)
      }
    });
    return countryArray
  }


}
