<div class='h-100 flex-nowrap  row m-0'>
    <div class='utility-bar utility-bar-at-the-left'>
        <button placement="right"
        ngbTooltip="You can't perform this action in this tab" tooltipClass="custom-tooltip" class="mb-2 disabled text-left btn btn-dark">
            <i class="mx-2 far fa-file-upload" aria-hidden="true"></i>
            <span>
                Upload
                files </span>
        </button>
        <div class=" btn-group-vertical" role="group">
            <button placement="right"
            ngbTooltip="You can't perform this action in this tab" tooltipClass="custom-tooltip"  class="btn text-left disabled btn-dark">
                <i class="mx-2 far fa-folder-plus" aria-hidden="true"></i>
                <span> Add folder
                </span>
            </button>
            <button  placement="right"
            ngbTooltip="You can't perform this action in this tab" tooltipClass="custom-tooltip" class="btn text-left disabled btn-dark">
                <i class="mx-2 far fa-object-group" aria-hidden="true"></i>
                <span> Create group
                </span>
            </button>
        </div>

    </div>
    <div class='col-7 p-0 h-100 d-flex flex-column' id='FILES-AND-GROUPS'>
        <div class='scrollable h-100'>
            <div id='FILES' class='m-3 footer-margin'>
                <div id="top-utility-bar" class='rounded'>
                    <div [style.min-height.px]="35" class='d-flex flex-wrap justify-content-between'>
                        <span class='flex-nowrap'>
                            <span class="cursor-info"
                                [ngbTooltip]="deletedFilesManager.isBulkSelected() ? 'Unselect all files' : 'Select all files'"
                                tooltipClass="custom-tooltip" placement="bottom">
                                <app-checkbox [id]="" [fileType]='null'
                                    [selectAction]='deletedFilesManager.selectAllDisplayedFiles'
                                    [unSelectAction]='deletedFilesManager.resetBulkSelection'
                                    [selected]="deletedFilesManager.isBulkSelected()" bulkType='allFiles'>
                                </app-checkbox>
                            </span>

                            <span class="px-3 text-light"
                                [class.font-weight-bold]='!deletedFilesManager.isBulkSelected()'>
                                Check all</span>
                            |
                            <span class="px-3  text-light"
                                [class.font-weight-bold]='deletedFilesManager.isBulkSelected()'>Uncheck
                                all</span>
                            <span *ngIf="deletedFilesManager.getBulkSelectionLength()">|</span>
                            <span class="px-3 text-light" *ngIf="deletedFilesManager.getBulkSelectionLength()"
                                click=""><span
                                    class="font-weight-bold">{{deletedFilesManager.getBulkSelectionLength()}}</span>
                                item{{deletedFilesManager.getBulkSelectionLength()>1 ? 's':''}}
                                selected <span class='mx-2'> -
                                </span><strong>{{deletedFilesManager.getBulkSize()[0]}}</strong>
                                {{deletedFilesManager.getBulkSize()[1]}}</span>
                        </span>


                        <div *ngIf="deletedFilesManager.filtersVisible" class="d-flex flex-nowrap">
                            <button
                                (click)='deletedFilesManager.setSortFilter(SortType.Title);deletedFilesManager.toggleSortDirection(); deletedFilesManager.listContent()'
                                class="btn noBoxShadow">
                                <span class="text-light">Sort By Name <i class="fas"
                                        *ngIf='deletedFilesManager.getSortFilter() === SortType.Title'
                                        [class.fa-sort-up]="deletedFilesManager.getSortDirection() === SortDirection.Asc"
                                        [class.fa-sort-down]="deletedFilesManager.getSortDirection() === SortDirection.Desc"
                                        aria-hidden="true"></i></span>
                            </button>
                            <button
                                (click)='deletedFilesManager.setSortFilter(SortType.DateCreation);deletedFilesManager.toggleSortDirection(); deletedFilesManager.listContent()'
                                class="btn noBoxShadow">
                                <span class="text-light">Sort By Date <i class="fas"
                                        *ngIf='deletedFilesManager.getSortFilter() === SortType.DateCreation'
                                        [class.fa-sort-up]="deletedFilesManager.getSortDirection() === SortDirection.Asc"
                                        [class.fa-sort-down]="deletedFilesManager.getSortDirection() === SortDirection.Desc"
                                        aria-hidden="true"></i></span>
                            </button>
                        </div>
                        <div class="flex-nowrap">
                            <button *ngIf="!deletedFilesManager.filtersVisible"
                                (click)='deletedFilesManager.toggleFilter()' class="btn noBoxShadow">
                                <span class="text-light font-weight-bold">Show filters</span>
                            </button>
                            <button *ngIf="deletedFilesManager.filtersVisible"
                                (click)='deletedFilesManager.toggleFilter()' class="btn noBoxShadow">
                                <span class="text-light font-weight-bold">Hide filters</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class='d-flex flex-nowrap'>
                    <div *ngIf='deletedFilesManager.getDisplayedFilesLength() && deletedFilesManager.finishedListing' id="files-content"
                        class="w-100 d-flex flex-column">
                        <div class="my-2 d-flex flex-column">
                            <div *ngFor="let item of 
                                deletedFilesManager.getDisplayedFiles(); trackBy: trackByFn
                              ">
                                <app-item-list [selected]='deletedFilesManager.isBulkSelected(item.id)' [item]="item">
                                </app-item-list>
                            </div>
                            <ngb-pagination (pageChange)="pageChanged()" class="pager my-3"
                                [(page)]="deletedFilesManager.page" [maxSize]="5"
                                [pageSize]="deletedFilesManager.pageSize" [collectionSize]="deletedFilesManager.count">
                                <ng-template ngbPaginationPrevious><i class="far fa-chevron-left"
                                        aria-hidden="true"></i></ng-template>
                                <ng-template ngbPaginationNext><i class="far fa-chevron-right" aria-hidden="true"></i>
                                </ng-template>
                            </ngb-pagination>
                        </div>

                    </div>

                    <div class='empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
                        *ngIf='deletedFilesManager.finishedListing && !deletedFilesManager.getDisplayedFilesLength()'>
                        <p>
                            All deleted files and folders will display here.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-3 p-0 active" id='PANNEL'>
        <div id='BUTTONS_RIGHT'>
            <div class='d-flex flex-nowrap'>
                <button (click)='deletedFilesManager.handleRestore()'
                    [class.disabled]='deletedFilesManager.getBulkSelectionLength() ? false : true'
                    class='m-2 btn btn-dark'><i class="far mx-2 fa-trash-undo-alt" aria-hidden="true"></i>
                    Restore </button>
                <button (click)='deletedFilesManager.handlePermanentelyDelete()'
                    [class.disabled]='deletedFilesManager.getBulkSelectionLength() ? false : true'
                    class='m-2 btn btn-outline-dark'><i class="far mx-2 fa-trash-alt" aria-hidden="true"></i>
                    Permanentely
                    delete</button>
            </div>
        </div>
    </div>
</div>