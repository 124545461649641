import { Component, OnInit, OnDestroy, EventEmitter, AfterViewInit } from '@angular/core';
import { ApplicationScreenSizeService } from './shared/application-screen-size-service/application-screen-size.service';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { LayoutOrganizationService } from './api/layout-organization.service';
import moment from 'moment';
import '../../node_modules/moment/locale/en-ca'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'MIMS';
  mobileSubscription: Subscription;
  mobile;
  orientationLandscape = false;
  needAppRefreshSubscription: Subscription;
  needAppRefresh = new EventEmitter<boolean>(false)
  versionTracker: number;

  public basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  constructor(private appScreenSize: ApplicationScreenSizeService, private l: LayoutOrganizationService, private readonly http: HttpClient) { }

  ngOnInit() {
    moment.locale('en-ca')
    this.mobileSubscription = this.appScreenSize.isMobile.subscribe((res) => {
      this.mobile = res;
      if (
        this.mobile &&
        window.matchMedia('(orientation: landscape)').matches
      ) {
        this.orientationLandscape = true;
      } else {
        this.orientationLandscape = false;
      }
    });
    this.appScreenSize.getMobile();
    this.needAppRefreshSubscription = this.needAppRefresh.subscribe(() => {
      this.l.customConfirm('A new version of MimsOmic is available. Click OK to refresh the page.',
        () => this.appRefresh(),
        () => {
          this.appUpdater(1000 * 60 * 60);
        })
    })

  }
  ngAfterViewInit() {
    this.appUpdater(1000 * 60 * 15)

  }
  appRefresh = () => {
    window.location.reload();
  }
  appUpdater = (frequency: number) => {
    const precedentVersion = _.cloneDeep(_.get(this, 'versionTracker', null))
    this.getVersion().subscribe((res) => {
      _.set(this, 'versionTracker', res)
      if (precedentVersion && precedentVersion !== _.get(this, 'versionTracker', null)) {
        this.needAppRefresh.emit(true)
        return;
      }
      setTimeout(() => {
        this.appUpdater(frequency)
      }, frequency)
    })
  }

  getVersion(): Observable<number> {
    let url = _.get(environment, 'platformUrl', 'https://mimsomicbeta.mims.ai')+ '/last_deployed.txt'
    return this.http.get<number>(url);
  }
  onResize() {
    this.appScreenSize.getMobile();
    this.appScreenSize.getTablet();
    this.appScreenSize.getDesktop();
  }

  ngOnDestroy() {
    if (this.mobileSubscription)
      this.mobileSubscription.unsubscribe();
    if (this.needAppRefreshSubscription)
      this.needAppRefreshSubscription.unsubscribe();
  }
}
