import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ExplorerService {
  @LocalStorage() session;

  protected basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  constructor() {}

  createSocket() {
    const address = `${this.basePath.replace('http', 'ws')}/gas?access_token=${
      this.session.accessToken
    }`;

    return new WebSocket(address);
  }
}
