import { Component, OnInit } from '@angular/core';
import { FileManager } from 'src/app/api/file-manager.service';
import * as _ from 'lodash';
import { FileSystemState, LoginHttpService } from '../../login/login-http-service/login-http.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AdditionalTypes, InputJson } from 'src/app/shared/helpers/formattedInput';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { LocalService } from 'src/app/api/local-storage.service';
@Component({
  selector: 'app-my-files-country-selector',
  templateUrl: './my-files-country-selector.component.html',
  styleUrls: ['../../../shared/layout/managers.scss', './my-files-country-selector.component.scss',]
})
export class MyFilesCountrySelectorComponent implements OnInit {
  countrySelectionForm: FormGroup ;
  countryFieldObject: InputJson = null;
  constructor(private localService : LocalService, private fileManager: FileManager, private l: LayoutOrganizationService) { };
  ngOnInit() {
    this.countrySelectionForm = this.createEmptyForm()
    this.countryFieldObject = this.createCountryField();
  }
  setCountry() {
    let countrySelected = this.countrySelectionForm.controls.country.value
    if (_.get(countrySelected, 'accessState') === FileSystemState.Disabled) {
      this.l.toast("<b>Disabled storage location</b>, please contact your team manager to request access", null, 8000, '', 'warning')
      return
    }
    if (_.get(countrySelected, 'accessState') === FileSystemState.Local || _.get(countrySelected,'accessState') === FileSystemState.External ) {
      this.updateCountry(countrySelected)
      return
    }
  }
  updateCountry(countrySelected) {
    this.l.close()
    this.localService.setLocalStorage('country',countrySelected)
    this.fileManager.countrySelected.emit(true)
    _.get(this.fileManager, 'eventRefreshGroups').next(true)
  }
  createCountryField = () => {
    let file_system = _.cloneDeep(this.localService.getFromLocalStorage('user','file_systems'))
    let default_value = _.find(file_system, (o) => { return o.value === this.localService.getFromLocalStorage('country','value') });
    return {
      formControlName: 'country',
      type: AdditionalTypes.Object,
      fitToContent: false,
      is_array: true,
      multiple_selection: false,
      allowed_values: file_system,
      default_value: default_value
    }
  }
  getCountrySelectionForm() {
    return _.get(this, 'countrySelectionForm')
  }
  getCountryFieldObject() {
    return _.get(this, 'countryFieldObject')
  }
  createEmptyForm() {
    return new FormGroup({
      country: new FormControl(),
    })
  }

}
