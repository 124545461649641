import { Component, Input, Output } from '@angular/core';
import { FileManager } from '../../../api/file-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import * as _ from 'lodash';
import { DashboardService } from 'src/app/api/dashboard.service';
import { NotificationsService } from 'src/app/api/notifications.service';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-horizontal-nav-tab',
  templateUrl: './horizontal-nav-tab.component.html',
  styleUrls: ['./horizontal-nav-tab.component.scss'],
})
export class HorizontalNavTabComponent {
  @Input() titles;
  @Input() links;
  @Input() functionsArray? = [];
  @Output() selectedLink? = new BehaviorSubject<any>(undefined);
  @Output() selectedIndex? = new BehaviorSubject<any>(undefined);
  @Output() selectedTitle? = new BehaviorSubject<any>(undefined);
  @Input() activeTab? = undefined;
  TabsName = TabsName;
  canBeActivated = true;
  PagesName = PagesName;
  manager = null;
  pageName = '';
  @Input() navigate?: boolean = true;
  constructor(
    public fileManager: FileManager,
    public notificationService: NotificationsService,
    public projectManager: ProjectManagerService,
    public dashboardService: DashboardService,
    public l: LayoutOrganizationService,
    public router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    let path = _.get(this, 'route.snapshot.url[0].path');
    if (path === this.PagesName.DevTest) {
      this.manager = this.projectManager;
      this.pageName = PagesName.DevTest;
    }
    if (path === this.PagesName.UserNotifications) {
      this.manager = this.notificationService;
      this.pageName = PagesName.UserNotifications;
    } else if (path === this.PagesName.FileManager) {
      this.manager = this.fileManager;
      this.pageName = PagesName.FileManager;
    } else if (path === this.PagesName.ProjectManager) {
      if (!_.nth(this.route.snapshot.url, 2)) {
        this.manager = this.projectManager;
        this.pageName = PagesName.ProjectManager;
      } else {
        this.manager = this.dashboardService;
        this.pageName = null;
      }
    }
  }

  isActive(link, i) {
    if (this.activeTab !== undefined) {
      return this.activeTab === i
    }
   return _.get(this, 'manager.viewTab') === link;
  }
  switchTabTo(viewTab: TabsName, i) {
    if (!viewTab) {
      return;
    }
    if (!this.canBeActivated) {
      return;
    }
    if (_.get(this, 'functionsArray') && _.nth(this.functionsArray, i)) {
      _.nth(this.functionsArray, i)();
    }
    this.canBeActivated = false;
    _.set(this, 'manager.viewTab', viewTab);
    let event = _.get(this, 'manager.tabEvent');
    if (event) {
      event.next(i);
    }
    this.activeTab = i
    this.selectedIndex.next(i);
    this.selectedTitle.next(_.nth(this.titles, i));
    this.selectedLink.next(_.nth(this.links, i));
    this.l.close();
    if (this.navigate) {
      this.router.navigate([this.pageName, viewTab]);
    }
    setTimeout(() => {
      this.canBeActivated = true;
    }, 100);
  }
}
