import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { ItemType } from 'src/app/api/share.service';
@Component({
  selector: 'app-share-project',
  styleUrls: ['./share-project.component.scss'],
  templateUrl: './share-project.component.html'
})
export class ShareProjectComponent implements OnInit, OnDestroy {
  ItemType = ItemType;
  @Input() item: any;
  constructor(
    public projectManagerService: ProjectManagerService,
    public layout: LayoutOrganizationService,
    public router: Router,
    public route: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    //this.router.navigate([PagesName.ProjectManager, this.projectManagerService.viewTab, this.projectManagerService.bulkSelection[0], 'share']);
  }

  ngOnDestroy() {
    // if (this.route.snapshot.url[0].path === PagesName.ProjectManager) {
    //   this.router.navigate([PagesName.ProjectManager, this.projectManagerService.viewTab]);
    // }
  }

}
