<div [id]="id" class="list">
  <div class="list-container row" [class.tile-selected]="isTrashed ? selected : false">
    <div class="item-text-container row mx-0 px-0 col-6 flex-nowrap ">
      <app-checkbox *ngIf='isTrashed' [fileType]='FileType.Project'
        [selectAction]='projectManagerService.addToBulkSelection' [country]='country' [title]='title'
        [unSelectAction]='projectManagerService.removeFromBulkSelection' [id]="id" bulkType='project'
        [selected]="selected">
      </app-checkbox>
      <div (click)='projectManagerService.goTo(id,country)' class="cursor-pointer item-text project-title " [class.px-4]='!isTrashed'>{{ title }}</div>
    </div>
    <div class="item-icons-container mx-0 px-0 row col-6">
      <div class="item-icon-text mx-0 px-0 col">
        {{ iconText }}
      </div>
      <div *ngIf='isTrashed' class="d-flex item-icons col-md-auto mx-0 px-0"><span class='mx-2 '><small>
            <span class='text-uppercase text-muted '>deleted : </span>
            <span class='text-muted'>{{moment.unix(project.date_of_trashing).format('YY/MM/DD - hh:mm A')}}
            </span></small></span>
      </div>
      <div *ngIf='!isTrashed' class="item-icons col-md-auto mx-0 px-0">
        <button class="btn btn-link hover-active goToProjectButton" (mouseenter)="setIconText('Go to project')"
          (mouseleave)="unSetIconText()"
          (click)="projectManagerService.goTo(id,country)">
          <i class="far fa-chart-bar" aria-hidden="true"></i>
        </button>
        <button class="btn btn-link hover-active"
          (click)="share($event)"
          (mouseenter)="setIconText('Share project')" (mouseleave)="unSetIconText()" class="btn btn-link hover-active">
          <i class="far fa-share-square" aria-hidden="true"></i>
        </button>
        <button
          (click)="getInfos($event)"
          (mouseenter)="setIconText('Read description')" (mouseleave)="unSetIconText()" class="btn btn-link hover-active">
          <i class="far fa-info-circle" aria-hidden="true"></i>
        </button>
        <button class="btn btn-link hover-active deleteButton" (mouseenter)="setIconText('Delete project')"
          (mouseleave)="unSetIconText()"
          (click)="trash($event)">
          <i class="far fa-trash-alt" [class.disabled]='!canTrash' aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</div>
