import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class PermissionsHttpService {
  protected basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  constructor(private http: HttpClient) {}

  checkPermission(permission) {
    return this.http.post<any>(
      `${this.basePath}/mas/auth/is_authorized`,
      permission
    );
  }
}
