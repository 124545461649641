<span class='h-100 w-100 d-flex align-items-center'>
  <span class="path w-100 ">
    <span *ngFor="let el of getPath(); let last=last; let first = first">
      <span [class.last-path-element]="last" [class.cursor-pointer]='!last && (el.action || getId(el))'
        class='path-element p-0' [class.btn-link]='getId(el)' *ngIf='getName(el)' (click)="handleClick(el,last)">
        <i aria-hidden="true" *ngIf='!first' [class.text-dark]='theme === "light" && !last'
          class="fal mx-2 fa-chevron-right"></i>
        <span [class.font-weight-bold]='first' [class.text-dark]='theme === "light" && !last'>{{getName(el) }}</span>
      </span>
    </span>
  </span>
</span>