import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
@Injectable({ providedIn: 'root' })
export class UserCreationHttpService {
  protected _basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  constructor(private http: HttpClient) {}

  existingUserCheck(
    email
  ): Observable<{
    [key: string]: boolean;
  }> {
    const emailObject = { email };
    return this.http.post<{ [key: string]: boolean }>(
      `${this._basePath}/mas/users/email-exists`,
      emailObject
    );
  }

  submitUser(user: {
    email: string;
    first_name: string;
    last_name: string;
    ontological_domain: string;
    file_hosting_country: string;
    organization: string;
    password: string;
  }) {
    return this.http.post(`${this._basePath}/mas/users`, user);
  }

  get basePath() {
    return this._basePath;
  }

  listAllUsers() {
    return this.http.get<Array<any>>(`${this.basePath}/mas/users`);
  }

  resetUserPassword(userEmail, password) {
    const body = {
      email: userEmail,
      new_password: password,
    };
    return this.http.post(`${this.basePath}/mas/auth/reset-password`, body);
  }

  activateUser(id) {
    return this.http.post(`${this.basePath}/mas/auth/activate`, {
      user_id: id,
    });
  }

  deactivateUser(id) {
    return this.http.post(`${this.basePath}/mas/auth/deactivate`, {
      user_id: id,
    });
  }
}
