import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ModelsPostGroup } from '../model/models';
import { FilesService } from './files.service';
import { LayoutOrganizationService } from './layout-organization.service';
import { FileManager } from './file-manager.service';
import { LocalService } from './local-storage.service';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { Observable } from 'rxjs';



@Injectable()
export class GroupsManagerService {
    groups: Array<any> = [];
    groupsTemp: Array<any> = [];
    groupTemp: Array<any> = []
    groupCreationForm;
    // openedGroupIndex;
    openedGroupId;
    groupSelectElement;
    page = 1;
    pageSize = 5;
    finishedListing;
    filegroupsStorageCountry = null
    itemsWithInfoDisplayed = []
    groupsFromAllCountries: Array<any> = [];
    bulkSelection = []
    constructor(
        private filesService: FilesService,
        private fileManager: FileManager,
        private layout: LayoutOrganizationService,
        private localService: LocalService
    ) { }

    async *getGroup(group, limit?) {
        let response = {}
        await new Promise((resolve) => {
            this.filesService.listGroup(group.id, 'title,asc', 'ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true', limit || 10)
                .subscribe((res) => {
                    response = res
                    resolve(true)
                }, () => {
                    this.layout.toast("Impossible to <b>list</b> filegroup <b>" + group.name + "</b> content", null, 5000, '', 'danger')
                    resolve(true)
                } )
        });
        yield response;
        while (_.get(response, 'links.next')) {
            await new Promise((resolve) => {
                this.filesService.listGroupFromUrl(_.get(response, 'links.next')).subscribe((res2) => {
                    _.set(response, 'data', _.concat(_.get(response, 'data'), res2.data))
                    _.set(response, 'links.next',_.get(res2, 'links.next'))
                    _.set(response, 'has_more',_.get(res2, 'has_more'))
                    resolve(true)
                }, () => {
                    this.layout.toast("Impossible to <b>list more</b> content of filegroup <b>" + group.name + "</b> ", null, 5000, '', 'danger')
                    resolve(true)
                })
            })
            yield response;
        }
    }




    handleShareFilegroup(id) {
        this.layout.close()
        this.layout.open('modalGroupsShare')
        this.bulkSelection = []
        this.bulkSelection.push(id)
        this.getInfos(id)
    }
    getInfos(id) {
        this.itemsWithInfoDisplayed = []
        this.filesService.retrieveFileGroup(id).subscribe((res) => {
            this.itemsWithInfoDisplayed.push(res);

        })
    }
    getFilegroupsStorageCountry() {
        if (_.includes(availableFeatures, Features.MultiCountry)) {
            return _.get(this, 'filegroupsStorageCountry', null)
        } else {
            return this.localService.getFromLocalStorage('user', 'account_country')
        }
    }
    setFilegroupsStorageCountry(country) {
        _.set(this, 'filegroupsStorageCountry', country)
    }

    getGroups = (filter?) => {
        if (filter === 'ownedByMe=true') {
            return _.filter(_.get(this, 'groups', []), (o) => { return o.owned_by_me })
        }
        else {
            return _.reject(_.get(this, 'groups', []), (o) => { return o.owned_by_me })
        }
    }
    getGroupsLength = (filter?) => {
        if (!filter) {
            return _.get(this, 'groups.length', 0)
        }
        return this.getGroups(filter).length
    }
    promise1 = (sortby, filters, limitParam) => new Promise((resolve, reject) => {
        this.filesService.listGroups(sortby, filters, limitParam).subscribe((res) => {
            this.groupsTemp = _.concat(this.groupsTemp, res.data)
            resolve(res)
        }, () => {
            reject()
        })
    })
    promise2 = (res) => {
        return new Promise((resolve, reject) => {
            this.filesService.listGroupsFromUrl(res.links.next).subscribe(
                (res2) => {
                    this.groupsTemp = _.concat(this.groupsTemp, res2.data)
                    if (res2['has_more']) {
                        this.promise2(res2)

                    } else {
                        this.updateMaxPage()
                        resolve(res2)
                    }
                },
                () => {
                    reject();
                }
            );
        })
    }
    listGroups = () => new Promise((resolve, reject) => {
        this.setFilegroupsStorageCountry(this.localService.getFromLocalStorage('country').value)
        this.page = 1
        this.finishedListing = false
        let limitParam = _.get(this, 'limitParam', 100)
        this.groupsTemp = []
        this.promise1(null, 'ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true', limitParam).then(res => {
            if (res['has_more']) {
                this.promise2(res).then(() => {
                    this.printList()
                    resolve(true)
                }).catch((e) => { console.log(e); reject() })
            }
            this.updateMaxPage()
            this.printList()
            resolve(true)
        })
    })
    updateMaxPage = () => {
        if (this.page > Math.ceil(this.groupsTemp.length / this.pageSize)) {
            _.set(this, ['page'], Math.ceil(this.groupsTemp.length / this.pageSize))
        }
    }

    printList = () => {
        _.set(this, 'groups', this.groupsTemp)
        this.finishedListing = true
    }

    getGroupFirstContent = (groupeId: string, groupTitle) => new Promise((resolve, reject) => {
        let limitParam = _.get(this, 'limitParam', 10)
        this.filesService.listGroup(groupeId, 'title,asc', 'ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true', limitParam)
            .subscribe((res) => {
                resolve(res)
            }, () => {
                this.layout.toast("Impossible to <b>list</b> filegroup <b>" + groupTitle + "</b> content", null, 5000, '', 'danger')

            })
    })




    getDragEndGroupTargetId(id) {
        this.fileManager.destinationId = id;
        this.fileManager.destinationIdType = 'group';
    }
    resetGroupCreationForm = () => {
        if (this.groupCreationForm) {
            this.groupCreationForm.reset();
        }
    };
    handleRemoveFromGroup = (groupId, fileId, groupTitle?, itemTitle?) =>  {
        return new Promise((resolve, reject) => {
        this.filesService.removeFileFromGroup(groupId, fileId).subscribe(
            (res) => {
                this.layout.toast("Item  <b>" + itemTitle + "</b> sucessfully <b>removed</b> from group <b>" + groupTitle + "</b>.", null, 5000, '', 'success')
                resolve(true)
            },
            (error) => {
                this.layout.toast("Impossible to <b>removed</b> item  <b>" + itemTitle + "</b> from group <b>" + groupTitle + "</b>.", null, 5000, '', 'danger')
                reject()
            }
        );
        })
    }
    _handleGroupDelete(id) {
        this.filesService.deleteGroup(id).subscribe(
            (res) => {
                this.fileManager.eventRefreshGroups.next(true)
                this.layout.close();
            },
            (error) => {
                if (error.status === 422) {
                    alert('This group can not be deleted.')
                } else {
                    alert(error.error.message);
                }
            }
        );

    }
    handleGroupDelete(id) {
        let text = 'This group will be deleted. Are you sure ?'
        let self = this
        this.layout.customConfirm(text, function () {
            self._handleGroupDelete(id)
        })
    }
    allowDrop(event) {
        event.preventDefault();
    }
    rename = (itemId, value, field) => {
        return new Observable((observer) => {
            this.filesService.filegroupPatch(itemId, value, field).subscribe((res) => {
                this.listGroups()
                observer.next(res)
            }, (error) => {
                observer.next(error)
            })

        })
    }
    handleNewGroup = () => {
        const newGroupObject: ModelsPostGroup = {
            description: '',
            metadata: {},
            name: this.groupCreationForm.get('groupInput').value,
        };
        this.filesService.postGroup(newGroupObject, 'response').subscribe(
            (res) => {
                this.layout.close('modalGroup');
                this.listGroups();
                this.resetGroupCreationForm();
            },
            (error) => {
                if (error.status === 422) {
                    alert('Error during the creation of the group.')
                } else {
                    alert(error.error.message);
                }
            }
        );
    };



    extractGroupsFromAllCountries(response, countryArray) {
        let groups = []
        countryArray.forEach(country => {
            let data = _.get(_.get(response, country), 'data')
            data.forEach(group => {
                _.set(group, 'country', country)
            });
            groups.push(...data)
        });
        return groups
    }


}