<div class="layout-container">
  <app-sidebar></app-sidebar>
  <div (click)="l.closeAllModals()" *ngIf="l.isOverlayOpen" class="modal-overlay" id="modal-overlay"></div>

  <div class="main-content" [hidden]="l.mobile && l.isSideBarOpen">
    <router-outlet> </router-outlet>

    <app-explorer *ngIf="_.includes(availableFeatures, Features.Explorer)" [hidden]="!l.explorerIsOpen"> </app-explorer>
    <app-explorer-icon
      *ngIf="_.includes(availableFeatures, Features.Explorer)"
      [class.opened]="l.explorerIsOpen"
      (click)="l.switchExplorerOpenStatus()"
    ></app-explorer-icon>
  </div>
</div>

<div (click)="l.close('modalConfirm')" *ngIf="l.isConfirmModalOverlayOpen" class="modal-overlay-light" id="modal-overlay-confirm"></div>

<app-custom-modal
  modalTitle="Confirm"
  idName="modalConfirm"
  [show]="l.isConfirmOpen"
  class="border"
  confirmTitle="ok"
  [confirmActions]="l.confirmCallback"
  [confirmValidator]="true"
  [cancelActions]="l.cancelCallback"
>
  <div *ngIf="l.confirmMessage.length" class="custom-modal-content">
    <div class="my-5" [innerHtml]="l.confirmMessage"></div>
  </div>
</app-custom-modal>

<div #toastContainer class="toast-container" aria-live="polite" aria-atomic="true"></div>
