<div class='w-100 h-100 d-flex flex-column'>
  <app-header>
    <div [hidden]='fileManager.quality'>

      <app-path [onClickAction]='myFilesManager.openFolderInPath' [onClickActionLastElement]='myFilesManager.refreshFolder'
      *ngIf='fileManager.viewTab === TabsName.MyFiles'
        [path]="myFilesManager.getCompletePath()">
      </app-path>
      <app-path [onClickAction]='sharedFilesManager.openFolderInPath'  [onClickActionLastElement]='sharedFilesManager.refreshFolder'
        *ngIf='fileManager.viewTab === TabsName.SharedFiles' 
        [path]="sharedFilesManager.getCompletePath()">
      </app-path>
      <app-path *ngIf='fileManager.viewTab === TabsName.DeletedFiles && _.get(deletedFilesManager,"pathIsReady")'
        [path]=" _.get(deletedFilesManager,'path.parts', [])">
      </app-path>
    </div>
    <div *ngIf='fileManager.quality' class="text-light h-100 w-100 d-flex justify-content-between bg-dark">
      <button (click)="_.set(fileManager, 'quality',null)" class="h-100 btn btn-link">
        <i aria-hidden='true' class="fal text-info fa-long-arrow-left"></i>
        <span class="text-info"> Back to file manager</span>
      </button>

      <div class="h-100 mx-3  d-flex align-items-center">
        <h3>{{_.get(fileManager,'quality.title','')}}</h3>
      </div>
      <div class="h-100 mx-3  d-flex align-items-center">
        <h3 class='text-primary'> Quality dashboard</h3>
      </div>
    </div>
  </app-header>
  <app-horizontal-nav-tab [hidden]='fileManager.quality' [navigate]='false'
    [links]="[TabsName.MyFiles, TabsName.SharedFiles, TabsName.DeletedFiles]" 
    [titles]="[TabsPublicName.MyFiles, TabsPublicName.SharedFiles, TabsPublicName.DeletedFiles]">
    <app-my-files-country-selector *ngIf='_.includes(availableFeatures,Features.MultiCountry)'></app-my-files-country-selector>
  </app-horizontal-nav-tab>
  <app-content [hidden]='fileManager.quality' [isTab]='true' [isHeader]='true'>
    <app-my-files *ngIf='fileManager.viewTab === TabsName.MyFiles'></app-my-files>
    <app-shared-files *ngIf='fileManager.viewTab === TabsName.SharedFiles'></app-shared-files>
    <app-deleted-files  *ngIf='fileManager.viewTab === TabsName.DeletedFiles'></app-deleted-files>
  </app-content>
  <app-content *ngIf='fileManager.quality' [isTab]='false' [isHeader]='true'>
    <app-quality-overview></app-quality-overview>
  </app-content>
</div>
<DlProxyComponent [fileId]='myFilesManager.bulkSelection[0]' [fileLink]='myFilesManager.dlLink'  [resetFunction]='myFilesManager.handleDownloadReset()'
  *ngIf='myFilesManager.download'>
</DlProxyComponent>
<DlProxyComponent [fileId]='sharedFilesManager.bulkSelection[0]'
  [resetFunction]='sharedFilesManager.handleDownloadReset()' [fileLink]='sharedFilesManager.dlLink' *ngIf='sharedFilesManager.download'>
</DlProxyComponent>