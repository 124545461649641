import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ShareService } from 'src/app/api/share.service';
import * as _ from 'lodash';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { LoginHttpService } from 'src/app/pages/login/login-http-service/login-http.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';
export const Menu = [{
  privateName: 'UserSettings',
  publicName: 'My profile',
  sub: [{
    privateName: 'Info',
    publicName: 'Account information'
  },
  {
    privateName: 'Password',
    publicName: 'Modify password'
  }],
},
{
  privateName: 'UserGroupSettings',
  publicName: 'Manage user groups',
  sub: [{
    privateName: 'UsergroupNew',
    publicName: 'Create a new group'
  },
  {
    privateName: 'UsergroupEdit',
    publicName: 'Edit user groups'
  }

  ]
}]

declare var $: any;
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  @LocalStorage() user;

  userGroupCreationForm: FormGroup;
  passwordModificationForm: FormGroup;
  showNewPassword = false;
  showCurrentPassword = false;
  showNewPasswordConfirmation = false;
  passwordFormSubmitted = false;
  currentPasswordError = false;
  inviteForm: FormGroup;
  activeLink;
  activeSubLink;
  environment = environment;
  usersToInvite = [];
  usersToRemove = [];
  filterEmail = '';
  _ = _;
  canCreateTeam = false;
  retrievedUsergroup;
  Menu = Menu;

  currentSection = this.Menu[0].privateName;
  currentSubSection = null
  avatarFontSize? = '4.5rem';
  teams;
  subscription;
  public debouncedFilter: any = _.debounce(() => {
    this.shareService.getTeamsAndUsergroupsMembers(this.filterEmail);
  }, 100);
  constructor(
    public shareService: ShareService, public l: LayoutOrganizationService, public loginHttpService: LoginHttpService,
  ) {

  }
  ngOnInit() {
    this.initUserGroupCreationForm()
    this.initPasswordForm()
    this.initInviteForm()
    let self = this;
    self.subscription = self.shareService.getTeams().subscribe((res) => {
      self.teams = res;
    })
  }

  getTeams() {
    return _.get(this, 'teams', [])
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }


  // INVITE FORM
  initInviteForm = () => {
    this.inviteForm = new FormGroup({
      usergroupSelected: new FormControl(
        null, [Validators.required]),
    })
  }
  get usergroupSelected() {
    return this.inviteForm.get('usergroupSelected');
  }
  getRetrievedUsergroupPermissions() {
    return _.get(this, 'retrievedUsergroup.permissions', [])
  }
  getCanManage() {
    return _.get(this, 'retrievedUsergroup.capabilities.can_manage', false)
  }
  toggleUserSelectionToRemove(user) {
    let index = _.indexOf(this.usersToRemove, user)
    if (index > -1) {
      this.usersToRemove.splice(index, 1)
    } else {
      this.usersToRemove.push(user)
    }
  }
  toggleUserSelectionToInvite(user) {
    let index = _.findIndex(this.usersToInvite, user)
    if (index > -1) {
      this.usersToInvite.splice(index, 1);
      if (user.type === this.shareService.AutoCompleteType.NewMembers) {
        this.removeNewMember(user)
      }
    } else {
      this.usersToInvite.push(user)
      if (user.type === this.shareService.AutoCompleteType.NewMembers) {
        this.addNewMember(user)
      }
    }

  }
  addNewMember = (user) => {
    let index = _.findIndex(this.shareService.autoCompleteData, user)
    if (index === -1) {
      this.filterEmail = ''
      this.shareService.autoCompleteData.push(user)
    }
  }
  removeNewMember = (user) => {
    let index = _.findIndex(this.shareService.autoCompleteData, user)
    if (index > -1) {
      this.shareService.autoCompleteData.splice(index, 1);
    }
  }
  removeUserSelectionToInvite(user) {
    let index = _.findIndex(this.usersToInvite, user)
    if (index > -1) {
      this.usersToInvite.splice(index, 1);
    }
  }
  createNewMember() {

    return { value: this.filterEmail, type: this.shareService.AutoCompleteType.NewMembers }
  }
  handleChangePermission(id, role) {
    this.shareService.updateUserPermissionInUsergroup(this.retrievedUsergroup.id, id, role).subscribe(() => {
      this.refreshGroup()
    })
  }
  handleDeleteUsersFromUsergroup() {
    this.usersToRemove.forEach((element, index) => {
      this.shareService.removeUserFromUserGroup(this.inviteForm.value.usergroupSelected, element).subscribe((res) => {
        if (index === (this.usersToRemove.length - 1)) {
          this.refreshGroup()
        }
        _.remove(this.usersToRemove, element)
      },
        (error) => {
          if (error.status === 404 || error.status === 422) {
            alert('Impossible to remove the user ( ' + element + ' ) to the usergroup')
          } else {
            alert(error.message);
          }
          if (index === (this.usersToRemove.length - 1)) {
            this.refreshGroup()

          }
        })
    });
  }
  handleGroupSelection() {
    this.refreshGroup()
  }
  refreshGroup() {
    if (this.inviteForm.value.usergroupSelected) {
      this.shareService.retrieveUserGroup(this.inviteForm.value.usergroupSelected).subscribe((res) => {
        this.retrievedUsergroup = res
        this.usersToRemove = []
      })
    }
    else {
      this.retrievedUsergroup = null
      this.usersToRemove = []
    }
  }
  inviteUsers(): void {
    let listToInvite = _.cloneDeep(this.usersToInvite)
    listToInvite.forEach((element, index) => {
      if (_.get(element, 'type') === this.shareService.AutoCompleteType.NewMembers) {
        this.l.toast('Impossible to invite <b>' + element.value + '</b> to the usergroup. (Future feature)', null, 8000, 'Profile', 'danger')
        this.toggleUserSelectionToInvite(element)


        if (index === (listToInvite.length - 1)) {
          this.refreshGroup()
        }
      } else {
        this.shareService.addUserToUserGroup(this.inviteForm.value.usergroupSelected, element.value, this.shareService.RoleType.Reader).subscribe((res) => {
          this.toggleUserSelectionToInvite(element)
          if (index === (listToInvite.length - 1)) {
            this.refreshGroup()
          }
        }, (error) => {
          if (index === (listToInvite.length - 1)) {
            this.refreshGroup()
          }
          if (error.status === 404 || error.status === 422) {
            this.l.toast('Impossible to add the user ( ' + element.value + ' ) to the usergroup', null, 8000, 'Profile', 'danger')
          } else {
            alert(error.message);
          }
        })
      }

    })
  }
  // handleDeleteUserGroup() {
  //   if (this.retrievedUsergroup && this.retrievedUsergroup.id) {
  //     if (confirm('Are you sure you want to delete this usergroup ?')) {
  //       this.shareService.deleteUserGroup(this.retrievedUsergroup.id).subscribe(() => {
  //         this.shareService.listUsergroups()
  //         this.retrievedUsergroup = null
  //       })
  //     }
  //   }
  // }





  //PROFIL
  setActive(link) {
    this.activeSubLink = ''
    this.activeLink = link;
  }
  setActiveSubLink(link, sublink) {
    this.activeLink = link
    this.activeSubLink = sublink;
  }
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }
  onSubSectionChange(sectionId: string) {
    this.currentSubSection = sectionId;
  }
  scrollTo(section) {
    document.querySelector('#' + section)
      .scrollIntoView();
  }


  //ALL FORMS
  getTooltipContent(type) {
    let response = ''
    if (type === 'userGroupName') {
      if (this.userGroupName.hasError('required') && (this.userGroupName.dirty || this.userGroupName.touched)) {
        response = 'This field is required.'
      }
      else if (this.userGroupName.hasError('alreadyExists')) {
        response = 'This user group already exists.'
      }
      else if (this.userGroupName.hasError('maxlength')) {
        response = 'This field must be shorter than 30 characters.'
      }
    }
    if (type === 'currentPassword') {
      if (this.currentPassword.hasError('required') && (this.currentPassword.dirty || this.currentPassword.touched)) {
        response = 'This field is required.'
      }
      if (this.currentPasswordError) {
        response = "Current password doesn't match"
      }

    }
    if (type === 'newPassword') {
      if (this.newPassword.hasError('required') && (this.newPassword.dirty || this.newPassword.touched)) {
        response = 'This field is required.'
      } else if (!this.newPassword.valid) {
        response = 'Invalid syntax.'
      }
    }
    if (type === 'newPasswordConfirmation') {
      if (this.newPasswordConfirmation.hasError('required') && (this.newPasswordConfirmation.dirty || this.newPasswordConfirmation.touched)) {
        response = 'This field is required.'
      }
      else if (this.passwordModificationForm.hasError('mustMatch')) {
        response = 'Passwords must match.'
      }
    }
    if (type === 'passwordModificationForm') {
      if (!this.passwordModificationForm.valid) {
        response = 'Form is invalid.'
      }
    }

    return response;
  }


  //CREATE USERGROUP FORM
  initUserGroupCreationForm = () => {
    this.userGroupCreationForm = new FormGroup({
      userGroupName: new FormControl(
        null, [Validators.required, Validators.maxLength(30), this.newUsergroupName.bind(this)]),
      userGroupDescription: new FormControl(
        null, []),
    })
  }
  newUsergroupName(control: FormControl): ValidationErrors | null {
    if (_.findIndex(this.shareService.getUsergroupNames(), { value: control.value }) > -1) {
      return { alreadyExists: true }
    } else { return null }
  }
  get userGroupName() {
    return this.userGroupCreationForm.get('userGroupName');
  }
  get userGroupDescription() {
    return this.userGroupCreationForm.get('userGroupDescription');
  }
  addUserGroup(): void {
    const usergroup = {
      name: this.userGroupCreationForm.value.userGroupName,
      description: this.userGroupCreationForm.value.userGroupDescription,
    };
    this.shareService.createUserGroup(usergroup.name, usergroup.description).subscribe(() => {
      this.l.toast('User group <b>' + usergroup.name + '</b> has been successfully created.', moment().unix(), 8000, 'Profile')
      this.shareService.listUsergroups();
    })
    this.userGroupCreationForm.reset();
  }






  //PASSWORD FORM

  initPasswordForm = () => {
    this.passwordModificationForm = new FormGroup({
      currentPassword: new FormControl(
        null, [Validators.required]),
      newPassword: new FormControl(
        null, [Validators.required, Validators.minLength(8), this.noSpacesValidator, this.specialCharacterValidator]),
      newPasswordConfirmation: new FormControl(null, [Validators.required]),
    }, this.areSamePasswords)
  }
  resetFeedbackError() {
    this.currentPasswordError = false;
  }
  passwordVisibilitySwitcher(variableName) {
    _.set(this, variableName, !_.get(this, variableName, null))
  }
  hasAtLeastOneError(type) {
    if (type === 'currentPassword') {
      if ((this.currentPasswordError || this.currentPassword.hasError('required')) && (this.currentPassword.dirty || this.currentPassword.touched)) {
        return true
      }
    }
    if (type === 'newPassword') {
      if (!this.newPassword.valid && (this.newPassword.dirty || this.newPassword.touched)) {
        return true
      }
    }
    if (type === 'newPasswordConfirmation') {
      if ((this.newPasswordConfirmation.hasError('required') || this.passwordModificationForm.hasError('mustMatch')) && (this.newPasswordConfirmation.dirty || this.newPasswordConfirmation.touched)) {
        return true
      }
    }
    return false
  }
  areSamePasswords(group: FormGroup) {
    let pass = group.get('newPassword').value;
    let confirmPass = group.get('newPasswordConfirmation').value;
    return pass !== confirmPass ? { mustMatch: true } : null
  }
  get currentPassword() {
    return this.passwordModificationForm.get('currentPassword');
  }
  get newPassword() {
    return this.passwordModificationForm.get('newPassword');
  }
  get newPasswordConfirmation() {
    return this.passwordModificationForm.get('newPasswordConfirmation');
  }
  noSpacesValidator = (control: FormControl): ValidationErrors | null => {
    if (control.value) {
      if (control.value.includes(' ')) {
        return { shouldNotHaveSpaces: true };
      }
    }
    return null;
  }
  specialCharacterValidator = (control: FormControl): ValidationErrors | null => {
    const regex = /[^a-zA-Z0-9]+/g;
    const value = control.value;
    if (value && value.match(regex)) {
      return null;
    } else {
      return { noSpecialCharacter: true };
    }
  }
  handlePasswordModification(): void {
    let currentPassword = this.passwordModificationForm.value.currentPassword;
    let newPassword = this.passwordModificationForm.value.newPassword;
    this.loginHttpService.resetOwnPassword(currentPassword, newPassword).subscribe(() => {
      this.l.toast('Your password has been <b>successfully</b> modified.', moment().unix(), 8000, 'Password')
      this.passwordModificationForm.reset()
    }, (error) => {
      if (error.error.code === 403 && error.error.message === "old password doesn't match") {
        this.currentPasswordError = true
        this.l.toast('<b>Impossible</b> to modify your password, please verify your current password.', moment().unix(), 8000, 'Password', 'danger')
      }
      else if (error.error.code === 403 && error.error.message === "new password invalid") {
        this.l.toast('<b>Impossible</b> to modify your password, please verify your new password syntax.', moment().unix(), 8000, 'Password', 'danger')
      } else {
        this.l.toast('<b>Impossible</b> operation, please try again.', moment().unix(), 8000, 'Password', 'danger')
      }
    }
    )
  }
}
