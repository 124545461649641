<div class="card-container">
  <div class="card-body">
    <h5 class="card-title">
      <span class="project-title">{{ project.title }}</span>
    </h5>
    <p class="card-text">
      STATUS : {{ project.running ? 'Running' : 'Ready' }}
    </p>
    <p lass="card-text">CREATION : {{ dateOfCreation }}</p>
    <p>
      <button [routerLink]="['/project/overview', project.id]" color="accent"
        class="goToProjectButton btn btn-outline-dark">
        <i class="far fa-chart-bar" aria-hidden="true"></i> Project details
      </button>
    </p>
    <p>
      <button (click)="deleteProject(project)" class="deleteButton btn btn-outline-dark"
        [class.disabled]='environment.name === "prod"'>
        <i class="far fa-trash-alt" aria-hidden="true"></i> Delete Project
      </button>
    </p>
  </div>
</div>