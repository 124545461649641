<div #toast class="toast" role="alert" [class.information-toast]='information' [hidden]='!show.value' aria-live="assertive" aria-atomic="true">

  <div *ngIf='!information' class='d-flex flex-nowrap'>
    <div class='d-flex toast-icon' [class.danger]='danger' [class.warning]='warning'>
      <i class="fas fa-check-circle" [class.fa-exclamation-triangle]='danger' [class.fa-exclamation]='warning'
        aria-hidden="true"></i>
    </div>
    <div>
      <div class='toast-body' [innerHtml]='content'></div>
    </div>
  </div>

  <div *ngIf='information'
    class='d-flex flex-nowrap border-bottom justify-content-between title-background'>
    <div class='d-flex flex-nowrap'>
      <div class='d-flex toast-icon information '>
        <i class="fas fa-info-circle " aria-hidden="true"></i>
      </div>
      <div>
        <div class='toast-body font-weight-bold' [innerHtml]='title.value'></div>
      </div>
    </div>
    <div class="d-flex flex-nowrap align-items-center ">
      <div class='btn btn-link small '  (click)='switchContentVisibility()' class="mx-3"> <i class="fal interactive-icon"
          [class.fa-chevron-down]='!contentIsVisible' [class.fa-chevron-up]='contentIsVisible'
          aria-hidden="true"></i></div>
      <div class='btn btn-link small' (click)='handleClose()' class="mx-3" ><i class="fal interactive-icon fa-times"
          aria-hidden="true"></i></div>
    </div>
  </div>
  <div *ngIf='information'   class='d-flex toast-sub flex-nowrap' >
    <div class='toast-sub-body '>
      <div class='toast-body' [class.notVisible]='!contentIsVisible'  [innerHtml]='content.value'></div>
    </div>
  </div>

</div>
