import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { TabsPublicName } from '../shared/helpers/tabsPublicName';
import { PagesPublicName } from '../shared/helpers/pagesPublicName';
import { Status } from '../shared/helpers/status';
import { BehaviorSubject } from 'rxjs';
import { FileManager } from './file-manager.service';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { LocalService } from './local-storage.service';
import { GroupsManagerService } from './groups-manager.service';
export enum SortType {
    Title = 'title',
    IsFolder = 'is_folder',
    DateCreation = 'date_of_creation',
}
export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}
@Injectable()
export class MyFilesManagerService {
   
    path = new BehaviorSubject<any>([])
    displayedFiles = new BehaviorSubject<any>([]);
    metadataToUpload: any;
    bulkSelection = [];
    finishedListing;
    page = 1;
    count;
    status = Status
    pageSize = 10;
    filtersVisible = false;
    limitParam = 10;
    folderCreationForm: FormGroup;
    fileUploadForm: FormGroup = new FormGroup({
        team: new FormControl(null),
        fileInput: new FormControl(null)
    });
    metadataUploadForm: FormGroup;
    groups = []
    dlLink = null
    public debouncedRefresh: any = _.debounce(() => {
        this.refreshFolder();
    }, 500);


    //download = false;
    itemsWithInfoDisplayed;
    filesToBeUploaded
    uploadingFiles = new BehaviorSubject<any>([]);
    download = false;
    size = 0;
    canDownload = new BehaviorSubject<boolean>(false);

    constructor(private localService: LocalService, private fileManager: FileManager , private groupsManagerService : GroupsManagerService) {
    }
    getBasePath= ()=>{
        return  _.includes(availableFeatures, Features.MultiCountry) ? [{ name: PagesPublicName.FileManager, id: null, page: undefined }, { name: TabsPublicName.MyFiles, id: null, page: undefined }, { name: 'Storage location - ' + this.localService.getFromLocalStorage('country', 'name'), id: 'root', page: undefined }]
        : [{ name: PagesPublicName.FileManager, id: null, page: undefined }, { name: TabsPublicName.MyFiles, id: 'root', page: undefined }]
    }

    getTitleFromGroupId(id) {
        return this.fileManager.getTitleFromGroupId(this.groupsManagerService.groups, id)
    }
    openInfo() {
        return this.fileManager.openInfo()
    }
    openShare() {
        return this.fileManager.openShare()
    }


    listContent = () => {
        return this.fileManager.listContent(this, 'MyFilesManagerService')
    }

    removeWaitingForRefreshFiles() {
        return this.fileManager.removeWaitingForRefreshFiles(this)
    }
    getFinishedListing = () => {
        return this.fileManager.getFinishedListing(this)
    }
    resetFinishedListing = () => {
        return this.fileManager.resetFinishedListing(this)
    }
    changeCurrentLocationId = (id) => {
        return this.fileManager.changeCurrentLocationId(id, this)
    };
    resetFolder = (id) => {
        return this.fileManager.resetFolder(id, this)
    };
    openFolder = (id) => {
        return this.fileManager.openFolder(id, this)
    };
    openFolderInPath = (id) => {
        return this.fileManager.openFolderInPath(id, this)
    };
    moveInsideFolder = () => {
        return this.fileManager.moveInsideFolder(this)
    };
    refreshFolder = () => {
        return this.fileManager.refreshFolder(this)
    }
    closeFolder = (id) => {
        return this.fileManager.closeFolder(id, this)
    }

    setBulkToOneFileOnly(id) {
        return this.fileManager.setBulkToOneFileOnly(id, this)
    }
    getCompletePath = () => {
        return this.fileManager.getCompletePath(this)
    }
    getParentFolderId = () => {
        return this.fileManager.getParentFolderId(this)
    }
    getParentFolderName = () => {
        return this.fileManager.getParentFolderName(this)
    };
    setParentFolderPage = (value) => {
        return this.fileManager.setParentFolderPage(value, this)
    };
    setCurrentFolderPage = (value) => {
        return this.fileManager.setCurrentFolderPage(value, this)
    };
    getOpenedFolderId = () => {
        return this.fileManager.getOpenedFolderId(this, 'MyFilesManagerService')
    }
    resetMetadataUploadForm = () => {
        return this.fileManager.resetMetadataUploadForm(this)
    };
    checkCanDownload = (path) => {
        return this.fileManager.checkCanDownload(path, this, 'MyFilesManagerService')
    }
    getDisplayedFilesLength = () => {
        return this.fileManager.getDisplayedFilesLength(this)
    }
    rename = (itemId, value, field) => {
        return this.fileManager.rename(itemId, value, field, this)
    }
    getInfos = () => {
        return this.fileManager.getInfos(this)
    }

    getSortByQuery = () => {
        return this.fileManager.getSortByQuery(this)
    }
    handleNewMetaData = () => {
        return this.fileManager.handleNewMetaData(this)
    }
    getDisplayedFiles = () => {
        return this.fileManager.getDisplayedFiles(this)
    }
    getUploadingFiles = () => {
        return this.fileManager.getUploadingFiles(this)
    }
    setSortFilter = (value: SortType) => {
        return this.fileManager.setSortFilter(value, 'MyFilesManagerService')
    }
    setSortDirection = (value: SortDirection) => {
        return this.fileManager.setSortDirection(value, 'MyFilesManagerService')
    }
    getSortFilter = () => {
        return this.fileManager.getSortFilter('MyFilesManagerService')
    }
    getSortDirection = () => {
        return this.fileManager.getSortDirection('MyFilesManagerService')
    }
    getBulkSelectionLength = () => {
        return this.fileManager.getBulkSelectionLength(this)
    }
    toggleSortDirection = () => {
        return this.fileManager.toggleSortDirection(this)
    }
    resetBulkSelection = () => {
        return this.fileManager.resetBulkSelection(this)
    }
    selectAllDisplayedFiles = () => {
        return this.fileManager.selectAllDisplayedFiles(this)
    }
    toggleFilter = () => {
        return this.fileManager.toggleFilter(this)
    }
    addToBulkSelection = (id) => {
        return this.fileManager.addToBulkSelection(id, this);
    }
    removeFromBulkSelection = (id) => {
        return this.fileManager.removeFromBulkSelection(id, this);
    }
    isBulkSelected = (id?) => {
        return this.fileManager.isBulkSelected(this, id ? id : undefined)
    }
    getBulkSize = () => {
        return this.fileManager.getBulkSize(this);
    }

    handleCompressAndDownload = (item) => {
        return this.fileManager.handleCompressAndDownload(item, this);

    }
    onCompressionToastClose = () => {
        return this.fileManager.onCompressionToastClose(this);

    }




    resetFolderCreationForm = () => {
        return this.fileManager.resetFolderCreationForm(this)
    }
    resetFileUploadForm = () => {
        return this.fileManager.resetFileUploadForm(this)
    }


    handleNewFolder = () => {
        return this.fileManager.handleNewFolder(this)
    }
    deleteAll = () => {
        return this.fileManager.deleteAll(this)
    }
    _deteleAll = () => {
        return this.fileManager._deteleAll(this)
    }
    _handleDelete = (id, title?) => {
        return this.fileManager._handleDelete(id, this, title ? title : undefined)
    }
    handleDelete = (item) => {
        return this.fileManager.handleDelete(item, this)
    }

    handleDeleteNoConfirm = (id, title?) => {
        return this.fileManager.handleDeleteNoConfirm(id, title ? title : undefined)
    }
    allowDrop = (event) => {
        return this.fileManager.allowDrop(event)
    }
    getDragEndTargetId = (id) => {
        return this.fileManager.getDragEndTargetId(id, this)
    }
    getDragEndGroupTargetId = (id) => {
        return this.fileManager.getDragEndGroupTargetId(id)
    }
    handleMoveSelection = (grabbedElementId) => {
        return this.fileManager.handleMoveSelection(grabbedElementId, this)
    }

    handleMoveSelectionToGroup = (grabbedElementId) => {
        return this.fileManager.handleMoveSelectionToGroup(grabbedElementId, this)
    }

    addToGroup = (res, grabbedElementId, itemTitle, groupTitle, last) => {
        return this.fileManager.addToGroup(res, grabbedElementId, itemTitle, groupTitle, last, this)
    }
    handleMoveSelectionToFolder = (grabbedElementId) => {
        return this.fileManager.handleMoveSelectionToFolder(grabbedElementId, this)
    }
    getTitleFromId = (id) => {
        return this.fileManager.getTitleFromId(id, this)
    }
    handleMoveBulkSelection = () => {
        return this.fileManager.handleMoveBulkSelection(this)
    }
    proceedUpload = (uploadId, fileToBeUploaded, resolve, reject) => {
        return this.fileManager.proceedUpload(uploadId, fileToBeUploaded, resolve, reject, this)
    }
    prepareUpload = (fileToBeUploaded) => {
        return this.fileManager.prepareUpload(fileToBeUploaded)
    }
    extractUploadId = (response) => {
        return this.fileManager.extractUploadId(response)
    }
    uploadASpecificFile = (fileToBeUploaded, from?) => {
        return this.fileManager.uploadASpecificFile(fileToBeUploaded, this, from ? from : undefined)
    }
    uploadAFile = () => {
        return this.fileManager.uploadAFile(this)
    }


    handleNewFiles = () => {
        return this.fileManager.handleNewFiles(this)
    }
    uploadFiles = () => {
        return this.fileManager.uploadFiles(this)
    }
    startUploadingSession = () => {
        return this.fileManager.startUploadingSession(this)
    }
    handleDownloadReset = () => {
        return this.fileManager.handleDownloadReset(this)
    }
    downloadFile = () => {
        return this.fileManager.downloadFile(this)
    }
    createTempFiles = (filesToUpload, teamId) => {
        return this.fileManager.createTempFiles(filesToUpload, teamId, this)
    }
    findAllFilesToBeUploaded = () => {
        return this.fileManager.findAllFilesToBeUploaded(this)
    }
    findAllFilesToBeRefreshed = () => {
        return this.fileManager.findAllFilesToBeRefreshed(this)
    }
    findFilesCurrentlyUploading = () => {
        return this.fileManager.findFilesCurrentlyUploading(this)
    }





}