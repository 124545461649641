import * as _ from 'lodash';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { RawInputTypes } from 'src/app/api/projects.service';
import { BehaviorSubject } from 'rxjs';
export enum AdditionalTypes {
  Object = 'object',
  Title = 'title',
  ShortText = 'shortText',
  Paragraph = 'paragraph',
  Password = 'password',
  File = 'file'
}

export enum Validations {
  IsRequired = 'isRequired',
  MinLength8 = 'MinLength8',
  ShouldHaveSpecialCharacter = 'ShouldHaveSpecialCharacter',
  ShouldNotHaveSpaces = 'ShouldNotHaveSpaces',
  ShouldBeIdentic = 'ShouldBeIdentic',
  ShouldHaveAtLeastOneCheck = 'ShouldHaveAtLeastOneCheck',
  ShouldHaveAtLeastOneAdditionalCheck = 'ShouldHaveAtLeastOneAdditionalCheck',
  ShouldHaveMax2Checks = 'ShouldHaveMax2Checks',
  ShouldHaveMax2AdditionalChecks = 'ShouldHaveMax2AdditionalChecks',
  ShouldBeAnEmail = 'ShouldBeAnEmail',
  MaxLength128 = 'MaxLength128',
  IdHelp = 'IdHelp',
  TeamIdHelp = 'TeamIdHelp'
}
export function getFormErrorSentence(errorName) {
  let sentence;
  switch (errorName) {
    case Validations.ShouldBeIdentic:
      sentence = "Passwords don't match"
      break;
    default:
      sentence = ''
  }
  return sentence
}

export function getFieldErrorSentence(errorName) {
  let sentence;
  switch (errorName) {
    case 'required':
    case Validators.required:
    case Validations.IsRequired:
      sentence = "This field is required"
      break;
    case Validations.ShouldHaveMax2Checks:
      sentence = "A maximum of 2 checkboxes can be checked"
      break;
    case Validations.ShouldHaveMax2AdditionalChecks:
      sentence = 'A maximum of 2 checkboxes can be checked'
      break;
    case Validations.MinLength8:
      sentence = "Please enter at least 8 characters"
      break;
    case Validations.MaxLength128:
      sentence = "This field must not be longer than 128 characters"
      break;
    case Validations.ShouldBeAnEmail:
      sentence = "This field must be an email"
      break;
    case Validations.ShouldNotHaveSpaces:
      sentence = "Spaces are forbidden"
      break;
    case Validations.ShouldHaveSpecialCharacter:
      sentence = "This password must contain at least one symbol, as @#$%"
      break;
    case Validations.ShouldHaveAtLeastOneCheck:
      sentence = "At least one checkbox must be checked"
      break;
    case Validations.ShouldHaveAtLeastOneAdditionalCheck:
      sentence = "At least one checkbox must be checked"
      break;
    case Validations.IdHelp:
      sentence = "ID can be found by clicking on a user ressource in another team, or directly in its own account information page"
      break;
    case Validations.TeamIdHelp:
      sentence = "This is your subscription plan number. Please contact us to subscribe"
      break;
    default:
      sentence = ""
  }
  return sentence
}
export function IsRequired(control: FormControl): ValidationErrors | null {
  if (_.get(control, 'value.length', 0) === 0) {
    let result = {}
    _.set(result, Validations.IsRequired, true)
    return result
  }

  return null;
}
export function IdHelp(control: FormControl): ValidationErrors | null {
  if (!_.get(control, 'touched')) {
    let result = {}
    _.set(result, Validations.IdHelp, true)
    return result
  }

  return null

}
export function TeamIdHelp(control: FormControl): ValidationErrors | null {
  if (!_.get(control, 'touched')) {
    let result = {}
    _.set(result, Validations.TeamIdHelp, true)
    return result
  }

  return null

}
export function MinLength8(control: FormControl): ValidationErrors | null {
  if (_.get(control, 'value.length', 0) < 8) {
    let result = {}
    _.set(result, Validations.MinLength8, true)
    return result
  }
  return null;
}
export function MaxLength128(control: FormControl): ValidationErrors | null {
  if (_.get(control, 'value.length', 0) > 128) {
    let result = {}
    _.set(result, Validations.MaxLength128, true)
    return result
  }
  return null;
}
export function ShouldNotHaveSpaces(control: FormControl): ValidationErrors | null {
  if (_.includes(_.get(control, 'value', ''), ' ')) {
    let result = {}
    _.set(result, Validations.ShouldNotHaveSpaces, true)
    return result
  }
  return null;
}
export function ShouldHaveSpecialCharacter(control: FormControl): ValidationErrors | null {
  const regex = /[^a-zA-Z0-9]+/g;
  const value = _.get(control, 'value', '');
  if (value.match(regex)) {
    return null;
  } else {
    let result = {}
    _.set(result, Validations.ShouldHaveSpecialCharacter, true)
    return result
  }
}
export function ShouldBeIdentic(control1: string, control2: string): (group: FormGroup) => ValidationErrors | null {
  return (group: FormGroup): ValidationErrors | null => {
    let control1Value = group.get(control1).value;
    let control2Value = group.get(control2).value;
    if (control1Value === control2Value) {
      return null;
    } else {
      let result = {}
      _.set(result, Validations.ShouldBeIdentic, true)
      return result
    }
  }
}

export function ShouldHaveAtLeastOneCheck(control: FormControl): ValidationErrors | null {
  if (control.value) {
    if (_.countBy(control.value).true === undefined) {
      let result = {}
      _.set(result, Validations.ShouldHaveAtLeastOneCheck, true)
      return result
    }
  }
  return null;
}
export function ShouldHaveAtLeastOneAdditionalCheck(control: FormControl): ValidationErrors | null {
  if (control.value) {
    if (_.countBy(control.value).true === undefined || _.countBy(control.value).true === 1) {
      let result = {}
      _.set(result, Validations.ShouldHaveAtLeastOneAdditionalCheck, true)
      return result
    }
  }
  return null;
}
export function ShouldHaveMax2AdditionalChecks(control: FormControl): ValidationErrors | null {
  if (control.value) {
    if (_.countBy(control.value).true > 3) {
      let result = {}
      _.set(result, Validations.ShouldHaveMax2AdditionalChecks, true)
      return result
    }
  }
  return null;
}
export function ShouldHaveMax2Checks(control: FormControl): ValidationErrors | null {
  if (control.value) {
    if (_.countBy(control.value).true > 2) {
      let result = {}
      _.set(result, Validations.ShouldHaveMax2Checks, true)
      return result
    }
  }
  return null;
}
export function ShouldBeAnEmail(control: FormControl): ValidationErrors | null {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (control.value) {
    if (!re.test(String(control.value).toLowerCase())) {
      let result = {}
      _.set(result, Validations.ShouldBeAnEmail, true)
      return result
    }
  }
  return null;

}
export interface InputJson {
  formControlName: string,
  originalJson?: object | string | null,
  placeholder?: string,
  type?: RawInputTypes | AdditionalTypes,
  formControl?: FormControl,
  label?: string,
  default_value?: any | null,
  description?: string,
  step?: string | null
  iconClassName?: string | null,
  fitToContent?: boolean,
  charMax?: number,
  is_array?: boolean,
  multiple_selection?: boolean,
  allowed_values?: Array<any>,
  allowed_values_descriptions?: Array<any>,
  readonly?: boolean
  fixed_width?: number,
  validations?: Array<any> | null
  retrievedValue?: BehaviorSubject<any>,
  retrieved_allowed_values?: BehaviorSubject<any>,
  tagname?: string
}

