import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FileManager } from '../../api/file-manager.service';
import * as _ from 'lodash';
import { availableFeatures } from 'src/environments/environment';
import { ItemType } from 'src/app/api/share.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { DeletedFilesManagerService } from 'src/app/api/deleted-files-manager.service';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';
import { Router } from '@angular/router';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { LocalStorage } from 'ngx-webstorage';
import { Features } from 'src/environments/features';
import { LocalService } from 'src/app/api/local-storage.service';
import { NotificationsService } from 'src/app/api/notifications.service';
import { Status } from 'src/app/shared/helpers/status';
@Component({
  selector: 'file-manager',
  styleUrls: ['../../shared/layout/managers.scss',
  ],
  templateUrl: './file-manager.component.html',
})
export class FileManagerComponent implements OnInit, OnDestroy {
  TabsPublicName = TabsPublicName;
  availableFeatures = availableFeatures
  _ = _;
  ItemType = ItemType
  Features = Features
  TabsName = TabsName;
  PagesName = PagesName;
  PagesPublicName = PagesPublicName;
  refreshSubscription
  @LocalStorage() user

  constructor(
    public router: Router,
    public fileManager: FileManager,
    public deletedFilesManager: DeletedFilesManagerService,
    public myFilesManager: MyFilesManagerService,
    public sharedFilesManager: SharedFilesManagerService,
    public groupsManagerService: GroupsManagerService,
    public localService: LocalService,
    public notifService : NotificationsService
  ) {
  }
  ngOnInit() {
    this.fileManager.viewTab = TabsName.MyFiles
    this.groupsManagerService.listGroups()
    this.refreshSubscription = this.fileManager.eventRefreshGroups.subscribe(() => {
      this.groupsManagerService.listGroups();
    });
    this.fileManager.checkPermissions()
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe()
    _.set(this, 'fileManager.quality', null);
    if (this.notifService.isCompressionToastOpened.value && _.filter(this.notifService.pendingFolders,(o)=>{ return o.status === Status.Pending}).length === 0){
      this.notifService.isCompressionToastOpened.next(false)
    }
  }



}
