import { Component, Input } from '@angular/core';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { ItemType } from 'src/app/api/share.service';
@Component({
  selector: 'app-share-group',
  styleUrls: ['./share-group.component.scss'],
  templateUrl: './share-group.component.html'
})
export class ShareGroupComponent {
  ItemType = ItemType;
  @Input() item: any;
  constructor(
  public groupsManagerService : GroupsManagerService
  ) { }

}
