<div class="list ">
    <div class="list-container border  d-flex flex-nowrap align-items-center justify-content-between">
        <div class="cursor-pointer  mx-0 px-0 d-flex flex-nowrap   ">
            <span  (click)='navigateToTeamEdition()' class='px-3'>
                <i class="far fa-user-friends" aria-hidden="true"></i>
                {{getCount()}}
                <!-- user{{getCount() > 1 ? 's' : ''}} -->
            </span>
            <span class="item-text px-2 pr-5 font-weight-bold">
                <span   (click)='navigateToTeamEdition()' class='pr-5 ' *ngIf='showName'>{{getName()}}</span>
                <div (click)="quitRenameMode()" *ngIf="!showName" class="overlay"></div>

                <app-rename *ngIf='!showName' [renameFunction]='teamsService.rename' class='h5 text-primary'
                    [type]='ItemType.Team' [item]='team' field='name'></app-rename>

            </span>

        </div>
        <div  class="mx-0 px-0 cursor-pointer h-100 flex-grow-1" (click)='navigateToTeamEdition()'></div>
        <div class=" mx-0 px-0  d-flex flex-nowrap align-items-center justify-content-between ">


            <div (click)='hasSubscription() ? manageSubscription() : addSubscription()' class="btn btn-link">
                <i class="far px-2 fa-file-invoice-dollar" aria-hidden="true"></i>
                {{hasSubscription() ? 'Manage subscription' : 'Add subcription'}}
            </div>


            <div  (click)='showName ? activateRenameMode() : quitRenameMode()' class="btn btn-link">
                <i class="far px-2 fa-edit" aria-hidden="true"></i> Rename
            </div>
            <div (click)='handleDelete(getKey(),getName())' class="btn btn-link">
                <i class="far px-2 fa-trash-alt" aria-hidden="true"></i> Delete Team
            </div>
        </div>
    </div>
</div>