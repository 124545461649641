import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { TeamsService, ModelTeamBody } from './teams.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ModelTeamAndUserEmails } from './team-manager/team-manager.component';
export class Teams {
  constructor(raw: Array<ModelTeamBody>) {
    return raw;
  }
}
export class Body {
  constructor(rawBody: ModelTeamAndUserEmails) {
    return _.get(rawBody, 'team') as ModelTeamBody;
  }
}

@Component({
  selector: 'app-teams-manager',
  templateUrl: './teams-manager.component.html',
  styleUrls: ['../../shared/layout/managers.scss', './teams-manager.component.scss',]
})
export class TeamsManagerComponent implements OnInit, OnDestroy {
  teams = new BehaviorSubject<any>(null);
  subscriptionEvent: Subscription;
  subscriptionTeams: Subscription;
  constructor(public teamsService: TeamsService, public layout: LayoutOrganizationService) {
    let self = this
    self.subscriptionEvent = self.teamsService.refreshTeams.subscribe((res) => {
      if (!res) {
        return
      }
      self.retrieveTeams()
    })
    self.retrieveTeams()

  }

  ngOnInit(){
    this.teamsService.selectedTeam = null
  }
  ngOnDestroy() {
    let self = this
    self.subscriptionEvent.unsubscribe()
    self.subscriptionTeams.unsubscribe()

  }

  handleChangeSubscription = () =>{
    let teamId = _.get(this.teamsService, 'selectedTeam._key')
    let subscriptionId = _.get(this.teamsService, 'teamSubscriptionForm.controls.subscriptionId.value')
    this.teamsService.getTeam(teamId).subscribe((res) => {
       let newBody = _.set( new Body(res),'subscription_id',subscriptionId )
       this.teamsService.updateTeam(teamId, newBody).subscribe(() => {
        this.teamsService.refreshTeams.next(true)
      },
        () => {
          this.layout.toast('<b>Error</b> while <b>updating</b> the subscription', null, 8000, '', 'danger')
        })
      })
    
   
  }
  hasSubscription = (team) => {
    return _.get(team, 'subscription_id.length', 0) > 0;
  }
  getTeamSubscriptionForm() {
    return _.get(this.teamsService, 'teamSubscriptionForm')
  }
  retrieveTeams = () => {
    let self = this
    self.subscriptionTeams = self.teamsService.getTeams().subscribe((res) => {
      self.teams.next(new Teams(res || []))
    }, () => {
      self.teams.next(new Teams([]))
      self.layout.toast('<b>Error</b> while retrieving the list of teams', null, 8000, '', 'danger')
    })
  }
  getTeams = () => {
    return _.get(this, 'teams.value')
  }
  resetSelectedTeam =() =>{
    this.teamsService.selectedTeam = null
  }
  handleNewTeam = () => {
    this.teamsService.createTeam().subscribe(() => {
      this.layout.toast('New team successfully <b> created </b>', null, 5000, '', 'success')
      this.teamsService.refreshTeams.emit(true)
    }, () => {
      this.layout.toast('<b>Error</b> while creating a team', null, 8000, '', 'danger')
    })
  }
  trackFn(index, item) {
    return _.get(item, 'id', '') + _.get(item, 'name', '');
  }
}
