<div class='justify-content-between d-flex align-items-center flex-nowrap bg-light'>
  <div *ngIf="links.length && (links.length == titles.length)"
  class='w-100 d-flex align-items-center flex-wrap align-items-stretch '>
  <div (click)=" switchTabTo(link,i)" *ngFor="let link of links; let i = index; let last = last"
    [class.small]='links.length > 5'  [class.active]='isActive(link,i)'  class='tab-container cursor-pointer d-flex align-items-center'
    [style.maxWidth.rem]='links.length > 5 ? 25 : "none"' [class.flex-fill]='links.length > 5'>
    <div class="tab w-100 vertical-bar d-flex align-items-center" [class.active]='isActive(link,i)' [class.last]='last'>
      <span class="w-100 text-center font-weight-bold nav-link align-bottom"
        [class.active]='isActive(link,i)'>{{ titles[i] }}</span>
    </div>
  </div>
  <div class='mx-3 ml-auto p-2 align-self-center'>
    <ng-content></ng-content>
  </div>
</div>