import { Component, Input, OnInit } from '@angular/core';
import { FileManager } from 'src/app/api/file-manager.service';
import * as _ from 'lodash';
import { Status } from 'src/app/shared/helpers/status';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';
import { DeletedFilesManagerService } from 'src/app/api/deleted-files-manager.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';

@Component({
  selector: 'app-item-list',
  styleUrls: [
    '../../../shared/layout/managers.scss',
  ],
  templateUrl: './item-list.component.html',
})
export class itemListComponent implements OnInit {
  @Input() item: any;
  @Input() selected: any;
  manager;
  Status = Status
  constructor(public fileManager: FileManager,
    public myFilesManager: MyFilesManagerService,
    public sharedFilesManager: SharedFilesManagerService,
    public deletedFilesManager: DeletedFilesManagerService,
  ) { }
  ngOnInit() {
    if (this.fileManager.viewTab === TabsName.DeletedFiles) {
      this.manager = this.deletedFilesManager
    } else if (this.fileManager.viewTab === TabsName.SharedFiles) {
      this.manager = this.sharedFilesManager
    } else if (this.fileManager.viewTab === TabsName.MyFiles) {
      this.manager = this.myFilesManager
    }
  }
  getStatus(item) {
    return _.get(item, 'status', null)
  }
  isCompression(item){
    return _.get(item, 'title') === 'Compression'
  }
  getId(item){
    return _.get(item, 'id')
  }
  isFolder(item){
    return _.get(item, 'is_folder', false)
  }
  isTempFile(item) {
    const status = this.getStatus(item)
    return status === Status.Paused || status === Status.Uploading ||  status === Status.Cancelled || status === Status.WaitingForRefresh || status === Status.NotStarted || status === Status.Error || status === Status.NetworkError
  }

  onDragStart(event) {
    if (this.selected) {
      event.dataTransfer.setData('text/plain', event.target.id);
    } else {
      event.preventDefault();
    }
  }
}
