import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ProjectsService } from '../../api/projects.service';
import { SessionStorage } from 'ngx-webstorage';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from "lodash";
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareService, ItemType } from 'src/app/api/share.service';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';


@Component({
  selector: 'app-project-manager',
  styleUrls: [
    '../../shared/layout/managers.scss',
    './project-manager.component.scss',

  ],
  templateUrl: './project-manager.component.html',
})
export class ProjectManagerComponent implements OnInit, OnDestroy, AfterViewInit {
  @SessionStorage() projects: any;
  //viewType = 'list';
  usergroups = [];
  TabsName = TabsName;
  TabsPublicName = TabsPublicName;
  PagesPublicName = PagesPublicName;
  ItemType = ItemType;
  previousUrlState = { id: '', viewTab: '' }

  constructor(
    public projectsService: ProjectsService,
    public shareService: ShareService,
    public projectManagerService: ProjectManagerService,
    public l: LayoutOrganizationService,
    public router: Router,
    public route: ActivatedRoute

  ) {
    this.route.params.subscribe(params => {
      if (this.previousUrlState.viewTab !== params['viewTab']) {
        this.projectManagerService.viewTab = params['viewTab']
        this.previousUrlState.viewTab = params['viewTab']
        this.previousUrlState.id = ''
        this.projectManagerService.bulkSelection = []
      }
      
      this.projectManagerService.refreshProjects().subscribe()
    })



  }

  ngOnInit() {
    this.l.isTopBarOpen = true;
    this.l.isTopNavOpen = true;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (_.endsWith(this.router.routerState.snapshot.url, '/info')) {
        this.projectManagerService.getInfos();
        this.projectManagerService.openInfo();
      }
    }, 1000)

    setTimeout(() => {
      if (_.endsWith(this.router.routerState.snapshot.url, '/share')) {
        this.projectManagerService.getInfos();
        this.projectManagerService.openShare();
      }
    }, 1000)

  }
  ngOnDestroy() {
    this.l.isTopBarOpen = false;
    this.l.isTopNavOpen = false;
  }
}
