import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkConnectionService {

  connectivitySub : Subject<boolean> = new Subject();
  networkOk$: Observable<boolean> = this.connectivitySub.asObservable();

  constructor() {
    fromEvent(window, 'online').subscribe(() => {
      this.connectivitySub.next(true);
    });
    fromEvent(window, 'offline').subscribe(() => {
      this.connectivitySub.next(false);
    });

  }
}
