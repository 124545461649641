import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Dashboard2Service } from '../dashboard2.service';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-project-dashboard2',
  templateUrl: './project-dashboard2.component.html',
  styleUrls: ['./project-dashboard2.component.scss'],
})
export class ProjectDashboard2Component implements OnInit, OnChanges {
  @Input() projectId;
  @Input() isActive?;

  @Input() dashboardId;
  sectionArray = [];
  dynamicDashboard = false;
  error = false
  isDownloaded = new BehaviorSubject<boolean>(false);
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;

  constructor(public dashboard2Service: Dashboard2Service) {}

  ngOnInit(): void {
    this.retrieveDashboard();
    this.scrollContainer.nativeElement.focus()

  }
  ngOnChanges(){
    if (this.isActive){
      this.scrollContainer.nativeElement.focus()
    }
  }
  retrieveDashboard() {
    this.sectionArray = [];
    if(this.projectId && this.dashboardId)
    this.dashboard2Service.downloadDashboard(this.projectId, this.dashboardId).subscribe((res) => {
      this.isDownloaded.next(true);
      const graphs = _.get(res, 'graphs', []);
      this.dynamicDashboard = graphs.some((graph) => graph.interactive);
      graphs.forEach((el) => {
        this.sectionArray.push({ graphId: _.get(el, 'graph_id'), webGlContexts: undefined });
      });
      _.set(_.nth(this.sectionArray, 0), 'show', true);
      console.log(this.sectionArray);
    }, (error) => {
      this.isDownloaded.next(true);
      this.error = true
    });
  }

  getShow(index) {
    return _.get(_.nth(this.sectionArray, index), 'show', false);
  }
  handleWebGlContexts(event) {
    if (!event) {
      return;
    }
    let el = _.find(this.sectionArray, (o) => {
      return _.get(o, 'graphId') === _.get(event, 'graphId');
    });
    _.set(el, 'webGlContexts', _.get(event, 'value'));
  }
  updateScatterGlPool() {
    let total = 0;
    this.sectionArray.forEach((el) => {
      total = total + _.get(el, 'webGlContexts', 0);
    });
    this.dashboard2Service.scatterGLPool = this.dashboard2Service.maxScatterGLPool - total;
  }
  handleShowChildren(event) {
    if (!event) {
      return;
    }
    let index = _.findIndex(this.sectionArray, (o) => {
      return _.get(o, 'graphId') === _.get(event, 'after');
    });
    this.sectionArray = this.sectionArray.slice(0, index + 1);
    _.get(event, 'children', []).forEach((el) => {
      if (!el.length){
        return
      }
      this.sectionArray.push({ graphId: el, webGlContexts: undefined });
    });
    this.scrollContainer.nativeElement.focus()
    if(_.get(event, 'children', []).length){
      setTimeout(()=>{this.scrollContainer.nativeElement.scrollBy(0, 100)},350)

    }

  }
}
