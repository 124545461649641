import {
  EventEmitter,
  Injectable
} from '@angular/core';
import {
  LocalStorage
} from 'ngx-webstorage';
import {
  Subject,
  BehaviorSubject,
  Observable
} from 'rxjs';
import * as _ from 'lodash';
import {
  PermissionsHttpService
} from '../shared/permissions-http-service/permissions-http.service';
import {
  ProjectsService
} from './projects.service';
import {
  FileSystemState
} from '../pages/login/login-http-service/login-http.service';
import {
  availableFeatures,
  environment
} from 'src/environments/environment';
import {
  Status
} from '../shared/helpers/status';
import {
  FilesService
} from './files.service';
import {
  ModelsPostObject
} from '../model/modelsPostObject';
import {
  LayoutOrganizationService
} from './layout-organization.service';
import {
  Features
} from 'src/environments/features';
import { NotificationsService } from './notifications.service';
export interface SortParameters {
  filter: string;
  direction: string;
}
export enum FileType {
  Folder,
  File,
  Group,
  Temp,
  Project
}
export enum SortType {
  Title = 'title',
  IsFolder = 'is_folder',
  DateCreation = 'date_of_creation',
}
export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

@Injectable()
export class FileManager {
  @LocalStorage() session;
  viewTab;
  destinationId = '';
  eventRefreshGroups = new Subject<boolean>();
  eventRefreshGroupId = new BehaviorSubject<any>('');
  destinationIdType = null;
  quality = null;
  Status = Status
  // path
  deleteFilePermission = false

  @LocalStorage() user
  countrySelected = new EventEmitter(false)
  constructor(public permissions: PermissionsHttpService, public notificationsService: NotificationsService, public projectsService: ProjectsService, public layout: LayoutOrganizationService, public filesService: FilesService) { }

  // SOURCE
  // test for future refactorisation. Idea is to have all the varaibles in this files and remove the three other manager services 
  // setVariable(tabName, varName, value) {
  //   _.set(this, varName + '.' + tabName, value)
  // }
  // getVariable(tabName, varName) {
  //   return _.get(this, varName + '.' + tabName)
  // }
  checkPermissions(){
    this.permissions
      .checkPermission({
        permissions: {
          deleteFile: {}
        },
      })
      .subscribe((res) => {
        this.deleteFilePermission = res.deleteFile;
      });
  }
  isMyFiles(serviceName) {
    return serviceName === 'MyFilesManagerService'
  }
  isSharedFiles(serviceName) {
    return serviceName === 'SharedFilesManagerService'
  }
  isDeletedFiles(serviceName) {
    return serviceName === 'DeletedFilesManagerService'
  }

  //MISC
  getTitleFromId(id, self) {
    const el = _.find(this.getDisplayedFiles(self), {
      id: id
    })
    if (el) {
      return el.title
    }
  }
  getTitleFromGroupId(groups, id) {
    const el = _.find(groups, { id: id })
    if (el) {
      return el.name
    }
  }
  // NAVIGATION
  getOpenedFolderId(self, serviceName) {
    return _.get(self, 'openedFolderId', this.isMyFiles(serviceName) ? 'root' : 'list')
  }
  changeCurrentLocationId(id, self) {
    _.set(self, 'openedFolderId', id);
  }
  openFolderInPath(id, self) {
    self.changeCurrentLocationId(id)
    self.resetFinishedListing()

    let newPathValue = self.getCompletePath()
    let index = _.findIndex(newPathValue, (o) => { return o.id === self.getOpenedFolderId() })
    self.page = _.get(self.getCompletePath()[index], 'page', 1)
    this.refreshFolder(self)

    newPathValue = _.slice(newPathValue, self.getBasePath().length, index + 1)

    self.path.next(newPathValue);
  }
  resetFolder(id, self) {
    self.page = 1
    this.resetBulkSelection(self)
    if (id) {
      self.resetFinishedListing()
      self.canDownload.next(false)
      self.changeCurrentLocationId(id)
      self.path.next([]);
      self.checkCanDownload(self.getCompletePath())
      self.setCurrentFolderPage(self.page)
      self.listContent()
    }
  }
  openFolder(id, self) {
    self.changeCurrentLocationId(id)
    self.canDownload.next(false)
    self.setCurrentFolderPage(self.page)
    self.resetFinishedListing()
    self.listContent()
    this.filesService.getPathFromFileId(self.getOpenedFolderId())
    .subscribe((res) => {
      let newPathValue = self.path.value
      if (_.get(res, 'parts.length'))
        newPathValue.push(_.last(_.get(res, 'parts')))
      self.path.next(newPathValue);
      self.checkCanDownload(self.getCompletePath())
    })
  };
  moveInsideFolder(self) {
    self.resetFinishedListing()
    self.listContent().then(() => {
      self.setCurrentFolderPage(self.page)
    })
  };
  refreshFolder(self) {
    self.resetFinishedListing()
    self.canDownload.next(false)
    self.listContent().then(() => {
      self.setCurrentFolderPage(self.page)
      self.checkCanDownload(self.getCompletePath())
    })
  }
  closeFolder(id, self) {
    const parentPath = _.nth(self.getCompletePath(), -2)
    self.page = _.get(parentPath, 'page', 1)
    self.changeCurrentLocationId(id)
    self.canDownload.next(false)

    let newPathValue = self.path.value
    newPathValue.pop()
    self.path.next(newPathValue);
    self.checkCanDownload(self.getCompletePath())

    self.resetFinishedListing()
    self.listContent()
  }



  // PATH
  getParentFolderId(self) {
    const path = self.getCompletePath()
    return _.get(_.nth(path, -2), 'id');
  }
  getParentFolderName(self) {
    const path = self.getCompletePath()
    return _.get(_.nth(path, -2), 'name');
  };
  setCurrentFolderPage(value, self) {
    const path = self.getCompletePath()
    _.set(_.nth(path, -1), 'page', value);
  };
  setParentFolderPage(value, self) {
    const path = self.getCompletePath()
    _.set(_.nth(path, -2), 'page', value);
  };
  getCompletePath(self) {
    const suffixPath = _.get(self, 'path.value')
    if (suffixPath)
      return _.concat(self.getBasePath(), suffixPath)
    else
      return self.getBasePath()

  }

  // FILTERS AND SORTING
  getFilterQuery(self, serviceName) {
    let filtersArray;
    filtersArray = [this.getFilters(serviceName)]
    if (this.isMyFiles(serviceName)) {
      filtersArray.push('parent="' + self.getOpenedFolderId() + '"')
    }
    if (this.isSharedFiles(serviceName)) {
      if (self.getOpenedFolderId() && self.getOpenedFolderId() !== 'list') {
        filtersArray.push('parent="' + self.getOpenedFolderId() + '"')
      }
    }
    let filters = '';
    for (const el of filtersArray) {
      if (filters === '') {
        filters = el;
      } else {
        if (el !== null) {
          filters = filters + ',' + el;
        }
      }
    }
    return filters
  }
  getSortByQuery(self) {
    let filter = self.getSortFilter()
    let direction = self.getSortDirection()
    return filter + ',' + direction
  }
  setSortFilter(value: SortType, serviceName) {
    if (this.isMyFiles(serviceName)) {
      localStorage.setItem("myFilesFilter", value);
    } else if (this.isSharedFiles(serviceName)) {
      localStorage.setItem("SharedFilesFilter", value);
    } else if (this.isDeletedFiles(serviceName)) {
      localStorage.setItem("deletedFilesFilter", value);
    }
  }
  setSortDirection = (value: SortDirection, serviceName) => {
    if (this.isMyFiles(serviceName)) {
      localStorage.setItem("myFilesDirection", value);
    } else if (this.isSharedFiles(serviceName)) {
      localStorage.setItem("SharedFilesDirection", value);
    } else if (this.isDeletedFiles(serviceName)) {
      localStorage.setItem("deletedFilesDirection", value);
    }
  }
  getSortDirection(serviceName: string) {
    let direction;
    if (this.isMyFiles(serviceName)) {
      direction = localStorage.getItem('myFilesDirection')
    } else if (this.isSharedFiles(serviceName)) {
      direction = localStorage.getItem('SharedFilesDirection')
    } else if (this.isDeletedFiles(serviceName)) {
      direction = localStorage.getItem('deletedFilesDirection')
    }
    if (!direction) {
      direction = SortDirection.Desc;
    }
    return direction
  }
  getSortFilter(serviceName: string) {
    let filter;
    if (this.isMyFiles(serviceName)) {
      filter = localStorage.getItem('myFilesFilter')
    } else if (this.isSharedFiles(serviceName)) {
      filter = localStorage.getItem('SharedFilesFilter')
    } else if (this.isDeletedFiles(serviceName)) {
      filter = localStorage.getItem('deletedFilesFilter')
    }
    if (!filter) {
      filter = SortType.DateCreation;
    }
    return filter
  }
  toggleSortDirection(self) {
    if (self.getSortDirection() === SortDirection.Asc) {
      self.setSortDirection(SortDirection.Desc)
    } else {
      self.setSortDirection(SortDirection.Asc)
    }
  }
  toggleFilter(self) {
    self.filtersVisible = !self.filtersVisible;
  }

  //FILE EDITION 
  rename(itemId, value, field, self) {
    return new Observable((observer) => {
      this.filesService.filePatch(itemId, value, field).subscribe((res) => {
        self.refreshFolder()
        observer.next(res)
      }, (error) => {
        observer.next(error)
      })

    })
  }

  //FILE SIZE
  addToBulkSize(id, self) {
    let res = _.find(this.getDisplayedFiles(self), {
      id
    })
    if (res) {
      self.size = self.size + Math.abs(res.content_size)
    }

  }
  getBulkSize = (self) => {
    let output = [0, 'kB']
    if (self.size !== 0) {
      var i = Math.floor(Math.log(self.size) / Math.log(1000));
      let calc: any = self.size / Math.pow(1000, i)
      output = [calc.toFixed(1) * 1, ['B', 'kB', 'MB', 'GB', 'TB'][i]];
    }
    return output;
  }
  removeFromBulkSize(id, self) {
    let res = _.find(this.getDisplayedFiles(self), {
      id
    })
    if (res) {
      self.size = self.size - Math.abs(res.content_size)
    }
  }

  //FILE SELECTION
  setBulkToOneFileOnly(id, self) {
    self.bulkSelection = [];
    self.bulkSelection.push(id);
  }
  getBulkSelectionLength(self) {
    return _.get(self, 'bulkSelection.length', 0);
  }
  getBulkSelection(self) {
    return _.get(self, 'bulkSelection', []);
  }
  resetBulkSelection(self) {
    self.bulkSelection = [];
    self.size = 0
  }
  selectAllDisplayedFiles(self) {
    self.bulkSelection = [];
    self.getDisplayedFiles().forEach((element) => {
      self.bulkSelection.push(element.id);
      this.addToBulkSize(element.id, self)
    });
  }
  addToBulkSelection(id, self) {
    self.bulkSelection.push(id);
    this.addToBulkSize(id, self)
  }
  removeFromBulkSelection(id, self) {
    const index = _.indexOf(self.bulkSelection, id);
    if (index > -1) {
      self.bulkSelection.splice(index, 1);
    }
    this.removeFromBulkSize(id, self)
  }
  isBulkSelected(self, id?) {
    if (id) {
      return _.indexOf(self.bulkSelection, id) > -1;
    } else {
      if (self.bulkSelection === []) {
        return false;
      } else if (self.bulkSelection.length) {
        return true;
      }
    }
  }
  //FILE MANIPULATION


  allowDrop(event) {
    event.preventDefault();
  }

  handleMoveSelection(grabbedElementId, self) {
    if (this.destinationId !== '') {
      if (this.destinationIdType === 'group') {
        self.handleMoveSelectionToGroup(grabbedElementId);
      } else if (this.destinationIdType === 'folder') {
        self.handleMoveSelectionToFolder(grabbedElementId);
      }
    }
  }
  handleMoveBulkSelection(self) {
    if (_.get(this, 'destinationId.length', -1) === 0) {
      return;
    }
    this.getBulkSelection(self).forEach((element) => {
      this.handleMoveSelection(element, self);
    });
  }
  // MOVE FILE TO FOLDER
  handleMoveSelectionToFolder(grabbedElementId, self) {
    if (grabbedElementId === this.destinationId) {
      return;
    }
    this.filesService.moveFile(grabbedElementId, this.destinationId).subscribe(
      () => {
        if (grabbedElementId === _.last(self.bulkSelection)) {
          self.refreshFolder();
          this.layout.close();
          this.destinationId = '';
          this.destinationIdType = null;
          self.resetBulkSelection();
        }
      },
      (error) => {
        if (error.status === 422) {
          alert('This item ( ' + self.getTitleFromId(grabbedElementId) + ' ) can not be moved to this folder.')
        } else {
          alert(error.error.message);
        }
      }
    );
  }
  getDragEndTargetId(id, self) {
    if (id === 'return') {
      this.destinationId = self.getParentFolderId();
      this.destinationIdType = 'folder';
    } else {
      this.destinationId = id;
      this.destinationIdType = 'folder';
    }
  }
  // MOVE FILE TO GROUPS
  handleMoveSelectionToGroup(grabbedElementId, self) {
    if (grabbedElementId === this.destinationId) {
      return;
    }

    let last = false
    if (grabbedElementId === _.last(self.bulkSelection)) {
      last = true
    }
    let itemTitle = self.getTitleFromId(grabbedElementId)
    let groupTitle = self.getTitleFromGroupId(this.destinationId)

    this.filesService.getFile(grabbedElementId).subscribe(
      (res) => {
        self.addToGroup(res, grabbedElementId, itemTitle, groupTitle, last)
      },
      (error) => {
        if (last) {
          this.eventRefreshGroupId.next(this.destinationId);
          this.layout.close();
          this.destinationId = '';
          this.destinationIdType = null;
        }
        this.layout.toast("Item <b>" + itemTitle + "</b> can not be added to the group <b>" + groupTitle + "</b>.", null, 8000, '', 'danger')

      }
    );


  }
  addToGroup = (res, grabbedElementId, itemTitle, groupTitle, last, self) => {
    if (_.get(res, 'status') === Status.Ready) {
      this.filesService
        .addToGroup(this.destinationId, grabbedElementId)
        .subscribe(
          () => {
            self.removeFromBulkSelection(grabbedElementId)
            this.layout.toast("Item  <b>" + itemTitle + "</b> sucessfully added to the group <b>" + groupTitle + "</b>.", null, 5000, '', 'success')
          },
          (error) => {
            this.layout.toast("Item  <b>" + itemTitle + "</b> can not be added to the group <b>" + groupTitle + "</b>.", null, 8000, '', 'danger')
          },
          () => {
            if (last) {
              this.eventRefreshGroupId.next(this.destinationId);
              this.layout.close();
              this.destinationId = '';
              this.destinationIdType = null;
            }
          }
        );
    } else {
      if (last) {
        this.eventRefreshGroupId.next(this.destinationId);
        this.layout.close();
        this.destinationId = '';
        this.destinationIdType = null;
      }
      this.layout.toast("Item  <b>" + itemTitle + "</b> status is <b>not 'ready'</b>. Impossible to add to the group <b>" + groupTitle + "</b>.", null, 8000, '', 'danger')
    }
  }
  getDragEndGroupTargetId(id) {
    this.destinationId = id;
    this.destinationIdType = 'group';
  }
  // UPLOAD
  removeWaitingForRefreshFiles(self) {
    let uploadingFilesCopy = this.getUploadingFiles(self)
    uploadingFilesCopy = _.reject(uploadingFilesCopy, (o) => { return o.status === Status.WaitingForRefresh })
    _.get(self, 'uploadingFiles').next(uploadingFilesCopy)
  }
  proceedUpload = (uploadId, fileToBeUploaded, resolve, reject, self) => {
    // let ngUnsubscribe = new Subject<void>();
    return this.filesService
      .upload(uploadId, fileToBeUploaded.file, 'body')
      // .pipe(takeUntil(ngUnsubscribe))
      .subscribe((res2) => {
        fileToBeUploaded.reportChunkProgress = Math.round(res2);
        if (fileToBeUploaded.status === Status.Cancelled) {
          // ngUnsubscribe.next();
          // ngUnsubscribe.complete();
          resolve(true)
          return
        }
        if (fileToBeUploaded.status === Status.NetworkError && res2 !== 100) {
          // ngUnsubscribe.next();
          // ngUnsubscribe.complete();
          resolve(true)
          return
        }
        if (res2 === 100) {
          fileToBeUploaded.status = Status.WaitingForRefresh;
          setTimeout(() => {
            self.debouncedRefresh()
            self.removeWaitingForRefreshFiles()
          }, 2000)
          resolve(true)
        }
      }, () => {
        fileToBeUploaded.status = Status.Error;
        resolve(true)
      });

  }
  prepareUpload = (fileToBeUploaded) => {
    _.set(fileToBeUploaded, 'status', Status.Uploading);
    const newFileObject: ModelsPostObject = {
      description: '',
      is_folder: false,
      metadata: {},
      size: fileToBeUploaded.size,
      title: fileToBeUploaded.title,
      team_id: fileToBeUploaded.teamId
    }

    return this.filesService.postFile(fileToBeUploaded.parent_id, newFileObject, 'response')
  }
  extractUploadId(response) {
    let uploadId = response.headers.get('Location');
    return uploadId.slice(15, uploadId.length);
  }
  uploadASpecificFile = (fileToBeUploaded, self, from?) => new Promise((resolve, reject) => {
    if (from === undefined) {
      self.prepareUpload(fileToBeUploaded).subscribe(
        (res) => {
          let uploadId = this.extractUploadId(res)
          _.set(fileToBeUploaded, 'uploadId', uploadId);
          _.set(fileToBeUploaded, 'reportChunkProgress', 1)
          this.proceedUpload(uploadId, fileToBeUploaded, resolve, reject, self)
        },
        () => {
          fileToBeUploaded.status = Status.Error;
          resolve(true)
        }
      );
    } else if (from === 0) {
      _.set(fileToBeUploaded, 'reportChunkProgress', 1)
      this.proceedUpload(_.get(fileToBeUploaded, 'uploadId'), fileToBeUploaded, resolve, reject, self)
    }
  })
  uploadAFile(self) {
    let fileToBeUploaded = _.find(self.getUploadingFiles(), (o) => {
      return o.status === Status.NotStarted
    })
    if (!fileToBeUploaded) {
      return
    }
    self.uploadASpecificFile(fileToBeUploaded).then(() => {
      self.uploadAFile()
    })
  }
  handleNewFiles = (self) => {
    this.layout.close('modalFile');
    self.createTempFiles(_.get(self, 'fileUploadForm.controls.fileInput.value'), _.get(self, 'fileUploadForm.controls.team.value._key'));
    self.startUploadingSession()
  };
  getUploadingFiles = (self) => {
    return _.get(self, 'uploadingFiles.value', [])
  }
  uploadFiles = (self) => {
    let teamId = _.get(self, 'fileUploadForm.controls.team.value._key')
    if (!self.findAllFilesToBeUploaded().length && !self.findFilesCurrentlyUploading().length) {
      setTimeout(() => {
        self.refreshFolder()
        self.uploadingFiles = _.filter(self.getUploadingFiles(), (o) => {
          return o.error !== false;
        });
      }, 2000)

    } else {
      const max =
        Math.min(self.findAllFilesToBeUploaded().length, 3) -
        self.findFilesCurrentlyUploading().length;
      for (let i = 0; i < max; i++) {
        self.uploadASpecificFile(_.nth(self.findAllFilesToBeUploaded(), i), teamId)
      }
    }
  }
  startUploadingSession = (self) => {
    for (let i = 0; i < Math.min(self.findAllFilesToBeUploaded().length, 3); i++) {
      setTimeout(() => {
        self.uploadAFile()
      }, (i + 1) * 50)
    }

  }
  resetMetadataUploadForm = (self) => {
    if (self.metadataUploadForm) {
      self.metadataUploadForm.reset();
      if (self.metadataUploadForm.controls.metadataInput.value) {
        self.metadataUploadForm.controls.metadataInput = null;
      }
    }
  };
  handleNewMetaData = (self) => {
    if (self.metadataToUpload) {
      this.layout.close('modalMetaData');
      this.filesService
        .postMetaDataFile(
          self.bulkSelection[0],
          self.metadataToUpload,
          'response'
        )
        .subscribe(
          (response) => {

            self.getInfos();
            self.listContent();
          },
          (error) => {
            if (error.status === 422) {
              alert('Metadata can not be updated.')
            } else {
              alert(error.error.message);
            }
          }
        );
    }
  };
  resetFileUploadForm = (self) => {
    if (self.fileUploadForm) {
      self.fileUploadForm.reset();
      if (self.fileUploadForm.controls.fileInput.value) {
        self.fileUploadForm.controls.fileInput = null;
      }
    }
  };
  createTempFiles(filesToUpload, teamId, self) {
    const currentLocation = self.getOpenedFolderId();
    let newUploadingFiles = self.getUploadingFiles() || [];
    const filesArray = [...filesToUpload]
    filesArray.forEach((el) => {
      newUploadingFiles.push({
        description: '',
        is_folder: false,
        reportChunkProgress: 0,
        status: Status.NotStarted,
        metadata: {},
        size: el.size,
        title: el.name,
        parent_id: currentLocation,
        file: el,
        teamId: teamId
      });
    });
    self.uploadingFiles.next(newUploadingFiles);
  }
  findAllFilesToBeUploaded(self) {
    return _.filter(self.getUploadingFiles(), (o) => {
      return o.status === Status.NotStarted;
    });
  }
  findAllFilesToBeRefreshed(self) {
    return _.filter(self.getUploadingFiles(), (o) => {
      return o.status === Status.WaitingForRefresh;
    });
  }
  findFilesCurrentlyUploading(self) {
    return _.filter(self.getUploadingFiles(), (o) => {
      return o.status === Status.Uploading;
    });
  }
  findFilesInPause(self) {
    return _.filter(self.getUploadingFiles(), (o) => {
      return o.status === Status.Paused;
    });
  }

  // RETRIEVING DATA
  listContent = (self, serviceName) => new Promise((resolve, reject) => {
    let limitParam = _.get(self, 'limitParam', 30)
    let filtersParam = this.getFilterQuery(self, serviceName)
    let sortByParam = self.getSortByQuery();

    this.filesService.countFiles(sortByParam, filtersParam, limitParam).subscribe((res) => {
      self.count = _.get(res, 'count', null)
    })
    this.filesService.listFiles(sortByParam, filtersParam, limitParam, self.page).subscribe((res) => {
      let displayedFilesTemp = res.data
      if (this.isMyFiles(serviceName)) {
        displayedFilesTemp = _.reject(res.data, (o) => {
          return o.title === 'Compression'
        })


      }
      if (this.getDisplayedFiles(self) !== displayedFilesTemp) {
        this.setDisplayedFiles(self, displayedFilesTemp)
      }
      self.finishedListing = true
      resolve(true)

    }, (error) => {
      reject()
    })
  })

  getFilters(serviceName) {
    if (this.isMyFiles(serviceName)) {
      return 'ownedByMe=true';
    } else if (this.isSharedFiles(serviceName)) {
      return 'sharedWithMe=true';
    } else if (this.isDeletedFiles(serviceName)) {
      return 'trashed=true';
    }
  }
  getFinishedListing(self) {
    return _.get(self, 'finishedListing', false)
  }
  resetFinishedListing(self) {
    _.set(self, 'finishedListing', false)
  }
  getDisplayedFilesLength(self) {
    return _.get(self.getDisplayedFiles(), 'length', 0)
  }
  getDisplayedFilesAndUploadingFiles(self) {
    let result = _.concat(this.getUploadingFiles(self), _.get(self, 'displayedFiles.value', []))
    return result
  }
  getDisplayedFiles(self) {
    return _.get(self, 'displayedFiles.value', [])
  }
  setDisplayedFiles(self, value) {
    _.get(self, 'displayedFiles').next(value)
  }
  getInfos(self) {
    self.itemsWithInfoDisplayed = [];
    this.filesService.getFile(self.bulkSelection[0]).subscribe(
      (res) => {
        self.itemsWithInfoDisplayed.push(res);
        return res;
      },
      (error) => {
        if (error.status === 422) {
          alert("Error during the downloading of the item details.")
        } else {
          alert(error.error.message);
        }
        return {};
      }
    );
  }

  // COMPRESSION
  handleCompressAndDownload = (item, self) => {
    if (!this.notificationsService.compressionToast) {
      this.notificationsService.compressionToast = this.layout.toast(this.notificationsService.compressionToastContent, null, null, this.notificationsService.compressionToastTitle,
        'information', [self.onCompressionToastClose], this.notificationsService.isCompressionToastOpened)
    }
    this.notificationsService.isCompressionToastOpened.next(true)

    this.filesService.compressFolder(item.id).subscribe(
      (res) => {
        this.notificationsService.pendingFolders.push({
          'execution_id': _.get(res, 'id'),
          'file_id': item.id,
          'name': item.title,
          'status': Status.Pending,
          'link': null
        })
        this.notificationsService.updateCompressionToastMessage()
      }, (error) => {
        this.notificationsService.pendingFolders.push({
          'execution_id': null,
          'file_id': item.id,
          'name': item.title,
          'status': Status.Error,
          'link': null
        })
        this.notificationsService.updateCompressionToastMessage()
      })
  }
  onCompressionToastClose = (self) => {
    let pendingFolders = _.filter(this.notificationsService.pendingFolders, (o) => {
      return o.status === Status.Pending
    })
    if (!pendingFolders.length) {
      this.notificationsService.isCompressionToastOpened.next(false)
      this.notificationsService.pendingFolders = []
      this.notificationsService.compressionToastTitle.next('Preparing download  . . .   ')
      this.notificationsService.updateCompressionToastMessage()
      return
    }
    let text = pendingFolders.length > 1 ? 'Downloads are not completed. Do you want to cancel all downloads ?' : 'Download is not completed. Do you want to cancel ?'
    this.layout.customConfirm(text, () => {
      this.notificationsService.pendingFolders = []
      this.notificationsService.compressionToastTitle.next('Preparing download  . . .   ')
      this.notificationsService.updateCompressionToastMessage()
      this.notificationsService.isCompressionToastOpened.next(false)
    })
  }

  // PERMISSIONS
  checkCanDownload(path, self, serviceName) {
    if (this.isMyFiles(serviceName)) {
      if (environment.name === 'prod') {
        this.permissions
          .checkPermission({
            permissions: {
              createTeam: {},
            },
          })
          .subscribe((res) => {
            let isProjectsSubFiles = false
            if (path) {
              isProjectsSubFiles = _.findIndex(path, function (o) {
                return o.name == 'Projects';
              }) > -1
            }
            if (isProjectsSubFiles) {
              self.canDownload.next(true)
            } else {
              self.canDownload.next(res.createTeam);
            }
          });
      } else {
        self.canDownload.next(true)
      }
    } else if (this.isSharedFiles(serviceName)) {
      if (environment.name === 'prod') {
        this.permissions
          .checkPermission({
            permissions: {
              createTeam: {},
            },
          })
          .subscribe((res) => {
            if (res.createTeam) {
              self.canDownload.next(res.createTeam);
              return
            }

            let countryArray = self.localService.getFromLocalStorage('user', 'file_systems')
            countryArray = _.filter(countryArray, (o) => {
              return o.accessState !== FileSystemState.Disabled
            })
            self.projectsService
              .projectList('date_of_creation', 'sharedWithMe=true,sharedWithMyUserGroups=true', 100)
              .subscribe(res => {
                let projects = self.extractProjectsFromDifferentStorageLocation(res, countryArray)
                let projectTitles = projects.map((el) => {
                  return _.get(el, 'title')
                })
                let isProjectsSubFiles = false
                if (path) {
                  isProjectsSubFiles = _.findIndex(path, function (o) {
                    return _.includes(projectTitles, o.name)
                  }) > -1
                }
                if (isProjectsSubFiles) {
                  self.canDownload.next(true)
                }
              });
          });
      } else {
        self.canDownload.next(true)
      }
    }
  }

  //MULTICOUNTRY
  extractProjectsFromDifferentStorageLocation(response, countryArray) {
    let displayedProjects = []
    countryArray.forEach(country => {
      let data = _.get(_.get(response, country.value), 'data')
      data.forEach(project => {
        _.set(project, 'country', country.value)
      });
      displayedProjects.push(...data)
    });
    displayedProjects = _.sortBy(displayedProjects, 'title')
    return displayedProjects
  }

  //PANNEL
  openInfo = () => {
    this.layout.close()
    this.layout.open('modalFilesInfos');
  }
  openShare = () => {
    this.layout.close();
    this.layout.open('modalFilesShare');
  }

  //FOLDERS
  resetFolderCreationForm = (self) => {
    if (self.folderCreationForm) {
      self.folderCreationForm.reset();
    }
  };
  handleNewFolder = (self) => {
    const currentLocation = self.getOpenedFolderId();
    const newFolderObject: ModelsPostObject = {
      description: '',
      is_folder: true,
      metadata: {},
      size: 0,
      title: self.folderCreationForm.get('folderInput').value,
    };
    this.filesService
      .postFile(currentLocation, newFolderObject, 'response')
      .subscribe(
        (res) => {
          this.layout.close('modalFolder');
          self.refreshFolder();
          self.resetFolderCreationForm();
        },
        (error) => {
          if (error.status === 422) {
            alert('Error during the creation of the folder.')
          } else {
            alert(error.error.message);
          }
        }
      );
  };

  // DOWNLOAD
  handleDownloadReset(self) {
    return () => {
      _.set(self, 'download', false)
      _.set(self, 'dlLink', null)
    }
  }
  downloadFile(self) {
    self.download = true
  }

  //DELETION
  deleteAll(self) {
    if (self.bulkSelection.length === 0) {
      this.layout.toast('Please select at least one item.', null, 8000, '', 'warning')
      return
    }
    let line1 = 'All selected files and folder will be trashed. Are you sure you want to do this ?'
    let line2 = '<br><br>' + 'Items will be permanentely deleted in 30 days.'
    let text = line1 + line2

    this.layout.customConfirm(text, function () {
      self._deteleAll()
    })
  }
  _deteleAll(self) {
    for (let i = 0; i < self.bulkSelection.length; i++) {
      self.handleDeleteNoConfirm(self.bulkSelection[i]);
    }
    setTimeout(() => {
      self.refreshFolder();
      self.resetBulkSelection();
    }, 500)

  }
  _handleDelete(id, self, title?) {
    this.filesService.deleteFile(id).subscribe(
      (result) => {
        self.refreshFolder();
        this.eventRefreshGroups.next(true)

        self.resetBulkSelection();
        this.layout.close();
      },
      (error) => {
        if (error.status === 422) {
          alert((title ? ('"' + title + '"') : 'This item') + ' can not be deleted.')
        } else {
          alert(error.error.message);
        }
      }
    );

  }
  handleDelete(item, self) {
    if (!item.capabilities.can_trash || !_.includes(availableFeatures, Features.DeleteFile || !this.deleteFilePermission)) {
      return
    }
    let id = _.get(item, 'id')
    let title = _.get(item, 'title')
    let line1 = (title ? ('"' + title + '"') : 'This item') + ' will be deleted. Are you sure you want to do this ?'
    let line2 = '<br><br>' + 'This item will be permanentely deleted in 30 days.'
    let text = line1 + line2
    this.layout.customConfirm(text, function () {
      self._handleDelete(id, title ? title : null)
    })
  }
  handleDeleteNoConfirm(id, title?) {
    this.filesService.deleteFile(id).subscribe()
  }


  //PERMANENTELY DELETE
  _handlePermanentelyDelete = (self) => {
    self.bulkSelection.forEach((element) => {
      self.handlePermanentelyDeleteFile(element);
    });

  }
  handlePermanentelyDelete = (self) => {
    let isPlural = self.getBulkSelectionLength() > 1
    let item = isPlural ? ('these ' + self.getBulkSelectionLength() + ' items ?') : 'this item ?';
    let text = 'Are you sure you want to permanentely delete ' + item
    this.layout.customConfirm(text, () => {
      self._handlePermanentelyDelete()
    })
  }
  handleRestore = (self) => {
    self.bulkSelection.forEach((element) => {
      self.handleRestoreFile(element);
    });
  }
  handleRestoreFile = (id, self) => {
    this.filesService.restore(id).subscribe(
      (res) => {
        if (id === _.last(self.bulkSelection)) {
          self.resetBulkSelection();
          self.listContent()
        }
      },
      (error) => {
        if (error.status === 422) {
          alert('Error during the restoration of the file : ' + id)
        } else {
          alert(error.error.message);
        }
      }
    );
  }
  handlePermanentelyDeleteFile = (id, self) => {
    this.filesService.permanentelyDelete(id).subscribe(
      (res) => {
        if (id === _.last(self.bulkSelection)) {
          setTimeout(() => {
            self.resetBulkSelection();
            self.listContent()
          }, 300)

        }
      },
      (error) => {
        if (error.status === 422) {
          alert('Error during the permanent deletion of the file : ' + id)
        } else {
          alert(error.error.message);
        }
      }
    );
  }
}
