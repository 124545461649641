<div id="{{ item.id }}" class="list">
  <div class="list-container row" [class.tile-selected]="selected"
    [class.tile-danger]=' getStatus() === Status.Unusable' [class.tile-warning]=' getStatus() === Status.Processing'
    [class.tile-selected-danger]='selected && getStatus() === Status.Unusable'
    [class.tile-selected-warning]='selected && getStatus() === Status.Processing'>

    <div class="item-text-container row mx-0 px-0 col-6 flex-nowrap ">
      <app-checkbox *ngIf='fileManager.viewTab === TabsName.MyFiles' [fileType]='FileType.File'
        [selectAction]='myFilesManager.addToBulkSelection' [unSelectAction]='myFilesManager.removeFromBulkSelection'
        [status]='getStatus()' [id]="item.id" bulkType='file' [selected]="selected"></app-checkbox>
      <app-checkbox *ngIf='fileManager.viewTab === TabsName.SharedFiles' [fileType]='FileType.File'
        [selectAction]='sharedFilesManager.addToBulkSelection'
        [unSelectAction]='sharedFilesManager.removeFromBulkSelection' [status]='getStatus()' [id]="item.id"
        bulkType='file' [selected]="selected"></app-checkbox>
      <app-checkbox *ngIf='fileManager.viewTab === TabsName.DeletedFiles' [fileType]='FileType.File'
        [selectAction]='deletedFilesManager.addToBulkSelection'
        [unSelectAction]='deletedFilesManager.removeFromBulkSelection' [status]='getStatus()' [id]="item.id"
        bulkType='file' [selected]="selected"></app-checkbox>
      <div class="item-text px-0" [class.text-warning]='getStatus() === Status.Processing'
        [class.text-danger]='getStatus() === Status.Unusable' [class.text-primary]='isRecent'>{{ item.title }}
        <small class='text-uppercase' *ngIf='getStatus() === Status.Processing'> – processing </small>
        <small class='text-uppercase' *ngIf='getStatus() === Status.Unusable'>
          – unusable </small>
          <small class='text-uppercase' *ngIf='isRecent'>
            – Recently uploaded </small>
      </div>
    </div>
    <div class="item-icons-container mx-0 px-0 row col-6">
      <div class="item-icon-text mx-0 px-0 col">
        {{ iconText }}
      </div>
      <div *ngIf='item.trashed' class="d-flex item-icons col-md-auto mx-0 px-0">
        <span class='mx-2 '><small>
            <span class='text-uppercase text-muted '>deleted : </span>

            <span class='text-muted'>{{moment.unix(item.date_of_trashing).format('YY/MM/DD - hh:mm A')}}
            </span></small></span>
      </div>
      <div *ngIf='!item.trashed' class="item-icons col-md-auto mx-0 px-0">
        <button  ngbTooltip="{{getTooltip('download')}}" tooltipClass="custom-tooltip" placement="left"
          (click)="(getCanDownload() && manager.canDownload.value) ? [manager.setBulkToOneFileOnly(item.id),manager.downloadFile(),$event.stopPropagation()]  : '' "
          class="btn btn-link hover-active" (mouseenter)="setIconText('Download file')" (mouseleave)="unSetIconText()">
          <i class="far fa-arrow-alt-to-bottom" [class.disabled]='!getCanDownload() || !manager.canDownload.value'
            aria-hidden="true"></i>
        </button>

        <button
          (click)="manager.setBulkToOneFileOnly(item.id);manager.getInfos();manager.openShare();$event.stopPropagation()"
          class="btn btn-link hover-active" (mouseenter)="setIconText('Share file')" (mouseleave)="unSetIconText()">
          <i class="far fa-share-square" aria-hidden="true"></i>
        </button>
        <button
          (click)="manager.setBulkToOneFileOnly(item.id);manager.getInfos(); manager.openInfo();$event.stopPropagation()"
          (mouseenter)="setIconText('Read description')" (mouseleave)="unSetIconText()" class="btn btn-link hover-active">
          <i class="far fa-info-circle" aria-hidden="true"></i>
        </button>

        <button (click)="manager.handleDelete(item)" [class.no-focus]='!getCanTrash()|| !_.includes(availableFeatures, Features.DeleteFile) || !fileManager.deleteFilePermission' (mouseleave)="unSetIconText()" class="btn btn-link hover-active">
          <i class="far fa-trash-alt" ngbTooltip="{{getTooltip('delete')}}" tooltipClass="custom-tooltip" placement="left"
            [class.disabled]='!getCanTrash()|| !_.includes(availableFeatures, Features.DeleteFile) || !fileManager.deleteFilePermission'
            (mouseenter)="setIconText('Delete file')" aria-hidden="true"></i>
        </button>


        <!--
      <button
        class="btn btn-link hover-active disabled"
        (mouseenter)="setIconText('Edit Annotation')"
        (mouseleave)="unSetIconText()"
      >
        <i class="far fa-pen-alt"></i>
      </button> -->
        <button (click)='activateQualityMode(item.id,item.title)' (mouseenter)="setIconText('Show quality')"
          (mouseleave)="unSetIconText()" class="btn btn-link hover-active">
          <i aria-hidden='true' class="far fa-check-square"></i>
        </button>
        <button *ngIf="hasMetaData()" class="btn btn-link hover-active cursor-auto">
          <div class="icon-blank"></div>
        </button>

        <button class="btn btn-link hover-active" *ngIf="!hasMetaData()"
          (click)="manager.setBulkToOneFileOnly(item.id);manager.getInfos(); manager.openInfo();$event.stopPropagation()"
          (mouseenter)="setIconText('This file has no metadata')" (mouseleave)="unSetIconText()"
          [class.text-danger]="!hasMetaData()">
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </button>

      </div>
    </div>
  </div>
</div>
