import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorage } from 'ngx-webstorage';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
export interface ModelTeamBody {
  name: string;
  _key?: string;
  owner_user_ids: Array<string>;
  subscription_id: string;
  user_ids: Array<string>;
}


@Injectable()
export class TeamsService {
  @LocalStorage() user
  refreshTeams = new EventEmitter<boolean>(false);
  refreshTeamId = new BehaviorSubject<string>('hello');
  selectedTeam = null
  memberAdditionForm = new FormGroup({
    memberInput: new FormControl(null),
  });
  newMemberAdditionForm = new FormGroup({
    email: new FormControl(null),
    first_name: new FormControl(null),
    last_name: new FormControl(null),
    ontological_domain: new FormControl(null),
    organization: new FormControl(null),
    file_hosting_country: new FormControl(null),
  })
  teamSubscriptionForm = new FormGroup({
    subscriptionId: new FormControl(null),
  })
  protected basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  constructor(private readonly http: HttpClient) { }


  submitUser(user: {
    email: string;
    first_name: string;
    last_name: string;
    ontological_domain: string;
    file_hosting_country: string;
    organization: string;
  }, country) {
    let queryParameters = new HttpParams({});
    queryParameters = queryParameters.set('set_password_via_email', true as any);
    return this.http.post(`${this.basePath}/mas/users`, user, {
      params: queryParameters,
    });
  }
  getSubscription = (subscriptionId): Observable<any> => {
    return this.http.get<any>(`${this.basePath}/mas/teams/subscription/${subscriptionId}`);
  }
 
  resetMemberAdditionForm = () => {
    if (this.memberAdditionForm) {
      this.memberAdditionForm.reset();
    }
  };
  getTeams = (): Observable<any[]> => {
    return this.http.get<any>(`${this.basePath}/mas/teams`);
  }
  getTeam = (id): Observable<any> => {
    return this.http.get<any>(`${this.basePath}/mas/teams/teamusers/${id}`);
  }
  deleteTeam = (id): Observable<any> => {
    return this.http.delete<any>(`${this.basePath}/mas/teams/${id}`);
  }
  
  createTeam = (): Observable<any> => {
    const body = {
      name: "New team",
      owner_user_ids: [_.get(this, 'user.id')],
      subscription_id: "",
      user_ids: [_.get(this, 'user.id')]
    }
    return this.http.post<any>(`${this.basePath}/mas/teams`, body);
  }
  deleteUser = (teamId, array): Observable<any> => {

    const body = {
      _key: teamId,
      name: "New team",
      owner_user_ids: [_.get(this, 'user.id')],
      subscription_id: "",
      user_ids: array
    }
    return this.http.patch<any>(`${this.basePath}/mas/teams/${teamId}`, body);
  }

  updateTeam = (teamId, newBody): Observable<any> => {
    return this.http.patch<any>(`${this.basePath}/mas/teams/${teamId}`, newBody);
  }

  rename = (teamId, value, field) => {
    return new Observable((observer) => {
      this.getTeam(teamId).subscribe((res) => {
        let body = res
        _.set(body, 'team.name', value)
        this.updateTeam(teamId, body.team).subscribe((res2) => {
          this.refreshTeams.emit(true)
          observer.next(res2)
        }, (error) => {
          observer.next(error)
        })
      })


    })
  }
}
