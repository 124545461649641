<div class="list-group-item scrollable" id={{item.id}}>
  <app-rename *ngIf='fileManager.viewTab === TabsName.MyFiles' [renameFunction]='myFilesService.rename'
    class='h5 text-primary' [type]='ItemType.File' [item]='item' field='title'></app-rename>
  <app-rename *ngIf='fileManager.viewTab === TabsName.SharedFiles' [renameFunction]='sharedFilesService.rename'
    class='h5 text-primary' [type]='ItemType.File' [item]='item' field='title'></app-rename>
  <div *ngIf='!item.is_folder' class='my-3'>
   
    <button type="button"  [class.disabled]="!_.includes(availableFeatures,Features.UploadFile)" placement="right"
    ngbTooltip="{{getTooltip('upload')}}" tooltipClass="custom-tooltip"
    (click)="_.includes(availableFeatures,Features.UploadFile) ? layout.open('modalMetaData') : '' "
    class="modal-btn btn btn-outline-danger upload-btn separated-button"
     >
      <i class="fas fa-arrow-circle-up" aria-hidden="true"></i>
      Update Metadata

      <i class="fas fa-exclamation-circle text-danger cornerIcon" *ngIf="!(hasMetaData | async)" aria-hidden="true"></i>
    </button>
    <small>
      <div class="text-danger" [hidden]="(hasMetaData | async)">File not found.
      </div>
    </small>
  </div>


  <ul class='mt-4'>

    <small>
      <li class='my-2 text-uppercase'><span class='text-muted'>status : </span><span
          [class.text-warning]="item.status === Status.Processing" [class.text-primary]="item.status === Status.Ready"
          class='font-weight-bold'> {{item.status}}</span></li>

      <li class='my-2 text-uppercase'><span class='text-muted'>creation : </span><span class='font-weight-bold'
          [class.text-warning]='dateCreation === "a few seconds ago"'>{{dateCreation}}</span></li>
      <li class='my-2 text-uppercase'><span class='text-muted'>last modification : </span><span class='font-weight-bold'
          [class.text-warning]='dateModification === "a few seconds ago"'>{{dateModification}}</span></li>

      <li *ngIf='!item.is_folder' class='my-2 text-uppercase'><span class='text-muted'>format : </span><span
          class='font-weight-bold'>.{{_.last(_.split(item.title, '.'))
            }}</span>
      <li class='my-2 text-uppercase'><span class='text-muted'>size : </span><span
          class='font-weight-bold'>{{size}}</span>
        <li *ngIf='teamName.value' class='my-2 text-uppercase'><span class='text-muted'>team : </span><span
          class='font-weight-bold'>{{teamName.value}}</span>
        <br>
      <li class="text-uppercase" *ngIf="!_.isEmpty(item.metadata)"><span class='text-muted'>metadata : </span>
        <ul class="details">
          <li *ngFor="let element of item.metadata | keyvalue "><span>{{element.key | uppercase }} :
            </span><span>{{element.value}}</span></li>
        </ul>
      </li>

      <li class='text-uppercase my-4 font-weight-bold'>
        <span>{{item.is_shared ? "This item is shared" : "This item isn't shared"}}</span></li>

    </small>

  </ul>
</div>