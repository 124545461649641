import {
  Component,
  OnInit,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProjectsService, RawInputTypes } from 'src/app/api/projects.service';
import { FilesService } from 'src/app/api/files.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { AdditionalTypes, IsRequired } from 'src/app/shared/helpers/formattedInput';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { TeamsService } from '../../teams-manager/teams.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { LocalService } from 'src/app/api/local-storage.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { BehaviorSubject, forkJoin} from 'rxjs';


@Component({
  selector: 'app-project-creation-form',
  templateUrl: './project-creation-form.component.html',
  styleUrls: ['../project-creation.component.scss', './project-creation-form.component.scss'],
})
export class ProjectCreationFormComponent implements OnInit {
  existingFilegroupInput;
  submitted = false;
  PagesPublicName = PagesPublicName;
  projectId;
  project
  tagName = 'is_filegroup'
  fieldObjects = new BehaviorSubject<any>([])
  inputsInProject = [];
  form: FormGroup;
  constructor(
    public projectsService: ProjectsService,
    public projectsManagerService: ProjectManagerService,
    private filesService: FilesService,
    private teamsService: TeamsService,
    private router: Router,
    private route: ActivatedRoute,
    private layout: LayoutOrganizationService,
    private groupsService: GroupsManagerService,
    private localService: LocalService
  ) {
    if (this.route.snapshot.paramMap.get('projectId')) {
      _.set(this, 'projectId', this.route.snapshot.paramMap.get('projectId'));
    }
    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'))
    } else {
      _.set(this.projectsService, 'projectStorageCountry', this.localService.getFromLocalStorage('user', 'account_country'))
    }
    this.route.queryParams.subscribe((params) => {
      if (_.get(params, 'fromDebugger') === 'true') {
        projectsService.setFromDebugger(true)
      }
    })
  }


  ngOnInit() {
    this.form = this.createEmptyForm();
    let titleFieldObject = this.createTitleField()
    let teamFieldObject = this.createTeamField()
    let descriptionFieldObject = this.createDescriptionField()
    let filegroupFieldObject = this.createFilegroupField()
    this.fieldObjects.next([titleFieldObject, teamFieldObject, filegroupFieldObject, descriptionFieldObject])
    forkJoin({
      teams: this.retrieveTeams(),
      groups: this.retrieveGroups(),
      project: this.retrieveProject()
    }).subscribe((res) => {

      //TEAMS
      teamFieldObject.retrieved_allowed_values.next(res.teams)
      let team = _.get(res.project, 'owner_team_id', '')
      let preselectedTeam = _.find(res.teams, (o) => { return o._key === team });
      if (preselectedTeam) {
        teamFieldObject.retrievedValue.next(preselectedTeam)
      } else {
        teamFieldObject.retrievedValue.next(_.nth(res.teams, 0))
      }
      //PROJECTS
      this.project = res.project
      let title = _.get(res.project, 'title')
      let description = _.get(res.project, 'description')
      if (title) {
        this.projectsService.setProjectName(title)
        titleFieldObject.retrievedValue.next(title)
      }
      if (description) {
        descriptionFieldObject.retrievedValue.next(description)
      }
      this.inputsInProject = _.get(res.project, 'input_list', [])
      this.existingFilegroupInput = _.nth(this.projectsService.filterProjectInputs(this.inputsInProject, this.tagName), 0)

      //FILEGROUPS
      let groups= [..._.get(res, 'groups')]
      groups.forEach((el) => {
        if (!el.owned_by_me && el.id)
        _.set(el,'name', el.name + ' –– shared with me  ')
      })
        filegroupFieldObject.retrieved_allowed_values.next(res.groups)
      let preselectedFilegroup = _.find(res.groups, (o) => { return o.id === _.get(this.existingFilegroupInput, 'value') });
      if (preselectedFilegroup) {
        filegroupFieldObject.retrievedValue.next(preselectedFilegroup)
      } else {
        filegroupFieldObject.retrievedValue.next(_.nth(res.groups, 0))
      }
    })
  }
  getAllowedValueUpdate(fieldobject) {
    return _.get(fieldobject, 'retrieved_allowed_values', new BehaviorSubject<any>(undefined))
  }
  getFieldObjectsLength() {
    return _.get(this, 'fieldObjects.value.length', 0)
  }
  retrieveTeams = () => new Promise((resolve, reject) => {
    this.teamsService.getTeams().subscribe((res) => {
      resolve(res)
    }, () => {
      this.layout.toast('Impossible to get teams', null, 8000, '', 'warning')
      reject()
    })
  })
  retrieveGroups = () => new Promise((resolve, reject) => {
    this.filesService.listAllGroups('name', 'ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true')
      .subscribe((res) => {
        const countryArray = this.localService.getAvailableCountries()
        this.groupsService.setFilegroupsStorageCountry(null)
        this.groupsService.groupsFromAllCountries = this.groupsService.extractGroupsFromAllCountries(res, countryArray)
        this.groupsService.groupsFromAllCountries = _.orderBy(this.groupsService.groupsFromAllCountries, ['owned_by_me', 'name'], ['desc', 'asc'])
        resolve(_.concat([{ name: 'No file group', id: null }], this.groupsService.groupsFromAllCountries))
      }, () => {
        this.layout.toast('Impossible to get file groups', null, 8000, '', 'warning')
        reject()
      })

  })
  retrieveProject = () => new Promise((resolve, reject) => {
    if (this.projectId) {
      this.projectsService.projectGet(this.projectId).subscribe((res) => {
        resolve(res)
      }, () => {
        this.layout.toast('Impossible to get project', null, 8000, '', 'warning')
        reject()
      })
    } else {
      this.projectsService.setProjectName('')
      resolve({})
    }

  })
  getFilegroupFieldObject() {
    return _.get(this, 'filegroupFieldObject', null)
  }

  getFormValidity() {
    return _.get(this, 'form.valid', false)
  }

  createTeamField = () => {
    return {
      formControlName: 'team',
      type: AdditionalTypes.Object,
      description: 'Select a team',
      fitToContent: false,
      is_array: true,
      multiple_selection: false,
      retrieved_allowed_values: new BehaviorSubject<any>(undefined),
      retrievedValue: new BehaviorSubject<any>(undefined)
    }
  }
  createDescriptionField = () => {
    return {
      formControlName: 'description',
      placeholder: 'describe your project',
      type: AdditionalTypes.Paragraph,
      description: 'Describe project',
      fitToContent: false,
      iconClassName: 'fas fa-info-circle',
      validations: [IsRequired],
      retrievedValue: new BehaviorSubject<any>(undefined)
    }
  }
  createTitleField = () => {
    return {
      formControlName: 'title',
      placeholder: 'name your project',
      type: AdditionalTypes.Title,
      description: 'Name project',
      fitToContent: false,
      iconClassName: 'fas fa-info-circle',
      validations: [IsRequired],
      retrievedValue: new BehaviorSubject<any>(undefined)
    }
  }
  getFieldObjects() {
    return _.get(this, 'fieldObjects.value', [])
  }

  getFormError() {
    return this.getFormValidity() ? '' : 'Please verify your form.'
  }
  createFilegroupField = () => {
    return {
      formControlName: 'filegroup',
      type: AdditionalTypes.Object,
      description: 'Select a file group',
      fitToContent: false,
      is_array: true,
      multiple_selection: false,
      retrievedValue: new BehaviorSubject<any>(undefined),
      retrieved_allowed_values: new BehaviorSubject<any>(undefined)

    }
  }
  createEmptyForm() {
    return new FormGroup({
      title: new FormControl(),
      description: new FormControl(),
      filegroup: new FormControl(),
      team: new FormControl()
    })
  }
  getForm() {
    return _.get(this, 'form', null)
  }
  createProject() {
    if (this.form.valid) {
      this.submitted = true;
      const description = this.getForm().value.description;
      const title = this.getForm().value.title;
      const filegroup = this.getForm().value.filegroup;
      const team = this.getForm().value.team;

      if (!filegroup.id) {
        if (!this.projectId) {
          this.projectsManagerService.setProjectStorageCountry(this.localService.getFromLocalStorage('user', 'account_country', 'US'))
        } else {
          this.projectsManagerService.setProjectStorageCountry(_.get(this.project, 'host_country'))
        }
      } else {
        if (!this.projectId) {
          this.projectsManagerService.setProjectStorageCountry(_.get(filegroup, 'country'))
        } else {
          if (filegroup.country !== _.get(this.project, 'host_country')) {
            this.layout.toast("Project location is <b>" + _.get(this.project, 'host_country') + "</b>. Please choose a filegroup located in " + _.get(this.project, 'host_country') + " too or create another project.", null, 5000, '', 'danger')
            return
          } else {
            this.projectsManagerService.setProjectStorageCountry(_.get(filegroup, 'country', null))
          }
        }
      }
      if (this.projectId) {
        this.submitProject(title, description, filegroup, team);
      } else {
        this.submitNewProject(title, description, filegroup, team);
      }
      this.getForm().reset();
    }
  }


  submitProject(title, description, filegroup, team) {
    this.projectsService.updateProject({ title, description }, this.projectId).subscribe(
      (res) => {
        this.projectsService.setProjectName(title)
      }, (error) => {
        this.layout.toast('Error when updating project', null, 8000, '', 'warning')
      }
    );
    if (_.get(team, '_key'))
      this.projectsService.associateTeam(this.projectId, team._key).subscribe()
    if (this.existingFilegroupInput && _.get(this, 'existingFilegroupInput.id') === _.get(filegroup, 'id')) {
      this.router.navigate([PagesName.ProjectCreation, PagesName.ChooseWorkflow, this.projectId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId: _.get(filegroup, 'id') } })
      return
    }
    if (this.existingFilegroupInput && _.get(this, 'existingFilegroupInput.id') !== _.get(filegroup, 'id')) {
      this.projectsService.removeInput(this.projectId, _.get(this, 'existingFilegroupInput.id')).subscribe(
        () => { }, () => {
          this.layout.toast('Error when deleting previously added file group input', null, 8000, '', 'warning')
        })
    }

    if (_.get(filegroup, 'id')) {
      const tags = { 'is_filegroup': true }
      this.projectsService.appendInput(this.projectId, RawInputTypes.FileGroup, _.get(filegroup, 'id'), {}, tags).subscribe(
        (res) => { },
        (error) => this.layout.toast('Error when setting file group input', null, 8000, '', 'warning')
      );
    }
    this.router.navigate([PagesName.ProjectCreation, PagesName.ChooseWorkflow, this.projectId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId: _.get(filegroup, 'id') } })

  }

  submitNewProject(title, description, filegroup, team) {

    this.projectsService.createProject({ title, description }).subscribe(
      (res) => {
        const id = res.id;
        if (_.get(team, '_key'))
          this.projectsService.associateTeam(id, team._key).subscribe()

        if (_.get(filegroup, 'id')) {
          const tags = { 'is_filegroup': true }
          this.projectsService.appendInput(id, RawInputTypes.FileGroup, _.get(filegroup, 'id'), {}, tags).subscribe(
            () => { },
            () => this.layout.toast('Error when setting file group input', null, 8000, '', 'warning')
          );
        }
        this.router.navigate([PagesName.ProjectCreation, PagesName.ChooseWorkflow, id, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId: _.get(filegroup, 'id') } });
      },
      () => this.layout.toast('Error when creating project', null, 8000, '', 'warning')
    );
  }
  previous() {
    this.submitted = true
    if (this.projectsService.getFromDebugger()) {
      this.router.navigate([PagesName.WorkflowDebugger]);
    } else {
      this.router.navigate([PagesName.ProjectManager, TabsName.MyProjects]);
    }
  }

}
