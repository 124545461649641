<div class="list-group-item d-flex flex-column  scrollable h-100" id={{item.id}}>
  <app-rename [renameFunction]='groupsManagerService.rename' class='h5 text-primary' [type]='ItemType.FileGroup'
    field='name' [item]='item'></app-rename>
  <ul>
    <small>
      <li class='py-2 text-uppercase'><span>owner : </span><span class='font-weight-bold'>
          {{item.owner.name}}</span>
      </li>
    </small>
  </ul>
  <app-share [item]='item' [type]='ItemType.FileGroup'></app-share>
</div>