<div *ngIf='!activated' class='background h-100 w-100 align-center'>
  <div class='card d-flex justify-content-between flex-column'>
    <div>
      <div class='mims mb-4'></div>
      <h2>Activate your account</h2>
      <h4 class='text-info my-1 font-italic'>You have been invited to {{getPlatformName()}}</h4>



    </div>
    <div class='flex-fill d-flex flex-nowrap align-items-center '>
      <div class='form-container h-100 d-flex flex-column justify-content-between flex-fill'>
        <div class='my-5'>
          <form [formGroup]='getPasswordForm()'>
            <app-input-field [writeError]='true' [parentForm]='getPasswordForm()' [inputJson]='newPasswordFieldObject'>
            </app-input-field>
            <app-input-field [parentForm]='getPasswordForm()' [inputJson]='getNewPasswordConfirmationFieldObject()'>
            </app-input-field>

          </form>


        </div>
        <div class='error-box'>
        <ul *ngIf='newPassword.valid && newPasswordConfirmation.valid' class='small list-unstyled text-danger '>
          <li *ngFor='let error of getFormErrorsList()'>
            <span>
              {{getFormErrorSentence(error)}}
            </span>
          </li>
        </ul>
        <ul class='small list-unstyled text-danger '>
          <li *ngIf='getApiError().length' >
            <span>
              {{getApiError()}}
            </span>
          </li>
        </ul>
      </div>
        
        <div class='buttons w-100 d-flex justify-content-end'>
          <div class="btn btn-dark" tabindex="0" type="button" (click)='handleActivation()'
            ngbTooltip="{{getFormError()}}" tooltipClass="custom-tooltip" placement="top"
            [class.disabled]='getPasswordForm() && !getPasswordFormValidity()'>
            <span class=' text-uppercase small'>
              Activate your account
            </span>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
<div *ngIf='activated' class='background h-100 w-100 align-center'>
  <div class='card justify-content-between d-flex flex-column '>
    <div class=''>
      <div class='mims'></div>
      <h2 class='my-3'>Congratulations ! </h2>
    </div>
    <div>
      <h2 class='text-info text-wrap'>Your account has been successfully activated</h2>
    </div>
    <div>
      <h4 class='text-info text-wrap'>Follow the button below to sign in to the {{getPlatformName()}} platform
      </h4>
      <div class='buttons d-flex'>
        <div class="btn btn-dark w-50 mt-3" type="button" (click)='navigateTo("/login")'>
          <span class=' text-uppercase small'>
            Sign in
          </span>
        </div>
      </div>
    </div>


  </div>

</div>
