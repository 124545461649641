import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
    HttpClient, HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { ProjectManagerService } from './project-manager.service';
import { FileManager } from './file-manager.service';
import { LocalStorage } from 'ngx-webstorage';
import { LayoutOrganizationService } from './layout-organization.service';
import { TabsName } from '../shared/helpers/tabsName';
import { MyFilesManagerService } from './my-files-manager.service';
import { SharedFilesManagerService } from './shared-files-manager.service';
import { GroupsManagerService } from './groups-manager.service';

export enum RoleType {
    None = 'none',
    Contributor = 'contributor',
    Reader = 'reader',
    Downloader = 'downloader',
    Writer = 'writer',
    Owner = 'owner'
}
export var RoleTypeProject = [RoleType.Reader, RoleType.Writer, RoleType.Contributor]
export var RoleTypeFile = [RoleType.Reader, RoleType.Downloader, RoleType.Writer, RoleType.Contributor]
export enum PermissionType {
    None = 'none',
    Reader = 'reader',
    Downloader = 'downloader',
    Writer = 'writer',
    Contributor = 'contributor',
    Owner = 'owner',
}
export enum AutoCompleteType {
    TeamsMembers,
    UsergroupNames,
    UsergroupsMembers,
    NewMembers,
}
export enum ItemType {
    Team = 'team',
    File = 'files',
    Project = 'projects',
    FileGroup = 'filegroups',
}
export enum UserType {
    User = 'users',
    Usergroup = 'usergroups',
}
export interface autoComplete {
    value: string;
    id?: string;
    team?: string;
    role?: string;
    type?: AutoCompleteType;
}

@Injectable()
export class ShareService {
    @LocalStorage() user;
    AutoCompleteType = AutoCompleteType;
    RoleType = RoleType;
    PermissionType = PermissionType;
    ItemType = ItemType;
    UserType = UserType;
    usersSelected = []
    autoCompleteData: Array<autoComplete> = []
    //usergroupNamess: Array<autoComplete> = []
    listingCompleted = new EventEmitter<boolean>(false)
    // teamsMembers: Array<autoComplete> = []
    // usergroupsMembers: Array<autoComplete> = []
    userAutocompleteData: Array<autoComplete> = []
    userAutocompleteDataFiltered: Array<autoComplete> = this.userAutocompleteData
    protected basePath = environment.apiBasePath
        ? environment.apiBasePath
        : 'http://localhost:8081';

    constructor(private myFilesManager: MyFilesManagerService, private groupsManagerService: GroupsManagerService, private SharedFilesManager: SharedFilesManagerService, private readonly http: HttpClient, private l: LayoutOrganizationService, private projectManagerService: ProjectManagerService, private fileManager: FileManager) { }


    listFromUrl(url): Observable<any> {
        return this.http.get<any>(url);
    }
    getTeams = (): Observable<any> => {
        return this.http.get<any>(`${this.basePath}/mas/teams`);
    }
    getTeam = (id): Observable<any> => {
        return this.http.get<any>(`${this.basePath}/mas/teams/teamusers/${id}`);
    }
    getTeamsMembers = () => {
        return _.filter(this.autoCompleteData, { type: AutoCompleteType.TeamsMembers })
    }
    getNewMembers = () => {
        return _.filter(this.autoCompleteData, { type: AutoCompleteType.NewMembers })
    }
    getUsergroupsMembers = () => {
        return _.filter(this.autoCompleteData, { type: AutoCompleteType.UsergroupsMembers })
    }
    getTeamsAndUsergroupsMembers = (filter?) => {
        let result = _.sortBy(_.filter(this.autoCompleteData, function (o) { return o.type === AutoCompleteType.UsergroupsMembers || o.type === AutoCompleteType.TeamsMembers }), 'value')
        if (!filter || !filter.length) {
            return result
        } else {
            return _.filter(
                result,
                (o) => {
                    return _.includes(_.toLower(o.value), _.toLower(filter));
                }
            );
        }

    }
    getTeamsAndUsergroupsMembersAndUsergroupNames = () => {
        return _.sortBy(_.filter(this.autoCompleteData, function (o) { return o.type === AutoCompleteType.UsergroupsMembers || o.type === AutoCompleteType.TeamsMembers || o.type === AutoCompleteType.UsergroupNames }), 'value')
    }

    getUsergroupNames = () => {
        return _.filter(this.autoCompleteData, { type: AutoCompleteType.UsergroupNames })
    }





    listTeamsMembers = () => {
        this.autoCompleteData = _.filter(this.autoCompleteData, { type: !AutoCompleteType.TeamsMembers })
        this.getTeams().subscribe((res) => {
            res.forEach((el, index) => {
                const key = _.get(el, '_key', null)
                this.getTeam(key).subscribe((res2) => {
                    const emails = _.get(res2, 'user_emails', [])
                    const ids = _.get(res2, 'team.user_ids', [])
                    ids.forEach((el2, index2) => {
                        // let elIndex = _.findIndex(this.autoCompleteData, { id: el, type: AutoCompleteType.UsergroupsMembers })
                        let elIndex = _.findIndex(this.autoCompleteData, { id: el2 })
                        if (elIndex > -1) {
                            this.autoCompleteData[elIndex].type = AutoCompleteType.TeamsMembers
                        } else {
                            this.autoCompleteData.push({ id: el2, value: _.nth(emails, index2), team: _.get(res, 'name', null), type: AutoCompleteType.TeamsMembers })
                        }
                    })
                })
            })
        }, (error) => {
            if (error.status === 422) {
                alert('Error during the listing of the teams members.')
            } else {
                alert(error.error.message);
            }
        })
    }
    listUsergroups(limit?: number, sortBy?: string) {
        this.autoCompleteData = _.filter(this.autoCompleteData, { type: !(AutoCompleteType.UsergroupNames && AutoCompleteType.UsergroupsMembers) })
        this.startListingUsergroups(limit ? limit : null, sortBy ? sortBy : null).subscribe(
            (res) => {
                if (!res || res.data === []) {
                    return;
                }
                for (let usergroup of res.data) {
                    if (usergroup.id && usergroup.name) {
                        this.autoCompleteData.push({ id: usergroup.id, value: usergroup.name, type: AutoCompleteType.UsergroupNames })

                    }
                    this.retrieveUserGroup(usergroup.id).subscribe((res2) => {
                        const permissions = _.get(res2, 'permissions', [])
                        for (let permission of permissions) {
                            if (_.findIndex(this.autoCompleteData, { id: permission.grantee.id }) === -1) {
                                this.autoCompleteData.push({ id: permission.grantee.id, value: permission.grantee.email, type: AutoCompleteType.UsergroupsMembers })
                            }
                        }
                    }, (error) => {
                        if (error.status === 422) {
                            alert('Error during the listing of the usergroup.')
                        } else {
                            alert(error.message);
                        }
                    })

                }
                if (res['has_more']) {
                    this.listNextUsergroups(res.links.next);
                }
            },
            (error) => {
                if (error.status === 422) {
                    alert('Error during the listing of the usergroups.')
                } else {
                    alert(error.error.message);
                }
            }

        );
    }
    startListingUsergroups(limit?: number, sortBy?: string): Observable<any> {
        let queryParameters = new HttpParams({});
        if (sortBy !== undefined && sortBy !== null) {
            queryParameters = queryParameters.set('sortBy', sortBy as any);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', limit as any);
        }
        queryParameters = queryParameters.set(
            'filters',
            `ownedByMe=true,sharedWithMe=true`
        );
        return this.http.get<any>(
            `${this.basePath}/mas/fs/usergroups`, {
            params: queryParameters
        }
        );
    }
    listNextUsergroups(url) {
        this.listFromUrl(url).subscribe(
            (res) => {
                if (!res || res.data === []) {
                    return;
                }
                for (let usergroup of res.data) {
                    if (usergroup.id && usergroup.name) {
                        this.autoCompleteData.push({ id: usergroup.id, value: usergroup.name, type: AutoCompleteType.UsergroupNames })
                    }
                    this.retrieveUserGroup(usergroup.id).subscribe((res2) => {
                        const permissions = _.get(res2, 'permissions', [])
                        for (let permission of permissions) {
                            if (_.findIndex(this.autoCompleteData, { value: permission.grantee.email }) === -1) {
                                this.autoCompleteData.push({ id: permission.grantee.id, value: permission.grantee.email, type: AutoCompleteType.UsergroupsMembers })
                            }
                        }
                    }, (error) => {
                        if (error.status === 422) {
                            alert('Error during the listing of the usergroup.')
                        } else {
                            alert(error.message);
                        }
                    })

                }

                if (res['has_more']) {
                    this.listNextUsergroups(res.links.next);
                }
            },
            (error) => {
                if (error.status === 422) {
                    alert('Error during the listing of usergroups.')
                } else {
                    alert(error.error.message);
                }
            }
        );
    }
    retrieveUserGroup(usergroupId: string): Observable<any> {
        return this.http.get<any>(
            `${this.basePath}/mas/fs/usergroups/${usergroupId}`, {}
        );

    }
    createUserGroup(name?, description?): Observable<any> {
        const body = {
            "description": description ? description : 'test',
            "metadata": {},
            "name": name ? name : 'test'
        }
        return this.http.post<any>(
            `${this.basePath}/mas/fs/usergroups`, body, {}
        );
    }
    deleteUserGroup(usergroupId: string): Observable<any> {
        return this.http.delete<any>(
            `${this.basePath}/mas/fs/usergroups/${usergroupId}`, {}
        );
    }
    addUserToUserGroup(usergroupId, email: string, role: RoleType): Observable<any> {
        const body = {
            "role": role,
            "email": email
        }

        return this.http.post<any>(
            `${this.basePath}/mas/fs/usergroups/${usergroupId}/users`, body, {}
        );
    }
    removeUserFromUserGroup(usergroupId: string, userId: string): Observable<any> {
        return this.http.delete<any>(
            `${this.basePath}/mas/fs/usergroups/${usergroupId}/users/${userId}`, {}
        );
    }
    updateUserPermissionInUsergroup(usergroupId: string, userId: string, role: RoleType): Observable<any> {
        const body = {
            'role': role
        }
        return this.http.patch<any>(
            `${this.basePath}/mas/fs/usergroups/${usergroupId}/users/${userId}`, body, {}
        );
    }

    postPermissionOfItem(itemType: ItemType, userType: UserType, permissionType: PermissionType, itemId: string, userId: string): Observable<any> {
        const body =
        {
            "date_of_expiration": 0,
            "grantee_id": userId,
            "grantee_kind": userType,
            "role": permissionType
        }
        return this.http.post<any>(
            `${this.basePath}/mas/fs/${itemType}/${itemId}/permissions`, body, {}
        );
    }
    removePermissionFromProject(permId: string, projectId): Observable<any> {
        return this.http.delete<any>(
            `${this.basePath}/mas/fs/projects/${projectId}/permissions/${permId}`, {}
        );
    }
    removePermissionFromFile(permId: string, fileId): Observable<any> {
        return this.http.delete<any>(
            `${this.basePath}/mas/fs/files/${fileId}/permissions/${permId}`, {}
        );
    }
    removePermissionFromFileGroup(permId: string, fileId): Observable<any> {
        return this.http.delete<any>(
            `${this.basePath}/mas/fs/filegroups/${fileId}/permissions/${permId}`, {}
        );
    }
    resetNonSentInvitations() {
        this.usersSelected = []
    }

    share(itemType, itemId, userType, permissionType, granteeId, nameOrEmail, last?: boolean) {

        let usertype = userType === UserType.User ? 'user' : 'user group'
        let itemtype = itemType === ItemType.File ? 'File' : (itemType === ItemType.Project ? 'Project' : 'Filegroup    ')


        this.postPermissionOfItem(itemType, userType, permissionType, itemId, granteeId).subscribe(() => {
            this.l.toast(itemtype + '<b> successfully </b> shared with ' + usertype + ' <b>' + nameOrEmail + '</b>.', null, 8000, 'Profile')
            if (last) {
                this.usersSelected = []
                if (itemType === ItemType.Project) { this.projectManagerService.getInfos() }
                if (itemType === ItemType.FileGroup) { this.groupsManagerService.getInfos(itemId) }
                if (itemType === ItemType.File) {
                    if (this.fileManager.viewTab === TabsName.MyFiles) {

                        this.myFilesManager.getInfos();

                    } else if (this.fileManager.viewTab === TabsName.SharedFiles) {
                        this.SharedFilesManager.getInfos();
                    }
                }
            }
        }, (error) => {
            if (error.status === 422) {
                this.l.toast('Impossible to share ' + itemType + ' with the ' + usertype + ' <b>' + nameOrEmail + '</b>.', null, 8000, 'Profile', 'danger')
            } else {
                this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger')
            }
        })
    }
    shareToUsers(itemType, itemId) {
        for (let i = 0; i < this.usersSelected.length; i++) {
            let el = this.usersSelected[i]
            if (i === this.usersSelected.length - 1) {
                this.share(itemType, itemId, el.userType, el.role, el.id, el.email, true)
            } else {
                this.share(itemType, itemId, el.userType, el.role, el.id, el.email)
            }
        }
    }


}