<div class='h-100 w-100 p-4 '>
  <div class="card mx-auto">
    <div class="card-body">
      <form *ngIf='getForm() && getFieldObjectsLength()' class='mx-auto form' [formGroup]="getForm()">
        <ul class='mt-4 inputs-form list-unstyled'>
          <li class='my-2'>
            <app-input-field *ngFor='let fieldObject of getFieldObjects()'  [allowedValueUpdate]='getAllowedValueUpdate(fieldObject)'  [valueUpdate]='fieldObject.retrievedValue' [parentForm]='getForm()'
              [inputJson]='fieldObject'>
            </app-input-field>
          </li>
        </ul>
      </form>
    </div>
    <div class='btn-container'>
      <div class='btn btn-light border' type="button" (click)='previous()'>
        <span class='text-uppercase small'>Cancel</span>
      </div>
      <div *ngIf='getForm()'
        ngbTooltip="{{getFormError()}}" tooltipClass="custom-tooltip" placement="left" (click)='createProject()'
        class="btn btn-dark " type="submit" [class.disabled]="!getFormValidity()">
        <span class='text-uppercase small'>
          next
        </span>
      </div>
    </div>
  </div>
</div>

