import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginHttpService } from '../../login/login-http-service/login-http.service';
import { SavedUser, SavedProject, NotificationsService } from '../../../api/notifications.service';
import * as _ from 'lodash';
import { TimeAgoPipe } from 'src/app/mims-ui-lib/core/pipes/time-ago.pipe';
import { ProjectsService } from 'src/app/api/projects.service';

@Pipe({
  name: 'removeAgo'
})
export class removeAgoPipe implements PipeTransform {
  transform(value) {
    if (value) {
      return _.replace(value, 'ago', '');
    }
  }
}

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss'],
  providers: [removeAgoPipe]
})
export class UserNotificationComponent implements OnInit {
  @Input() notification: {
    body: string,
    _body: any
    date_of_creation: number,
    date_of_reading: number,
    dismissed: boolean,
    id: string,
    links: any,
    nanoseconds: boolean,
    read: boolean,
    resource_type: string,
    title: string,
    type: string
  }
  TimeAgoPipe = TimeAgoPipe
  removeAgoPipe = removeAgoPipe
  user: SavedUser = ({ userId: undefined, userName: new BehaviorSubject<string>(undefined) })
  project: SavedProject = ({ projectId: undefined, projectName: new BehaviorSubject<string>(undefined) })

  constructor(private notificationService: NotificationsService, private loginHttp: LoginHttpService, private projectsService: ProjectsService) {
  }
  ngOnInit(): void {
    if (!this.notification || !this.notificationService.usersRelatedToNotifications || !this.notificationService.projectsRelatedToNotifications) {
      return
    }
    this.getUserFromNotification()
    this.getProjectFromNotification()

  }

  getUserFromNotification = (): void => {
    const userId: string = this.notificationService.getUserId(this.notification)
    const userAlreadyFetched: SavedUser = _.find(this.notificationService.usersRelatedToNotifications, { 'userId': userId })

    if (!userAlreadyFetched) {
      this.user.userId = userId
      this.notificationService.usersRelatedToNotifications.push(this.user)

      this.fetchNewUser(userId).then((res: string) => {
        this.user.userName.next(res)
      })
    } else {
      this.user = userAlreadyFetched
    }
  }

  getProjectFromNotification = (): void => {
    const projectId: string = this.notificationService.getProjectId(this.notification)
    const projectAlreadyFetched: SavedProject = _.find(this.notificationService.projectsRelatedToNotifications, { 'projectId': projectId })

    if (!projectAlreadyFetched) {
      this.project.projectId = projectId
      this.notificationService.projectsRelatedToNotifications.push(this.project)

      this.fetchNewProject(projectId).then((res: string) => {
        this.project.projectName.next(res)
      })
    } else {
      this.project = projectAlreadyFetched
    }
  }

  markAsRead = (): void => {
    _.set(this.notification, 'read', true)
    this.updateUserNotifications()
    this.notificationService.markAsRead(this.notification.id).subscribe()
  }
  updateUserNotifications = (): void => {
    this.notificationService.userNotifications.next(_.clone(this.notificationService.userNotifications.value))
  }

  getDateOfCreation = (): number => {
    const date = _.get(this, 'notification.date_of_creation', 0)
    const nano = _.get(this, 'notification.nanoseconds')
    if (!nano) {
      return Math.floor(date* 1000 || 0)
    } else if (nano) {
      return Math.floor(date/ 1000000 || 0)
    } 
  }

  fetchNewUser = (userId) =>
    new Promise((resolve, reject) => {
      this.loginHttp.getUser(userId).subscribe((res) => {
        resolve(_.get(res, 'name',''))
      }, () => {
        reject()
      })
    })

  fetchNewProject = (projectId) =>
    new Promise((resolve, reject) => {
      this.projectsService.projectGet(projectId).subscribe((res) => {
        resolve(_.get(res, 'title',''))
      }, () => {
        reject()
      })
    })

  getFullName = (): string => {
    return _.get(this, 'user.userName.value', '')
  }
  getProjectName = (): string => {
    return _.get(this, 'project.projectName.value', '')
  }

  isRead = (): boolean => {
    return _.get(this, 'notification.read',false)
  }

  getTitle = (): string => {
    return _.get(this, 'notification.title','')
  }
}
