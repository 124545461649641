import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FileManager } from 'src/app/api/file-manager.service';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'group',
  styleUrls: ['../../../shared/layout/managers.scss'],
  templateUrl: './group.component.html',
})
export class GroupComponent implements OnInit, OnDestroy {
  @Input() group: any;
  @Input() index: any;
  iconText
  refreshSubscription;
  public groupContent = new BehaviorSubject<any>(null);
  public iconClass: string[] = ['far fa-circle'];
  iterator;
  hiddenContent = []
  hasMore = new BehaviorSubject<Boolean>(false);
  constructor(public layout: LayoutOrganizationService, public groupsManagerService: GroupsManagerService, public fileManager: FileManager) { }
  ngOnInit() {
    this.refreshSubscription = this.fileManager.eventRefreshGroupId.subscribe((res) => {
      if (res !== this.group.id) {
        return
      }
      this.listContent(true);
    });
  }

  listContent = (bool?) => {
    if (bool) {
      this.iterator = this.groupsManagerService.getGroup(this.group, 10)
    }
    // else {
    //   this.listMoreTimesCount++
    // }
    this.iterator.next().then(obj => {
      if (bool) {
        this.groupContent.next(null)
        this.hasMore.next(null);
      }
      if (!obj.value)
        return
      this.groupContent.next(_.get(obj.value, 'data'))
      this.hasMore.next(_.get(obj.value, 'has_more'));
    })
  }
  getGroupContent = () => {
    return _.get(this, 'groupContent.value', [])
  }
  getGroupContentLength = () => {
    return _.get(this, 'groupContent.value.length', 0)
  }


  contentVisibilitySwitcher() {
    if (this.groupsManagerService.openedGroupId === this.group.id) {
      this.groupsManagerService.openedGroupId = null;
    } else {
      this.groupsManagerService.openedGroupId = this.group.id;
      if (_.isEmpty(this.getGroupContent())) {
        this.fileManager.eventRefreshGroupId.next(this.group.id)

      }
    }
  }
  handleRemove = (group, groupItem) => {
    this.groupsManagerService.handleRemoveFromGroup(group.id, groupItem.id, group.name, groupItem.title).then((res)=>{
      if (res){
        this.hiddenContent.push(groupItem.id)
      }
    });
  }
  isAlreadyDeleted= (id) => {
    return _.includes(this.hiddenContent, id)
  }
  setIconText(text) {
    this.iconText = text;
  }
  unSetIconText() {
    this.iconText = '';
  }

  trackFn(index, item) {
    return _.get(item, 'id') + ',' + _.get(item, 'title')
  }
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe()
  }
}
