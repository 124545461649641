import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SortType, SortDirection, FileManager } from './file-manager.service';
import { PagesPublicName } from '../shared/helpers/pagesPublicName';
import { TabsPublicName } from '../shared/helpers/tabsPublicName';
import { availableFeatures } from 'src/environments/environment';
import { LocalService } from './local-storage.service';
import { Features } from 'src/environments/features';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DeletedFilesManagerService {
    path = new BehaviorSubject<any>([])
    pathIsReady = false;
    count;
    displayedFiles = new BehaviorSubject<any>([]);

    bulkSelection = [];
    displayedFilesTemp;
    finishedListing;
    page = 1;
    pageSize = 10;
    filtersVisible = false;
    limitParam = 10;
    constructor(private localService: LocalService, private fileManager: FileManager
    ) {
    }
    resetFolder= () => {
        return this.fileManager.resetFolder(null, this)
    }
    getBasePath = () => {
        return _.includes(availableFeatures, Features.MultiCountry) ? [{ name: PagesPublicName.FileManager, id: null, page: undefined }, { name: TabsPublicName.DeletedFiles, id: null, page: undefined }, { name: 'Storage location - ' + this.localService.getFromLocalStorage('country', 'name'), id: 'list', page: undefined }]
        : [{ name: PagesPublicName.FileManager, id: null, page: undefined }, { name: TabsPublicName.DeletedFiles, id: 'list', page: undefined }]
    }
    listContent = () => {
        return this.fileManager.listContent(this, 'DeletedFilesManagerService')
    }
    getDisplayedFilesLength = () => {
        return this.fileManager.getDisplayedFilesLength(this)
    }
    getSortByQuery = () => {
        return this.fileManager.getSortByQuery(this)
    }
    getDisplayedFiles = () => {
        return this.fileManager.getDisplayedFiles(this)
    }
    setSortFilter = (value: SortType) => {
        return this.fileManager.setSortFilter(value, 'DeletedFilesManagerService')
    }
    setSortDirection = (value: SortDirection) => {
        return this.fileManager.setSortDirection(value, 'DeletedFilesManagerService')
    }
    getSortFilter = () => {
        return this.fileManager.getSortFilter('DeletedFilesManagerService')
    }
    getSortDirection = () => {
        return this.fileManager.getSortDirection('DeletedFilesManagerService')
    }
    getBulkSelectionLength = () => {
        return this.fileManager.getBulkSelectionLength(this)
    }
    toggleSortDirection = () => {
        return this.fileManager.toggleSortDirection(this)
    }
    resetBulkSelection = () => {
        return this.fileManager.resetBulkSelection(this)
    }
    selectAllDisplayedFiles = () => {
        return this.fileManager.selectAllDisplayedFiles(this)
    }
    toggleFilter = () => {
        return this.fileManager.toggleFilter(this)
    }
    addToBulkSelection = (id) => {
        return this.fileManager.addToBulkSelection(id, this);
    }
    removeFromBulkSelection = (id) => {
        return this.fileManager.removeFromBulkSelection(id, this);
    }
    isBulkSelected = (id?) => {
        return this.fileManager.isBulkSelected(this, id ? id : undefined)
    }
    getBulkSize = () => {
        return this.fileManager.getBulkSize(this);
    }
    _handlePermanentelyDelete = () => {
        return this.fileManager._handlePermanentelyDelete(this);
    }
    handlePermanentelyDelete = () => {
        return this.fileManager.handlePermanentelyDelete(this);
    }
    handleRestore = () => {
        return this.fileManager.handleRestore(this);
    }
    handleRestoreFile = (id) => {
        return this.fileManager.handleRestoreFile(id, this);
    }
    handlePermanentelyDeleteFile = (id) => {
        return this.fileManager.handlePermanentelyDeleteFile(id, this);
    }

}