
export enum TabsName {
  SharedProjects = 'shared-projects',
  MyProjects = 'my-projects',
  MyFiles = 'my-files',
  SharedFiles = 'shared-files',
  DeletedFiles = 'deleted-files',
  DeletedProjects = 'deleted-projects',
  MyNotifications = 'my-notifications',
  DeletedNotifications = 'deleted-notifications'

}