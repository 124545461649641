<div [class.hidden]="!show">
  <div [id]="idName" [class.justify-content-between]="layout.mobile" [class.d-flex]="layout.mobile"
    [class.flex-column]="layout.mobile" class="rounded border custom-modal">
    <div class="data-container">
      <div class="btn btn-link close" aria-label="Close" (click)="layout.close(idName); executeCancelCallbacks()">
        <i aria-hidden="true" class="far fa-times"></i>
      </div>
      <div>
        <h3 class="title mx-2 my-2 text-muted">{{ modalTitle }}</h3>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
    <div  *ngIf='buttons' class="btn-container">
      <div class="btn  text-uppercase border btn-light"
        (click)="layout.close(idName); executeCancelCallbacks()">
        <small>
          Cancel
        </small>
      </div>

      <div class="btn btn-dark text-uppercase" ngbTooltip="{{getFormError()}}"
        tooltipClass="custom-tooltip" placement="top"
        [class.disabled]="!getValidator()" (click)="getValidator() ? handleValidation() : ''">
        <small>{{ confirmTitle }} </small>
      </div>
    </div>
  </div>


</div>
