import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';

@Component({
  selector: 'app-folder-creation-form',
  templateUrl: './folder-creation-form.component.html',
  styleUrls: ['../../../shared/styles/forms.component.scss'],
})
export class FolderCreationFormComponent implements OnInit {
  constructor(public myFilesManager: MyFilesManagerService) { }

  ngOnInit() {
    this.myFilesManager.folderCreationForm = new FormGroup({
      folderInput: new FormControl(null, [Validators.required]),
    });
  }
  get folderInput() {
    return this.myFilesManager.folderCreationForm.get('folderInput');
  }
}
