<div class='px-5 mx-auto'>
  <div class="card mx-auto w-100 mx-4 mb-5">
    <div class="small card-header pb-4 ">
      <div class='h-100 w-100 row mx-0'>

        <div class='col'>
          <div *ngIf='getWorkflowDataTypes().length'>
            <div class='text-uppercase text-muted'>Data types accepted by the solution :</div>
            <ul class='list-unstyled font-weight-bold'>
              <li *ngFor='let el of getWorkflowDataTypes()'>{{el}}</li>

            </ul>
          </div>
        </div>
        <div *ngIf='hasAtLeastOneType()' class='col'>
          <div>
            <div class='text-uppercase text-muted'>Data types present in filegroup :</div>
            <ul class='list-unstyled font-weight-bold'>
              <li *ngFor="let el of filegroupTypes"><span *ngIf='el.count && el.count.value'>{{el.description}}</span>
              </li>

            </ul>
          </div>
        </div>
        <div class='col'>
          <div hidden class=' mb-2'>
            <div class='text-uppercase text-muted'>release date :</div>
            <span class='font-weight-bold'>?</span>
          </div>
          <div *ngIf='getSubjects()'>
            <div class='text-uppercase text-muted'>total number of subjects :</div>
            <span class='font-weight-bold'>{{getSubjects()}}</span>
          </div>

        </div>
        <div class='col-3'>
          <div hidden class=' mb-4'>
            <span class='text-uppercase text-muted'>usage :</span>
            <span class='font-weight-bold'>?</span>
          </div>
          <div *ngIf='canSelect' (click)="next()" class="btn btn-dark" [class.btn-primary]='selected' type="button">
            <span class='text-uppercase small'>{{btnTitle}}
            </span>
          </div>
        </div>
      </div>


    </div>
    <div class="card-body">
      <h1 class="font-weight-bold text-underlined mb-4">{{getWorkflowName()}}
      </h1>
      <p class="card-text my-3 ">{{getWorkflowDescription()}}</p>


      <div *ngIf='file.value && expended' class='mt-4'>
        <markdown class='w-100' [data]='file.value'></markdown>
      </div>
      <div class='cursor-pointer text-center '>
        <span role='button' (click)='switchExpension()'
          class='text-uppercase btn-link  mx-2 font-weight-bold small text-primary'>
          {{expended? 'Read less' : 'Read more'}}
          <i aria-hidden='true' class="fas" [class.fa-chevron-down]='!expended' [class.fa-chevron-up]='expended'></i>
        </span>
      </div>

    </div>

  </div>
</div>
