import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {
  template,
  cyan,
  red,
  yellow,
  lime,
  constructLayoutUsingTemplate,
  addCustomColorscale,
} from '../../plotly-templating';
import * as _ from 'lodash';
import { VizSubType } from 'src/app/shared/helpers/vizSubType';

declare let Plotly: any;

@Component({
  selector: 'app-viz-plotly-thumbnail',
  templateUrl: './viz-plotly-thumbnail.component.html',
  styleUrls: ['./viz-plotly-thumbnail.component.scss', '../viz-style.scss'],
})
export class VizPlotlyThumbnailComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() size;
  @ViewChild('thumbnail') thumbnail: ElementRef;
  @Input() title;
  constructor() {}

  ngOnInit(): void {
    window.addEventListener(
      'load',
      () => {
        let layout = _.cloneDeep(this.layout);
        layout.showlegend = false;
        const layoutUsingTemplate = constructLayoutUsingTemplate(
          layout,
          template(true, this.data[0].type)
        );
        this.data = addCustomColorscale(this.data, cyan);

        Plotly.toImage({ data: this.data, layout: layoutUsingTemplate }).then(
          (src) => {
            return new Promise((resolve, reject) => {
              this.thumbnail.nativeElement.onload = resolve;
              this.thumbnail.nativeElement.onerror = reject;
              this.thumbnail.nativeElement.style = `background-image: url(${src})`;
            });
          }
        );
      },
      false
    );
  }
}
