import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LayoutOrganizationService } from './layout-organization.service';
import { ProjectsService } from './projects.service';
import { Router } from '@angular/router';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Observable } from 'rxjs';
import { FileSystemState } from '../pages/login/login-http-service/login-http.service';
import { LocalService } from './local-storage.service';

@Injectable()
export class ProjectManagerService {
  itemsWithInfoDisplayed = []
  bulkSelection = []
  viewTab = TabsName.MyProjects;
  displayedProjects = [];
  constructor(
    private projectsService: ProjectsService,
    private layout: LayoutOrganizationService,
    private router: Router,
    private localService: LocalService
  ) { }
  rename = (itemId, value, field, country?) => {
    if (country) {
      this.setProjectStorageCountry(country)
    }
    return new Observable((observer) => {
      this.projectsService.projetPatch(itemId, value, field).subscribe((res) => {
        this.refreshProjects().subscribe()
        observer.next(res)
      }, (error) => {
        observer.next(error)
      })

    })
  }
  setBulkToOneFileOnly(element) {
    this.resetBulkSelection()
    const id = _.get(element, 'id')
    const country = _.get(element, 'country', null)
    const title = _.get(element, 'title', '')
    this.addToBulkSelection(id, country, title);
  }


  getDisplayedProjects() {
    return _.get(this, 'displayedProjects', [])
  }
  getViewTab() {
    return _.get(this, 'viewTab', TabsName.MyProjects)
  }

  handleDelete = (id, title) => {
    let text = '<b>' + title + '</b> will be deleted. Are you sure you want to do this ?'
    let self = this
    this.layout.customConfirm(text, function () {
      self._handleDelete(id, title)
    })
  }

  _handleDelete(id, title) {
    this.projectsService.projectRemove(id).subscribe(
      () => {
        this.layout.toast("Project <b>" + title + "</b> successfully deleted.", null, 5000, '', 'success')
        setTimeout(() => {
          this.refreshProjects().subscribe();
          this.layout.close();
        }, 100
        )
      },
      () => {
        this.layout.toast("<b>Error</b> during the deletion of the project <b> " + title + "</b>", null, 5000, '', 'danger')

      }
    );
  } I

  isBulkSelected = (id) => {
    let selection = _.find(this.bulkSelection, function (o) { return o.id === id });
    if (selection) {
      return true
    } else {
      return false
    }
  };

  addToBulkSelection = (id, country, title) => {
    this.layout.close();
    this.bulkSelection.push({ id, country, title })
  };

  removeFromBulkSelection = (id) => {
    this.layout.close();
    _.remove(this.bulkSelection, function (el) {
      return el.id === id;
    });
  };
  setProjectStorageCountry(country) {
    _.set(this.projectsService, 'projectStorageCountry', country)
  }
  handleRestore = () => {
    this.bulkSelection.forEach((element) => {
      this.handleRestoreProject(element);
    });
  }
  handleRestoreProject = (element) => {
    this.setProjectStorageCountry(element.country)
    this.projectsService.restore(element.id).subscribe(
      () => {
        this.layout.toast("Project <b>" + element.title + "</b> has been successfully restored", null, 5000, '', 'success')
      },
      () => {
        this.layout.toast("<b>Error</b> during the restoration of the project <b> " + element.title + "</b>", null, 5000, '', 'danger')

      }, () => {
        if (element.id === _.get(_.last(this.bulkSelection), 'id')) {
          setTimeout(() => {
            this.resetBulkSelection();
            this.refreshProjects().subscribe();
          }, 100
          )

        }
      }
    );
  }
  _handlePermanentelyDelete = () => {
    this.bulkSelection.forEach((element) => {
      this.handlePermanentelyDeleteProject(element);
    });

  }
  handlePermanentelyDelete = () => {
    let isPlural = this.getBulkSelectionLength() > 1
    let subject = isPlural ? ('these ' +this.getBulkSelectionLength()+ ' projects ?') : 'this project ? ';
    let text = 'Are you sure you want to permanentely delete ' + subject
    let self = this
    this.layout.customConfirm(text, function () {
      self._handlePermanentelyDelete()
    })
  }

  handlePermanentelyDeleteProject = (element) => {
    this.setProjectStorageCountry(element.country)
    this.projectsService.permanentelyDelete(element.id).subscribe(
      () => {
        this.layout.toast("Project <b>" + element.title + "</b> has been successfully permanently deleted", null, 5000, '', 'success')
      },
      () => {
        this.layout.toast("<b>Error</b> during the permanent deletion of the project <b> " + element.title + "</b>", null, 5000, '', 'danger')
      },
      () => {
        if (element.id === _.get(_.last(this.bulkSelection), 'id')) {
          setTimeout(() => {
            this.resetBulkSelection();
            this.refreshProjects().subscribe();
          }, 100
          )
        }
      }
    );
  }
  resetBulkSelection() {
    this.layout.close();
    this.bulkSelection = [];
  }
  getBulkSelectionLength() {
    return _.get(this, "bulkSelection.length", 0);
  }

  goTo(id, country) {
    this.setProjectStorageCountry(country)
    this.router.navigate(['/' + PagesName.ProjectManager, this.viewTab, id, country, PagesName.ProjectOverview]);
  }

  extractProjectsFromDifferentStorageLocation(response, countryArray) {
    let displayedProjects = []
    countryArray.forEach(country => {
      let data = _.get(_.get(response, country.value), 'data')
      data.forEach(project => {
        _.set(project, 'country', country.value)
      });
      displayedProjects.push(...data)
    });
    displayedProjects = _.sortBy(displayedProjects, 'title')
    return displayedProjects
  }
  refreshProjects = (viewTab?) => {
    return new Observable((observer) => {
      this.setProjectStorageCountry(null)
      let countryArray = this.localService.getFromLocalStorage('user', 'file_systems')
      countryArray = _.filter(countryArray, (o) => { return o.accessState !== FileSystemState.Disabled })
      const tab = viewTab ? viewTab : this.viewTab
      if (tab === TabsName.MyProjects) {

        this.projectsService.projectList('date_of_creation', 'ownedByMe=true', 100)
          .subscribe((res) => {
            this.displayedProjects = this.extractProjectsFromDifferentStorageLocation(res, countryArray)
            observer.next(this.displayedProjects)
          });
      } else if (tab === TabsName.SharedProjects) {
        this.projectsService
          .projectList('date_of_creation', 'sharedWithMe=true,sharedWithMyUserGroups=true', 100)
          .subscribe(res => {
            this.displayedProjects = this.extractProjectsFromDifferentStorageLocation(res, countryArray)
            observer.next(this.displayedProjects)
          });
      } else if (tab === TabsName.DeletedProjects) {
        this.projectsService
          .projectList('date_of_trashing', 'trashed=true', 100)
          .subscribe(res => {
            this.displayedProjects = this.extractProjectsFromDifferentStorageLocation(res, countryArray)
            observer.next(this.displayedProjects)
          });
      }
    })
  }
  openShare() {
    this.layout.close();
    this.layout.open('modalProjectsShare');
  }
  getInfos = () => {
    this.itemsWithInfoDisplayed = [];
    this.projectsService.projectGet(_.get(this, 'bulkSelection[0].id')).subscribe(
      res => {
        this.itemsWithInfoDisplayed.push(res);

        return res;
      },
      error => {
        alert(error.error.message);
        return {};
      }
    );
  }
  openInfo() {
    this.layout.close();
    this.layout.open('modalProjectsInfos');
  }
}

