import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss', '../../../shared/layout/managers.scss']
})
export class GroupsComponent implements OnInit {
  hasGroups = new BehaviorSubject<Boolean>(false)
  @Input() sharedWithMe? = false
  @Input() marginBottom? = false

  constructor(public groupsManagerService: GroupsManagerService, public layout: LayoutOrganizationService) { }
  ngOnInit(){
    let groups= this.getGroups() || []
    if(groups.length){
      this.hasGroups.next(true)
    }
  }
  trackByFn(index, item) {
    let metadata = !_.isEmpty(item.metadata) ? Object.keys(item.metadata).length : null
    return item.id + ',' + metadata + ',' + item.status
  }
  impossibleDropErrorMessage(event) {
    event.preventDefault()
    this.layout.toast("This file group is in <b>read-only </b> state. <b>Impossible to modify</b> its content", null, 5000, '', 'danger')
  }
  getGroups = () => {
    if (this.sharedWithMe){
    return this.groupsManagerService.getGroups('sharedWithMe=true,sharedWithMyUserGroups=true')
    }
    return this.groupsManagerService.getGroups('ownedByMe=true')
  }
}
