<ng-template [ngIf]="isFolder(item) && !isCompression(item)">
  <div draggable="{{selected}}" (dragover)="manager.allowDrop($event)" (drop)="manager.getDragEndTargetId(getId(item))"
    (dragend)="manager.handleMoveBulkSelection()" (dragstart)="onDragStart($event)">
    <folder [selected]="selected" [item]="item"></folder>
  </div>
</ng-template>
<ng-template [ngIf]="isTempFile(item)">
  <tempFile [item]="item"></tempFile>
</ng-template>
<ng-template [ngIf]="!isTempFile(item)">
  <ng-template [ngIf]="!isFolder(item)">
    <div draggable="{{selected}}" (dragend)="manager.handleMoveBulkSelection()" (dragstart)="onDragStart($event)">
      <file [selected]="selected" [item]="item"> </file>
    </div>
  </ng-template>
</ng-template>