import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { InputJson, IsRequired } from 'src/app/shared/helpers/formattedInput';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { ProjectsService, WorkflowInput, getVisible, isBool, isArray, isMultiple } from 'src/app/api/projects.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';

@Component({
  selector: 'app-project-configuration',
  templateUrl: './project-configuration.component.html',
  styleUrls: ['../project-creation.component.scss','./project-configuration.component.scss']
})
export class ProjectConfigurationComponent implements OnInit {

  @Input() projectId?;
  @Input() workflowId?;
  workflow;
  tagName = 'is_config'
  submitted = false;
  inputsInProject = new BehaviorSubject<any>([]);
  inputsInWorkflow = new BehaviorSubject<any>(null);
  form: FormGroup
  fieldObjects = new BehaviorSubject<any>([]);
  PagesPublicName = PagesPublicName
  workflowInputsSubscription: Subscription;
  projectInputsSubscription: Subscription;
  diseaseMetadata
  dataTypeMetadata
  filegroupId

  constructor(public projectsService: ProjectsService,
    private router: Router,
    private route: ActivatedRoute,
    private layout: LayoutOrganizationService) {
    if (!this.projectId)
      _.set(this, 'projectId', this.route.snapshot.paramMap.get('projectId'));
    if (!this.workflowId)
      _.set(this, 'workflowId', this.route.snapshot.paramMap.get('workflowId'));
    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'))
    }
    this.route.queryParams.subscribe((params) => {
      if (params['fromDebugger'] === 'false' || !_.get(params, 'fromDebugger')) {
        projectsService.setFromDebugger(false)
      } else if (params['fromDebugger'] === 'true') {
        projectsService.setFromDebugger(true)
      }
      _.set(this, 'diseaseMetadata', params['diseaseMetadata']);
      _.set(this, 'dataTypeMetadata', params['dataTypeMetadata']);
      _.set(this, 'filegroupId', params['filegroupId']);

    })
  }

  ngOnInit(): void {
    this.getWorkflowInputs()

    this.workflowInputsSubscription = this.inputsInWorkflow.subscribe((res) => {
      let fieldObjects = []
      this.getProjectInputs(this.tagName)

      if (_.isEmpty(res)) {
        this.fieldObjects.next(fieldObjects)
        return
      }
      this.form = this.projectsService.createEmptyForm(res);

      for (const [key, value] of Object.entries(res)) {
        let w = value as WorkflowInput
        let input: InputJson = {
        formControlName: key,
         originalJson: w,
         type: _.get(w, 'type'),
         description: _.get(w, 'description'),
         is_array: _.get(w, 'tags.is_array'),
         multiple_selection: _.get(w, 'tags.multiple_selection'),
         allowed_values: _.words(_.get(w, 'tags.allowed_values', ''), /[^,]+/g),
         allowed_values_descriptions: _.words(_.get(w, 'tags.allowed_values_descriptions', ''), /[^,]+/g),
         fitToContent: true,
         iconClassName: 'fa fa-info-circle',
         validations: _.get(w,'optional') ? [] : [IsRequired],
         retrievedValue: new BehaviorSubject<any>(undefined)
        }
        fieldObjects.unshift(input)
      }
      this.fieldObjects.next(fieldObjects)

    })
    this.projectInputsSubscription = this.inputsInProject.subscribe((res) => {
      if (_.isEmpty(res)) {
        return
      }
      this.projectsService.setRetrievedValue(res,this.getFieldObjects())
    })
  }

  filterWorkflowInputs(inputs) {

    let newInputs = _.omitBy(inputs, (value, key) => (_.includes(key, 'data_type') ||  _.includes(key, 'dataType')));
    newInputs = _.omitBy(newInputs, (value, key) => (_.includes(key, 'disease')));
    newInputs = _.pickBy(newInputs, getVisible);
    return newInputs
  }

  getProjectInputs(tagName) {
    this.projectsService.projectGet(this.projectId).subscribe((res) => {
      if (!this.projectsService.getProjectName()) {
        this.projectsService.setProjectName(res.title)
      }
      let inputsInProject = _.get(res, 'input_list', [])
      inputsInProject = this.projectsService.filterProjectInputs(inputsInProject, tagName)
      this.inputsInProject.next(inputsInProject)
    }, () => {
      this.layout.toast('Error when getting projet inputs', null, 8000, '', 'warning')
    })
  }

  getWorkflowInputs() {
    this.projectsService.getWorkflow(this.workflowId).subscribe((res) => {
      this.workflow = res
      let inputs = this.filterWorkflowInputs(_.get(res, 'inputs', {}))
      this.inputsInWorkflow.next(inputs)
    }, () => {
      this.inputsInWorkflow.next({})
      this.layout.toast('Error when getting workflow inputs', null, 8000, '', 'warning')
    })
  }

  getFormError() {
    return this.getFormValidity() ? '' : 'Please verify your form.'
  }
  getWorkflowName() {
    return _.get(this, 'workflow.name', null)
  }
  getForm() {
    return _.get(this, 'form', null)
  }
  getFormValidity() {
    return _.get(this, 'form.status', false) === 'VALID'
  }
  getFieldObjects() {
    return _.get(this, 'fieldObjects.value', [])
  }
  getFieldObjectsLength() {
    return _.get(this, 'fieldObjects.value.length', [])
  }
  getInputsInProjects() {
    return _.get(this, 'inputsInProject.value', [])
  }

  next() {
    this.submitted = true
    this.projectsService.updateAllInputs(this.projectId, this.getFieldObjects(), this.getInputsInProjects(), this.getForm(), this.tagName).then(() => {
      this.projectsService
            .triggerWorkflow(this.projectId, this.workflowId)
            .subscribe(() => {
              this.router.navigate([PagesName.ProjectCreation, PagesName.WorkflowInProgress, this.projectId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger() } })
            }, () => {
              this.layout.toast('Error when triggering project execution', null, 8000, null, 'danger')
            });
          this.router.navigate([PagesName.ProjectCreation, PagesName.WorkflowInProgress, this.projectId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger() } })

    }, () => {
      this.layout.toast('Error when updating inputs', null, 8000, null, 'danger')
    })
  }

  previous() {
    this.submitted = true
    this.router.navigate([PagesName.ProjectCreation, PagesName.ChooseDataTypes, this.projectId, this.workflowId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId: this.filegroupId,  diseaseMetadata: this.diseaseMetadata, dataTypeMetadata: this.dataTypeMetadata } })
  }

  ngOnDestroy() {
    this.workflowInputsSubscription.unsubscribe()
    this.projectInputsSubscription.unsubscribe()
  }

}
