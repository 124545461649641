import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ProjectsService } from '../../../../../api/projects.service';
import moment from 'moment';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-project-card',
  styleUrls: ['./project-card.component.scss'],
  templateUrl: './project-card.component.html',
})
export class ProjectCardComponent implements OnInit {
  @Input() project: any;
  public dateOfCreation: any;
  @Output() refreshParent = new EventEmitter<boolean>();
  @LocalStorage() session;
  // @SessionStorage() currentProject;
  public environment = environment;

  constructor(
    private projectsService: ProjectsService
  ) { }

  ngOnInit(): void {
    this.getDateOfCreation();
  }

  getDateOfCreation() {
    this.dateOfCreation = moment(
      this.project.date_of_creation * 1000
    ).fromNow();
    return this.dateOfCreation;
  }

  // goToProject(project) {
  //   this.projectsService.projectGet(project.id).subscribe(res => {
  //     this.currentProject = res;
  //     this.router.navigateByUrl('project/overview');
  //   });
  // }

  deleteProject(project) {
    this.projectsService.projectRemove(project.id).subscribe(() => {
      this.refreshParent.emit(true);
    });
  }
}
