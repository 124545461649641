import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
@Component({
  selector: 'app-group-creation-form',
  templateUrl: './group-creation-form.component.html',
  styleUrls: ['../../../shared/styles/forms.component.scss'],
})
export class GroupCreationFormComponent implements OnInit {
  constructor(public groupsManagerService: GroupsManagerService) { }

  ngOnInit() {
    this.groupsManagerService.groupCreationForm = new FormGroup({
      groupInput: new FormControl(null, [Validators.required]),
    });
  }
  get groupInput() {
    return this.groupsManagerService.groupCreationForm.get('groupInput');
  }
}
