import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mims-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent implements OnInit {
  /** Set visibility of mims-overlay */
  @Input() @HostBinding('class.visible') visible: boolean;

  constructor() {}

  ngOnInit(): void {}
}
