import { NavItem } from './ribbon-nav-item.interface';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mims-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RibbonComponent implements OnInit {
  /** Available navigations */
  @Input() navigations: NavItem[];

  /** Ribbon title */
  @Input() title: string;

  workflowEditorState = '';

  constructor() {}

  ngOnInit(): void {}
  onExport() {}

  onChooseWorkflow() {}

  onCreateWorkflow() {}

  onArrangePositions() {}

  onRevertChange() {}
}
