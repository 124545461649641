<div class="page d-flex flex-column">
  <app-header theme="transparent">
    <app-path
      *ngIf="!projectsService.getProjectName() && !projectsService.getFromDebugger()"
      theme="light"
      [path]="[
        { name: PagesPublicName.ProjectManager, id: null, action: [PagesName.ProjectManager] },
        { name: PagesPublicName.ProjectCreation, id: null },
        { name: 'New Project', id: null },
        { name: _.nth(projectsService.getStepsList(), projectsService.getActiveStep()), id: null }
      ]"
    >
    </app-path>
    <app-path
      *ngIf="projectsService.getProjectName() && !projectsService.getFromDebugger()"
      theme="light"
      [path]="[
        { name: PagesPublicName.ProjectManager, id: null, action: [PagesName.ProjectManager] },
        { name: PagesPublicName.ProjectCreation, id: null },
        { name: 'Project ' + projectsService.getProjectName(), id: null },
        { name: _.nth(projectsService.getStepsList(), projectsService.getActiveStep()), id: null }
      ]"
    >
    </app-path>
    <app-path
      *ngIf="projectsService.getProjectName() && projectsService.getFromDebugger()"
      theme="light"
      [path]="[
        { name: PagesPublicName.WorkflowDebugger, id: null, action: [PagesName.WorkflowDebugger] },
        { name: 'Project Edition', id: null },
        { name: 'Project ' + projectsService.getProjectName(), id: null },
        { name: _.nth(projectsService.getStepsList(), projectsService.getActiveStep()), id: null }
      ]"
    >
    </app-path>
    <app-path
      *ngIf="!projectsService.getProjectName() && projectsService.getFromDebugger()"
      theme="light"
      [path]="[
        { name: PagesPublicName.WorkflowDebugger, id: null, action: [PagesName.WorkflowDebugger] },
        { name: PagesPublicName.ProjectCreation, id: null },
        { name: 'New Project', id: null },
        { name: _.nth(projectsService.getStepsList(), projectsService.getActiveStep()), id: null }
      ]"
    >
    </app-path>
  </app-header>
  <app-content [isHeader]="true">
    <div id="grid">
      <div id="steps">
        <ul class="list-unstyled">
          <li
            class="step mb-5"
            [class.active]="index === projectsService.getActiveStep()"
            *ngFor="let step of projectsService.getStepsList(); let index = index; let last = last"
          >
            <span *ngIf="!last">
              <i class="fa fal fa-check" [class.text-transparent]="!(projectsService.getActiveStep() > index)" aria-hidden="true"></i>
              {{ step }}
            </span>
          </li>
        </ul>
      </div>
      <div id="router" class="border">
        <router-outlet></router-outlet>
      </div>
    </div>
  </app-content>
</div>
