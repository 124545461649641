import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ProjectsService } from 'src/app/api/projects.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';

@Component({
  selector: 'app-preselected-workflow',
  templateUrl: './preselected-workflow.component.html',
  styleUrls: ['./preselected-workflow.component.scss'],
})
export class PreselectedWorkflowComponent implements OnInit {
  projectId;
  workflowId;
  subjects = new BehaviorSubject<Number>(0)
  filegroupId;
  _=_
  submitted = false
  workflowDownloaded = new BehaviorSubject<any>(false);
  dataTypes = {
    transcriptome: { description: 'Transcriptome', count: new BehaviorSubject<Number>(0) },
    genome: { description: 'Genome', count: new BehaviorSubject<Number>(0) },
    epigenome: { description: 'Epigenome', count: new BehaviorSubject<Number>(0) }
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectsService: ProjectsService
  ) {
    _.set(this, 'projectId', this.route.snapshot.paramMap.get('projectId'));
    _.set(this, 'workflowId', this.route.snapshot.paramMap.get('workflowId'))
    
    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'))
    }
    this.route.queryParams.subscribe((params) => {
      if (params['fromDebugger'] === 'false' || !_.get(params, 'fromDebugger')) {
        projectsService.setFromDebugger(false)
      } else if (params['fromDebugger'] === 'true') {
        projectsService.setFromDebugger(true)
      }
      _.set(this, 'filegroupId', params['filegroupId']);

    })

  }
  workflowIsDownloaded(event){
    if (event)
    this.workflowDownloaded.next(true)
  }
  ngOnInit() {
    if (!this.projectsService.getProjectName()) {
      this.projectsService.projectGet(this.projectId).subscribe((res) => {
        this.projectsService.setProjectName(res.title)

      })
    };
    if (this.filegroupId) {
      let body = {
        filegroup_id: this.filegroupId,
      }
      this.projectsService.searchFiles(body).subscribe(res => {
        this.dataTypes['transcriptome'].count.next(_.get(res, 'counts.transcriptome'))
        this.dataTypes['genome'].count.next(_.get(res, 'counts.genome'))
        this.dataTypes['epigenome'].count.next(_.get(res, 'counts.epigenome'))
        this.subjects.next(_.get(res, 'counts.patients'))
      })
    }


  }
  next(workflowId) {

    if (workflowId) {
      this.submitted = true;
      this.router.navigate([PagesName.ProjectCreation, PagesName.ChooseDiseases, this.projectId, workflowId,this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId : this.filegroupId }})
    }
  }
  previous() {
    this.submitted = true;
    this.router.navigate([PagesName.ProjectCreation, PagesName.ChooseWorkflow, this.projectId,this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId : this.filegroupId} })
  }


}
