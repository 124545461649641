import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
@Component({
  selector: 'app-path',
  styleUrls: ['../../../shared/layout/managers.scss'],
  templateUrl: './path.component.html',
})
export class PathComponent {
  @Input() path;
  @Input() onClickAction?
  @Input() onClickActionLastElement?
  @Input() theme?;
  _=_;
  constructor(public router: Router) { }
  getPath = () => {
    return _.get(this, 'path', [])
  }
  getName = (el) =>{
    return _.get(el, 'name', '')
  }
  getId = (el) =>{
    return _.get(el, 'id', '')
  }
  handleClick = (el,last) => {
    if (!el.id && !_.get(el,'action')){
      return
    }
    if (last && this.onClickActionLastElement ){
        this.onClickActionLastElement()
    }
    else{ 
      if (_.get(el, 'id')) {
        if (this.onClickAction) {
          this.onClickAction(el.id)
        }
      } else {
        if (_.get(el, 'action')) {
          this.router.navigate(_.get(el, 'action'))
        }
      }
    }
    
  }
}
