import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { FileSystemState, LoginHttpService } from './login-http-service/login-http.service';
import * as _ from 'lodash';
import { availableFeatures, environment } from 'src/environments/environment';
import { LocalService } from 'src/app/api/local-storage.service';
import { Features } from 'src/environments/features';


@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class PageLoginComponent implements OnInit {
  validationForm: FormGroup;
  secondFactorForm: FormGroup;
  error: string = null;
  qrCodeImage: string = '';
  timeForQRCode: boolean = false;
  timeForSecondFactor: boolean = false;

  constructor(private router: Router, private loginHttp: LoginHttpService, private localService: LocalService) { }

  ngOnInit(): void {
    this.buildForm();
    localStorage.clear()
  }

  buildForm(): void {
    this.validationForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
    this.secondFactorForm = new FormGroup({
      code: new FormControl(null, [Validators.required]),
    });
  }

  login(): void {
    const email = this.validationForm.value.email;
    const password = this.validationForm.value.password;

    if (this.validationForm.valid) {
      if (_.includes(availableFeatures,Features.DoubleAuth)) {

        // Two-Factor Authentication
        this.loginHttp.login(email, password).subscribe(
          (response) => {
            //const userID = response.body.userID;
            const basePath = response.url.substring(
              0,
              response.url.length - '/mas/auth/login/v2'.length
            );

            let session = {
              accessToken: response.body.first_factor_token,
              apiPath: basePath,
              isLoggedIn: false,
            };
            this.localService.setLocalStorage('session',session)
            this.qrCodeImage = this.loginHttp.getQRCodeURL(
              basePath,
              response.body.first_factor_token
            );
          },
          (errorMessage) => {
            this.error = errorMessage;
          }
        );

      } else { // Legacy Single-Factor Authentication
        this.loginHttp.legacyLogin(email, password).subscribe(
          (data) => {
            const accessToken = data.token;
            const userID = data.userID;

            let session = {
              accessToken: accessToken,
              isLoggedIn: true,
            };
            this.localService.setLocalStorage('session',session)

            this.loginHttp.getUser(userID).subscribe(
              (response) => {
                const country = _.get(response, 'account_country.length', 0) ? _.get(response, 'account_country') : 'US'
                const file_systems = this.constructFileSystem(country)
                let user = {
                  id: userID,
                  email: response.email,
                  first_name: response.first_name,
                  last_name: response.last_name,
                  organization: response.organization,
                  account_country: country,
                  file_systems: file_systems
                };
                this.localService.setLocalStorage('user',user)

                this.localService.setLocalStorage('country',file_systems);

                this.router.navigate(['/']);
              },
              (error) => {
                console.log(error);
              }
            );
          },
          (errorMessage) => {
            this.error = errorMessage;
          }
        );
      }
    }
  }
  constructFileSystem(account_country, external_user_ids?) {
    let file_systems = [
      {
        name: 'Canada',
        value: 'CA',
      },
      { name: 'France', value: 'FR' },
      { name: 'United States', value: 'US' }
    ]
    if (!external_user_ids) {
      let file_system = _.find(file_systems, (o) => { return o.value === account_country })
      _.set(file_system, 'accessState', FileSystemState.Local)
      let result = []
      result.push(file_system)
      return result
    } else {
      const externalCountries = _.keys(external_user_ids);
      file_systems.forEach(element => {
        if (element.value === account_country) {
          _.set(element, 'accessState', FileSystemState.Local)
        } else if (_.includes(externalCountries, element.value)) {
          _.set(element, 'accessState', FileSystemState.External)
        } else {
          _.set(element, 'accessState', FileSystemState.Disabled)
        }
      })
      return file_systems
    }
  }
  getLoginFormClass(): string {
    if (!this.timeForQRCode && !this.timeForSecondFactor) {
      return '';
    }
    return 'hidden';
  }
  getQRCodeFormClass(): string {
    if (this.timeForQRCode) {
      return '';
    }
    return 'hidden';
  }

  getSecondFactorFormClass(): string {
    if (this.timeForSecondFactor) {
      return '';
    }
    return 'hidden';
  }

  showQRCodeForm() {
    this.error = '';
    this.timeForQRCode = true;
  }

  showSecondFactorForm() {
    this.error = '';
    this.timeForQRCode = false;
    this.timeForSecondFactor = true;
  }

  submitSecondFactor(): void {
    this.loginHttp
      .validateSecondFactor(this.secondFactorForm.value.code)
      .subscribe(
        (response) => {
          let apiPath = this.localService.getFromLocalStorage('session','apiPath')
          let session = {
            accessToken: response.access_token,
            apiPath: apiPath,
            isLoggedIn: true,
          };
          this.localService.setLocalStorage('session',session)
          this.loginHttp.verifyAccess().subscribe(
            (response) => {
              this.loginHttp.getUser(response.user_id).subscribe(
                (response) => {
  
                  const external_user_ids = _.get(response, 'external_user_ids', {})
                  const account_country = _.get(response, 'account_country', {})
                  let file_systems = this.constructFileSystem(account_country, external_user_ids)
                  _.set(response, 'file_systems', file_systems)
                  this.localService.setLocalStorage('user',response)
                  if (_.includes(availableFeatures,Features.DoubleAuth)) {
                    this.localService.setLocalStorage('country',_.find(file_systems, (o) => { return o.accessState === FileSystemState.Local }));
                  }

                  file_systems.forEach(element => {
                    if (element.accessState === FileSystemState.External) {
                    this.loginHttp.getExternalStorageAccessToken(element.value).subscribe((response2) => {
                      _.set(element, 'url', _.get(response2, 'url'))
                      _.set(element, 'access_token', _.get(response2, 'access_token'))
                      this.localService.editLocalStorage('user','file_systems', file_systems)
                    })
                  }
                  });

                  this.router.navigate(['/']);
                },
                (error) => {
                  console.log(error);
                }
              );

            },
            (error) => {
              console.log(error);
              this.error = 'Invalid Second Factor Code';
            }
          );
        })
  }

}
