import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectsService } from '../../../../api/projects.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import moment from 'moment';
import { FileType } from 'src/app/api/file-manager.service';
import * as _ from 'lodash';
import { LocalService } from 'src/app/api/local-storage.service';

@Component({
  selector: 'app-project-list',
  styleUrls: [
    '../../../../shared/layout/managers.scss',
    './project-list.component.scss',
  ],
  templateUrl: './project-list.component.html',
})
export class ProjectListComponent {
  moment = moment;
  @Input() project: any;
  @Input() selected;
  @Output() refreshParent = new EventEmitter<boolean>();
  public environment = environment;
  public iconText = '';
  FileType = FileType;

  constructor(
    public router: Router,
    public projectsService: ProjectsService,
    public projectManagerService: ProjectManagerService,
    private localService : LocalService
  ) { }

  share(event) {
    this.projectManagerService.setProjectStorageCountry(this.country);
    this.projectManagerService.setBulkToOneFileOnly(this.project);
    this.projectManagerService.getInfos();
    this.projectManagerService.openShare();
    event.stopPropagation()
  }
  getInfos(event) {
    this.projectManagerService.setProjectStorageCountry(this.country);
    this.projectManagerService.setBulkToOneFileOnly(this.project);
    this.projectManagerService.getInfos();
    this.projectManagerService.openInfo();
    event.stopPropagation()
  }
  trash(event) {
    if (!this.project.capabilities.can_trash) {
      return
    }
    this.projectManagerService.setProjectStorageCountry(this.country)
    this.projectManagerService.handleDelete(this.id, this.title)
    event.stopPropagation()
  }
  get id() {
    return _.get(this, 'project.id', null)
  }
  get title() {
    return _.get(this, 'project.title', null)
  }
  get country() {
    return _.get(this, 'project.country', this.localService.getFromLocalStorage('user','account_country'))
  }
  
  get canTrash(){
    return _.get(this, 'project.capabilities.can_trash', false)
  }
  get isTrashed(){
    return _.get(this, 'project.trashed', false)
  }

  setIconText(text) {
    this.iconText = text;
  }
  unSetIconText() {
    this.iconText = '';
  }

}
