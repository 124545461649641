import { Component, OnInit, OnDestroy } from '@angular/core';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { ItemType, ShareService } from 'src/app/api/share.service';
import { FileManager, SortDirection, SortType } from 'src/app/api/file-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';

@Component({
  selector: 'app-shared-files',
  templateUrl: './shared-files.component.html',
  styleUrls: ['./shared-files.component.scss', '../../../shared/layout/managers.scss']
})
export class SharedFilesComponent implements OnInit, OnDestroy {
  TabsName = TabsName;
  ItemType = ItemType;
  SortDirection = SortDirection;
  SortType = SortType;
  timer;
  subscription;
  constructor(private fileManager : FileManager, public groupsManagerService: GroupsManagerService, public sharedFilesManager: SharedFilesManagerService, public layout: LayoutOrganizationService, public shareService: ShareService) { }

  ngOnInit(): void {
    this.sharedFilesManager.refreshFolder()
    // this.timer = setInterval(() => {
    //   this.sharedFilesManager.refreshFolder()
    // }, 60000)
    if (_.get(this.fileManager,'countrySelected'))
      this.subscription = this.fileManager.countrySelected.subscribe((res)=>{
        if (res && this.fileManager.viewTab === TabsName.SharedFiles){
          this.sharedFilesManager.resetFolder('list')
        }
      })
    
    

  }
  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    if(this.subscription){
      this.subscription.unsubscribe()
    }
  }

  pageChanged() {
    setTimeout(() => {
      this.sharedFilesManager.moveInsideFolder()
    }, 1)
  }

  trackByFn(index, item) {
    let metadata = !_.isEmpty(item.metadata) ? Object.keys(item.metadata).length : null
    return item.id + ',' + metadata + ',' + item.status
  }

}
