import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ShareService, UserType, ItemType, PermissionType, RoleType, RoleTypeFile, RoleTypeProject } from 'src/app/api/share.service';
import * as _ from 'lodash';
import { FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { UserCreationHttpService } from 'src/app/pages/user-creation/user-creation-http/user-creation-http.service';
import { LocalStorage } from 'ngx-webstorage';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { FileManager } from 'src/app/api/file-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';
import { TabsName } from '../helpers/tabsName';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';




@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss', '../layout/managers.scss']
})

export class ShareComponent implements OnInit, AfterViewInit {
  @Input() type: ItemType;
  @Input() item;
  @LocalStorage() user;
  autocompleteData = []
  _ = _;
  usergroups = []
  keyword = 'value';
  emailToSearch = '';
  searchUser: FormGroup;

  UserType = UserType;
  ItemType = ItemType;
  RoleType = RoleType;
  pending = false;
  PermissionType = PermissionType;
  userNotFound = false

  addType: UserType;
  manager;
  constructor(public shareService: ShareService, private groupsService : GroupsManagerService, private fileManager: FileManager, private myFilesManager: MyFilesManagerService, private sharedFilesManager: SharedFilesManagerService, private l: LayoutOrganizationService, private projectManagerService: ProjectManagerService) { }

  @ViewChild('select') select: ElementRef;
  @ViewChild('autocompleteField') autocompleteField: ElementRef;


  ngOnInit(): void {
    if (ItemType.File) {
      if (this.fileManager.viewTab === TabsName.MyFiles) {
        this.manager = this.myFilesManager;
      } else if (this.fileManager.viewTab === TabsName.SharedFiles) {
        this.manager = this.sharedFilesManager;
      }
    }else if (ItemType.FileGroup){
      this.manager = this.groupsService
    }
    this.autocompleteData = _.cloneDeep(this.shareService.getTeamsAndUsergroupsMembersAndUsergroupNames())
    if (!this.item) {
      return;
    }

    this.initForm();

  }
  ngAfterViewInit() {
    //this.selectFirstOption(this.select)
  }
  initForm() {
    this.searchUser = new FormGroup({
      email: new FormControl(
        null,
        [Validators.required, Validators.min(1), this.granteeHasNotAlreadyAccess, this.granteeIsNotMe, this.granteeIsNotOwner,
        this.customValidator],

      ),
      permission: new FormControl(
        null, [Validators.required]),
    })
  }

  submitForm() {
    if (this.addType === UserType.Usergroup) {
      this.addUserGroup(this.email.value, this.permission.value)
    } else {
      this.addUserByEmail(this.email.value, this.permission.value)
    }

  }
  hasAtLeastOneError(type) {
    if (type === 'email') {
      if ((!this.email.valid || this.userNotFound) && (this.email.dirty || this.email.touched)) {
        return true
      }
    }
    return false;
  }
  addUserByEmail(email, role) {
    const description = this.describeRole(role);
    const user = _.find(this.shareService.getTeamsAndUsergroupsMembers(), { value: email })
    if (!user) {
      this.l.toast('Impossible to share this item to <b>' + email + '</b>. (Future feature)', null, 8000, 'Profile', 'danger')
      this.searchUser.reset();
      return;
    }
    this.shareService.usersSelected.push({ email: email, id: _.get(user, 'id'), userType: UserType.User, role: role, role_description: description })
    this.searchUser.reset();

  }
  manuallyTriggerValidation() {
    const input = this.autocompleteField.nativeElement.querySelectorAll("input")[0]
    setTimeout(() => { input.dispatchEvent(new Event("input")); }, 10)
  }
  describeAllRoles() {
    let text = ''
    let model: any = RoleTypeProject;
    if (this.type === ItemType.File) {
      model = RoleTypeFile
    }
    for (let el of model) {
      text = text + ' ' + el.toUpperCase() + ' => ' + this.describeRole(el)
    }
    return text
  }
  describeRole(role) {
    if (this.type === ItemType.Project) {
      switch (role) {
        case RoleType.Reader:
          return 'can read'
        case RoleType.Writer:
          return 'can read, execute, and modify'
        case RoleType.Contributor:
          return 'can read, execute, modify and share'
      }
    }
    if (this.type === ItemType.File) {
      switch (role) {
        case RoleType.Reader:
          return 'can list and use in workflow'
        case RoleType.Downloader:
          return 'can list and use in workflow'
        case RoleType.Writer:
          return 'can list, use in workflow and modify'
        case RoleType.Contributor:
          return 'can list, use in workflow and modify and share'
      }
    }
    if (this.type === ItemType.FileGroup) {
      switch (role) {
        case RoleType.Reader:
          return 'can list and use in workflow'
        case RoleType.Writer:
          return 'can list, use in workflow and modify'
        case RoleType.Contributor:
          return 'can list, use in workflow and modify and share'
      }
    }
  }
  addUserGroup(name, role) {
    let usergroup = _.find(this.shareService.getUsergroupNames(), { value: name })
    let description = this.describeRole(role);
    this.shareService.usersSelected.push({ email: name, userType: UserType.Usergroup, id: usergroup.id, role: role, role_description: description })
    this.searchUser.reset();
    //this.selectFirstOption(this.select)


  }
  removeInvited(index) {
    this.shareService.usersSelected.splice(index, 1);
  }

  removePermission = (permissionId, itemId) => {
    let text = 'Are you sure you want to remove this permission ?'
    this.l.customConfirm(text, () => {
      this._removePermission(permissionId, itemId)
    })
  }
  _removePermission = (permissionId, itemId) => {
    if (this.type === ItemType.Project) {
      this.shareService.removePermissionFromProject(permissionId, itemId).subscribe(() => {
        this.projectManagerService.getInfos();
        this.l.toast('Permission <b>successfully</b> removed from project.', null, 8000, 'Profile')
      }, () => {
        this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger')
      })
    }
    if (this.type === ItemType.File) {
      this.shareService.removePermissionFromFile(permissionId, itemId).subscribe(() => {
        this.manager.getInfos()
        this.l.toast('Permission <b>successfully</b> removed from file.', null, 8000, 'Profile')
      }, () => {
        this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger')
      })
    }
    if (this.type === ItemType.FileGroup) {
      this.shareService.removePermissionFromFileGroup(permissionId, itemId).subscribe(() => {
        this.groupsService.getInfos(itemId)
        this.l.toast('Permission <b>successfully</b> removed from file group.', null, 8000, 'Profile')
      }, () => {
        this.l.toast('Impossible operation', null, 8000, 'Profile', 'danger')
      })
    }
  }
  existingUserGroupValidator(control: FormControl): ValidationErrors | null {
    return _.findIndex(this.shareService.getUsergroupNames(), { 'value': control.value }) > -1 ? null : { userGroupDoesNotExist: true }
  }
  // existingUserEmailValidatorInSubscription(control: FormControl): ValidationErrors | null {
  //   return _.findIndex(this.shareService.emailsAutocompleteData, { 'value': control.value }) > -1 ? null : { userEmailIsNotKnown: true }
  // }
  emailValidator(control: FormControl): ValidationErrors | null {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(control.value).toLowerCase()) ? null : { notAnEmail: true }
  }

  granteeIsNotMe = (control: FormControl): ValidationErrors | null => {
    if (control.value === this.user.email) {
      return { userIsMe: true }
    } else { return null }
  }
  granteeIsNotOwner = (control: FormControl): ValidationErrors | null => {
    if (control.value === this.item.owner.email) {
      return { userIsOwner: true }
    } else { return null }
  }
  granteeHasNotAlreadyAccess = (control: FormControl): ValidationErrors | null => {
    let permissions = _.get(this, 'item.permissions', [])
    let userAlreadyAdded = _.find(permissions, function (o) { return o.grantee.email === control.value })
    let usergroupAlreadyAdded = _.find(permissions, function (o) { return o.grantee.name === control.value })
    if (userAlreadyAdded === undefined && usergroupAlreadyAdded === undefined) {
      return null
    } else {
      return { userIsAlreadyAdded: true }
    }
  }

  getTooltipContent(type) {
    let response = ''
    if (type === 'email') {
      if (this.email.hasError('required') && this.email.dirty || this.email.touched) {
        response = "This field is required"
      }
      else if (this.email.hasError('userIsOwner')) {
        response = "This user is already the owner."
      }
      else if (this.email.hasError('userIsMe')) {
        response = "You can't share this to yourself."
      }
      else if (this.userNotFound) {
        response = "User not found"
      }
      else if (this.email.hasError('userIsAlreadyAdded')) {
        response = 'Already added.'
      }
      else if (!this.email.valid && this.email.dirty || this.email.touched) {
        response = "You can't share to this user or usergroup."
      }
    }
    return response

  }
  resetEmailError() {
    this.userNotFound = false
  }

  customValidator = (
    control: FormControl
  ): ValidationErrors | null => {
    this.addType = null

    if (this.emailValidator(control) === null) {
      this.addType = UserType.User
      return null
    }
    if (this.existingUserGroupValidator(control) === null) {
      this.addType = UserType.Usergroup
      return null
    }

    return { userNorUserGoupExist: true }
  }

  get email() {
    return this.searchUser.get('email');
  }
  get permission() {
    return this.searchUser.get('permission');
  }
}
