<div class='h-100 flex-nowrap row m-0'>
  <div class="utility-bar utility-bar-at-the-left ">

    <button [class.disabled]="!_.includes(availableFeatures,Features.UploadFile)" placement="right"
      ngbTooltip="{{getTooltip('upload')}}" tooltipClass="custom-tooltip"
      (click)="_.includes(availableFeatures,Features.UploadFile) ? layout.open('modalFile') : '' "
      class="mb-2 text-left btn btn-dark">
      <i class="mx-2 far fa-file-upload" aria-hidden="true"></i>
      <span class="cursor-info">
        Upload
        files </span>
    </button>
    <button  ngbTooltip="Delete all selected files and folders" tooltipClass="custom-tooltip"
    placement="right" *ngIf='_.includes(availableFeatures,Features.DeleleMultipleFiles)' [class.disabled]="!myFilesManager.getBulkSelectionLength()" (click)="myFilesManager.deleteAll()"
      class="mb-2 text-left  btn btn-dark">
      <i class="mx-2 far fa-trash-alt" aria-hidden="true"></i>
      <span class="cursor-info"> Delete selected  
      </span>
    </button>
    <div class="btn-group-vertical" role="group">
      <button ngbTooltip="Add a new folder to organize your files" tooltipClass="custom-tooltip"
      placement="right" (click)="layout.open('modalFolder')" class="btn text-left  btn-dark">
        <i class="mx-2 far fa-folder-plus" aria-hidden="true"></i>
        <span class="cursor-info" > Add folder
        </span>
      </button>
      <button  ngbTooltip="Create a group with your files and folders. A group is used in a project to perform analyses"
      tooltipClass="custom-tooltip" placement="right" (click)="layout.open('modalGroup')" class="btn text-left  btn-dark">
        <i class="mx-2 far fa-object-group" aria-hidden="true"></i>
        <span class="cursor-info"
         > Create group
        </span>
      </button>
    </div>


  </div>


  <div class='col p-0 h-100 d-flex flex-column ' id='FILES-AND-GROUPS'>
    <div class='scrollable h-100'>
      <div id='FILES' class='m-3'>
        <div id="top-utility-bar" class="rounded hidden-on-mobile">
          <div [style.min-height.px]="35" class='d-flex flex-wrap justify-content-between'>
            <span class='flex-nowrap'>
              <span class="cursor-info"
                [ngbTooltip]="myFilesManager.isBulkSelected() ? 'Unselect all files' : 'Select all files'"
                tooltipClass="custom-tooltip" placement="bottom">
                <app-checkbox [id]="" [fileType]='null' [selectAction]='myFilesManager.selectAllDisplayedFiles'
                  [unSelectAction]='myFilesManager.resetBulkSelection' [selected]="myFilesManager.isBulkSelected()"
                  bulkType='allFiles'>
                </app-checkbox>

              </span>

              <span class="px-3 text-light" [class.font-weight-bold]='!myFilesManager.isBulkSelected()'>
                Check all</span>
              |
              <span class="px-3  text-light" [class.font-weight-bold]='myFilesManager.isBulkSelected()'>Uncheck
                all</span>
              <span *ngIf="myFilesManager.getBulkSelectionLength()">|</span>
              <span class="px-3 text-light" *ngIf="myFilesManager.getBulkSelectionLength()" click=""><span
                  class="font-weight-bold">{{myFilesManager.getBulkSelectionLength()}}</span>
                item{{myFilesManager.getBulkSelectionLength()>1 ? 's':''}}
                selected <span class='mx-2'> -
                </span><strong>{{myFilesManager.getBulkSize()[0]}}</strong>
                {{myFilesManager.getBulkSize()[1]}}</span>
            </span>

            <div *ngIf="myFilesManager.filtersVisible" class="d-flex flex-nowrap">
              <button
                (click)='myFilesManager.setSortFilter(SortType.Title);myFilesManager.toggleSortDirection(); myFilesManager.refreshFolder()'
                class="btn noBoxShadow">
                <span class="text-light">Sort By Name <i class="fas"
                    *ngIf='myFilesManager.getSortFilter() === SortType.Title'
                    [class.fa-sort-up]="myFilesManager.getSortDirection() === SortDirection.Asc"
                    [class.fa-sort-down]="myFilesManager.getSortDirection() === SortDirection.Desc"
                    aria-hidden="true"></i></span>
              </button>

              <button
                (click)='myFilesManager.setSortFilter(SortType.DateCreation);myFilesManager.toggleSortDirection(); myFilesManager.refreshFolder()'
                class="btn noBoxShadow">
                <span class="text-light">Sort By Date <i class="fas"
                    *ngIf='myFilesManager.getSortFilter() === SortType.DateCreation'
                    [class.fa-sort-up]="myFilesManager.getSortDirection() === SortDirection.Asc"
                    [class.fa-sort-down]="myFilesManager.getSortDirection() === SortDirection.Desc"
                    aria-hidden="true"></i></span>
              </button>

            </div>
            <div class="flex-nowrap">
              <button *ngIf="!myFilesManager.filtersVisible" (click)='myFilesManager.toggleFilter()'
                class="btn noBoxShadow">
                <span class="text-light font-weight-bold">Show filters</span>
              </button>
              <button *ngIf="myFilesManager.filtersVisible" (click)='myFilesManager.toggleFilter()'
                class="btn noBoxShadow">
                <span class="text-light font-weight-bold">Hide filters</span>
              </button>
            </div>
          </div>
        </div>
        <div class='d-flex flex-column flex-nowrap'>
          <div *ngIf="myFilesManager.getOpenedFolderId() !== 'root'">
            <div id="return" (drop)="myFilesManager.getDragEndTargetId('return')"
              (dragover)="myFilesManager.allowDrop($event)" class="list-container d-flex justify-content-between">
              <p>
                <button class="btn btn-link"
                  (click)="myFilesManager.closeFolder(myFilesManager.getParentFolderId())">
                  <i class="far fa-arrow-left" aria-hidden="true"></i> Back to
                  {{myFilesManager.getParentFolderName()}}
                </button>
              </p>
            </div>
          </div>
          <div class='empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
          *ngIf='myFilesManager.finishedListing && !myFilesManager.getDisplayedFilesLength() && myFilesManager.getOpenedFolderId() !== "root"'>
          <p>
            Empty folder.
          </p>
        </div>
        <div class='empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
          *ngIf='myFilesManager.finishedListing && !myFilesManager.getDisplayedFilesLength() && myFilesManager.getOpenedFolderId() === "root"'>
          <p>
            All your files and folders will display here.
          </p>
        </div>
          <div id="files-content" *ngIf='(myFilesManager.getDisplayedFilesLength() || myFilesManager.getUploadingFiles()) && myFilesManager.finishedListing'
            class="w-100 d-flex flex-column">
            <div class="my-2 d-flex flex-column">
              <div *ngFor="let item of 
                            _.concat(myFilesManager.getUploadingFiles(), myFilesManager.getDisplayedFiles());
                              trackBy: trackByFn">
                <app-item-list [selected]='myFilesManager.isBulkSelected(item.id)' [item]="item">
                </app-item-list>
              </div>
              <ngb-pagination (pageChange)="pageChanged()" class="pager my-3" [(page)]="myFilesManager.page"
                [maxSize]="5" [pageSize]="myFilesManager.pageSize" [collectionSize]="myFilesManager.count">
                <ng-template ngbPaginationPrevious><i class="far fa-chevron-left" aria-hidden="true"></i></ng-template>
                <ng-template ngbPaginationNext><i class="far fa-chevron-right" aria-hidden="true"></i>
                </ng-template>
              </ngb-pagination>
            </div>

          </div>
        
        </div>
      </div>

      <app-groups *ngIf='groupsManagerService.finishedListing' [marginBottom]='true'></app-groups>


    </div>
  </div>
  <div [class.active]='layout.isFilesInfosModalOpen || layout.isFilesShareModalOpen || layout.isGroupShareModalOpen' class="col-3 p-0" id='PANNEL'>
    <div id="info-content" [class.active]="layout.isFilesInfosModalOpen">
      <div *ngIf="layout.isFilesInfosModalOpen" id="modalFilesInfos">
        <div class="d-flex justify-content-end">
          <button type="button" class="close" aria-label="Close" (click)="layout.close('modalFilesInfos')">
            <span><i class="far fa-times" aria-hidden="true"></i></span>
          </button>
        </div>
        <div *ngIf="myFilesManager.itemsWithInfoDisplayed">
          <ul class="info-scroll">
            <info *ngFor="let item of myFilesManager.itemsWithInfoDisplayed" [item]="item"></info>
          </ul>
        </div>
      </div>
    </div>
    <div id="share-content" [class.active]="layout.isFilesShareModalOpen">
      <div *ngIf="layout.isFilesShareModalOpen" class='h-100 d-flex flex-column'>
        <div class="d-flex flex-column flex-fill" id="modalFilesShare">
          <div class="d-flex justify-content-end">
            <button type="button" class="close" aria-label="Close" (click)="layout.close('modalFilesShare')">
              <span><i class="far fa-times" aria-hidden="true"></i></span>
            </button>
          </div>
          <div class='flex-fill' *ngIf="myFilesManager.itemsWithInfoDisplayed">
            <ul class="info-scroll d-flex flex-column">
              <app-share-file class='flex-fill d-flex flex-column'
                *ngFor="let item of myFilesManager.itemsWithInfoDisplayed" [item]="item">
              </app-share-file>
            </ul>
          </div>
        </div>
        <div class="btn-container">
          <div class="d-flex flex-nowrap">
            <button type="button" class="modal-btn btn btn-dark"
              (click)="layout.close();shareService.resetNonSentInvitations()">
              Cancel
            </button>
            <button type="button" class="modal-btn btn btn-dark" [disabled]="!getUsersSelectedLength()"
              (click)="shareService.shareToUsers(ItemType.File, myFilesManager.bulkSelection[0])">
              Confirm
            </button>
          </div>
        </div>
      </div>

    </div>
    <div id="share-content-group" [class.active]="layout.isGroupShareModalOpen">
      <div *ngIf="layout.isGroupShareModalOpen" class='h-100 d-flex flex-column'>
        <div class="d-flex flex-column flex-fill" id="modalGroupsShare">
          <div class="d-flex justify-content-end">
            <button type="button" class="close" aria-label="Close" (click)="layout.close('modalGroupsShare')">
              <span><i class="far fa-times" aria-hidden="true"></i></span>
            </button>
          </div>
          <div class='flex-fill' *ngIf="groupsManagerService.itemsWithInfoDisplayed">
            <ul class="info-scroll d-flex flex-column">
              <app-share-group class='flex-fill d-flex flex-column'
                *ngFor="let item of groupsManagerService.itemsWithInfoDisplayed" [item]="item">
              </app-share-group>
            </ul>
          </div>
        </div>
        <div class="btn-container">
          <div class="d-flex flex-nowrap">
            <button type="button" class="modal-btn btn btn-dark"
              (click)="layout.close();shareService.resetNonSentInvitations()">
              Cancel
            </button>
            <button type="button" class="modal-btn btn btn-dark" [disabled]="!getUsersSelectedLength()"
              (click)="shareService.shareToUsers(ItemType.FileGroup, groupsManagerService.bulkSelection[0])">
              Confirm
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<app-custom-modal modalTitle="Upload meta data relative to your file" idName="modalMetaData"
  [show]="layout.isMetaDataModalOpen" confirmTitle="Save" [confirmActions]="[myFilesManager.handleNewMetaData]"
  [confirmValidator]="
    myFilesManager.metadataUploadForm?.controls?.metadataInput
      ? myFilesManager.metadataUploadForm.controls.metadataInput.valid
      : false
  " [cancelActions]="[myFilesManager.resetMetadataUploadForm]">
  <div class="custom-modal-content">
    <app-metadata-upload-form></app-metadata-upload-form>
  </div>
</app-custom-modal>

<app-custom-modal modalTitle="Upload new file(s)" idName="modalFile" [show]="layout.isFileModalOpen" confirmTitle="Save"
    [confirmActions]="[myFilesManager.handleNewFiles]" confirmTooltip='Please verify your form.'  [form]='myFilesManager.fileUploadForm'
   [cancelActions]="[myFilesManager.resetFileUploadForm]" >
        <app-file-upload-form *ngIf='layout.isFileModalOpen'></app-file-upload-form>
    
</app-custom-modal>
<app-custom-modal modalTitle="Enter a name for a new folder" idName="modalFolder" [show]="layout.isFolderModalOpen"
  confirmTitle="Save" [confirmActions]="[myFilesManager.handleNewFolder]" [confirmValidator]="
    myFilesManager.folderCreationForm?.controls?.folderInput
      ? myFilesManager.folderCreationForm.controls.folderInput.valid
      : false
  " [cancelActions]="[myFilesManager.resetFolderCreationForm]">
  <div class="custom-modal-content">
    <app-folder-creation-form></app-folder-creation-form>
  </div>
</app-custom-modal>
