import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FileManager, SortDirection, SortType } from 'src/app/api/file-manager.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { ItemType, ShareService } from 'src/app/api/share.service';
import * as _ from 'lodash';
import { availableFeatures } from 'src/environments/environment';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { GroupsManagerService } from 'src/app/api/groups-manager.service';
import { Features } from 'src/environments/features';

@Component({
  selector: 'app-my-files',
  templateUrl: './my-files.component.html',
  styleUrls: ['./my-files.component.scss', '../../../shared/layout/managers.scss',],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MyFilesComponent implements OnInit, OnDestroy {
  TabsName = TabsName;
  ItemType = ItemType;
  SortDirection = SortDirection;
  SortType = SortType;
  Features = Features;
  _ = _;
  availableFeatures = availableFeatures;
  timer;
  subscription
  constructor(public fileManager: FileManager, public groupsManagerService: GroupsManagerService, public myFilesManager: MyFilesManagerService, public layout: LayoutOrganizationService, public shareService: ShareService) {
  }
  ngOnInit(): void {

    this.myFilesManager.refreshFolder()
    if (_.get(this.fileManager, 'countrySelected'))
      this.subscription = this.fileManager.countrySelected.subscribe((res) => {
        if (res && this.fileManager.viewTab === TabsName.MyFiles) {
          this.myFilesManager.resetFolder('root')
        }
      })



  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }


  pageChanged() {
    setTimeout(() => {
      this.myFilesManager.moveInsideFolder()
    }, 1)
  }

  getTooltip(btn) {
    if (btn === 'upload') {
      if (_.includes(availableFeatures, Features.UploadFile)) {
        return 'Select a file to upload'
      } else {
        return "You don't have the permission to upload"
      }
    }
  }

  trackByFn(index, item) {
    const key = Object.keys(item.metadata)
    const metadata = !_.isEmpty(item.metadata) ? _.get(key, 'length', 0) : null
    return item.id + ',' + metadata + ',' + item.status
  }
  getUsersSelectedLength() {
    return _.get(this.shareService, 'usersSelected.length', 0)
  }
}
