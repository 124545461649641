import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { UserCreationHttpService } from '../user-creation-http/user-creation-http.service';
import { map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-user-creation-form',
  templateUrl: './user-creation-form.component.html',
  styleUrls: ['./user-creation-form.component.scss'],
})
export class UserCreationFormComponent implements OnInit {
  userCreationForm: FormGroup;
  @Output() userAdded = new EventEmitter<any>();

  constructor(private userCreationHttp: UserCreationHttpService) { }

  ngOnInit() {
    this.userCreationForm = new FormGroup({
      email: new FormControl(
        null,
        [Validators.required, Validators.email],
        this.existingUserValidator.bind(this)
      ),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      ontologicalDomain: new FormControl(null, [
        Validators.required,
        this.ontologicalDomainValidator,
      ]),
      fileHostingCountry: new FormControl(null, [
        Validators.required,
        this.hostingCountryValidator,
      ]),
      organization: new FormControl(null, [Validators.required]),
      passwordData: new FormGroup(
        {
          password: new FormControl(null, [
            Validators.required,
            Validators.minLength(8),
            this.noSpacesValidator,
            this.specialCharacterValidator,
          ]),
          confirm: new FormControl(null, [Validators.required]),
        },
        this.passwordsMatchValidator
      ),
    });
  }

  get email() {
    return this.userCreationForm.get('email');
  }

  get firstName() {
    return this.userCreationForm.get('firstName');
  }

  get lastName() {
    return this.userCreationForm.get('lastName');
  }

  get ontologicalDomain() {
    return this.userCreationForm.get('ontologicalDomain');
  }

  get fileHostingCountry() {
    return this.userCreationForm.get('fileHostingCountry');
  }

  get organization() {
    return this.userCreationForm.get('organization');
  }

  get password() {
    return this.userCreationForm.get('passwordData.password');
  }

  get confirm() {
    return this.userCreationForm.get('passwordData.confirm');
  }

  get passwordData() {
    return this.userCreationForm.get('passwordData');
  }

  existingUserValidator(
    control: FormControl
  ): Observable<ValidationErrors | null> {
    return this.userCreationHttp.existingUserCheck(control.value).pipe(
      map((response) => (response.exists ? { userExists: true } : null)),
      catchError((error) => {
        console.log(error);
        return null;
      })
    );
  }

  passwordsMatchValidator(control: FormGroup): ValidationErrors | null {
    const password = control.get('password');
    const confirm = control.get('confirm');
    return password.value !== confirm.value ? { mustMatch: true } : null;
  }

  ontologicalDomainValidator(control: FormGroup): ValidationErrors | null {
    let value = control.value;
    if (value) {
      value = value.toLowerCase();
    }
    if (value === 'human' || value === 'plant') {
      return null;
    } else {
      return { wrongValue: true };
    }
  }

  hostingCountryValidator(control: FormGroup): ValidationErrors | null {
    const value = control.value;
    if (value === 'CA' || value === 'USA') {
      return null;
    } else {
      return { wrongValue: true };
    }
  }

  noSpacesValidator(control: FormControl): ValidationErrors | null {
    if (control.value) {
      if (control.value.includes(' ')) {
        return { shouldNotHaveSpaces: true };
      }
    }

    return null;
  }

  specialCharacterValidator(control: FormControl): ValidationErrors | null {
    const regex = /[^a-zA-Z0-9]+/g;
    const value = control.value;

    if (value && value.match(regex)) {
      return null;
    } else {
      return { noSpecialCharacter: true };
    }
  }

  addUser(): void {
    const user = {
      email: this.userCreationForm.value.email,
      first_name: this.userCreationForm.value.firstName,
      last_name: this.userCreationForm.value.lastName,
      ontological_domain: this.userCreationForm.value.ontologicalDomain,
      file_hosting_country: this.userCreationForm.value.fileHostingCountry,
      organization: this.userCreationForm.value.organization,
      password: this.userCreationForm.value.passwordData.password,
    };

    this.userAdded.emit(user);
    this.userCreationForm.reset();
  }
}
