import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import * as _ from 'lodash';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';
import { ProjectsService } from 'src/app/api/projects.service';

@Component({
  selector: 'app-project-creation',
  templateUrl: './project-creation.component.html',
  styleUrls: ['./project-creation.component.scss'],
})
export class ProjectCreationComponent {
  PagesPublicName = PagesPublicName;
  TabsPublicName = TabsPublicName;
  PagesName = PagesName;
  _ = _;
  constructor(public projectsService: ProjectsService, private router: Router) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((res: NavigationEnd) => {
        const url = res.urlAfterRedirects;
        if (url.includes(PagesName.DefineProject)) {
          this.projectsService.setActiveStep(0)
        }
        if (url.includes(PagesName.ChooseWorkflow)) {
          this.projectsService.setActiveStep(1)
        }
        if (url.includes(PagesName.ChooseDiseases)) {
          this.projectsService.setActiveStep(2)
        }
        if (url.includes(PagesName.ChooseDataTypes)) {
          this.projectsService.setActiveStep(3)
        }
        if (url.includes(PagesName.ConfigureWorkflow)) {
          this.projectsService.setActiveStep(4)
        }
        if (url.includes(PagesName.WorkflowInProgress)) {
          this.projectsService.setActiveStep(5)

        }
      })
  }

}
