export const PagesPublicName = {
  FileManager: 'Manage Files',
  ProjectManager: 'Manage Projects',
  TeamsManager: 'Manage Teams',
  WorkflowDebugger: 'Workflow Debugger',
  WorkflowEditor: 'Workflow Editor',
  MethodEditor: 'Method Editor',
  Workflow: 'Workflow Editor',
  ProjectCreation: 'Project Creation',
  UserCreation: 'Create New Users',
  NotificationsUnread: 'Recent Updates',
  NotificationsAllRead: 'All Caught Up !',
  Notifications: 'Notifications',
  Home: 'My Home',
  Account: 'Account',
};
