import { VizSubType } from 'src/app/shared/helpers/vizSubType';
import { WHITE_ON_BLACK_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';

function LightenDarkenColor(col, amt) {
  let usePound = false;
  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }
  let num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}
// const col1 = '#DEEFB7'; // lime
// const col2 = '#04ADA3'; // dark blue mims
// const col3 = '#b6b8d6'; // violet

// const col4 = '#414288'; // dark violet
// const col5 = '#C3C3C3'; // grey
// const col6 = '#EFF6EE'; // grey light
// const col7 = '#d8b0ff'; // violet light
// const col8 = '#acf9c5'; // light blue mims
// const col9 = '#171738'; // dark
// const col10 = '#F6BD60'; // yellow
// const col11 = '#f1f1f1'; // white
// const col12 = 'rgb(49, 198, 189)'; //bleu mims\

// const col30 = '#f4f3f3';
// const col31 = '#dfdfdf';
// const col32 = '#bfd8d5';
// const col33 = '#b1bed5';
// const col34 = '#373640';
// const col35 = '#63686e';
// const col13 = LightenDarkenColor(col30, 0);
// const col14 = LightenDarkenColor(col31, 0);
// const col15 = LightenDarkenColor(col32, 0);
// const col16 = LightenDarkenColor(col33, 0);
// const col17 = LightenDarkenColor(col34, 30);
// const col18 = LightenDarkenColor(col35, 30);
// const col19 = LightenDarkenColor(col32, -20);
// const col20 = LightenDarkenColor(col33, -20);
// const col21 = LightenDarkenColor(col30, 70);
// const col22 = LightenDarkenColor(col31, 70);
// const col23 = LightenDarkenColor(col32, 70);
// const col24 = LightenDarkenColor(col33, 70);
// export const colorscale4 = [
//     [0.0, col30],
//     [0.33, col31],
//     [0.66, col32],
//     [1.0, col35]
// ];

// export const colorscale2 = [
//     [0, '#440154'],
//     [0.3764705882352941, '#2c728e'],
//     [0.5019607843137255, '#21918c'],
//     [0.7529411764705882, '#5ec962'],
//     [1, 'white']
// ];

// export const colorscale4 = [
//     [0.0, '#E3E2E6'],
//     [0.25, '#607AC8'],
//     [0.50, '#4EC1BD'],
//     [0.75, '#847B80'],
//     [1, '#303239']
// ];

// export const colorscale4 = [
//     [0.0, col1],
//     [0.50, col2],
//     [1, col4]
// ]
// export const colorscale3 = [
//     [0, 'white'],
//     [1, col12]
// ];

// export const colorscalebw = [
//     [0, 'white'],
//     [1, '#363940']
// ];

// export const colorscale = [
//     ['0.0', col11],
//     ['0.50', col7],
//     ['1.0', col9]
// ];

// export const dense = [
//     ['0.0', 'rgb(234,240,240)'],
//     ['0.1', 'rgb(191,221,229)'],
//     ['0.2', 'rgb(156,201,226)'],
//     ['0.3', 'rgb(129,180,227)'],
//     ['0.4', 'rgb(115,154,228)'],
//     ['0.5', 'rgb(117,127,221)'],
//     ['0.6', 'rgb(120,100,202)'],
//     ['0.7', 'rgb(191,74,175)'],
//     ['0.8', 'rgb(113,50,114)'],
//     ['0.9', 'rgb(100,31,104)'],
//     ['1', 'rgb(80,20,66)'],
// ];

export const lime = [
  [0, '#f4fce3'],
  [0.11111111, '#e9fac8'],
  [0.22222222, '#d8f5a2'],
  [0.33333333, '#c0eb75'],
  [0.44444444, '#a9e34b'],
  [0.55555555, '#94d82d'],
  [0.66666666, '#82c91e'],
  [0.77777777, '#74b816'],
  [0.88888888, '#66a80f'],
  [1, '#5c940d'],
];
export const yellow = [
  [0, '#fff9db'],
  [0.11111111, '#fff3bf'],
  [0.22222222, '#ffec99'],
  [0.33333333, '#ffe066'],
  [0.44444444, '#ffd43b'],
  [0.55555555, '#fcc419'],
  [0.66666666, '#fab005'],
  [0.77777777, '#f59f00'],
  [0.88888888, '#f08c00'],
  [1, '#e67700'],
];
export const red = [
  [0, '#fff5f5'],
  [0.11111111, '#ffe3e3'],
  [0.22222222, '#ffc9c9'],
  [0.33333333, '#ffa8a8'],
  [0.44444444, '#ff8787'],
  [0.55555555, '#ff6b6b'],
  [0.66666666, '#fa5252'],
  [0.77777777, '#f03e3e'],
  [0.88888888, '#e03131'],
  [1, '#c92a2a'],
];
export const cyan = [
  [0, '#e3fafc'],
  [0.11111111, '#c5f6fa'],
  [0.22222222, '#99e9f2'],
  [0.33333333, '#66d9e8'],
  [0.44444444, '#3bc9db'],
  [0.55555555, '#22b8cf'],
  [0.66666666, '#15aabf'],
  [0.77777777, '#1098ad'],
  [0.88888888, '#0c8599'],
  [1, '#0b7285'],
];

export const col1 = '#087282';
export const col2 = '#CF367F';
export const col3 = '#21B7CF';
export const col4 = '#CFB90C';
export const col5 = '#82750E';
export const col6 = '#820143'; // rose fonce

export const colorscaleSteps = [
  [0, '#087282'],
  [0.167, '#087282'],
  [0.167, '#CF367F'],
  [0.333, '#CF367F'],
  [0.333, '#820143'],
  [0.5, '#820143'],
  [0.5, '#CFB90C'],
  [0.667, '#CFB90C'],
  [0.667, '#82750E'],
  [0.833, '#82750E'],
  [0.833, '#21B7CF'],
  [1, '#21B7CF'],
];

export function constructLayoutUsingTemplate(layout, importedTemplate) {
  layout.template = importedTemplate;
  return layout;
}
export function addCustomColorscale(data, importedColorscale) {
  if (data[0].marker) {
    data[0].marker.colorscale = importedColorscale;
    if (data[0].marker.colorbar) {
      data[0].marker.colorbar.thickness = 20;
      data[0].marker.colorbar.outlinewidth = 0.1;
    }
  }

  data[0].colorscale = importedColorscale;
  return data;
}

export function templateLayout(thumbnail, type, title?) {
  const obj = {
    colorway: [col1, col2, col3, col4, col5],
    title: {
      font: {
        family: 'Montserrat',
        size: 1,
        color: 'white',
      },
    },
    font: {
      family: 'Montserrat',
    },
    margin: { l: 30, r: 30, t: 30, b: 30 },
    xaxis: {
      ticksuffix: '   ',
      automargin: true,
    },
    yaxis: {
      tickprefix: '   ',
      automargin: true,
    },
    coloraxis: { autocolorscale: true },
    colorscale: { sequential: { colorscale: cyan } },
    showlegend: true,
    hovermode: 'closest',
  };
  return obj;
}

export function template(thumbnail, type, title?) {
  return {
    data: {},
    layout: templateLayout(thumbnail, type, title ? title : null),
  };
}
