
export enum TabsPublicName {
  SharedProjects = 'Projects shared with me',
  MyProjects = 'My projects',
  MyFiles = 'My files',
  SharedFiles = 'Files shared with me',
  DeletedFiles = 'Deleted files',
  DeletedProjects = 'Deleted projects',
  MyNotifications = 'Notifications',
  DeletedNotifications = 'Deleted notifications'
}
