import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorage } from 'ngx-webstorage';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelsFileShortList } from '../model/modelsFileShortList';
import { ModelsFile } from '../model/modelsFile';
import { ModelsNewID } from '../model/modelsNewID';
import { ModelsPostObject } from '../model/modelsPostObject';
import { ModelsPostGroup } from '../model/modelsPostGroup';
import { ModelsGroupShortList } from '../model/modelsGroupShortList';
import { environment } from '../../environments/environment';
import { FileSystemState } from '../pages/login/login-http-service/login-http.service';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { LocalService } from './local-storage.service';

export interface PathPartsModel {
  name: string;
  id: string;
}
export interface PathModel {
  path: string;
  parts: Array<PathPartsModel>;
}

@Injectable({ providedIn: 'root' })
export class FilesService {
  public basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  private reportChunkProgress: number;
  @LocalStorage() user;

  constructor(private readonly http: HttpClient, private localService: LocalService) { }

  filePatch(fileId: string, newValue: string, field: string): Observable<any> {
    let body = {};
    body[field] = newValue;
    return this.http.patch<any>(
      `${this.basePath}/mas/fs/files/${fileId}`,
      body
    );
  } 
  
  filegroupPatch(fileId: string, newValue: string, field: string): Observable<any> {
    let body = {};
    body[field] = newValue;
    return this.http.patch<any>(
      `${this.basePath}/mas/fs/filegroups/${fileId}`,
      body
    );
  } 
  
  retrieveFileGroup(groupId: string): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/mas/fs/filegroups/${groupId}`,
      {}
    );
  }

  downloadBlobFile(fileId: string, dataType?: string): Observable<any> {
    let queryParameters = new HttpParams({});
    if (!!dataType) {
      queryParameters = queryParameters.set('data_type', dataType as any);
    }
    return this.http.get(`${this.basePath}/mas/fs/files/${fileId}/data`, {
      params: queryParameters,
      responseType: 'blob',
    });
    // .subscribe(
    //   res => {
    //     return res;
    //   },
    //   error => {
    //     return alert(error.error.message);
    //   }
    // );
  }

  downloadFile(fileId: string, dataType?: string): Observable<any> {
    let queryParameters = new HttpParams({});
    if (!!dataType) {
      queryParameters = queryParameters.set('data_type', dataType as any);
    }
    return this.http.get<any>(`${this.basePath}/mas/fs/files/${fileId}/data`, {
      params: queryParameters,
    });
  }


  compressFolder(folderId: string): Observable<any> {
    let body = {
      "file_ids": [
        folderId
      ]
    }
    return this.http.post<any>(`${this.basePath}/mas/fs/files/compress`, body);
  }

  downloadPlotFile(
    fileId: string,
    projectId: string,
    projectOwnerUserId: string
  ): Observable<any> {
    const headers = {};
    let params = new HttpParams({});
    params = params.set('project_id', projectId);
    params = params.set('file_id', fileId);
    params = params.set('user_id', projectOwnerUserId);
    return this.http.get<any>(`${this.basePath}/wdv/download`, {
      headers,
      params,
    });
  }

  getPathFromFileId(fileId: string): Observable<PathModel> {
    if (
      fileId === 'root' ||
      fileId === '' ||
      fileId === 'list' ||
      fileId === null
    ) {
      return new Observable((observer) => {
        observer.next({ path: null, parts: [] });
      });
    } else {
      return this.http.get<PathModel>(
        `${this.basePath}/mas/fs/files/${fileId}/path`
      );
    }
  }

  postFile(
    fileId: string,
    body: ModelsPostObject,
    observe: any,
    dataType?: string
  ): Observable<any> {
    let queryParameters = new HttpParams({});
    if (!!dataType) {
      queryParameters = queryParameters.set('data_type', dataType as any);
    }
    return this.http.post<ModelsNewID>(
      `${this.basePath}/mas/fs/files/${fileId}`,
      body,
      {
        params: queryParameters,
        observe,
      }
    );
  }

  isCsv(name) {
    return !!name.match(/.csv$/i);
  }

  postMetaDataFile(fileId: string, body: any, observe: any): Observable<any> {
    let headers = new HttpHeaders({});
    let type = '';
    if (this.isCsv(body.name)) {
      type = 'text/csv';
    } else {
      type = 'application/json';
    }
    headers = headers.set('Content-Type', type);
    return this.http.patch<any>(
      `${this.basePath}/mas/fs/files/${fileId}/metadata`,
      body,
      {
        observe,
        headers,
      }
    );
  }

  addToGroup(groupId: string, file_id: string): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/mas/fs/filegroups/${groupId}/files/${file_id}`,
      {}
    );
  }

  postGroup(body: ModelsPostGroup, observe: any): Observable<any> {
    return this.http.post<ModelsNewID>(
      `${this.basePath}/mas/fs/filegroups`,
      body
    );
  }

  deleteGroup(groupId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/mas/fs/filegroups/${groupId}`,
      {}
    );
  }

  deleteFile(fileId: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/mas/fs/files/${fileId}`, {});
  }

  removeFileFromGroup(groupId: string, fileId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/mas/fs/filegroups/${groupId}/files/${fileId}`,
      {}
    );
  }

  getFile(fileId: string): Observable<ModelsFile> {
    return this.http.get<any>(`${this.basePath}/mas/fs/files/${fileId}`);
  }
  restore(fileId: string): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/mas/fs/files/${fileId}/restore`,
      {}
    );
  }
  permanentelyDelete(fileId: string): Observable<any> {
    let queryParameters = new HttpParams({});
    const notrash = true;
    queryParameters = queryParameters.set('notrash', notrash as any);
    return this.http.delete<any>(`${this.basePath}/mas/fs/files/${fileId}`, {
      params: queryParameters,
    });
  }

  listFiles(
    sortBy?: string,
    filters?: string,
    limit?: number,
    page?: number
  ): Observable<ModelsFileShortList> {
    let queryParameters = new HttpParams({});
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    if (page !== undefined && page !== null) {
      queryParameters = queryParameters.set('page', (page - 1) as any);
    }
    return this.http.get<ModelsFileShortList>(`${this.basePath}/mas/fs/files`, {
      params: queryParameters,
    });
  }
  countFiles(
    sortBy?: string,
    filters?: string,
    limit?: number
  ): Observable<any> {
    let queryParameters = new HttpParams({});
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    return this.http.get<any>(`${this.basePath}/mas/fs/files/count`, {
      params: queryParameters,
    });
  }

  listFilesFromUrl(url): Observable<ModelsFileShortList> {
    return this.http.get<ModelsFileShortList>(url);
  }

  listFolder(folderId: string): Observable<ModelsFileShortList> {
    return this.http.get<ModelsFileShortList>(
      `${this.basePath}/mas/fs/files/${folderId}/ls`,
      {}
    );
  }

  getChildId(parentId, childName) {
    return this.http.get(
      `${this.basePath}/mas/fs/internal/get-child-id/${parentId}/${childName}`
    );
  }
  listGroup(
    groupId: string,
    sortBy?: string,
    filters?: string,
    limit?: number
  ): Observable<ModelsFileShortList> {
    let queryParameters = new HttpParams({});

    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    return this.http.get<ModelsFileShortList>(
      `${this.basePath}/mas/fs/filegroups/${groupId}/files`,
      {
        params: queryParameters,
      }
    );
  }


  moveFile(fileId: string, destinationId: string): Observable<any> {
    return this.http.patch<any>(
      `${this.basePath}/mas/fs/files/${fileId}/move?dest_id=${destinationId}`,
      {}
    );
  }


  getChunkData(file, currentChunk, chunkSize, totalNbChunks) {
    let dataToUpload: any;
    let contentRange = '';
    const offset = currentChunk * chunkSize;

    if (currentChunk < totalNbChunks - 1) {
      dataToUpload = file.slice(offset, offset + chunkSize);
      contentRange =
        'bytes ' + offset + '-' + (offset + chunkSize) + '/' + file.size;
    } else {
      dataToUpload = file.slice(offset, file.size);
      contentRange = 'bytes ' + offset + '-' + file.size + '/' + file.size;
    }

    return { dataToUpload, contentRange };
  }

  uploadChunk(observer, file, currentChunk, uploadSessionId, headers, observe) {
    const chunkSize = file.size <= 100000000 ? file.size : 5000000;
    const totalNbChunks = Math.ceil(file.size / chunkSize);
    const { dataToUpload, contentRange } = this.getChunkData(
      file,
      currentChunk,
      chunkSize,
      totalNbChunks
    );
    headers = headers.set('Content-Range', contentRange);
    this.http
      .patch<any>(
        `${this.basePath}/mas/fs/upload/${encodeURIComponent(
          String(uploadSessionId)
        )}`,
        dataToUpload,
        {
          headers,
          observe,
        }
      )
      .subscribe(
        (res) => {
          this.reportChunkProgress = ((currentChunk + 1) / totalNbChunks) * 100;
          observer.next(this.reportChunkProgress);
          if (this.reportChunkProgress < 100) {
            this.uploadChunk(
              observer,
              file,
              currentChunk + 1,
              uploadSessionId,
              headers,
              observe
            );
          } else {
            observer.complete();
          }
        },
        (error) => {
          observer.error(error)
        }
      );
  }

  upload(uploadSessionId: string, file?: Blob, observe?: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('accept', 'application/json');
    headers = headers.set('Content-Type', 'application/octet-stream');
    return new Observable((observer) => {
      this.uploadChunk(observer, file, 0, uploadSessionId, headers, observe);
    });
  }

  listSystemFiles(folderId) {
    let queryParameters = new HttpParams({});
    queryParameters = queryParameters.set(
      'filters',
      `parent="${folderId}",ownedByMe=true,sharedWithMe=true,sharedWithMyUserGroups=true`
    );

    const headers = new HttpHeaders({
      'X-User-Id': environment.name == 'prod' ? '9f8d47e8-d99a-455f-83b2-6e56ae64d330' : '41632a54-fe86-49a8-aaf6-fc110f96f5cf',
    });

    return this.http.get<ModelsFileShortList>(`${this.basePath}/mas/fs/files`, {
      params: queryParameters,
      headers,
    });
  }

  getSystemFile(fileId: string) {
    const headers = new HttpHeaders({
      'X-User-Id': environment.name == 'prod' ? '9f8d47e8-d99a-455f-83b2-6e56ae64d330' : '41632a54-fe86-49a8-aaf6-fc110f96f5cf',
    });

    return this.http.get<any>(`${this.basePath}/mas/fs/files/${fileId}`, {
      headers,
    });
  }
  
  listGroups(
    sortBy?: string,
    filters?: string,
    limit?: number
  ): Observable<any> {
    let queryParameters = new HttpParams({});

    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    return this.http.get<ModelsGroupShortList>(
      `${this.basePath}/mas/fs/filegroups`,
      {
        params: queryParameters,
      }
    );
  }

  listAllGroups(
    sortBy?: string,
    filters?: string,
    limit?: number
  ): Observable<ModelsFileShortList> {
    let queryParameters = new HttpParams({});
    const user = this.localService.getFromLocalStorage('user')
    const file_systems = _.get(user, 'file_systems')
    const available_countries = _.filter(file_systems, function (o) { return o.accessState !== FileSystemState.Disabled });
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    let forkJoinObject = {}
    available_countries.forEach(element => {
      let url = _.get(element, 'url', this.basePath)
      let accessToken = _.get(element, 'access_token', null)
      _.set(forkJoinObject, element.value, this.listGroupsByCountry(queryParameters, url, accessToken))
    });
    return forkJoin(forkJoinObject)

  }
  listGroupsByCountry(queryParameters, url, accessToken) {
    let headers = new HttpHeaders({});
    if (accessToken) {
      headers = headers.set('X-Access-Token', accessToken);
    }
    return this.http.get<ModelsFileShortList>(
      `${url}/mas/fs/filegroups/`,
      {
        params: queryParameters,
        headers
      }
    )
  }
  listGroupFromUrl(url): Observable<ModelsFileShortList> {
    return this.http.get<ModelsFileShortList>(url);
  }
  listGroupsFromUrl(url): Observable<ModelsGroupShortList> {
    return this.http.get<ModelsGroupShortList>(url);
  }

}
