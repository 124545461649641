<div class="h-100 w-100 p-4">
  <div class="card mx-auto">
    <div class="card-body">
      <div *ngIf="inputsInWorkflow.value && !getFieldObjectsLength()">
        The chosen solution doesn't require <strong>disease</strong> selection.
      </div>
      <app-spinner *ngIf="inputsInWorkflow.value === null"></app-spinner>

      <form *ngIf="getForm() && getFieldObjectsLength()" class="ml-3" [formGroup]="getForm()">
        <h4>Select a maximum of 2 diseases</h4>
        <ul class="mt-4 inputs-form list-unstyled">
          <li class="my-2">
            <app-input-field
              *ngFor="let fieldObject of getFieldObjects()"
              (valueSelected)="onCheck()"
              [writeError]="true"
              [valueUpdate]="fieldObject.retrievedValue"
              [parentForm]="getForm()"
              [inputJson]="fieldObject"
            >
            </app-input-field>
          </li>
        </ul>
      </form>
      <div *ngIf="filegroupId" class="p-2 mb-3 small text-uppercase text-muted font-weight-bold">
        Number of subjects records : <span class="text-primary">{{ getFilteredValue() }}</span>
      </div>
    </div>
    <div class="btn-container">
      <div class="btn btn-light border" (click)="previous()" type="submit">
        <span class="text-uppercase small">Previous</span>
      </div>
      <div
        *ngIf="getForm()"
        class="btn btn-dark"
        ngbTooltip="{{ getFormError() }}"
        tooltipClass="custom-tooltip"
        placement="left"
        [class.disabled]="!getFormValidity()"
        (click)="next()"
        type="submit"
      >
        <span class="text-uppercase small">Next</span>
      </div>
      <div *ngIf="!getForm()" class="btn btn-dark" (click)="next()" type="submit">
        <span class="text-uppercase small">Next</span>
      </div>
    </div>
  </div>
</div>
