import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { ProjectsService, WorkflowInput, getVisible, isBool, isArray, isMultiple } from 'src/app/api/projects.service';
import { InputJson, ShouldHaveAtLeastOneAdditionalCheck, ShouldHaveMax2AdditionalChecks } from 'src/app/shared/helpers/formattedInput';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { PagesPublicName } from 'src/app/shared/helpers/pagesPublicName';

@Component({
  selector: 'app-project-diseases',
  templateUrl: './project-diseases.component.html',
  styleUrls: ['../project-creation.component.scss', './project-diseases.component.scss']
})
export class ProjectDiseasesComponent implements OnInit, OnDestroy {
  @Input() projectId?;
  @Input() workflowId?;
  workflow;
  tagName = 'is_diseases'
  submitted = false;
  inputsInProject = new BehaviorSubject<any>([]);
  inputsInWorkflow = new BehaviorSubject<any>(null);
  form: FormGroup
  fieldObjects = new BehaviorSubject<any>([]);
  PagesPublicName = PagesPublicName
  workflowInputsSubscription: Subscription;
  projectInputsSubscription: Subscription;
  filteredSubjects = new BehaviorSubject<Number>(0)
  diseasesArray = []
  filegroupId
  diseaseMetadata;
  dataTypeMetadata
  fieldName
  constructor(public projectsService: ProjectsService,
    private router: Router,
    private route: ActivatedRoute,
    private layout: LayoutOrganizationService) {
    if (!this.projectId)
      _.set(this, 'projectId', this.route.snapshot.paramMap.get('projectId'));
    if (!this.workflowId)
      _.set(this, 'workflowId', this.route.snapshot.paramMap.get('workflowId'));
    if (this.route.snapshot.paramMap.get('country')) {
      _.set(this.projectsService, 'projectStorageCountry', this.route.snapshot.paramMap.get('country'))
    }
    this.route.queryParams.subscribe((params) => {
      if (params['fromDebugger'] === 'false' || !_.get(params, 'fromDebugger')) {
        projectsService.setFromDebugger(false)
      } else if (params['fromDebugger'] === 'true') {
        projectsService.setFromDebugger(true)
      }
      _.set(this, 'filegroupId', params['filegroupId']);
      _.set(this, 'diseaseMetadata', params['diseaseMetadata']);


    })
  }
  onCheck() {

    let answer = _.get(this.getForm(),'controls['+ this.fieldName + '].value') 
    let body = {
      filegroup_id: this.filegroupId,
    }
    let selectedDiseasesArray = []
    this.diseasesArray.forEach((element, index) => {
      if (_.nth(answer, index) === true) {
        selectedDiseasesArray.push(element)
      }
    });
    if (selectedDiseasesArray.length){
      _.set(body, 'disease_filters', _.join(selectedDiseasesArray))

    }
    this.diseaseMetadata = _.join(selectedDiseasesArray)
    this.projectsService.searchFiles(body).subscribe((res) => {
      this.filteredSubjects.next(_.get(res, 'counts.patients'))
    })
  }

  getFilteredValue() {
    return _.get(this, 'filteredSubjects.value')
  }
  ngOnInit(): void {
    this.getWorkflowInputs()
    this.workflowInputsSubscription = this.inputsInWorkflow.subscribe((res) => {
      let fieldObjects = []
      this.getProjectInputs(this.tagName)

      if (_.isEmpty(res)) {
        this.fieldObjects.next(fieldObjects)
        return
      }
      this.form = this.projectsService.createEmptyForm(res);

      for (const [key, value] of Object.entries(res)) {
        let w = value as WorkflowInput
        this.diseasesArray = _.words(_.get(w, 'tags.allowed_values', ''), /[^,]+/g)
        this.fieldName = key
        let input: InputJson = {
          formControlName: key,
          originalJson: w,
          type: _.get(w, 'type'),
          description: 'You can select 1 or 2 items in the list below :',
          allowed_values: _.words(_.get(w, 'tags.allowed_values', ''), /[^,]+/g),
          allowed_values_descriptions: _.words(_.get(w, 'tags.allowed_values_descriptions', ''), /[^,]+/g),
          is_array: _.get(w, 'tags.is_array', false),
          fitToContent: true,
          iconClassName: 'fa fa-info-circle',
          multiple_selection: _.get(w, 'tags.multiple_selection', false),
          validations: _.get(w, 'optional') ? [] : [ShouldHaveAtLeastOneAdditionalCheck, ShouldHaveMax2AdditionalChecks],
          retrievedValue: new BehaviorSubject<any>(undefined),
          tagname: this.tagName
        }
        fieldObjects.unshift(input)
        setTimeout(() => { this.onCheck() }, 1)
      }
      this.fieldObjects.next(fieldObjects)

    })
    this.projectInputsSubscription = this.inputsInProject.subscribe((res) => {
      if (_.isEmpty(res)) {
        return
      }
      this.projectsService.setRetrievedValue(res, this.getFieldObjects())
      setTimeout(()=>{this.onCheck()},1)
    })
  }

  filterWorkflowInputs(inputs) {
    let newInputs = _.pickBy(inputs, (value, key) => (_.includes(key, 'disease') || _.includes(key, 'Disease')));
    newInputs = _.pickBy(newInputs, getVisible);
    newInputs = _.pickBy(newInputs, isArray);
    newInputs = _.pickBy(newInputs, isMultiple);
    return newInputs
  }

  getProjectInputs(tagName) {
    this.projectsService.projectGet(this.projectId).subscribe((res) => {
      if (!this.projectsService.getProjectName()) {
        this.projectsService.setProjectName(res.title)
      }
      let inputsInProject = _.get(res, 'input_list', [])
      inputsInProject = this.projectsService.filterProjectInputs(inputsInProject, tagName)
      this.inputsInProject.next(inputsInProject)
    }, () => {
      this.layout.toast('Error when getting projet inputs', null, 8000, '', 'warning')
    })
  }

  getWorkflowInputs() {
    this.projectsService.getWorkflow(this.workflowId).subscribe((res) => {
      this.workflow = res
      let inputs = this.filterWorkflowInputs(_.get(res, 'inputs', {}))
      this.inputsInWorkflow.next(inputs)

    }, () => {
      this.inputsInWorkflow.next({})
      this.layout.toast('Error when getting workflow inputs', null, 8000, '', 'warning')
    })
  }

  getFormError() {
    return this.getFormValidity() ? '' : 'Please verify your form.'
  }
  getWorkflowName() {
    return _.get(this, 'workflow.name', null)
  }
  getForm() {
    return _.get(this, 'form', null)
  }
  getFormValidity() {
    return _.get(this, 'form.status', false) === 'VALID'
  }
  getFieldObjects() {
    return _.get(this, 'fieldObjects.value', [])
  }
  getFieldObjectsLength() {
    return _.get(this, 'fieldObjects.value.length', [])
  }
  getInputsInProjects() {
    return _.get(this, 'inputsInProject.value', [])
  }

  next() {
    this.submitted = true
    this.projectsService.updateAllInputs(this.projectId, this.getFieldObjects(), this.getInputsInProjects(), this.getForm(), this.tagName).then(() => {
      this.router.navigate([PagesName.ProjectCreation, PagesName.ChooseDataTypes, this.projectId, this.workflowId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId: this.filegroupId, diseaseMetadata: this.diseaseMetadata } })
    }, () => {
      this.layout.toast('Error when updating inputs', null, 8000, null, 'danger')
    })
  }

  previous() {
    this.submitted = true
    this.router.navigate([PagesName.ProjectCreation, PagesName.ChooseWorkflow, this.projectId, this.workflowId, this.projectsService.getProjectStorageCountry()], { queryParams: { fromDebugger: this.projectsService.getFromDebugger(), filegroupId: this.filegroupId } })
  }

  ngOnDestroy() {
    this.workflowInputsSubscription.unsubscribe()
    this.projectInputsSubscription.unsubscribe()
  }

}
