<div class='h-100 d-flex flex-column' *ngIf='item'>
    <div *ngIf="!item.capabilities.can_share" class='my-3'>
        <small>
            <div class="text-danger">You can't share this item.</div>
        </small>
    </div>
    <div *ngIf="item.capabilities.can_share" class='d-flex flex-column'>
        <form (ngSubmit)="submitForm()" [formGroup]="searchUser">
            <small>
                <div class="my-2"> <label for="email">

                        <span class='text-uppercase text-info'>Enter email address or usergroup name</span>

                    </label>

                    <div #autocompleteField [class.field-error]="hasAtLeastOneError('email')"
                        class="input-icon-container d-flex align-items-center flex-nowrap">
                        <ng-autocomplete (keydown)='resetEmailError()' minQueryLength='1' debounceTime='300'
                            [data]="autocompleteData" [searchKeyword]="keyword" [itemTemplate]="itemTemplate"
                            (selected)="manuallyTriggerValidation()" [notFoundText]="notFoundTemplate"
                            class="input-email form-control" [class.text-error]="hasAtLeastOneError('email')"
                            type="search" formControlName="email">
                        </ng-autocomplete>
                        <div class='d-flex align-items-center' ngbTooltip="{{getTooltipContent('email')}}"
                            tooltipClass="custom-tooltip" placement="left">
                            <i [class.error]="(!email.valid || userNotFound) && (this.email.dirty || this.email.touched)"
                                class="user-icon far fa-user-check" aria-hidden="true"></i>
                        </div>
                    </div>
                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.value"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                    </ng-template>
                </div>
                <div class="my-2">
                    <label for="permission">

                        <span [ngbTooltip]="describeAllRoles()" tooltipClass="custom-tooltip" placement="right"
                            class='cursor-info text-uppercase text-info'>Select role</span>
                    </label>
                    <div class="input-group">

                        <select #select [class.invalid]='!permission.valid && (permission.dirty || permission.touched)'
                            formControlName="permission" class="permission-select form-control">

                            <option class="text-capitalize" [value]="RoleType.Reader">
                                {{RoleType.Reader}}
                            </option>


                            <option class="text-capitalize" *ngIf='type === ItemType.File   '
                                [value]="RoleType.Downloader"> {{ RoleType.Downloader}}
                            </option>
                            <option class="text-capitalize"  *ngIf='type !== ItemType.FileGroup' [value]="RoleType.Writer"> {{ RoleType.Writer}}</option>
                            <option class="text-capitalize" *ngIf='type !== ItemType.FileGroup' [value]="RoleType.Contributor">
                                {{ RoleType.Contributor}}
                            </option>
                        </select>
                        <button class="btn btn-add btn-dark" type="submit" value="Add User"
                            [disabled]="!searchUser.valid || pending">add</button>
                    </div>

                </div>

            </small>
        </form>

    </div>
    <div class='flex-fill user-list' *ngIf="item.permissions.length > 1 || item.capabilities.can_share">
        <table aria-describedby="shared users table" role='presentation' class="table">
            <tbody>
                <tr class="shared" *ngFor="let permission of item.permissions" [hidden]="permission.role === 'owner'">
                    <td>
                        <i *ngIf="permission.grantee.kind === UserType.User" class="user-icon far fa-user text-dark"
                            aria-hidden="true"></i>
                        <i *ngIf="permission.grantee.kind === UserType.Usergroup"
                            class="user-icon far fa-users text-dark" aria-hidden="true"></i>
                    </td>
                    <td>
                        <span class='font-weight-bold'>
                            {{permission.grantee.kind === UserType.User ? permission.grantee.email : ''}}
                            {{permission.grantee.kind === UserType.Usergroup ? permission.grantee.name : ''}}

                        </span>
                    </td>
                    <td>

                        <span class="text-capitalize  cursor-info" ngbTooltip=" {{permission.role_description}}"
                            tooltipClass="custom-tooltip" placement="bottom">
                            {{permission.role}}</span>

                    </td>
                    <td>
                    </td>
                    <td><button class="btn btn-link" *ngIf="item.capabilities.can_share"
                            [hidden]="permission.grantee.id === user.id"
                            (click)="removePermission(permission.id, item.id)">
                            <i class="far user-icon text-dark fa-times" aria-hidden="true"></i>
                        </button></td>
                </tr>

                <tr *ngFor="let user of shareService.usersSelected; let i = index">
                    <td> <i class="user-icon far fa-paper-plane text-dark" aria-hidden="true"></i>
                    </td>
                    <td>
                        <span class='font-weight-bold'>{{user.email}}</span>
                    </td>
                    <td>
                        <span class="text-capitalize  cursor-info" ngbTooltip=" {{user.role_description}}"
                            tooltipClass="custom-tooltip" placement="bottom">
                            {{user.role}} </span>
                    </td>
                    <td class='text-info'>
                        confirm to allow access
                    </td>
                    <td><button class="btn btn-link" (click)="removeInvited(i)">
                            <i class="far user-icon text-dark fa-times" aria-hidden="true"></i>
                        </button></td>
                </tr>

            </tbody>
        </table>
    </div>
</div>