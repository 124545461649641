<div *ngIf="displayedProjects" id="files-content" class="d-flex flex-column footer-margin">
  <div *ngFor="let project of displayedProjects " >
    <app-project-list [selected]='projectManagerService.isBulkSelected(project.id)' [project]="project">
    </app-project-list>
  </div>
  <div class='noProjectMessage empty-display w-100 justify-content-center align-items-center d-flex font-weight-bold'
    *ngIf='!displayedProjects.length'>
    <p *ngIf="viewTab == TabsName.MyProjects">
      All your projects will display here.</p>
    <p *ngIf="viewTab == TabsName.SharedProjects">
      All projects shared with you will display here.
    </p>
    <p *ngIf="viewTab == TabsName.DeletedProjects">
      All deleted projects will display here.
    </p>
  </div>
</div>