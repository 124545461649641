import { AfterViewInit, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Dashboard2Service } from '../../dashboard2.service';
import * as _ from 'lodash';
import { VizType } from 'src/app/shared/helpers/vizType';
import { VizSubType } from 'src/app/shared/helpers/vizSubType';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { BigNumberPipe } from 'src/app/mims-ui-lib/core/pipes/big-number.pipe';
declare var Plotly: any;

@Component({
  selector: 'app-viz2',
  templateUrl: './viz2.component.html',
  styleUrls: ['./viz2.component.scss'],
})
export class Viz2Component implements OnInit, AfterViewInit {
  @Input() graphId;
  @Input() projectId;
  @Input() static? = false;
  @Input() dashboardId;
  @Output() showChildren = new BehaviorSubject<any>(null);
  @Output() webGlContexts = new BehaviorSubject<any>({ graphId: undefined, value: 0 });
  VizType = VizType;
  VizSubType = VizSubType;
  BigNumberPipe = BigNumberPipe;
  title = undefined;
  interactive = false;
  formattedInteractions = new BehaviorSubject<any>(null);
  octet_stream;
  isPloted = false;
  destroyLoader = false;
  type = null;
  config = { staticPlot: true };
  content = new BehaviorSubject<any>(null);
  @ViewChild('vizRef', { static: false }) vizRef;

  constructor(private dashboard2Service: Dashboard2Service, public _DomSanitizer: DomSanitizer) {}
  ngOnInit() {
    if(this.projectId && this.dashboardId && this.graphId)
    this.dashboard2Service
      .downloadVizContent(this.projectId, this.dashboardId, this.graphId)
      .pipe(
        map((res) => {
          res = this.manageWebGlContexts(res);
          if (this.getWdvType(res) === VizType.Plot || this.getWdvType(res) === VizType.ColorscaledImage) {
            res = this.dashboard2Service.modifyData(res);
            res = this.dashboard2Service.modifyLayout(res);
          }
          return res;
        })
      )
      .subscribe((res) => {
        this.type = this.getWdvType(res);
        this.title = this.dashboard2Service.getTitleText(res);
        this.interactive = _.get(res, 'interactive', false);
        if (this.interactive) {
          this.dashboard2Service
            .downloadVizInterations(this.projectId, this.dashboardId, this.graphId)
            .pipe(
              map((res) => {
                return res;
              })
            )
            .subscribe((res) => {
              if (this.type === VizType.Select || this.type === VizType.ColorscaledImage || this.type === VizType.Image) {
                this.formattedInteractions.next(this.formatInteractions(res.interactions[0]));
              } else {
                this.formattedInteractions.next(res.interactions);
              }
              if (this.type === VizType.Plot) {
                this.attachEventListeners(this.formattedInteractions);
              }
            });
        }
        setTimeout(() => {
          this.content.next(res);
        }, 1);
      });
  }
  manageWebGlContexts(res) {
    let newRes = res;
    if (this.getWdvType(res) === VizType.Plot) {
      newRes.data.forEach((element) => {
        if (_.get(element, 'type') === VizSubType.Scatter && this.dashboard2Service.scatterGLPool > 0) {
          _.set(element, 'type', VizSubType.ScatterGl);
          if (this.webGlContexts.value.value < 2) {
            const newValue = this.webGlContexts.value.value + 1;
            this.webGlContexts.next({ graphId: this.graphId, value: newValue });
          }
        }
      });
    }
    return newRes;
  }
  ngAfterViewInit() {
    this.content.subscribe((value) => {
      if (!value) {
        return;
      }

      if (this.type === VizType.Plot || this.type === VizType.ColorscaledImage) this.plot();
      else this.hideLoader();
    });
  }
  getWdvType = (content) => {
    return _.get(content, 'wdv_type');
  };
  getVizRef() {
    return _.get(this.vizRef, 'nativeElement');
  }

  handleSelect(event) {
    this.showChildren.next({ after: this.graphId, children: event.value });
  }
  getTitleSelect() {
    return _.get(this.content, 'value.data[0].titleSelect', 'select');
  }
  getValues() {
    return _.get(this.content, 'value.data[0].values');
  }

  formatInteractions = (interactions) => {
    let result = [];
    interactions.map((el) => {
      result.push({ label: _.nth(el, 0), value: el.slice(1, el.length) });
    });
    return result;
  };
  sanitizedUrl(url) {
    let sanitizedUrl = this._DomSanitizer.bypassSecurityTrustUrl(url);
    return sanitizedUrl ? sanitizedUrl : '';
  }

  hideLoader() {
    this.isPloted = true;
    setTimeout(() => {
      this.destroyLoader = true;
    }, 150);
  }
  plot() {
    if (this.getVizRef())
      Plotly.plot(this.getVizRef(), this.content.value.data, this.content.value.layout, this.static ? this.config : {}).then(() => {
        this.hideLoader();
      });
  }
  getTableContentValues() {
    return _.get(this.content, 'value.data[0].values', []);
  }
  getTableDownloadButtonDescription() {
    return _.get(this.content, 'value.layout.mims_layout.download-button-description', 'Download CSV');
  }
  getTableDisplay() {
    return _.get(this.content, 'value.layout.mims_layout.display');
  }
  getSubType() {
    return _.get(this.content, 'value.data[0].type', null);
  }
  generateHref() {
    this.octet_stream = 'data:application/octet-stream,' + this.dashboard2Service.generateOctetStream(_.get(this.content, 'value.data'));
  }
  getTableHeaders() {
    return _.get(this.content, 'value.data[0].headers', []);
  }
  getTableCells() {
    return _.get(this.content, 'value.data[0].cells', []);
  }
  getJavascript() {
    return _.get(this.content, 'value.javascript', undefined);
  }
  attachEventListeners = (formattedInteractions) => {
    if (this.getJavascript()) {
      let stringifiedFunctions = _.join(_.valuesIn(this.getJavascript()), ' ');
      eval(stringifiedFunctions);
    }
    if (!this.getVizRef()) {
      return;
    }
    if (this.getSubType() === VizSubType.Treemap) {
      this.getVizRef().on('plotly_treemapclick', (event) => {
        let parent =  _.get(this.content, 'value.data[0].labels[0]')
        
        if (_.get(event, 'nextLevel') === parent) {
          this.showChildren.next({ after: this.graphId, children: [] });
          return;
        }
        let clickedPoint = event.points[0].pointNumber - 1;
        let childrenIds = _.nth(_.get(formattedInteractions, 'value[0]'), clickedPoint);
        this.showChildren.next({ after: this.graphId, children: childrenIds ? childrenIds : [] });
      });
    } else {
      this.getVizRef().on('plotly_click', (event) => {
        let clickedTrace = event.points[0].curveNumber;
        let clickedPoint = event.points[0].pointNumber;
        if (clickedTrace === undefined) {
          clickedTrace = 0;
        }
        let childrenIds;
        childrenIds = _.nth(_.nth(_.get(formattedInteractions, 'value'), clickedTrace), clickedPoint);
        if (clickedPoint === 0 && !childrenIds) {
          childrenIds = _.nth(_.get(formattedInteractions, 'value'), clickedTrace);
        }
        this.showChildren.next({ after: this.graphId, children: childrenIds ? childrenIds : [] });
      });
    }
  };
}
