import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { Status } from 'src/app/shared/helpers/status';
import * as _ from 'lodash';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { NetworkConnectionService } from 'src/app/shared/global-services/network-connection.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tempFile',
  styleUrls: ['../../../shared/layout/managers.scss'],
  templateUrl: './tempFile.component.html',
})
export class TempFileComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @SessionStorage() public selectedItems;
  @SessionStorage() viewType;
  public isSelected = false;
  Status = Status
  public iconClass: string[] = ['far fa-circle'];

  networkSubscription : Subscription;
 constructor(private networkConnectionService : NetworkConnectionService, private myFilesManager: MyFilesManagerService) { }

  ngOnInit(){
    this.networkSubscription = this.networkConnectionService.networkOk$.subscribe((observer)=>{
      if (observer === false){
        if (this.getStatus() === Status.Uploading || this.getStatus() === Status.NotStarted )
        this.setStatus(Status.NetworkError)
      }
    })
  }
  ngOnDestroy() {
    if (this.networkSubscription){
      this.networkSubscription.unsubscribe()
    }
  }
  getErrorStatus () {
    return this.getStatus() === Status.Error || this.getStatus() === Status.NetworkError
  }
  selectorSwitcher() {
    this.isSelected = !this.isSelected;
    if (this.isSelected) {
      this.selectedItems.push(this.item);
      this.iconClass = ['far fa-check-circle'];
    } else {
      this.selectedItems.splice(this.selectedItems.indexOf(this.item), 1);
      this.iconClass = ['far fa-circle'];
    }
  }
  getStatus() {
    return _.get(this, 'item.status', null)
  }
  getPauseStatus() {
    return this.getStatus() === Status.Paused
  }
  retry = () => {
    let fileToBeUploaded = this.item
    if (_.get(fileToBeUploaded, 'uploadId')) {
      this.myFilesManager.uploadASpecificFile(fileToBeUploaded, 0)
    } else {
      this.myFilesManager.uploadASpecificFile(fileToBeUploaded)
    }
  } 
  setStatus(value) {
    _.set(this, 'item.status', value)
  }

  getTooltip(name?) {
    if (name && name === 'pause') {
      return 'pause upload'
    }
    else if (name && name === 'resume') {
      return 'resume upload'
    }
  }

}
