import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ItemType } from 'src/app/api/share.service';
import { FileManager } from 'src/app/api/file-manager.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-share-file',
  styleUrls: ['./share-file.component.scss'],
  templateUrl: './share-file.component.html'
})
export class ShareFileComponent implements OnDestroy {
  ItemType = ItemType;
  TabsName = TabsName
  @Input() item: any;

  constructor(
    public fileManager: FileManager,
    public layout: LayoutOrganizationService,
    public router: Router,
    public route: ActivatedRoute,
    public myFilesService: MyFilesManagerService,
    public sharedFilesService: SharedFilesManagerService,
  ) { }

  ngOnDestroy() {
    if (this.route.snapshot.url[0].path !== PagesName.FileManager) {
      return;
    }

  }
}




