<form [formGroup]="groupsManagerService.groupCreationForm" class="d-flex flex-column custom-modal-content">
  <div>
    <input class="form-control" id="groupInput" type="text" formControlName="groupInput"
      placeholder="enter group's name" />
  </div>
  <div class="correct" *ngIf="groupInput.valid && (groupInput.dirty || groupInput.touched)">
    &#10003;
  </div>
  <div class="incorrect" *ngIf="!groupInput.valid && (groupInput.dirty || groupInput.touched)">
    &#10005; This field is required
  </div>
</form>