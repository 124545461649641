import { IOptionData } from '../option.interface';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

/** Default list item height */
export const DEFAULT_OPTION_HEIGHT = 40;

@Component({
  selector: 'mims-dropdown-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownListComponent<T> implements OnChanges, AfterViewInit {
  /** Data source */
  @Input() dataSource: IOptionData<T>[];

  /** Default list item height */
  @Input() optionHeight = DEFAULT_OPTION_HEIGHT;

  /**
   * Specifies the number of options visible in the list
   * This will be used to calculate list height
   */
  @Input() maxOptionsVisibleInList = 10;

  /** The selected option, must be from the same instance of dataSource */
  @Input() selectedOption: IOptionData<T>;

  /** Emits every time an option is selected */
  @Output() optionSelected = new EventEmitter<IOptionData<T>>();

  @ViewChild('viewport', { static: true }) viewport: ElementRef;

  constructor() {}

  get viewportHeight(): number {
    return this.dataSource?.length < this.maxOptionsVisibleInList
      ? this.dataSource?.length * this.optionHeight
      : this.maxOptionsVisibleInList * this.optionHeight;
  }

  ngOnChanges({ selectedOption }: SimpleChanges) {
    if (selectedOption && !selectedOption.isFirstChange()) {
      const index = this.dataSource?.indexOf(this.selectedOption);
      if (index > 0) {
        this.scrollToIndex(index);
      }
    }
  }

  ngAfterViewInit() {
    const selectedOptionIndex = this.dataSource?.indexOf(this.selectedOption);
    if (selectedOptionIndex >= 0) {
      this.scrollToIndex(selectedOptionIndex);
    }
  }

  onClick(event: any, option: IOptionData<T>) {
    if (event) {
      event.stopPropagation();
    }
    this.optionSelected.emit(option);
    this.selectedOption = option;
  }

  /** Scroll the option into view */
  scrollToIndex(index: number) {
    const offset = index * this.optionHeight - this.viewportHeight;
    if (offset >= 0) {
      // if need scroll, scroll to the top
      this.viewport.nativeElement.scrollTop = offset + this.viewportHeight;
    }
  }
}
