 <div class='user-container mx-auto d-flex justify-content-between flex-column'>
   <small>
     <ul class='list-unstyled'>
       <li *ngFor='let item of userDetails | keyvalue' class='my-2 '>
         <span class='text-muted text-uppercase'>{{getReadableKey(item.key)}} : </span>
         <span class='font-weight-bold text-uppercase'>{{getReadableValue(item.key,item.value)}}</span>
         <span *ngIf='item.key === "role"'>
           <span class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
             <i class=" far fa-edit" aria-hidden="true"></i>
           </span>
           <span class="dropdown-menu" aria-labelledby="dropdownMenuButton">
             <a (click)='handleChangePermission("Team Manager")' class="dropdown-item"><i class="far"
                 [class.fa-check]=' getUserRole() === "Team manager"'
                 [class.icon-blank]=' getUserRole() !== "Team manager"' aria-hidden="true"></i>
               Team manager</a>
             <a (click)='handleChangePermission("Member")' class="dropdown-item"><i class="far"
                 [class.fa-check]=' getUserRole() === "Member"' [class.icon-blank]=' getUserRole() !== "Member"'
                 aria-hidden="true"></i>
               Member</a>
           </span>
         </span>
       </li>
       <li class='my-4 '>
         <div class='btn btn-light border' (click)='handleRemove()'> <i class="far fa-minus-circle mx-2"
             aria-hidden="true"></i>
          Remove from team</div>
       </li>
       <li class='my-4 '>
         <div class='btn btn-light border' (click)='handleDelete()'> <i class="far fa-trash-alt mx-2" aria-hidden="true"></i>Delete account
           permanently</div>
       </li>
     </ul>
   </small>
 </div>
