import { Component, OnInit, Input, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { ItemType } from 'src/app/api/share.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { Router } from '@angular/router';
import { TeamsService } from '../teams.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['../../../shared/layout/managers.scss', './team.component.scss']
})
export class TeamComponent implements OnInit {
  @Input() team
  ItemType = ItemType
  showName = true
  constructor(public teamsService: TeamsService, private layout: LayoutOrganizationService, private router: Router) { }

  ngOnInit(): void {

  }


  @HostListener('document:keydown', ['$event'])
  handleKeyDownKeyboardEvent(event: KeyboardEvent) {
    const enter = 13;
    if (!this.showName) {
      const code = event.keyCode || event.which;
      switch (code) {
        case enter:
          this.showName = false
          break;
      }
    }
  }
  quitRenameMode(){
    this.showName = true
  }

  activateRenameMode() {
    this.showName = false
  }
  hasSubscription = () => {
    return _.get(this, 'team.subscription_id.length', 0) > 0;
  }
  getName = () => {
    return _.get(this, 'team.name', '');
  }
  getKey = () => {
    return _.get(this, 'team._key', '');
  }
  getCount = () => {
    return _.get(this, 'team.user_ids.length', 0);
  }
  manageSubscription = () => {
    this.teamsService.selectedTeam = this.team
    this.layout.open('modalManageSubscription')
    // this.layout.toast('<b>Future feature</b>. Please <b>communicate</b> with us in order to manage your subscription', null, 8000, null, 'warning')
  }
  addSubscription = () => {
    this.teamsService.selectedTeam = this.team

    this.layout.open('modalManageSubscription')
    // this.layout.toast('<b>Future feature</b>. Please <b>communicate</b> with us in order to add a subscription', null, 8000, null, 'warning')
  }
  navigateToTeamEdition = () => {
    this.router.navigate([PagesName.TeamsManager, this.getKey()]);
  }

  handleDelete(key, name) {
    let text = ('Team <b>"' + name + '"</b>  will be deleted. Are you sure you want to do this ?')
    this.layout.customConfirm(text, () => {
      this._handleDelete(key, name)
    })
  }


  _handleDelete = (key, name) => {
    this.teamsService.deleteTeam(key).subscribe(() => {
      this.layout.toast('Team <b>"' + name + '"</b> successfully <b>deleted</b>', null, 5000, null, 'success')
      this.teamsService.refreshTeams.emit(true)
    }, () => {
      this.layout.toast('<b>Error</b> while deleting the team  <b>"' + name + '"</b>', null, 8000, null, 'danger')

    })
  }
}
