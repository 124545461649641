import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-viz-table-thumbnail',
  templateUrl: './viz-table-thumbnail.component.html',
  styleUrls: ['./viz-tables.scss', '../viz-style.scss'],
})
export class VizTableThumbnailComponent implements OnInit {
  @Input() data;
  @Input() layout;
  @Input() size;
  @Input() title;
  constructor() {}

  shrinkTableLength(table, maxNbColumns, maxNbLines) {
    if (table.headers) {
      table.headers = _.take(table.headers, maxNbColumns);
    }
    table.cells = _.take(table.cells, maxNbLines);
    for (let i = 0; i < maxNbLines; i++) {
      if (table.cells[i]) {
        table.cells[i] = _.take(table.cells[i], maxNbColumns);
      }
    }
    return table;
  }

  ngOnInit(): void {
    if (this.data && this.data.length) {
      this.data[0] = this.shrinkTableLength(this.data[0], 4, 5);
    }
  }
}
