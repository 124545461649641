<div id="{{ item.id }}" class="list">
  <div class="list-container folder-tile row" [class.tile-selected]="selected"
    [class.tile-danger]=' getStatus() === Status.Unusable' [class.tile-warning]=' getStatus() === Status.Processing'
    [class.tile-selected-danger]='selected && getStatus() === Status.Unusable'
    [class.tile-selected-warning]='selected && getStatus() === Status.Processing'
    (dblclick)="!item.trashed ? manager.openFolder(item.id) : ''">
    <div class="item-text-container mx-0 row px-0 col-6 flex-nowrap">

      <app-checkbox *ngIf='fileManager.viewTab === TabsName.MyFiles' [selectAction]='myFilesManager.addToBulkSelection'
        [unSelectAction]='myFilesManager.removeFromBulkSelection' [fileType]='FileType.Folder' [id]="item.id"
        bulkType='file' [selected]="selected" [status]='getStatus()'></app-checkbox>
      <app-checkbox *ngIf='fileManager.viewTab === TabsName.SharedFiles'
        [selectAction]='sharedFilesManager.addToBulkSelection'
        [unSelectAction]='sharedFilesManager.removeFromBulkSelection' [fileType]='FileType.Folder' [id]="item.id"
        bulkType='file' [selected]="selected" [status]='getStatus()'></app-checkbox>
      <app-checkbox *ngIf='fileManager.viewTab === TabsName.DeletedFiles'
        [selectAction]='deletedFilesManager.addToBulkSelection'
        [unSelectAction]='deletedFilesManager.removeFromBulkSelection' [fileType]='FileType.Folder' [id]="item.id"
        bulkType='file' [selected]="selected" [status]='getStatus()'></app-checkbox>
      <div class="item-text px-0" [class.text-warning]='getStatus() === Status.Processing'
        [class.text-danger]='getStatus() === Status.Unusable'>{{ item.title }} <small class='text-uppercase'
          *ngIf='getStatus() === Status.Processing'> – processing </small>
        <small class='text-uppercase' *ngIf='getStatus() === Status.Unusable'>
          – unusable </small>
      </div>
    </div>
    <div class="item-icons-container mx-0 px-0 row col-6">
      <div class="item-icon-text mx-0 px-0 col">
        {{ iconText }}
      </div>
      <div *ngIf='item.trashed' class="d-flex item-icons col-md-auto mx-0 px-0"><span class='mx-2 '>
          <small>
            <span class='text-uppercase text-muted '>deleted : </span>

            <span class=' text-muted'>{{moment.unix(item.date_of_trashing).format('YY/MM/DD - hh:mm A')}}
            </span></small>
        </span>
      </div>
      <div *ngIf='!item.trashed' class="item-icons col-md-auto mx-0 px-0">
        <button *ngIf='!downloadIsPending()' ngbTooltip="{{getTooltip('download')}}"
        tooltipClass="custom-tooltip" placement="left"
          (click)="(getCanDownload() && manager.canDownload.value) ? [manager.setBulkToOneFileOnly(item.id),manager.handleCompressAndDownload(item),$event.stopPropagation()]  : '' "
          class="btn btn-link hover-active" (mouseenter)="setIconText('Download folder')" (mouseleave)="unSetIconText()">
          <i class="far fa-arrow-alt-to-bottom "  [class.disabled]='!getCanDownload() || !manager.canDownload.value' aria-hidden="true"></i>
        </button>
        <button class='btn btn-link cursor-auto' *ngIf='downloadIsPending()' ngbTooltip="{{getTooltip('isCreatingArchive')}}"
        tooltipClass="custom-tooltip" placement="left"
         (mouseenter)="setIconText('Download folder')" (mouseleave)="unSetIconText()">
        <app-spinner [small]='true'></app-spinner>
      </button>
        
        <button
          (click)="manager.setBulkToOneFileOnly(item.id);manager.getInfos();manager.openShare();$event.stopPropagation()"
          class="btn btn-link hover-active" (mouseenter)="setIconText('Share folder')" (mouseleave)="unSetIconText()">
          <i class="far fa-share-square" aria-hidden="true"></i>
        </button>
        <button
          (click)="manager.setBulkToOneFileOnly(item.id);manager.getInfos(); manager.openInfo();$event.stopPropagation()"
          (mouseenter)="this.setIconText('Read description')" (mouseleave)="this.unSetIconText()" class="btn btn-link hover-active">
          <i class="far fa-info-circle" aria-hidden="true"></i>
        </button>
        <button (click)="manager.handleDelete(item)" [class.no-focus]='!item.capabilities.can_trash || !_.includes(availableFeatures, Features.DeleteFile) || !fileManager.deleteFilePermission' 
          (mouseenter)="setIconText('Delete folder')" (mouseleave)="unSetIconText()" class="btn btn-link hover-active">
          <i class="far fa-trash-alt" ngbTooltip="{{getTooltip('delete')}}"
          tooltipClass="custom-tooltip" placement="left" [class.disabled]='!item.capabilities.can_trash || !_.includes(availableFeatures, Features.DeleteFile) || !fileManager.deleteFilePermission' aria-hidden="true"></i>
        </button>
        <button class="btn btn-link hover-active cursor-auto">
          <div class="icon-blank"></div>
        </button>
        <!-- <button class="btn btn-link hover-active" *ngIf="!hasMetaData()"
          (click)="manager.setBulkToOneFileOnly(item.id);manager.getInfos(); manager.openInfo();$event.stopPropagation()"
          (mouseenter)="setIconText('This file has no metadata')" (mouseleave)="unSetIconText()"
          [class.text-danger]="!hasMetaData()">
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        </button> -->
      </div>
    </div>
  </div>
</div>