import { Component, Input } from '@angular/core';
import { FileType } from 'src/app/api/file-manager.service';
import { Status } from '../helpers/status';
export enum BulkType {
  File = 'file',
  AllFile = 'allFiles',
  Project = 'project',
  AllProject = 'allProjects',
}
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  BulkType = BulkType;
  @Input() bulkType;
  @Input() status?;
  @Input() title?
  @Input() fileType;
  @Input() id: string;
  @Input() country?:string;
  @Input() selected;
  Status = Status;
  FileType = FileType;
  @Input() selectAction;
  @Input() unSelectAction;
  constructor() { }

  handleClick = () => {
    if (this.country && this.title){
      if (this.selected) {
        this.unSelectAction(this.id)
      } else {
        this.selectAction(this.id, this.country,this.title)
      }
    }else{
      if (this.selected) {
        this.unSelectAction(this.id)
      } else {
        this.selectAction(this.id)
      }
    }

   
  }
  handleAllClick = () => {
    if (this.selected) {
      this.unSelectAction()
    } else {
      this.selectAction()
    }
  }
}