<div class="page-auth">
  <div class="main-body">
    <div class="card card-white">
      <div class="card-content">
        <div class="login-title"></div>
        <div class="alert alert-danger" *ngIf="error">
          <p class="error-message">{{ error }}</p>
        </div>

        <form [formGroup]="validationForm" (ngSubmit)="login()" [class]="getLoginFormClass()">
          <fieldset>
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" formControlName="email" class="form-control" />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" id="password" formControlName="password" class="form-control" />
            </div>

            <button [disabled]="validationForm.status != 'VALID'" class="btn btn-dark float-right login-btn" type="submit">Login</button>
          </fieldset>
        </form>

        <form (ngSubmit)="showSecondFactorForm()" [class]="getQRCodeFormClass()">
          <label>Since it's your first time logging in, you need to register the following QR Code in the Google Authenticator app. </label>
          <img
            *ngIf="qrCodeImage"
            [src]="qrCodeImage"
            (load)="showQRCodeForm()"
            (error)="showSecondFactorForm()"
            class="qrcode"
            alt="qr-code-image"
          />
          <label>When done, click this button: </label>
          <button class="btn btn-dark float-right login-btn" type="Next">Next</button>
        </form>

        <form (ngSubmit)="submitSecondFactor()" [class]="getSecondFactorFormClass()">
          <label>Enter the 6-digit number shown on your Google Authenticator app. </label>

          <div [formGroup]="secondFactorForm" class="form-group">
            <input type="code" id="code" formControlName="code" class="form-control" />
          </div>
          <button class="btn btn-dark float-right login-btn" type="Next">Login</button>
        </form>
      </div>
    </div>
  </div>
</div>
