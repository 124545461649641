import { Component, Input, OnInit } from '@angular/core';
import { FileManager, FileType } from 'src/app/api/file-manager.service';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import moment from 'moment'
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Status } from 'src/app/shared/helpers/status';
import { MyFilesManagerService } from 'src/app/api/my-files-manager.service';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { DeletedFilesManagerService } from 'src/app/api/deleted-files-manager.service';
import { SharedFilesManagerService } from 'src/app/api/shared-files-manager.service';
import { availableFeatures } from 'src/environments/environment';
import { Features } from 'src/environments/features';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { NotificationsService } from 'src/app/api/notifications.service';
import { FilesService } from 'src/app/api/files.service';


@Component({
  selector: 'folder',
  styleUrls: ['../../../shared/layout/managers.scss'],
  templateUrl: './folder.component.html',
})
export class FolderComponent implements OnInit {
  @Input() item: any;
  @Input() selected;
  manager;
  _ = _
  moment = moment
  FileType = FileType;
  Status = Status;
  availableFeatures = availableFeatures
  Features = Features
  TabsName = TabsName;
  public iconClass: string[] = ['far fa-circle'];
  public iconText = '';
  isCreatingArchive = new BehaviorSubject<Boolean>(false);
  newSubscription
  linkSubscription
  linkIsGenerated = new Subject()

  executionId = null


  notificationWSsubscription: Subscription
  constructor(
    public fileManager: FileManager,
    public filesService: FilesService,
    public layout: LayoutOrganizationService,
    public router: Router,
    public myFilesManager: MyFilesManagerService,
    public sharedFilesManager: SharedFilesManagerService,
    public deletedFilesManager: DeletedFilesManagerService,
    public notificationsService: NotificationsService
  ) { }
  ngOnInit() {
    if (this.fileManager.viewTab === TabsName.DeletedFiles) {
      this.manager = this.deletedFilesManager
    } else if (this.fileManager.viewTab === TabsName.SharedFiles) {
      this.manager = this.sharedFilesManager
    } else if (this.fileManager.viewTab === TabsName.MyFiles) {
      this.manager = this.myFilesManager
    }

  }
  getCanDownload(){
    return _.get(this, 'item.capabilities.can_download',false)
  }
  downloadIsPending = () => {
    let folders = this.notificationsService.getFolderById(this.item.id)
    if (!folders.length){
      return false
    }
    if (_.find(folders,(o)=>{return _.get(o, 'status') === Status.Pending} )) {
      return true
    }
  }

  hasMetaData = () => {
    let hasMetaData;
    if (this.item.metadata && Object.keys(this.item.metadata).length > 0) {
      hasMetaData = true
    } else {
      hasMetaData = false
    }
    return hasMetaData
  }
  getStatus() {
    return _.get(this, 'item.status', null)
  }
  setIconText(text) {
    this.iconText = text;
  }
  unSetIconText() {
    this.iconText = '';
  }
  getTooltip(name?) {
    if (name && name === 'download') {
      if (this.manager.canDownload.value) {
        return ''
      } else {
        return "You don't have the permission to download"

      }
    }
    else if (name && name === 'isCreatingArchive') {
      return "Preparing archive ... "
    }
    else if (name && name === 'delete') {
      return !_.includes(availableFeatures, Features.DeleteFile) || !this.fileManager.deleteFilePermission ? "You don't have the permission to delete" : ""
    }
  }

}
