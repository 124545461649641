import { Component, Input } from '@angular/core';
import { TabsName } from 'src/app/shared/helpers/tabsName';
import { TabsPublicName } from 'src/app/shared/helpers/tabsPublicName';
import { NotificationsService } from '../../api/notifications.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-notifications-page',
  templateUrl: './user-notifications-page.component.html',
  styleUrls: ['./user-notifications-page.component.scss'],
})
export class UserNotificationsPageComponent {
  TabsName = TabsName;
  TabsPublicName = TabsPublicName;
  page = 1;
  pageSize = 25;
  @Input() showRibbon = true;
  constructor(public notificationsService: NotificationsService) {}
  trackByFn(index, item) {
    return item.id;
  }
  getUserNotifications = () => {
    return _.get(this, 'notificationsService.userNotifications.value', []);
  };
}
