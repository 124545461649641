import { Component, OnInit } from '@angular/core';
import { TeamsService } from '../../teams.service';
import { AdditionalTypes, IdHelp, IsRequired } from 'src/app/shared/helpers/formattedInput';

@Component({
  selector: 'app-member-addition-form',
  templateUrl: './member-addition-form.component.html',
  styleUrls: []
})
export class MemberAdditionFormComponent {
   idFieldObject = {
    formControlName: 'memberInput',
    type: AdditionalTypes.ShortText,
    placeholder: "",
    iconClassName: 'far fa-info-circle',
    validations: [IsRequired, IdHelp]

  }
  constructor(public teamsService: TeamsService) { }


  get memberInput() {
    return this.teamsService.memberAdditionForm.get('memberInput');
  }
}
