import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';

export enum FileSystemState {
  Local = 'local',
  External = 'external',
  Disabled = 'disabled',
}

interface UserObject {
  email: string;
  first_name: string;
  id: string;
  kind: string;
  last_name: string;
  links: { self: string };
  name: string;
  organization: string;
  file_hosting_country: string;
}

interface LegacyLoginResponse {
  token: string;
  userID: string;
}
interface LoginResponse {
  first_factor_token: string;
}

interface SecondFactorResponse {
  access_token: string;
  authorized: boolean;
}

interface VerifyResponse {
  user_id: string;
  expiration: number;
}
interface ExternalStorageAccessTokenRequest {
  target_country: string;
}



@Injectable({ providedIn: 'root' })
export class LoginHttpService {
  
  protected _basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  constructor(private http: HttpClient) { }
  activateAccountWithPassword(token, password): Observable<any> {
    let body = {};
    body['activation_token'] = token;
    body['password'] = password;
    return this.http.post<any>(
      `${environment.activationPath}​/mas/auth/activate-with-new-password`,
      body
    );
  }
  resetOwnPassword(oldPassword: string, newPassword: string): Observable<any> {
    let body = {};
    body['old_password'] = oldPassword;
    body['new_password'] = newPassword;
    return this.http.post<any>(
      `${this.basePath}/mas/auth/reset-own-password`,
      body
    );
  }

  getExternalStorageAccessToken(targetCountry): Observable<ExternalStorageAccessTokenRequest> {
    let body = { 'target_country': targetCountry }
    return this.http.post<any>(
      `${this.basePath}/mas/auth/get-external-storage-access-token`,
      body
    );
  }
  getUser(userId): Observable<UserObject> {
    return this.http.get<UserObject>(`${this._basePath}/mas/users/${userId}`);
  }

  verifyAccess(): Observable<VerifyResponse> {
    return this.http.get<VerifyResponse>(`${this._basePath}/mas/auth/verify`);
  }

  legacyLogin(
    email: string,
    password: string
  ): Observable<LegacyLoginResponse> {
    return this.http
      .post<LegacyLoginResponse>(
        `${this._basePath}/mas/auth/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .pipe(catchError(this.handleError));
  }

  login(
    email: string,
    password: string
  ): Observable<HttpResponse<LoginResponse>> {
    return this.http
      .post<LoginResponse>(
        `${this._basePath}/mas/auth/login/v2`,
        {
          email,
          password,
        },
        { withCredentials: true, observe: 'response' }
      )
      .pipe(catchError(this.handleError));
  }

  validateSecondFactor(code: string): Observable<SecondFactorResponse> {
    return this.http.post<SecondFactorResponse>(
      `${this._basePath}/mas/auth/validate-second-factor`,
      {
        token: code,
      },
      { withCredentials: true }
    );
  }
  deleteAccount(id: string): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/mas/users/${id}`
    );
  }
  getQRCode(): Observable<Blob> {
    return this.http
      .get(`${this._basePath}/mas/auth/get-google-qrcode`, {
        responseType: 'blob',
      })
      .pipe(catchError(this.handleError));
  }
  getQRCodeURL(basePath: string, token: string): string {
    return `${basePath}/mas/auth/get-google-qrcode?access_token=${token}`;
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occured.';
    if (errorRes.status && errorRes.status === 403) {
      errorMessage = 'Invalid email or password.';
    }
    return throwError(errorMessage);
  }

  get basePath() {
    return this._basePath;
  }
  set basePath(basePath) {
    this._basePath = basePath;
  }


}
