import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoginAuthGuard implements CanActivate {
  @LocalStorage() session;
  @LocalStorage() user;

  public basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  constructor(private router: Router, private http: HttpClient) { }

  canActivate():
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    if (this.session && this.session.isLoggedIn) {
      return this.http.get(`${this.basePath}/mas/auth/verify`).pipe(
        map((res) => {
          return true;
        }),
        catchError((error: HttpErrorResponse) => {
          localStorage.clear()
          return of(this.router.createUrlTree(['/login']));
        })
      );
    } else {
      return this.router.createUrlTree(['/login']);
    }
  }
}
