import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs/Observable';
import { availableFeatures, environment } from '../../environments/environment';
import * as _ from 'lodash';
import { FileSystemState } from '../pages/login/login-http-service/login-http.service';
import { forkJoin } from 'rxjs';
import { LocalService } from './local-storage.service';
import { Features } from 'src/environments/features';
import { FormControl, FormGroup } from '@angular/forms';
export function getVisible(value) {
  if (_.get(value, 'tags.invisible') === false)
    return true
}
export function isBool(value) {
  if (_.get(value, 'type') === RawInputTypes.Bool) {
    return true
  }
}
export function isArray(value) {
  if (_.get(value, 'tags.is_array')) {
    return true
  }
}
export function isMultiple(value) {
  if (_.get(value, 'tags.multiple_selection')) {
    return true
  }
}
export interface WorkflowInput {
  description: string,
  metadata: Object,
  object_type: string,
  tags: Object | null,
  type: RawInputTypes
  optional: boolean,
}

export enum RawInputTypes {
  FileGroup = 'filegroups',
  Directory = 'directory',
  File = 'files',
  Bool = 'bool',
  String = 'string',
  Int = 'int',
  Float = 'float',
  CPUNbr = 'cpu_nbr'
}
interface Workflow {
  is_a_match: boolean;
  name: string;
  question: string;
  workflow_id: string;
}

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  @LocalStorage() session;

  activeStep = 0;
  stepsList = [
    'Describe and create project',
    'Select solution',
    'Select diseases',
    'Select data types',
    'Configure parameters',
    'Workflow in progress'
  ];
  stepsListEdition = [
    'Update project',
    'Select solution',
    'Select diseases',
    'Select data types',
    'Configure parameters',
    'Workflow in progress'
  ];
  projectName = '';
  fromDebugger = false;
  projectStorageCountry = null
  protected basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';

  constructor(private readonly http: HttpClient, private localService : LocalService) { }


  associateTeam(projectId: string,teamId : string): Observable<any> {
    return this.http.post<any>(`${this.basePath}/mas/fs/projects/${projectId}/team/${teamId}`, {
    });
  }
  createEmptyForm = (inputs) => {
    let object = {};
    for (const [key, value] of Object.entries(inputs)) {
      _.set(object, key, new FormControl(null))
    }
    return new FormGroup(object)
  }
  filterProjectInputs(inputs, tagName) {
    return _.filter(inputs, (o) => { return _.get(o, 'tags.' + tagName) })
  }

  setRetrievedValue(response,fieldObject){
    response.forEach(element => {
      let el = _.find(fieldObject, (o) => { return o.formControlName === element.tags.input_key_value })
      if (el) {
        if (_.get(el, 'is_array') && !_.get(el, 'multiple_selection')) {
          el.retrievedValue.next(_.nth(_.words(_.get(element, 'value'), /[^,]+/g), 0))
        } else {
          el.retrievedValue.next(_.words(_.get(element, 'value'), /[^,]+/g))
        }
      }
    })
  }
  handleRemoveInput = (projectId, element) => new Promise((resolve, reject) => {
    this.removeInput(projectId,
      _.get(element, 'id'),
    )
      .subscribe((res) => {
        resolve(res)
      }, () => {
        reject()
      })
  })
  handleAppendInput = (projectId, form, element, tagName) => {
    return new Promise((resolve, reject) => {
      let sentValue = this.formatValue(this.getUserValue(form,element), this.getAllowedValue(element))
      let tags = { is_array: this.getIsArray(element), input_key_value: this.getFormControlName(element)}
      _.set(tags,tagName,true)

      this.appendInput(projectId,
        element.type,
        sentValue,
        element.originalJson.metadata,
        tags
      )
        .subscribe(() => {
          resolve(true)
        }, () => {
          reject()
        })
    })
  }
  updateAllInputs = (projectId,fieldObjects, inputsInProjects, form,tagName) => {
    return new Promise((resolve, reject) => {
      let array = [];
      fieldObjects.forEach((element) => {
        array.push(this.handleAppendInput(projectId,form, element,tagName))
      });
      inputsInProjects.forEach((element) => {
        array.push(this.handleRemoveInput(projectId, element))
      });
      Promise.all(array).then(() => {
        resolve(true)
      },
        () => {
          reject()
        }
      );
    })
  }

  formatValue = (rawValue, allowedValues?) => {
    let sentValue = '';
    if (Array.isArray(rawValue)) {
      if (allowedValues) {
        allowedValues.forEach((el, index) => {
          if (rawValue[index]) {
            if (sentValue === '') {
              sentValue = sentValue + el
            } else {
              sentValue = sentValue + ',' + el
            }
          }
        }, this);
      }
    } else {
      sentValue = rawValue
      if (typeof sentValue !== 'string') {
        sentValue = JSON.stringify(sentValue)
      }
    }
    return sentValue
  }

  getFormControl(form,fieldObject){
    return _.get( form,'controls['+ this.getFormControlName(fieldObject) + ']')
  }
  getFormControlByName(form,fieldName){
    return _.get( form,'controls['+ fieldName + ']')
  }
  getUserValue(form,fieldObject){
    return _.get(this.getFormControl(form,fieldObject),'value')
  }
  getAllowedValue(fieldObject){
    return _.get(fieldObject, 'allowed_values', [])
  }
  getIsArray(fieldObject){
    return _.get(fieldObject, 'is_array')
  }
  getFormControlName(fieldObject){
    return _.get(fieldObject, 'formControlName')
  }

  getProjectStorageCountry() {
    if (_.includes(availableFeatures,Features.MultiCountry)){
      return _.get(this, 'projectStorageCountry', null)
    }else{
      return this.localService.getFromLocalStorage('user','account_country')
    }
  }
  getProjectName() {
    return _.get(this, 'projectName', '')
  }
  getActiveStep() {
    return _.get(this, 'activeStep', 0)
  }
  setActiveStep(value) {
    _.set(this, 'activeStep', value)
  }
  setProjectName(value) {
    _.set(this, 'projectName', value)
  }
  getFromDebugger() {
    return _.get(this, 'fromDebugger', false)
  }
  setFromDebugger(value: boolean) {
    _.set(this, 'fromDebugger', value)
  }
  getStepsList() {
    if (this.getFromDebugger() && this.getProjectName().length) {
      return _.get(this, 'stepsListEdition')
    } else {
      return _.get(this, 'stepsList')
    }
  }
  projetPatch(projectId: string, newValue: string, field: string): Observable<any> {
    let body = {}
    body[field] = newValue
    return this.http.patch<any>(
      `${this.basePath}/mas/fs/projects/${projectId}`, body
    );
  }
  projectRemove(projectId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.basePath}/mas/fs/projects/${projectId}`
    );
  }
  projectGet(projectId: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/mas/fs/projects/${projectId}`);
  }
  searchFiles(body): Observable<any> {
    return this.http.post<any>(`${this.basePath}/mas/fs/files/search`, body);
  }
  restore(projectId: string): Observable<any> {
    return this.http.post<any>(`${this.basePath}/mas/fs/projects/${projectId}/restore`, {});

  }
  permanentelyDelete(projectId: string): Observable<any> {
    let queryParameters = new HttpParams({});
    const notrash = true;
    queryParameters = queryParameters.set('notrash', notrash as any);
    return this.http.delete<any>(`${this.basePath}/mas/fs/projects/${projectId}`, {
      params: queryParameters,
    });
  }

  projectList(
    sortBy?: string,
    filters?: string,
    limit?: number
  ): Observable<any> {
    let queryParameters = new HttpParams({});
    const user = this.localService.getFromLocalStorage('user')
    const file_systems = _.get(user, 'file_systems')
    const available_countries = _.filter(file_systems, function (o) { return o.accessState !== FileSystemState.Disabled });
    if (sortBy !== undefined && sortBy !== null) {
      queryParameters = queryParameters.set('sortBy', sortBy as any);
    }
    if (filters !== undefined && filters !== null) {
      queryParameters = queryParameters.set('filters', filters as any);
    }
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit as any);
    }
    let forkJoinObject = {}
    available_countries.forEach(element => {
      let url = _.get(element, 'url', this.session.apiPath ? this.session.apiPath : this.basePath)
      let accessToken = _.get(element, 'access_token', this.session.accessToken)
      _.set(forkJoinObject, element.value, this.projectListByCountry(queryParameters, url, accessToken))
    });
    return forkJoin(forkJoinObject)
  }
  projectListByCountry(queryParameters, url,accessToken) {
    let headers = new HttpHeaders({});
    if (accessToken){
      headers = headers.set('X-Access-Token', accessToken);
    }
      return this.http.get<any>(
        `${url}/mas/fs/projects`,
        {
          params: queryParameters,
          headers
        }
      );
  }

  createProject(project) {
    interface Response {
      id: string;
    }

    return this.http.post<Response>(
      `${this.basePath}/mas/fs/projects/root`,
      project
    );
  }

  updateProject(project, projectId) {
    return this.http.patch(
      `${this.basePath}/mas/fs/projects/${projectId}`,
      project
    );
  }

  addInput(input, projectId): Observable<any> {
    return this.http.post(
      `${this.basePath}/mas/fs/projects/${projectId}/inputs`,
      input
    );
  }

  removeInput(projectId, inputId) {
    return this.http.delete(
      `${this.basePath}/mas/fs/projects/${projectId}/inputs/${inputId}`
    );
  }
  getWorkflow(workflowId): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/mas/workflows/${workflowId}`
    );
  }
  retrieveFileGroup(groupId: string): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/mas/fs/filegroups/${groupId}`,
      {}
    );
  }

  getWorkflows(projectId?) {
    if (!projectId) {
      return this.http.get<Array<any>>(`${this.basePath}/mas/workflows`);
    }
    return this.http.get<Array<Workflow>>(
      `${this.basePath}/mas/fs/projects/${projectId}/workflows`
    );
  }

  triggerWorkflow(projectId, workflowId) {
    const body = { workflow_id: workflowId };
    return this.http.post(
      `${this.basePath}/mas/fs/projects/${projectId}/workflows`,
      body
    );
  }

  cancelRunningWorkflow(projectId, executionId) {
    return this.http.post(`${this.basePath}/mas/fs/projects/cancel/${projectId}/${executionId}`, undefined);
  }

  appendInput = (projectId: string, inputType: RawInputTypes, inputValue, metadata, tags?): Observable<any> => {
    let body = {
      input_kind: inputType,
      input_value: inputValue,
      metadata,
    }
    if (tags) {
      _.set(body, 'tags', tags)
    }

    return this.http.post<any>(
      `${this.basePath}/mas/fs/projects/${projectId}/inputs`, body
    );
  }



}
