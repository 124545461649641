import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/api/dashboard.service';
import { FileManager } from 'src/app/api/file-manager.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-quality-overview',
  templateUrl: './quality-overview.component.html',
  styleUrls: []
})
export class QualityOverviewComponent implements OnInit, OnDestroy {
  constructor(private dashboardService: DashboardService, public fileManager: FileManager) {
  }
  ngOnInit() {
    this.dashboardService.qualityDashboard = true
    this.dashboardService.setContextId(_.get(this.fileManager, 'quality.id'))
    this.dashboardService.createDashboard()
  }
  ngOnDestroy() {
    this.dashboardService.qualityDashboard = false

  }


}
