import { Component } from '@angular/core';

@Component({
  selector: 'app-project-overview-new',
  templateUrl: './project-overview-new.component.html',
  styleUrls: ['./project-overview-new.component.scss'],
})
export class ProjectOverviewNewComponent {
  constructor() {}
}
