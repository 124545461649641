import { HttpClient } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { Configuration } from './configuration';

import { ProjectsService } from './api/projects.service';
import { FileManager } from './api/file-manager.service';
import { ExplorerService } from './api/explorer.service';
import { ExplorerManagerService } from './api/explorer-manager.service';
import { DashboardService } from './api/dashboard.service';
import { ShareService } from './api/share.service';
import { ProjectManagerService } from './api/project-manager.service';
import { VizService } from './api/viz.service';
import { MyFilesManagerService } from './api/my-files-manager.service';
import { DeletedFilesManagerService } from './api/deleted-files-manager.service';
import { SharedFilesManagerService } from './api/shared-files-manager.service';
import { GroupsManagerService } from './api/groups-manager.service';
import { TeamsService } from './pages/teams-manager/teams.service';
import { LocalService } from './api/local-storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Dashboard2Service } from './pages/project-overview2/dashboard2.service';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [],
  exports: [],
  providers: [
    ProjectsService,
    FileManager,
    DashboardService,
    Dashboard2Service,
    ExplorerManagerService,
    ExplorerService,
    ShareService,
    ProjectManagerService,
    VizService,
    MyFilesManagerService,
    DeletedFilesManagerService,
    SharedFilesManagerService,
    GroupsManagerService,
    TeamsService,
    LocalService
  ]
})
export class ApiModule {
  static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
