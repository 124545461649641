import { Component, OnInit, Input } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @LocalStorage() user;
  @Input() fontSize;
  initials;

  constructor() { }

  ngOnInit() {
    const name = this.user.first_name + ' ' + this.user.last_name;
    const matches = name.match(/\b(\w)/g);
    this.initials = matches.join('').toUpperCase();
  }
}
