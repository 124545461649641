import { Component, Input } from '@angular/core';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['../../shared/layout/managers.scss',
    './custom-modal.component.scss'],
})
export class CustomModalComponent {
  @Input() modalTitle?: any = '';
  @Input() idName: any;
  @Input() show: boolean = false;
  @Input() confirmTitle: string;
  @Input() confirmActions: Array<any> = [];
  @Input() confirmValidator? = true;
  @Input() cancelActions: Array<any> = [];
  @Input() confirmTooltip?: string = '';
  @Input() form?: FormGroup;
  @Input() buttons? =true 


  constructor(
    public layout: LayoutOrganizationService
  ) { }

  executeCancelCallbacks() {
    if (this.cancelActions.length) {
      this.cancelActions.forEach((func) => func());
    }
  }

  executeCallbacks() {
    if (this.confirmActions.length) {
      this.confirmActions.forEach((func) => func());
    }
  }
  getValidator() {
    if (!_.get(this,'form')) {
      return this.confirmValidator
    }
    else {
      return _.get(this, 'form.status') === 'VALID'
    }
  }
  handleValidation(){
    this.layout.close(this.idName)
    this.executeCallbacks()
  }
  getFormError() {
    if (!this.getValidator()) {
      return _.get(this, 'confirmTooltip', '')
    }
  }

}
