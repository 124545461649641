import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { AdditionalTypes, InputJson, Validations, getFieldErrorSentence } from '../helpers/formattedInput';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RawInputTypes } from 'src/app/api/projects.service';
import { BehaviorSubject, Subscription } from 'rxjs';
export enum HtmlTypes {
  Text = 'text',
  TextArea = 'textarea',
  Number = 'number',
  Checkbox = 'checkbox',
  Checkboxes = 'checkboxes',
  Select = 'select',
  Radio = 'radio',
  File = 'file'
}
export enum HtmlEmphasize {
  Title
}
@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputFieldComponent,
    multi: true
  }]
})
export class InputFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() inputJson: InputJson;
  @Input() parentForm: FormGroup;
  @Input() valueUpdate?= new BehaviorSubject<any>(null)
  @Input() allowedValueUpdate?= new BehaviorSubject<any>(null)
  @Input() writeError?= false
  @Output() valueSelected = new EventEmitter(false)
  showPassword = false
  RawInputTypes = RawInputTypes
  AdditionalTypes = AdditionalTypes
  charRemaining;
  value
  onChange
  _ = _;
  HtmlTypes = HtmlTypes;
  valueSubscription: Subscription
  allowedValueSubscription: Subscription
  constructor() {
  }
  writeValue(value) {
    this.value = value;
  }
  registerOnChange(fn) { this.onChange = fn; }
  registerOnTouched(fn) { }

  ngOnInit() {
    if (this.getIsArray() && this.getAllowedValues().length !== this.getAllowedValuesDescriptions().length) {
      this.inputJson.allowed_values_descriptions = this.inputJson.allowed_values
    }
    if (this.getFormControl()) {
      this.getFormControl().setValue(this.getDefaultValue())

    }
    this.valueSubscription = this.valueUpdate.subscribe((res) => {
      if (!res || res === undefined) {
        return
      }
      _.set(this.inputJson, 'default_value', res)
      this.getFormControl().patchValue(this.getDefaultValue())
    }
    )
    this.allowedValueSubscription = this.allowedValueUpdate.subscribe((res) => {
      if (!res || res === undefined) {
        return
      }
      _.set(this.inputJson, 'allowed_values', res)
    })
  }
  ngAfterViewInit() {
    if (this.getFormControl()) {
      setTimeout(() => {
        this.getFormControl().setValidators(this.getValidations())
        this.getFormControl().updateValueAndValidity();
      }, 1)

    }
  }

  updateCount() {
    const charUsed = _.get(this, 'parentForm.controls.' + this.getFormControlName() + '.value.length', 0);
    this.charRemaining = this.getCharMax() - charUsed;
  }
  getCharMax() {
    return _.get(this.inputJson, 'charMax', null)
  }
  getCharRemaining() {
    return _.get(this, 'charRemaining', null)
  }
  checkValue(index?) {
    if (index || index === 0) {
      let value = this.getValue(index);
      this.getFormControl().value[index] = !value;
      this.getFormControl().updateValueAndValidity();
      this.getFormControl().markAsDirty();

    } else {
      this.getFormControl().value = !this.getFormControl().value;

    }
    this.valueSelected.emit(true)

  }
  checkFiles(event) {
    if (event.target.value){
      this.getFormControl().setErrors(null);
      this.getFormControl().value = event.target.files
    }else{
      this.getFormControl().setErrors({'required': true});
      this.getFormControl().value = ''
    }
    this.valueSelected.emit(true)

  }


  selectValue(index) {
    let value = _.nth(this.getAllowedValues(), index);
    this.getFormControl().value = value;
    this.valueSelected.emit(true)

  }
  getReadOnly() {
    return _.get(this.inputJson, 'readonly', false)
  }
  isTranscriptomic(index) {
    if (this.getTagname() !== 'is_data_types') {
      return false
    }
    return this.getAllowedValuesDescriptions(index) === "transcriptome" || this.getAllowedValuesDescriptions(index) === "Transcriptome" || this.getAllowedValuesDescriptions(index) === "Transcriptomic" || this.getAllowedValuesDescriptions(index) === "transcriptomic"
  }
  getTagname() {
    return _.get(this.inputJson, 'tagname', null)
  }
  isClinical(index) {
    if (this.getTagname() !== 'is_data_types') {
      return false
    }
    return this.getAllowedValuesDescriptions(index) === "clinical" || this.getAllowedValuesDescriptions(index) === "Clinical"
  }
  isAny(index) {
    if (this.getTagname() !== 'is_diseases') {
      return false
    }
    return this.getAllowedValuesDescriptions(index) === "Any" || this.getAllowedValuesDescriptions(index) === "any"
  }
  updateField() {
    this.getFormControl().markAsTouched();
    this.getFormControl().updateValueAndValidity();

  }


  getDefaultValue() {
    if (!this.getIsArray()) {
      return _.get(this.inputJson, 'default_value', '')

    }


    if (this.getHtmlType() === this.HtmlTypes.Checkboxes) {
      let default_value = [];
      this.getAllowedValues().forEach(element => {
        if (_.includes(_.get(this.inputJson, 'default_value', []), element)) {
          default_value.push(true)
        } else {
          if (this.getTagname() === 'is_data_types' &&
            _.includes(['Transcriptomic',
              'transcriptomic',
              'transcriptome',
              'Transcriptome',
              'clinical',
              'Clinical']
              , element)) {
            default_value.push(true)
          } else if (this.getTagname() === 'is_diseases' && _.includes([
            'Any',
            'any']
            , element)) {
            default_value.push(true)
          } else
            default_value.push(false)
        }
      });
      return default_value
    } else if (this.getHtmlType() === HtmlTypes.Radio || this.getType() === AdditionalTypes.Object) {
      return _.get(this.inputJson, 'default_value', _.nth(this.getAllowedValues(), 0))
    } else {
      const length = this.getAllowedValues().length
      let array = []
      for (let i = 0; i < length; i++) {
        array.push(false)
      }
      return _.get(this.inputJson, 'default_value', array)
    }
  }
  getValue(index?) {
    if (index || index === 0) {
      return _.nth(_.get(this.getFormControl(), 'value'), index)
    }
    return _.get(this.getFormControl(), 'value')
  }

  getFitToContent() {
    return _.get(this.inputJson, 'fitToContent', false)
  }
  getDescription() {
    return _.get(this.inputJson, 'description', null)
  }
  getValidations() {
    return _.get(this.inputJson, 'validations', [])
  }
  getStep() {
    return _.get(this.inputJson, 'step', null)
  }
  getIconClassName() {
    return _.get(this.inputJson, 'iconClassName', null)
  }
  getPlaceholder() {
    return _.get(this.inputJson, 'placeholder', null)
  }
  getPlaceholderLength() {
    return _.get(this.inputJson, 'placeholder.length', 0)
  }
  getLabel() {
    return _.get(this.inputJson, 'label', null)
  }
  getEnumValues() {
    return _.get(this.inputJson, 'enumValues', null)
  }
  getEmphasize() {
    if (_.get(this.inputJson, 'type') === AdditionalTypes.Title) {
      return true
    }
  }
  getPassword() {
    if (_.get(this.inputJson, 'type') === AdditionalTypes.Password) {
      return true
    }
  }
  getType() {
    return _.get(this.inputJson, 'type')
  }
  getHtmlType() {
    let type;
    if (!this.getIsArray()) {
      switch (this.getType()) {
        case AdditionalTypes.File:
          type = HtmlTypes.File
          break;
        case RawInputTypes.Bool:
          type = HtmlTypes.Checkbox
          break;
        case RawInputTypes.FileGroup:
          type = HtmlTypes.Select
          break;
        case RawInputTypes.CPUNbr:
          type = HtmlTypes.Number
          break;
        case AdditionalTypes.Paragraph:
          type = HtmlTypes.TextArea
          break;
        case AdditionalTypes.ShortText:
        case AdditionalTypes.Password:
        case AdditionalTypes.Title:
          type = HtmlTypes.Text
          break;

        default:
          type = null
      }
    } else {
      if (!this.getMultipleSelection()) {
        switch (this.getType()) {
          case AdditionalTypes.File:
            type = HtmlTypes.File
            break;
          case RawInputTypes.Bool:
            type = HtmlTypes.Radio
            break;
          case RawInputTypes.String:
          case RawInputTypes.Int:
          case RawInputTypes.Float:
            if (this.getAllowedValues().length > 5) {
              type = HtmlTypes.Select;
            } else {
              type = HtmlTypes.Radio
            }
            break;
          case AdditionalTypes.Object:
            type = HtmlTypes.Select;
            break;
          default:
            type = null
        }
      } else {
        switch (_.get(this.inputJson, 'type')) {
          case RawInputTypes.Bool:
            type = HtmlTypes.Checkboxes
            break;
          case RawInputTypes.String:
          case RawInputTypes.Int:
          case RawInputTypes.Float:
            type = HtmlTypes.Checkboxes
            break;
          default:
            type = null
        }
      }


    }

    return type
  }
  getRequired() {
    return _.get(this.inputJson, 'required', false)
  }
  getFormControlName() {
    return _.get(this.inputJson, 'formControlName', 'test')
  }
  getFormControl() {
    return _.get(this.parentForm, 'controls.' + this.getFormControlName())
  }

  getFixedWidth() {
    return _.get(this.inputJson, 'fixed_width', null)
  }
  getAllowedValues() {
    if (this.getType() !== AdditionalTypes.Object) {
      return _.words(_.get(this.inputJson, 'allowed_values', ''), /[^,]+/g)
    }
    return _.get(this.inputJson, 'allowed_values', [])
  }
  passwordVisibilitySwitcher() {
    _.set(this, 'showPassword', !_.get(this, 'showPassword', null))
  }
  getWidth() {
    if (this.getFixedWidth) {
      return this.getFixedWidth() + "Ch"
    }
    return (this.getFitToContent() && this.getPlaceholder()) ? (this.getPlaceholderLength() + 10) + "Ch" : "100%"
  }
  getAllowedValuesDescriptions(index?) {
    if (index || index === 0) {
      return _.nth(_.get(this.inputJson, 'allowed_values_descriptions', this.getAllowedValues()), index)
    }
    return _.get(this.inputJson, 'allowed_values_descriptions', this.getAllowedValues())
  }
  getIsArray() {
    return _.get(this.inputJson, 'is_array', false)
  }

  getMultipleSelection() {
    return _.get(this.inputJson, 'multiple_selection', false)
  }


  hasError() {
    return (!this.getFormControl().valid && (this.getFormControl().dirty || this.getFormControl().touched))
  }


  getErrorsList() {
    return _.keys(_.get(this.getFormControl(), 'errors'))
  }


  getError() {
    let sentence = ''
    this.getErrorsList().forEach(element => {
      sentence = sentence + getFieldErrorSentence(element) + '. '
    });

    return sentence
  }
  getSpecificErrorSentence(element) {
    return getFieldErrorSentence(element)
  }
  ngOnDestroy() {
    this.valueSubscription.unsubscribe()
    this.allowedValueSubscription.unsubscribe()

  }

}
