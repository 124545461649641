import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectManagerService } from 'src/app/api/project-manager.service';
import { ItemType } from 'src/app/api/share.service';
import { InputJson, AdditionalTypes, IsRequired } from 'src/app/shared/helpers/formattedInput';
import { FormControl, FormGroup } from '@angular/forms';
import { Status } from 'src/app/shared/helpers/status';
import { ProjectsService } from 'src/app/api/projects.service';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-info-project',
  styleUrls: ['./info-project.component.scss'],
  templateUrl: './info-project.component.html'
})
export class InfoProjectComponent implements OnInit {
  public dateCreation: any;
  public dateModification: any;
  public highlightDate = false;
  public ItemType = ItemType;
  public _ = _;
  public Status = Status
  workflowGroupId = new BehaviorSubject<any>(null)
  @Input() item: any;
  originalSelectedItemId;
  descriptionFormGroup
  parameters = [];
  filegroupInput;
  filteredSubjects = new BehaviorSubject<any>(null)
  workflowName = new BehaviorSubject<any>('')
  diseaseParam;
  dataTypeParam;
  constructor(
    public projectManagerService: ProjectManagerService,
    public projectsService: ProjectsService,
    public layout: LayoutOrganizationService,
    public router: Router,
    public route: ActivatedRoute
  ) { }
  ngOnInit(): void {
    this.descriptionFormGroup = new FormGroup({ 'description': new FormControl() })
    let instance = this.getMoreRecentInstance()
    this.parameters = this.getNoneFilegroupParameters(instance)
    this.filegroupInput = this.getFilegroupParameter(instance)

    if (this.filegroupInput) {
      this.dataTypeParam = _.nth(_.filter(this.parameters, (o) => {
        return _.get(o, 'input_name').includes('data_type')
      }), 0)
      this.diseaseParam = _.nth(_.filter(this.parameters, (o) => { return _.get(o, 'input_name').includes('disease_filters') }), 0)
      let body = {
        filegroup_id: _.get(this.filegroupInput, 'input_value'),
      }
      if (_.get(this.diseaseParam, 'input_value')) {
        _.set(body, 'disease_filters', _.get(this.diseaseParam, 'input_value'))
      }
      if (_.get(this.dataTypeParam, 'input_value')) {
        _.set(body, 'data_type', _.get(this.dataTypeParam, 'input_value'))
      }
      this.projectsService.searchFiles(body).subscribe((res) => {
        this.filteredSubjects.next(_.get(res, 'counts.patients'))
      })
    }
    if (this.getWorkflowId(instance)) {
      this.projectsService.getWorkflow(this.getWorkflowId(instance)).subscribe((res) => {
        this.workflowGroupId.next(_.get(res, 'workflow_group_id'))
        return this.workflowName.next(_.get(res, 'name'))

      },
        () => {
          this.layout.toast('Error when retrieving project solution information', null, 8000, '', 'danger')
        }
      )
    }

    this.dateCreation = this.fromNowOrNow(this.item.date_of_creation);
    this.dateModification = this.fromNowOrNow(this.item.date_of_modification);
    setInterval(() => {
      this.dateCreation = this.fromNowOrNow(this.item.date_of_creation);
      this.dateModification = this.fromNowOrNow(this.item.date_of_modification);
    }, 10000);


  }
  getWorkflowId(instance) {
    return _.get(instance, 'workflow_id')
  }
  getWorkflowName() {
    return _.get(this, 'workflowName.value', '')
  }
  isServier() {
    if (this.workflowGroupId.value === '58358741') {
      return true
    } else {
      return false
    }
  }
  getName(parameter) {
    return _.get(parameter, 'input_name')
  }
  getDescriptionFieldObject = (): InputJson => {
    return {
      formControlName: 'description',
      type: AdditionalTypes.Paragraph,
      formControl: new FormControl(),
      default_value: this.getDescription(),
      fitToContent: false,
      readonly: true,
      validations: [IsRequired]
    }

  }
  getInputValue(parameter) {
    let inputValue = _.get(parameter, 'input_value')
    if (parameter === this.diseaseParam)
      inputValue = inputValue.replace(',Any', '')
    if (parameter === this.dataTypeParam)
      inputValue = inputValue.replace(',Clinical', '')
    inputValue = inputValue.replaceAll(',', ', ')
    return inputValue
  }
  getLocation() {
    let countryCode = _.get(this, 'item.host_country', null)
    let location;
    switch (countryCode) {
      case 'US':
        location = 'United States'
        break;
      case 'CA':
        location = 'Canada'
        break;
      case 'FR':
        location = 'France'
        break;
      default:
        location = null
    }
    return location
  }

  sortInstancesDescOrder(unsortedInstance) {
    let sortedInstances = unsortedInstance.sort((a, b) => {
      return b.date_of_creation - a.date_of_creation;
    });
    return sortedInstances
  }

  getMoreRecentInstance() {
    let sortedInstances = this.sortInstancesDescOrder(_.get(this, 'item.instances', []))
    return _.head(sortedInstances)
  }
  getParameters(instance) {
    return _.get(instance, 'parameters', [])
  }
  getNoneFilegroupParameters(instance) {
    return _.filter(this.getParameters(instance), (o) => { return _.get(o, 'input_kind') !== 'filegroups' })
  }

  getFilegroupParameter(instance) {
    return _.find(this.getParameters(instance), (o) => { return _.get(o, 'input_kind') === 'filegroups' })
  }
  getDescription() {
    return _.get(this, 'item.description', null)
  }
  getStatus() {
    let instances = _.get(this, 'item.instances', [])
    instances = _.sortBy(instances, ['date_of_creation']);
    if (instances.length)
      return _.get(_.last(instances), 'status', null)
    else
      return 'not executed'
  }
  getFileGroupName() {
    return _.get(this, 'filegroupInput.input_name', null)
  }
  fromNowOrNow(date) {
    date = date < moment().unix() ? date : moment().unix();
    return moment.unix(date).fromNow();
  }
}
