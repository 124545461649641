<div *ngIf="getStatus() !== Status.Cancelled" id="{{ item.id }}" class="list " [class.selected]="isSelected">
  <div class="list-container temp bg-white">
    <div class="progress" style="height: 5px" [class.bg-danger]='getErrorStatus()'>
      <div class="progress-bar" [class.bg-danger]='getErrorStatus()' role="progressbar"
        [style.width.%]="item.reportChunkProgress" [attr.aria-valuenow]="item.reportChunkProgress" aria-valuemin="0"
        aria-valuemax="100"></div>
    </div>
    <div class="row mx-0" style="height: 40px">
      <div class="item-text-container-temp row px-0  mx-0 col-6">
        <div class="px-5 item-text">
          {{ item.title }} - {{ item.reportChunkProgress }}%
          <small *ngIf='getErrorStatus() ' class='text-danger text-uppercase'> – upload error </small>
        </div>
      </div>
      <div class="item-icons col-6 h-100 mx-0 px-0 d-flex justify-content-end">
        <button *ngIf="getStatus() === Status.Error || getStatus() === Status.NetworkError"
          (click)='setStatus(Status.NotStarted);retry()' class="btn bg-light my-1 mx-3 px-2">
          Retry upload
          <i class="fas fa-sync mx-1" aria-hidden="true"></i>
        </button>
        <!-- <button (click)='setStatus(Status.Cancelled)'  
        class="btn bg-light my-1 mx-3 px-2">
        Cancel
        <i class="fas fa-close mx-1"
          aria-hidden="true"></i>
      </button> -->
        <!-- <button  *ngIf='!getPauseStatus()' (click)='setPauseStatus(Status.Paused)'  
          class="btn bg-light my-1 mx-3 px-2">
          Pause upload
          <i class="fas fa-pause-circle mx-1"
            aria-hidden="true"></i>
        </button>
        <button  *ngIf='getPauseStatus()' (click)='setPauseStatus(Status.Uploading)'
          class="btn bg-light my-1 mx-3 px-2">
          Resume upload
          <i class="fas fa-play-circle mx-1"
            aria-hidden="true"></i>
        </button> -->
      </div>
    </div>

  </div>
</div>
