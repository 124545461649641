import { availableFeatures, environment } from 'src/environments/environment';
import { LocalStorage } from 'ngx-webstorage';
import { Component, Input } from '@angular/core';
import { LocalService } from 'src/app/api/local-storage.service';
import { Features } from 'src/environments/features';
@Component({
  selector: 'DlProxyComponent',
  templateUrl: './dl-proxy.component.html',
})
export class DlProxyComponent {
  private basePath = environment.apiBasePath ? environment.apiBasePath : 'http://localhost:8081';

  @LocalStorage() session;
  @Input() fileId?;
  @Input() resetFunction;
  @Input() fileLink?;

  constructor(private localService: LocalService) {}
  ngOnInit() {
    var url;
    if (!this.fileLink) {
      url =
        (this.session.apiPath || environment.apiBasePath) +
        '/mas/fs/files/' +
        this.fileId +
        '/data?access_token=' +
        this.localService.getFromLocalStorage('country', 'access_token', this.session.accessToken);
      url = this.localService.getRegionalizedURL(url);
    } else {
      url = this.fileLink;
    }
    this.downloadFile(url);
    setTimeout(() => {
      this.resetFunction();
    }, 1);
  }
  downloadFile(link) {
    var element = window.document.createElement('a');
    element.setAttribute('href', link);
    element.setAttribute('download', 'compression.zip');
    element.style.display = 'none';
    window.document.body.appendChild(element);
    element.click();
    window.document.body.removeChild(element);
  }
}
