import { Component} from '@angular/core';
import { LocalService } from 'src/app/api/local-storage.service';
import { ShouldBeAnEmail, IsRequired, AdditionalTypes, InputJson } from 'src/app/shared/helpers/formattedInput';
import { TeamsService } from '../../teams.service';
import * as _ from 'lodash';

const emailFieldObject = {
  formControlName: 'email',
  type: AdditionalTypes.ShortText,
  fitToContent: true,
  placeholder: '',
  fixed_width: 35,
  label: 'Enter email',
  iconClassName: 'far fa-info-circle',
  validations: [IsRequired, ShouldBeAnEmail]
}
const firstNameFieldObject = {
  formControlName: 'first_name',
  type: AdditionalTypes.ShortText,
  fitToContent: true,
  placeholder: '',
  fixed_width: 35,
  label: 'Enter first name',
  iconClassName: 'far fa-info-circle',
  validations: [IsRequired]
}
const lastNameFieldObject = {
  formControlName: 'last_name',
  type: AdditionalTypes.ShortText,
  fitToContent: true,
  placeholder: '',
  fixed_width: 35,
  label: 'Enter last name',
  iconClassName: 'far fa-info-circle',
  validations: [IsRequired]

}
const  organizationFieldObject = {
  formControlName: 'organization',
  type: AdditionalTypes.ShortText,
  fitToContent: true,
  placeholder: '',
  fixed_width: 35,
  label: 'Enter organization',
  iconClassName: 'far fa-info-circle',
  validations: [IsRequired]
}
var accountCountryFieldObject : InputJson = {   
  formControlName: 'file_hosting_country',
  type: AdditionalTypes.Object,
  fitToContent: true,
  is_array: true,
  multiple_selection: false,
  fixed_width: 35,
  label: 'Account country',
  iconClassName: 'far fa-info-circle',
}

@Component({
  selector: 'app-new-member-addition-form',
  templateUrl: './new-member-addition-form.component.html',
  styleUrls: ['./new-member-addition-form.component.scss']
})
export class NewMemberAdditionFormComponent {
  
  fieldObjects = []

  constructor(public teamsService: TeamsService, public localService : LocalService ) {
    let fileSystem =  _.cloneDeep(this.localService.getFromLocalStorage('user','file_systems'))
    let defaultValue = _.find(fileSystem, (o) => { return o.value === this.localService.getFromLocalStorage('user','account_country') });
    //TODO do no restrict anymore
    let restrictedFileSystem = []
    restrictedFileSystem.push(defaultValue)
    _.set(accountCountryFieldObject,'allowed_values',restrictedFileSystem)
    _.set(accountCountryFieldObject,'default_value',defaultValue)
    this.fieldObjects = [emailFieldObject,
      firstNameFieldObject,
      lastNameFieldObject,
      organizationFieldObject,
      accountCountryFieldObject]
  }
   getNewMemberAdditionForm(){
    return _.get(this.teamsService, 'newMemberAdditionForm')
  }
  }



