<div *ngIf='project.value' class="h-100 d-flex flex-column">
  <app-header>
    <app-m-logo-btn class='ml-3'></app-m-logo-btn>
    <div class="text-light h-100 w-100 d-flex bg-dark">
      <button (click)="
          router.navigate([
            PagesName.ProjectManager + '/' + projectManagerService.viewTab
          ])
        " class="h-100 btn btn-link">
        <i class="fal text-info fa-long-arrow-left" aria-hidden="true"></i>
        <span class="text-info"> Back to project manager</span>
      </button>
      <div class="h-100 col-sm-4 d-flex align-items-center">
        <h3>{{ getTitle(project) }}</h3>
      </div>
      <div class="h-100 justify-content-center col-sm-4 d-flex flex-column align-items-start">
        <div>
          <i class="far mx-2 fa-calendar-alt" aria-hidden="true"></i> Project created
          {{getDateOfCreation(project) | timeAgo}}

        </div>
        <div *ngIf="getInstanceEndTime(project,currentInstanceIndex) > 0">
          <i class="far mx-2 fa-calendar-alt" aria-hidden="true"></i> Results generated
          {{getInstanceEndTime(project,currentInstanceIndex) | timeAgo}}

        </div>
        <div *ngIf="getInstanceEndTime(project, currentInstanceIndex) === 0" class="text-warning">
          <i class="far mx-2 fa-calendar-alt" aria-hidden="true"></i> No result generated yet
        </div>
        <div *ngIf="getCanExecute(project) && getFormattedInstances(project).length">
          <i class="mx-2 far fa-sync-alt" aria-hidden="true"></i>
          <button (click)="handleReRun(project)" class="btn btn-outline-light btn-sm">
            Re-run
          </button>
        </div>
      </div>
      <div *ngIf="getFormattedInstances(project).length" class="h-100 col-sm-2 d-flex align-items-center">
        <mims-select [label]="'Show version : '" [selectedOption]='_.nth(getFormattedInstances(project),0)'
          [dataSource]="getFormattedInstances(project)" (valueChanged)="handleInstanceSelection(project,$event)">
        </mims-select>
      </div>
    </div>
  </app-header>
  <app-horizontal-nav-tab *ngIf="getFormattedInstances(project).length" [activeTab]='tabIndex' [navigate]="false"
    (selectedIndex)='handleTabSelection(project, $event)' [links]="getLinks(project, currentInstanceIndex)"
    [titles]="getTabNames(project, currentInstanceIndex)">
    <div class='btn btn-link ' *ngIf='showPopSegLegend' (click)='handleLegend()'> <i class="far fa-info-circle"
        aria-hidden="true"></i> Figure Legends</div>
  </app-horizontal-nav-tab>
  <app-content [isTab]='true' [isHeader]='true'>
    <div
      *ngIf="getInstanceStatus(project, currentInstanceIndex) === Status.Running || getInstanceStatus(project, currentInstanceIndex) === Status.InTreatment || getInstanceStatus(project, currentInstanceIndex) === Status.NotStarted">
      <app-spinner></app-spinner>
      <div class="mt-5 text-center">
        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
        In progress...
      </div>
    </div>
    <div *ngIf="getInstanceStatus(project, currentInstanceIndex) === Status.Failed">
      <div class="mt-5 text-center">
        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
        An error occurred during the processing.
      </div>
    </div>
    <div *ngIf="!getFormattedInstances(project).length">
      <div class="mt-5 text-center">
        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
        No execution found
      </div>
    </div>
    <div *ngIf="getInstanceStatus(project, currentInstanceIndex) === Status.Done && getFormattedInstances(project).length">
      <app-project-dashboard2 [hidden]='index !== tabIndex'
        *ngFor='let link of getLinks(project,currentInstanceIndex); let index = index' [isActive]='index === tabIndex' [projectId]='projectId'
        [dashboardId]='link'></app-project-dashboard2>
    </div>
  </app-content>
</div>


<app-full-screen-modal *ngIf='layout.isLegendModalOpen' idName='modalLegend' class='d-flex flex-column'>
  <div class='w-100 h-100 px-5 scrollable'>
    <markdown class='w-100' *ngIf='tabIndex === 0' [data]='legendfile1.value'>
    </markdown>
    <markdown class='w-100' *ngIf='tabIndex === 1' [data]='legendfile2.value'> </markdown>
  </div>
</app-full-screen-modal>
