import { DropDownModule } from './mims-ui-lib/drop-down/drop-down.module';
import { CoreModule } from './mims-ui-lib/core/core.module';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgModule, Injectable } from '@angular/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageLoginComponent } from './pages/login/login.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

// PROJECT MANAGER
import { ProjectManagerComponent } from './pages/project-manager/project-manager.component';
import { DisplayedProjectsComponent } from './pages/project-manager/displayed-projects/displayed-projects.component';
import { ProjectCardComponent } from './pages/project-manager/displayed-projects/devtest/project-card/project-card.component';
import { ProjectListComponent } from './pages/project-manager/displayed-projects/project-list/project-list.component';

// DASHBOARD
import { ProjectOverviewComponent } from './pages/project-overview/project-overview.component';
import { ProjectOverviewNewComponent } from './pages/project-overview-new/project-overview-new.component';
import { ProjectDashboardComponent } from './pages/project-overview/project-dashboard/project-dashboard.component';
import { VizComponent } from './pages/project-overview/project-dashboard/viz/viz.component';

// FILE MANAGER
import { FileManagerComponent } from './pages/file-manager/file-manager.component';
import { InfoComponent } from './pages/file-manager/info/info.component';
import { FileComponent } from './pages/file-manager/file/file.component';
import { FolderComponent } from './pages/file-manager/folder/folder.component';
import { GroupComponent } from './pages/file-manager/group/group.component';
import { TempFileComponent } from './pages/file-manager/tempFile/tempFile.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from './api.module';
import { Configuration, ConfigurationParameters } from './configuration';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { LoggedInLayoutComponent } from './shared/logged-in-layout/logged-in-layout.component';

// User Creation Page
import { UserCreationComponent } from './pages/user-creation/user-creation.component';
import { UserCreationFormComponent } from './pages/user-creation/user-creation-form/user-creation-form.component';
import { HttpInterceptorService } from './shared/http-interceptor-service/http-interceptor.service';
import { itemListComponent } from './pages/file-manager/item-list/item-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AccountComponent } from './pages/account/account.component';

// ScrollBar
import { AvatarComponent } from './shared/avatar/avatar.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';

// Project Creation
import { ProjectCreationComponent } from './pages/project-creation/project-creation.component';
import { ProjectCreationFormComponent } from './pages/project-creation/project-creation-form/project-creation-form.component';
import { AvailableWorkflowsComponent } from './pages/project-creation/available-workflows/available-workflows.component';

// IA
import { ExplorerComponent } from './pages/explorer/explorer.component';
import { ExplorerResponseComponent } from './pages/explorer/explorer-response/explorer-response.component';
import { ExplorerFullResponseComponent } from './pages/explorer/explorer-full-response/explorer-full-response.component';
import { SlideShortListAnswersComponent } from './pages/explorer/slides/slide-short-list-answers/slide-short-list-answers.component';
import { SlideNoQuestionComponent } from './pages/explorer/slides/slide-no-question/slide-no-question.component';
import { SlideAnswerFullDescriptionComponent } from './pages/explorer/slides/slide-answer-full-description/slide-answer-full-description.component';
import { QuestionFormComponent } from './pages/explorer/question-form/question-form.component';
import { ExplorerIconComponent } from './pages/explorer/explorer-icon/explorer-icon.component';

// LAYOUT
import { HorizontalNavTabComponent } from './shared/layout/horizontal-nav-tab/horizontal-nav-tab.component';
import { RouterModule } from '@angular/router';
import { CustomModalComponent } from './shared/custom-modal/custom-modal.component';
import { FolderCreationFormComponent } from './pages/file-manager/folder-creation-form/folder-creation-form.component';
import { fileUploadFormComponent } from './pages/file-manager/file-upload-form/file-upload-form.component';
import { MetadataUploadFormComponent } from './pages/file-manager/metadata-upload-form/metadata-upload-form.component';
import { GroupCreationFormComponent } from './pages/file-manager/group-creation-form/group-creation-form.component';
import { ExplorerPreviousQuestionComponent } from './pages/explorer/explorer-previous-question/explorer-previous-question.component';
import { BackComponent } from './pages/explorer/slides/back/back.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { PathComponent } from './pages/file-manager/path/path.component';
import { CheckboxComponent } from './shared/checkbox/checkbox.component';

import { VizPlotlyComponent } from './pages/project-overview-new/viz-types/viz-plotly/viz-plotly.component';
import { DashboardVersionComponent } from './pages/project-overview-new/dashboard-version/dashboard-version.component';
import { VizMenuComponent } from './pages/project-overview-new/viz-menu/viz-menu.component';
import { VizTestingSetComponent } from './pages/project-overview-new/viz-testing-set/viz-testing-set.component';
import { VizImageSelectComponent } from './pages/project-overview-new/viz-types/viz-image-select/viz-image-select.component';
import { VizSelectComponent } from './pages/project-overview-new/viz-types/viz-select/viz-select.component';

import { VizSequenceComponent } from './pages/project-overview-new/viz-types/viz-sequence/viz-sequence.component';
import { VizTableComponent } from './pages/project-overview-new/viz-types/viz-table/viz-table.component';
import { VizDescriptionComponent } from './pages/project-overview-new/viz-types/viz-description/viz-description.component';
import { VizTableThumbnailComponent } from './pages/project-overview-new/viz-types/viz-table/viz-table-thumbnail.component';
import { VizThumbnailContainerComponent } from './pages/project-overview-new/viz-types/viz-thumbnail-container/viz-thumbnail-container.component';
import { VizPlotlyThumbnailComponent } from './pages/project-overview-new/viz-types/viz-plotly/viz-plotly-thumbnail.component';
import { VizSequenceThumbnailComponent } from './pages/project-overview-new/viz-types/viz-sequence/viz-sequence-thumbnail.component';
import { DashboardContentComponent } from './pages/project-overview-new/dashboard-content/dashboard-content.component';
import { VizContainerComponent } from './pages/project-overview-new/viz-types/viz-container/viz-container.component';
import { VizAlignmentComponent } from './pages/project-overview-new/viz-types/viz-alignment/viz-alignment.component';
import { VizAlignmentThumbnailComponent } from './pages/project-overview-new/viz-types/viz-alignment/viz-alignment-thumbnail.component';
import { VizImageSelectColorscaleComponent } from './pages/project-overview-new/viz-types/viz-image-select-colorscale/viz-image-select-colorscale.component';
import { VizImageSelectThumbnailComponent } from './pages/project-overview-new/viz-types/viz-image-select/viz-image-select-thumbnail.component';
import { VizImageSelectColorscaleThumbnailComponent } from './pages/project-overview-new/viz-types/viz-image-select-colorscale/viz-image-select-colorscale-thumbnail.component';
import { ShareComponent } from './shared/share/share.component';
import { InfoProjectComponent } from './pages/project-manager/info-project/info-project.component';
import { ShareProjectComponent } from './pages/project-manager/share-project/share-project.component';
import { ShareFileComponent } from './pages/file-manager/share-file/share-file.component';
import { RenameComponent } from './shared/rename/rename.component';
import { ScrollSpyDirective } from './pages/account/scroll-spy.directive';
import { ResetPasswordModalComponent } from './pages/user-creation/reset-password-modal/reset-password-modal.component';
import { VizTileComponent } from './pages/project-overview/project-dashboard/viz-tile/viz-tile.component';
import { FullScreenModalComponent } from './shared/full-screen-modal/full-screen-modal.component';
import { VizSelectBoxComponent } from './pages/project-overview/project-dashboard/viz/viz-select/viz-select-box.component';
import { QualityOverviewComponent } from './pages/file-manager/quality-overview/quality-overview.component';
import { MyFilesComponent } from './pages/file-manager/my-files/my-files.component';
import { DeletedFilesComponent } from './pages/file-manager/deleted-files/deleted-files.component';
import { SharedFilesComponent } from './pages/file-manager/shared-files/shared-files.component';
import { TeamsManagerComponent } from './pages/teams-manager/teams-manager.component';
import { TeamComponent } from './pages/teams-manager/team/team.component';
import { ContentComponent } from './shared/layout/content/content.component';
import { InvitationComponent } from './pages/teams-manager/devtest/invitation/invitation.component';
import { TeamManagerComponent } from './pages/teams-manager/team-manager/team-manager.component';
import { MemberAdditionFormComponent } from './pages/teams-manager/team-manager/member-addition-form/member-addition-form.component';
import { GroupsComponent } from './pages/file-manager/groups/groups.component';
import { ProjectConfigurationComponent } from './pages/project-creation/project-configuration/project-configuration.component';
import { InputFieldComponent } from './shared/input-field/input-field.component';
import { ProjectDataTypesComponent } from './pages/project-creation/project-data-types/project-data-types.component';
import { WorkflowInProgressComponent } from './pages/project-creation/workflow-in-progress/workflow-in-progress.component';
import { AvailableWorkflowComponent } from './pages/project-creation/available-workflows/available-workflow/available-workflow.component';
import { PreselectedWorkflowComponent } from './pages/project-creation/preselected-workflow/preselected-workflow.component';
import { SidebarButtonComponent } from './shared/sidebar/sidebar-button.component';
import { MyFilesCountrySelectorComponent } from './pages/file-manager/my-files-country-selector/my-files-country-selector.component';
import { NewAccountComponent } from './pages/new-account/new-account.component';
import { NewMemberAdditionFormComponent } from './pages/teams-manager/team-manager/new-member-addition-form/new-member-addition-form.component';
import { CommonModule } from '@angular/common';
import { ProjectDiseasesComponent } from './pages/project-creation/project-diseases/project-diseases.component';
import { UserCardComponent } from './pages/teams-manager/team-manager/user-card/user-card.component';
import { TeamSubscriptionFormComponent } from './pages/teams-manager/team-manager/team-subscription-form/team-subscription-form.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { ToastComponent } from './shared/toast/toast.component';
import { ShareGroupComponent } from './pages/file-manager/my-files/share-group/share-group.component';
import { GroupContentComponent } from './pages/file-manager/group/group-content/group-content.component';
import { UserNotificationsPageComponent } from './pages/user-notifications-page/user-notifications-page.component';
import { removeAgoPipe, UserNotificationComponent } from './pages/user-notifications-page/user-notification/user-notification.component';
import { InitialsBubbleModule } from './mims-ui-lib/initials-bubble/initials-bubble.module';
import { LoaderModule, SelectComponent, SelectModule } from './mims-ui-lib/public_api';
import { ProjectDashboard2Component } from './pages/project-overview2/project-dashboard2/project-dashboard2.component';
import { ProjectOverview2Component } from './pages/project-overview2/project-overview2.component';
import { Viz2Component } from './pages/project-overview2/project-dashboard2/viz2/viz2.component';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { LayoutModule } from './modules/layout/layout.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BigNumberPipe } from './mims-ui-lib/core/pipes/big-number.pipe';
declare var Hammer: any;
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: 'auto',
    });
    return mc;
  }
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
  };

  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    PageLoginComponent,
    ProjectManagerComponent,
    ProjectListComponent,
    ProjectCardComponent,
    ProjectOverviewComponent,
    SidebarComponent,
    LoggedInLayoutComponent,
    UserCreationComponent,
    UserCreationFormComponent,
    FileManagerComponent,
    FileComponent,
    FolderComponent,
    GroupComponent,
    TempFileComponent,
    InfoComponent,
    itemListComponent,
    AccountComponent,
    ProjectDashboardComponent,
    VizComponent,
    AvatarComponent,
    ProjectCreationComponent,
    DisplayedProjectsComponent,
    ExplorerComponent,
    ExplorerResponseComponent,
    ExplorerFullResponseComponent,
    ExplorerIconComponent,
    SpinnerComponent,
    ProjectCreationFormComponent,
    AvailableWorkflowsComponent,
    WorkflowInProgressComponent,
    HorizontalNavTabComponent,
    CustomModalComponent,
    FolderCreationFormComponent,
    fileUploadFormComponent,
    CustomModalComponent,
    MetadataUploadFormComponent,
    SlideShortListAnswersComponent,
    SlideNoQuestionComponent,
    SlideAnswerFullDescriptionComponent,
    GroupCreationFormComponent,
    QuestionFormComponent,
    ExplorerPreviousQuestionComponent,
    BackComponent,
    NotFoundComponent,
    PathComponent,
    ProjectOverviewNewComponent,
    VizPlotlyComponent,
    VizMenuComponent,
    VizPlotlyThumbnailComponent,
    DashboardVersionComponent,
    VizTestingSetComponent,
    VizImageSelectComponent,
    VizSelectComponent,
    VizSequenceComponent,
    VizSequenceThumbnailComponent,
    VizTableComponent,
    VizTableThumbnailComponent,
    VizDescriptionComponent,
    VizThumbnailContainerComponent,
    CheckboxComponent,
    ProjectOverviewNewComponent,
    VizMenuComponent,
    VizTableThumbnailComponent,
    VizPlotlyThumbnailComponent,
    DashboardVersionComponent,
    VizImageSelectComponent,
    VizImageSelectColorscaleComponent,
    VizSelectComponent,
    VizSequenceComponent,
    VizSequenceThumbnailComponent,
    VizTableComponent,
    VizTableThumbnailComponent,
    VizDescriptionComponent,
    VizThumbnailContainerComponent,
    CheckboxComponent,
    DashboardContentComponent,
    VizContainerComponent,
    VizAlignmentComponent,
    VizAlignmentThumbnailComponent,
    VizImageSelectThumbnailComponent,
    VizImageSelectColorscaleThumbnailComponent,
    ShareComponent,
    InfoProjectComponent,
    ShareProjectComponent,
    ShareFileComponent,
    RenameComponent,
    ScrollSpyDirective,
    ProjectDashboardComponent,
    VizComponent,
    ResetPasswordModalComponent,
    VizTileComponent,
    FullScreenModalComponent,
    VizSelectBoxComponent,
    QualityOverviewComponent,
    ContentComponent,
    MyFilesComponent,
    DeletedFilesComponent,
    SharedFilesComponent,
    TeamsManagerComponent,
    TeamComponent,
    ContentComponent,
    InvitationComponent,
    TeamManagerComponent,
    MemberAdditionFormComponent,
    GroupsComponent,
    ProjectConfigurationComponent,
    InputFieldComponent,
    ProjectDataTypesComponent,
    AvailableWorkflowComponent,
    PreselectedWorkflowComponent,
    SidebarButtonComponent,
    MyFilesCountrySelectorComponent,
    NewAccountComponent,
    NewMemberAdditionFormComponent,
    ProjectDiseasesComponent,
    UserCardComponent,
    TeamSubscriptionFormComponent,
    ToastComponent,
    ShareGroupComponent,
    GroupContentComponent,
    UserNotificationsPageComponent,
    UserNotificationComponent,
    removeAgoPipe,
    ProjectDashboard2Component,
    ProjectOverview2Component,
    Viz2Component,
    BigNumberPipe
  ],
  imports: [
    CoreModule,
    AppRoutingModule,
    RouterModule,
    ApiModule.forRoot(apiConfigFactory),
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    NgxWebstorageModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatRippleModule,
    DragDropModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbModule,
    AutocompleteLibModule,
    CommonModule,
    SharedModule,
    InitialsBubbleModule,
    LoaderModule,
    DeferLoadModule,
    SelectModule,
    DropDownModule,
    LayoutModule,
    NgxJsonViewerModule,
    MarkdownModule
      .forRoot
      // {
      // markedOptions: {
      //   provide: MarkedOptions,
      //   useValue: {
      //     sanitize: false, // setting `true` does not sanitize without specifying a sanitizer function
      // },
      // }
      // }
      (),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
