<div class="page d-flex flex-column">
  <app-header>
    <span class="path h-100 w-100 d-flex align-items-center">
      <span class='path-element font-weight-bold'>
        {{PagesPublicName.ProjectManager}} </span>
    </span>

  </app-header>
  <app-horizontal-nav-tab [links]="[TabsName.MyProjects, TabsName.SharedProjects, TabsName.DeletedProjects]"
    [titles]="[TabsPublicName.MyProjects, TabsPublicName.SharedProjects, TabsPublicName.DeletedProjects]">
  </app-horizontal-nav-tab>

  <app-content [isTab]='true' [isHeader]='true'>


   
    <div class="manager-container hidden-on-tablet align-items-stretch">
      <div class="utility-bar utility-bar-at-the-left ">

        <button  [class.disabled]='projectManagerService.viewTab !== TabsName.MyProjects'  routerLink="/project-creation" class="left-tools btn btn-dark no-wrap">
          <i class="icon-plus fas fa-plus-square" aria-hidden="true"></i>Create new project
        </button>




      </div>

      <div class='flex-fill h-100 d-flex  flex-nowrap'>
        <div class='m-3 flex-fill scrollable'>

          <app-displayed-projects [displayedProjects]='projectManagerService.getDisplayedProjects()'
            [viewTab]='projectManagerService.getViewTab()'></app-displayed-projects>
        </div>
        <div *ngIf='projectManagerService.viewTab === TabsName.DeletedProjects'>
          <div class='buttons-right-container d-flex flex-nowrap'>
            <button (click)='projectManagerService.handleRestore()'
              [class.disabled]='projectManagerService.getBulkSelectionLength() ? false : true'
              class='m-2 btn btn-dark'><i class="far mx-2 fa-trash-undo-alt" aria-hidden="true"></i>
              Restore </button>
            <button (click)='projectManagerService.handlePermanentelyDelete()'
              [class.disabled]='projectManagerService.getBulkSelectionLength() ? false : true'
              class='m-2 btn btn-outline-dark'><i class="far mx-2 fa-trash-alt" aria-hidden="true"></i>
              Permanentely
              delete</button>
          </div>
        </div>


      </div>

      <div id="info-content" class="hidden-on-mobile" [class.active]="l.isProjectInfosModalOpen">
        <div *ngIf="l.isProjectInfosModalOpen" id="modalProjectsInfos">
          <div class="d-flex justify-content-end">
            <button type="button" class="close" aria-label="Close" (click)="l.close('modalProjectsInfos')">
              <span><i class="far fa-times" aria-hidden="true"></i></span>
            </button>
          </div>
          <div *ngIf="projectManagerService.itemsWithInfoDisplayed">
            <ul class="info-scroll">
              <app-info-project *ngFor="let item of projectManagerService.itemsWithInfoDisplayed" [item]="item">
              </app-info-project>
            </ul>
          </div>
        </div>
      </div>

      <div id="share-content" class="hidden-on-mobile" [class.active]="l.isProjectShareModalOpen">
        <div *ngIf="l.isProjectShareModalOpen" class='h-100 d-flex flex-column'>
          <div class="d-flex flex-column flex-fill" id="modalProjectsShare">
            <div class="d-flex justify-content-end">
              <button type="button" class="close" aria-label="Close" (click)="l.close('modalProjectsShare')">
                <span><i class="far fa-times" aria-hidden="true"></i></span>
              </button>
            </div>
            <div class='flex-fill overflow-hidden' *ngIf="projectManagerService.itemsWithInfoDisplayed">
              <ul class="info-scroll d-flex flex-column">
                <app-share-project class='flex-fill d-flex flex-column'
                  *ngFor="let item of projectManagerService.itemsWithInfoDisplayed" [item]="item">
                </app-share-project>
              </ul>
            </div>
          </div>
          <div class="btn-container">
            <div class="d-flex flex-nowrap">
              <button type="button" class="modal-btn btn btn-dark"
                (click)="l.close();shareService.resetNonSentInvitations()">
                Cancel
              </button>
              <button type="button" class="modal-btn btn btn-dark" [disabled]="!shareService.usersSelected.length"
                (click)="shareService.shareToUsers(ItemType.Project, projectManagerService.bulkSelection[0].id)">
                Confirm
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </app-content>


</div>