<div *ngIf="hasGroups.value" id='GROUPS' class='m-2 ' [class.footer-margin]='marginBottom'>
    <div>
        <div class="group-utility-bar rounded bg-dark">
            <div [style.height.px]="35" [style.lineHeight.px]="35" class="px-5 text-light font-weight-bold ">
              {{sharedWithMe ? 'File Groups shared with me': 'My File Groups'}} 
             </div>
        </div>
        <div>
            <div *ngFor="let group of getGroups(); trackBy: trackByFn; let i = index"
                class='project-list-container'>
                <group *ngIf='!group.capabilities.can_modify' (dragover)="groupsManagerService.allowDrop($event)" (drop)="impossibleDropErrorMessage($event)" [group]="group" [index]='i'>
                </group>
                <group *ngIf='group.capabilities.can_modify' (dragover)="groupsManagerService.allowDrop($event)"
                (drop)="groupsManagerService.getDragEndGroupTargetId(group.id)" [group]="group" [index]='i'>
            </group>
            </div>
        </div>
    </div>
</div>

<app-custom-modal *ngIf="!sharedWithMe" modalTitle="Enter a name for a new group" idName="modalGroup" [show]="layout.isGroupModalOpen"
    confirmTitle="Save" [confirmActions]="[groupsManagerService.handleNewGroup]" [confirmValidator]="
    groupsManagerService.groupCreationForm?.controls?.groupInput
      ? groupsManagerService.groupCreationForm.controls.groupInput.valid
      : false
  " [cancelActions]="[groupsManagerService.resetGroupCreationForm]">
    <div class="custom-modal-content">
        <app-group-creation-form></app-group-creation-form>
    </div>
</app-custom-modal>