<div class="list-group-item d-flex flex-column  scrollable h-100" [id]='item.id'>
  <app-rename *ngIf='fileManager.viewTab === TabsName.MyFiles' [renameFunction]='myFilesService.rename'
    class='h5 text-primary' [type]='ItemType.File' [item]='item' field='title'></app-rename>
  <app-rename *ngIf='fileManager.viewTab === TabsName.SharedFiles' [renameFunction]='sharedFilesService.rename'
    class='h5 text-primary' [type]='ItemType.File' [item]='item' field='title'></app-rename>

  <ul>
    <small>
      <li class='py-2 text-uppercase'><span>owner : </span><span class='font-weight-bold'>
          {{item.owner.name}}</span>
      </li>
    </small>
  </ul>
  <app-share [item]='item' [type]='ItemType.File'></app-share>
</div>