import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from '../../environments/environment';

interface Notification {
  body: string;
  date_of_creation: number;
  date_of_reading: number;
  id: string;
  links: { mark_as_read: string };
  read: boolean;
  resource_type: string;
  title: string;
  type: string;
}

interface NotificationResponse {
  count: number;
  data: Array<Notification>;
  has_more: boolean;
  links: { self: string; next?: string };
}

@Injectable({ providedIn: 'root' })
export class NotificationHttpService {
  protected _basePath = environment.apiBasePath
    ? environment.apiBasePath
    : 'http://localhost:8081';
  @LocalStorage() session;

  constructor(private http: HttpClient) { }

  getNotifications(): Observable<NotificationResponse> {
    let queryParameters = new HttpParams({});
    queryParameters = queryParameters.set('limit', 100 as any);
    return this.http.get<NotificationResponse>(
      `${this._basePath}/mas/notifs?sortBy=date_of_creation,desc`, {
        params: queryParameters,
      }
    );
  }
  markAllAsRead() {
    return this.http.post(`${this._basePath}/mas/notifs/all/read`, {});
  }
  markAsRead(notifId) {
    return this.http.post(`${this._basePath}/mas/notifs/${notifId}/read`, {});
  }
  markAllAsDismiss() {
    return this.http.post(`${this._basePath}/mas/notifs/all/dismiss`, {});
  }
  markAsDismiss(notifId) {
    return this.http.post(`${this._basePath}/mas/notifs/${notifId}/dismiss`, {});
  }
  notificationSocket(): WebSocketSubject<Notification> {
    const basePath = this.session.apiPath
      ? this.session.apiPath
      : this._basePath;
    return webSocket(
      `${basePath.replace('http', 'ws')}/mas/notifs/register?access_token=${this.session.accessToken
      }`
    );
  }
  get basePath() {
    return this._basePath;
  }
}
