import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { TeamsService, ModelTeamBody } from '../teams.service';
import { PagesName } from 'src/app/shared/helpers/pagesName';
import { LayoutOrganizationService } from 'src/app/api/layout-organization.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PermissionsHttpService } from 'src/app/shared/permissions-http-service/permissions-http.service';


export interface ModelTeamAndUserEmails {
  team: ModelTeamBody;
  user_emails: Array<string>;
}
export interface ModelExploitableTeam {
  name: string;
  id: string;
  user_ids: Array<string>;
  owner_user_ids: Array<string>
}
export interface ModelExploitableUser {
  email: string;
  id: string;
  role: string;
}

export class Users {
  constructor(rawTeam: ModelTeamAndUserEmails) {
    let output = []
    const array = _.get(rawTeam, 'team.user_ids')
    array.forEach((element, index) => {
      let user = {};
      _.set(user, 'id', element);
      _.set(user, 'email', _.nth(_.get(rawTeam, 'user_emails', []), index))
      if (_.includes(_.get(rawTeam, 'team.owner_user_ids', []), element)) {
        _.set(user, 'role', 'Team manager')
      } else {
        _.set(user, 'role', 'Member')
      }
      output.push(<ModelExploitableUser>user)
    });
    return output

  }

}
export class Team implements ModelExploitableTeam {
  name: string = '';
  id: string = '';
  user_ids: Array<string> = [];
  owner_user_ids: Array<string> = []
  constructor(rawTeam: ModelTeamAndUserEmails) {
    this.name = _.get(rawTeam, 'team.name', '');
    this.id = _.get(rawTeam, 'team._key', '');
    this.user_ids = _.get(rawTeam, 'team.user_ids', []);
    this.owner_user_ids = _.get(rawTeam, 'team.owner_user_ids', []);
  }
}
export class Body {
  constructor(rawBody: ModelTeamAndUserEmails) {
    return _.get(rawBody, 'team') as ModelTeamBody;
  }
}

@Component({
  selector: 'app-team-manager',
  templateUrl: './team-manager.component.html',
  styleUrls: ['../../../shared/layout/managers.scss', './team-manager.component.scss']
})
export class TeamManagerComponent implements OnInit, OnDestroy {
  id: string | null = null;
  team;
  users
  body
  selectedUser = new BehaviorSubject<any>(null)
  subscriptionEvent: Subscription;
  createUserPermission = false
  subscriptionTeam: Subscription;
  constructor(private permissions: PermissionsHttpService, public layout: LayoutOrganizationService, private router: Router, private route: ActivatedRoute, public teamsService: TeamsService) {
    this.route.params.subscribe(params => {
      _.set(this, 'id', params['id'])
      let self = this
      self.subscriptionEvent = self.teamsService.refreshTeamId.subscribe((res) => {
        if (res !== self.getId()) {
          return
        }
        self.retrieveTeam()
      })

      self.retrieveTeam()
    })

  }
  ngOnInit(): void {
    this.permissions.checkPermission({
      permissions: {
        createUser: {},
      },
    })
      .subscribe((res) => {
        _.set(this, 'createUserPermission', _.get(res, 'createUser'));
      });



  }
  retrieveTeam = () => {
    let self = this
    self.subscriptionTeam = this.teamsService.getTeam(self.getId()).subscribe((res) => {
      _.set(self, 'users', new Users(res))
      _.set(self, 'team', new Team(res))
      _.set(self, 'body', new Body(res))
    })
  }
  ngOnDestroy() {
    let self = this
    self.subscriptionEvent.unsubscribe()
    self.subscriptionTeam.unsubscribe()
  }

  handleChangePermission(user, role) {
    let newArray = _.get(this, 'body.owner_user_ids', [])
    if (user.role === role) {
      newArray = _.without(newArray, user.id)
    } else {
      newArray.push(user.id)
    }
    let newBody = _.cloneDeep(this.body)
    _.set(newBody, 'owner_user_ids', newArray)
    this.teamsService.updateTeam(this.getId(), newBody).subscribe(() => {
      this.teamsService.refreshTeamId.next(this.getId())
    },
      () => {
        this.layout.toast('<b>Error</b> while <b>updating</b> the user role in the team', null, 8000, '', 'danger')
      })
  }




  navigateToTeamsManager = () => {
    this.router.navigate([PagesName.TeamsManager]);
  }
  getName = () => {
    return _.get(this, 'team.name', '')
  }
  getUsers = () => {
    return _.get(this, 'users', [] as Array<ModelExploitableUser>)
  }
  getUserEmail = (user) => {
    return _.get(user, 'email', '')
  }
  getUserRole = (user) => {
    return _.get(user, 'role', '')
  }

  getUserId = (user) => {
    return _.get(user, 'id', '')
  }
  getId = () => {
    return _.get(this, 'id', '')
  }
  getHeaders = () => {
    return ['', 'Email', 'Role']
  }
  

  getNewMemberAdditionForm() {
    return _.get(this.teamsService, 'newMemberAdditionForm')
  }
  handleInvitation = () => {
    let body = this.teamsService.newMemberAdditionForm.value
    let country = _.get(body, 'file_hosting_country.value')
    _.set(body, 'file_hosting_country', country)
    _.set(body, 'ontological_domain', 'Human')

    this.teamsService.submitUser(body,country).subscribe((res) => {
      this.layout.toast('A invitation to join Mimsomic has been sucessfully sent to ' + body.email, null, 5000, '', 'success')
      let newUserId = _.get(res, "user_id")
      this.handleAddUserById(newUserId)

    }, (error) => {
      if (error.error.message === 'One account with that e-mail address already exists')
        this.layout.toast('One account with the email address <b>' + body.email + '</b> already exists', null, 5000, '', 'warning')
    }
    )

  }
  openUserCard = (user) => {
    this.layout.open('modalUserCard')

    this.selectedUser.next(user)
  }

  _handleAddUserById = () => {
    let self = this
    return this.handleAddUserById(self.teamsService.memberAdditionForm.controls['memberInput'].value)
  }
  handleAddUserById = (memberId) => {
    if (!_.includes(_.get(this, 'body.user_ids', []), memberId)) {
      let newBody = _.cloneDeep(this.body)
      newBody.user_ids.push(memberId)
      this.teamsService.updateTeam(this.id, newBody).subscribe(() => {
        this.layout.toast('Member <b>successfully added</b> to the team', null, 5000, '', 'success')
        this.teamsService.refreshTeamId.next(this.id)
      },
        () => {
          this.layout.toast('User <b>not found</b>', null, 5000, '', 'danger')

        })
    } else {
      this.layout.toast('Member <b>already part</b> of the team', null, 5000, '', 'warning')
    }
  }
  getIsNewMemberAdditionModalOpen(){
    return  _.get(this.layout, 'isNewMemberAdditionModalOpen', false)
  }
  getNewMemberAdditionFormValidity() {
    return _.get(this.teamsService, 'newMemberAdditionForm.status', false) === 'VALID'
  }

  handleAddUser = () => {
    this.layout.open('modalMemberAddition')
  }
  handleAddNewUser = () => {
    this.layout.open('modalNewMemberAddition')
  }
  trackFn(index, user) {
    return _.get(user.value, 'id') + _.get(user.value, 'role');
  }


}
