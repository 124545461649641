import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'bigNumber', pure: false })
export class BigNumberPipe implements PipeTransform {
  transform(value: string): string {
    const valueAsArray = _.words(value, /[^, ]+/g);
    valueAsArray.forEach((word, index) => {
      if (this.isNumber(word)) {
        valueAsArray[index] = this.numberWithCommas(word);
      }
    });
    return _.join(valueAsArray, ' ');
  }

  private numberWithCommas(value) {
    return value.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }
  private isNumber(value) {
    return !isNaN(parseFloat(value)) && !isNaN(value - 0);
  }
}
