import { Component } from '@angular/core';
import { Dashboard2Service } from './dashboard2.service';
@Component({
  selector: 'app-project-overview2',
  templateUrl: './project-overview2.component.html',
  styleUrls: ['./project-overview2.component.scss']
})

export class ProjectOverview2Component  {
  constructor(private dashboard2Service: Dashboard2Service) { 
    this.dashboard2Service.scatterGLPool = this.dashboard2Service.maxScatterGLPool;
  }
}
